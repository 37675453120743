<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_stockTransitionUserItem" />
      <!-- 検索項目 -->
      <v-form ref="form">
        <v-container fluid>
          <!-- １行目スタート -->
          <v-row class="search-row">
            <!-- ユーザー取引先 -->
            <div class="serch-textbox-useritemCd first-search-item" style="float: left">
              <v-autocomplete
                dense
                id="get-supplier"
                v-model="suppliersSelected"
                maxlength="100"
                :items="supplierList"
                :label="$t('label.lbl_userSupplier')"
                :rules="[rules.supplierRequired]"
                persistent-hint
                :hint="setSrhSupplier()"
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span></template
                >
              </v-autocomplete>
            </div>
            <span class="require" style="float: left">*</span>

            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!-- 基準日-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="referenceDate"
                maxlength="10"
                :label="$t('label.lbl_refernceDate')"
                @change="changeDate"
                class="txt-single date-style"
                :rules="[rules.yyyymmdd]"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>

            <div class="calender-btn-area">
              <!-- 基準日カレンダー-->
              <v-menu
                v-model="referenceDateMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="referenceDateCal"
                  @input="referenceDateMenu = false"
                  max="2099-12-31"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
                <!-- 前日-->
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                <!-- 次日-->
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!-- 表示期間ステータス-->
            <div class="search-autocomplete" style="float: left">
              <v-autocomplete
                dense
                v-model="displayPeriodSelected"
                maxlength="3"
                :items="displayPeriodList"
                :label="$t('label.lbl_display_period')"
                :rules="[rules.displayRequired]"
                class="txt-single"
                persistent-hint
                ><template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span></template
                >
              </v-autocomplete>
            </div>
            <span class="require" style="float: left">*</span>

            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!-- 推移ステータス-->
            <div class="search-autocomplete" style="float: left">
              <v-autocomplete
                dense
                v-model="transactionTypeSelected"
                maxlength="3"
                :items="transactionList"
                :label="$t('label.lbl_transition')"
                class="txt-single"
                persistent-hint
                ><template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span></template
                >
              </v-autocomplete>
            </div>
          </v-row>
          <v-row>
            <div class="search-row-exeBtn">
              <!--ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 計算ボタン -->
                <v-btn class="other-btn" @click="apigetCalculation()">{{
                  $t("btn.btn_calculation")
                }}</v-btn>
                <!-- 検索ボタン -->
                <v-btn
                  color="primary"
                  id="get-search"
                  class="api-btn"
                  @click="apigetStock('get-search')"
                  >{{ $t("btn.btn_search") }}</v-btn
                >
                <!--画面クリアボタン-->
                <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>
              <!--データ出力ボタン開始-->
              <div class="btn-search-area" style="float: right">
                <!-- データ出力ボタン -->
                <v-btn
                  color="primary"
                  id="get-outPut"
                  :disabled="outputBtnActive"
                  class="api-btn"
                  style="width: auto"
                  @click="outPutClick()"
                >
                  {{ $t("btn.btn_makerItemCsvOutput") }}
                </v-btn>
              </div>
              <!--データ出力ボタン終了-->
            </div>
          </v-row>

          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span>総件数：{{ totalCount }}件</span>
              </div>
              <!-- 検索時間表示部分 -->
              <div class="text-label" style="float: left">
                <span v-if="inputList.length != 0" class="nowTime">
                  （{{ today }} {{ nowTime }}時点）
                </span>
              </div>
              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  {{ $t("label.lbl_itemsPerPage") }}
                </div>
                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>
                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                    mandatory
                  >
                    <v-btn class="pasing-btn" value="100" @click="u_10_on()" small>
                      {{ 100 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="250" @click="u_10_clear(250)" small>
                      {{ 250 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="500" @click="u_10_clear(500)" small>
                      {{ 500 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
          <!-- 2行目ここまで -->
        </v-container>
      </v-form>
      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          class="tableData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :server-items-length="itemsPerPage"
          disable-filtering
          :hide-default-footer="true"
          height="100%"
        >
          <!-- 検索結果明細情報 -->
          <template v-slot:[`item.pulldown`]="{ item, index }">
            <v-container
              v-if="item.userFlg && item.itemFlg"
              fluid
              style="padding: 0px"
              :class="item.class"
            >
              <v-btn x-medium icon>
                <v-icon v-if="!item.isOpen" @click="toggleOpen(index)">mdi-plus</v-icon>
                <v-icon v-else @click="toggleClose(index)">mdi-minus</v-icon>
              </v-btn>
            </v-container>
            <v-container
              v-else-if="item.userFlg"
              fluid
              style="padding: 0px; width: 36px"
              :class="item.class"
            >
            </v-container>
          </template>
          <!-- ユーザー品番/品名 -->
          <template v-slot:[`item.userProductCnCd`]="{ item }">
            <v-row v-if="item.userFlg" :class="item.class">
              <v-col style="text-align: left; padding: 0 12px">
                <!-- ユーザー品番 -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-overflow" v-bind="attrs" v-on="on">
                      {{ item.userProductCnCd }}
                    </div>
                  </template>
                  <div>{{ item.userProductCnCd }}</div>
                </v-tooltip>
                <!-- ユーザー品番 -->
                <!-- ユーザー品名 -->
                <div v-if="item.userProductName != ''">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="text-overflow" v-bind="attrs" v-on="on">
                        {{ item.userProductName }}
                      </div>
                    </template>
                    <div>{{ item.userProductName }}</div>
                  </v-tooltip>
                </div>
                <div v-else>&nbsp;</div>
                <!-- ユーザー品名 -->
              </v-col>
            </v-row>
          </template>
          <!-- ユーザー品番/品名 -->
          <!-- 警告 -->
          <template v-slot:[`item.alert`]="{ item }">
            <v-row :class="item.class">
              <v-col style="font-weight: bold; color: #ff0000">
                <div>{{ item.alert }}</div>
              </v-col>
            </v-row>
          </template>
          <!-- 警告 -->
          <!-- メーカー品番/品名 -->
          <template v-slot:[`item.productCnCd`]="{ item }">
            <v-row :class="item.class">
              <v-col v-if="!item.userFlg" style="text-align: left; padding: 0 12px">
                <!-- メーカー品番 -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-overflow" v-bind="attrs" v-on="on">
                      {{ item.productCnCd }}
                    </div>
                  </template>
                  <div>{{ item.productCnCd }}</div>
                </v-tooltip>
                <!-- メーカー品番 -->
                <!-- メーカー品名 -->
                <div v-if="item.productName != ''">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="text-overflow" v-bind="attrs" v-on="on">
                        {{ item.productName }}
                      </div>
                    </template>
                    <div>{{ item.productName }}</div>
                  </v-tooltip>
                </div>
                <div v-else>&nbsp;</div>
                <!-- メーカー品名 -->
              </v-col>
              <v-col
                v-else-if="item.userFlg && !item.itemFlg"
                style="font-weight: bold; color: #ff0000"
              >
                <div>リンクなし</div>
              </v-col>
              <v-col v-else
                ><div>{{ item.productCnCd }}</div></v-col
              >
            </v-row>
          </template>
          <!-- メーカー品番/品名 -->
          <!-- 付帯品番 -->
          <template v-slot:[`item.accessoryProduct`]="{ item }">
            <v-row :class="item.class">
              <v-col style="text-align: left">
                <div>{{ item.accessoryProduct }}</div>
              </v-col>
            </v-row>
          </template>
          <!-- 付帯品番 -->
          <!-- 項目 -->
          <template v-slot:[`item.itemKbn`]="{ item }">
            <v-row :class="item.class">
              <v-col v-if="item.userFlg" style="text-align: left; padding: 0 12px">
                <!-- 搬計 -->
                <div>搬計</div>
                <!-- 搬計 -->
                <!-- 推移 -->
                <div>推移</div>
                <!-- 推移 -->
              </v-col>
            </v-row>
          </template>
          <!-- 項目 -->
          <!-- 基準日 -->
          <template v-slot:[`item.inScheduleQuantity00`]="{ item }">
            <v-row :class="item.class">
              <v-col style="text-align: right; padding: 0 12px">
                <!-- 基準日-入荷予定数 -->
                <div>{{ item.inScheduleQuantity00 }}</div>
                <!-- 基準日-入荷予定数 -->
                <!-- 基準日-在庫数 -->
                <div v-if="item.scheduleQuantitycheck00 >= 0">{{ item.scheduleQuantity00 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity00 }}</div>
                <!-- 基準日-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日 -->
          <!-- 基準日+1 -->
          <template v-slot:[`item.inScheduleQuantity01`]="{ item }">
            <v-row :class="item.class">
              <v-col style="text-align: right; padding: 0 12px">
                <!-- 基準日+1-入荷予定数 -->
                <div>{{ item.inScheduleQuantity01 }}</div>
                <!-- 基準日+1-入荷予定数 -->
                <!-- 基準日+1-在庫数 -->
                <div v-if="item.scheduleQuantitycheck01 >= 0">{{ item.scheduleQuantity01 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity01 }}</div>
                <!-- 基準日+1-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+1 -->
          <!-- 基準日+02 -->
          <template v-slot:[`item.inScheduleQuantity02`]="{ item }">
            <v-row :class="item.class">
              <v-col style="text-align: right; padding: 0 12px">
                <!-- 基準日+02-入荷予定数 -->
                <div>{{ item.inScheduleQuantity02 }}</div>
                <!-- 基準日+02-入荷予定数 -->
                <!-- 基準日+02-在庫数 -->
                <div v-if="item.scheduleQuantitycheck02 >= 0">{{ item.scheduleQuantity02 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity02 }}</div>
                <!-- 基準日+02-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+02 -->
          <!-- 基準日+03 -->
          <template v-slot:[`item.inScheduleQuantity03`]="{ item }">
            <v-row :class="item.class">
              <v-col style="text-align: right; padding: 0 12px">
                <!-- 基準日+03-入荷予定数 -->
                <div>{{ item.inScheduleQuantity03 }}</div>
                <!-- 基準日+03-入荷予定数 -->
                <!-- 基準日+03-在庫数 -->
                <div v-if="item.scheduleQuantitycheck03 >= 0">{{ item.scheduleQuantity03 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity03 }}</div>
                <!-- 基準日+03-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+03 -->
          <!-- 基準日+04 -->
          <template v-slot:[`item.inScheduleQuantity04`]="{ item }">
            <v-row :class="item.class">
              <v-col style="text-align: right; padding: 0 12px">
                <!-- 基準日+04-入荷予定数 -->
                <div>{{ item.inScheduleQuantity04 }}</div>
                <!-- 基準日+04-入荷予定数 -->
                <!-- 基準日+04-在庫数 -->
                <div v-if="item.scheduleQuantitycheck04 >= 0">{{ item.scheduleQuantity04 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity04 }}</div>
                <!-- 基準日+04-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+04 -->
          <!-- 基準日+05 -->
          <template v-slot:[`item.inScheduleQuantity05`]="{ item }">
            <v-row :class="item.class">
              <v-col style="text-align: right; padding: 0 12px">
                <!-- 基準日+05-入荷予定数 -->
                <div>{{ item.inScheduleQuantity05 }}</div>
                <!-- 基準日+05-入荷予定数 -->
                <!-- 基準日+05-在庫数 -->
                <div v-if="item.scheduleQuantitycheck05 >= 0">{{ item.scheduleQuantity05 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity05 }}</div>
                <!-- 基準日+05-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+05 -->
          <!-- 基準日+06 -->
          <template v-slot:[`item.inScheduleQuantity06`]="{ item }">
            <v-row :class="item.class">
              <v-col style="text-align: right; padding: 0 12px">
                <!-- 基準日+06-入荷予定数 -->
                <div>{{ item.inScheduleQuantity06 }}</div>
                <!-- 基準日+06-入荷予定数 -->
                <!-- 基準日+06-在庫数 -->
                <div v-if="item.scheduleQuantitycheck06 >= 0">{{ item.scheduleQuantity06 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity06 }}</div>
                <!-- 基準日+06-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+06 -->
          <!-- 基準日+07 -->
          <template v-slot:[`item.inScheduleQuantity07`]="{ item }">
            <v-row :class="item.class">
              <v-col style="text-align: right; padding: 0 12px">
                <!-- 基準日+07-入荷予定数 -->
                <div>{{ item.inScheduleQuantity07 }}</div>
                <!-- 基準日+07-入荷予定数 -->
                <!-- 基準日+07-在庫数 -->
                <div v-if="item.scheduleQuantitycheck07 >= 0">{{ item.scheduleQuantity07 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity07 }}</div>
                <!-- 基準日+07-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+07 -->
          <!-- 基準日+08 -->
          <template v-slot:[`item.inScheduleQuantity08`]="{ item }">
            <v-row :class="item.class">
              <v-col style="text-align: right; padding: 0 12px">
                <!-- 基準日+08-入荷予定数 -->
                <div>{{ item.inScheduleQuantity08 }}</div>
                <!-- 基準日+08-入荷予定数 -->
                <!-- 基準日+08-在庫数 -->
                <div v-if="item.scheduleQuantitycheck08 >= 0">{{ item.scheduleQuantity08 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity08 }}</div>
                <!-- 基準日+08-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+08 -->
          <!-- 基準日+09 -->
          <template v-slot:[`item.inScheduleQuantity09`]="{ item }">
            <v-row :class="item.class">
              <v-col style="text-align: right; padding: 0 12px">
                <!-- 基準日+09-入荷予定数 -->
                <div>{{ item.inScheduleQuantity09 }}</div>
                <!-- 基準日+09-入荷予定数 -->
                <!-- 基準日+09-在庫数 -->
                <div v-if="item.scheduleQuantitycheck09 >= 0">{{ item.scheduleQuantity09 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity09 }}</div>
                <!-- 基準日+09-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+09 -->
          <!-- 基準日+10 -->
          <template v-slot:[`item.inScheduleQuantity10`]="{ item }">
            <v-row :class="item.class">
              <v-col style="text-align: right; padding: 0 12px">
                <!-- 基準日+10-入荷予定数 -->
                <div>{{ item.inScheduleQuantity10 }}</div>
                <!-- 基準日+10-入荷予定数 -->
                <!-- 基準日+10-在庫数 -->
                <div v-if="item.scheduleQuantitycheck10 >= 0">{{ item.scheduleQuantity10 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity10 }}</div>
                <!-- 基準日+10-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+10 -->
          <!-- 基準日+11 -->
          <template v-slot:[`item.inScheduleQuantity11`]="{ item }">
            <v-row :class="item.class">
              <v-col style="text-align: right; padding: 0 12px">
                <!-- 基準日+11-入荷予定数 -->
                <div>{{ item.inScheduleQuantity11 }}</div>
                <!-- 基準日+11-入荷予定数 -->
                <!-- 基準日+11-在庫数 -->
                <div v-if="item.scheduleQuantitycheck11 >= 0">{{ item.scheduleQuantity11 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity11 }}</div>
                <!-- 基準日+11-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+11 -->
          <!-- 基準日+12 -->
          <template v-slot:[`item.inScheduleQuantity12`]="{ item }">
            <v-row :class="item.class">
              <v-col style="text-align: right; padding: 0 12px">
                <!-- 基準日+12-入荷予定数 -->
                <div>{{ item.inScheduleQuantity12 }}</div>
                <!-- 基準日+12-入荷予定数 -->
                <!-- 基準日+12-在庫数 -->
                <div v-if="item.scheduleQuantitycheck12 >= 0">{{ item.scheduleQuantity12 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity12 }}</div>
                <!-- 基準日+12-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+12 -->
          <!-- 基準日+13 -->
          <template v-slot:[`item.inScheduleQuantity13`]="{ item }">
            <v-row :class="item.class">
              <v-col style="text-align: right; padding: 0 12px">
                <!-- 基準日+13-入荷予定数 -->
                <div>{{ item.inScheduleQuantity13 }}</div>
                <!-- 基準日+13-入荷予定数 -->
                <!-- 基準日+13-在庫数 -->
                <div v-if="item.scheduleQuantitycheck13 >= 0">{{ item.scheduleQuantity13 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity13 }}</div>
                <!-- 基準日+13-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+13 -->
          <!-- 基準日+14 -->
          <template v-slot:[`item.inScheduleQuantity14`]="{ item }">
            <v-row :class="item.class">
              <v-col style="text-align: right; padding: 0 12px">
                <!-- 基準日+14-入荷予定数 -->
                <div>{{ item.inScheduleQuantity14 }}</div>
                <!-- 基準日+14-入荷予定数 -->
                <!-- 基準日+14-在庫数 -->
                <div v-if="item.scheduleQuantitycheck14 >= 0">{{ item.scheduleQuantity14 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity14 }}</div>
                <!-- 基準日+14-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+14 -->
          <!-- 基準日+15 -->
          <template v-slot:[`item.inScheduleQuantity15`]="{ item }">
            <v-row :class="item.class">
              <v-col style="text-align: right; padding: 0 12px">
                <!-- 基準日+15-入荷予定数 -->
                <div>{{ item.inScheduleQuantity15 }}</div>
                <!-- 基準日+15-入荷予定数 -->
                <!-- 基準日+15-在庫数 -->
                <div v-if="item.scheduleQuantitycheck15 >= 0">{{ item.scheduleQuantity15 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity15 }}</div>
                <!-- 基準日+15-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+15 -->
          <!-- 基準日+16 -->
          <template v-slot:[`item.inScheduleQuantity16`]="{ item }">
            <v-row :class="item.class">
              <v-col style="text-align: right; padding: 0 12px">
                <!-- 基準日+16-入荷予定数 -->
                <div>{{ item.inScheduleQuantity16 }}</div>
                <!-- 基準日+16-入荷予定数 -->
                <!-- 基準日+16-在庫数 -->
                <div v-if="item.scheduleQuantitycheck16 >= 0">{{ item.scheduleQuantity16 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity16 }}</div>
                <!-- 基準日+16-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+16 -->
          <!-- 基準日+17 -->
          <template v-slot:[`item.inScheduleQuantity17`]="{ item }">
            <v-row :class="item.class">
              <v-col style="text-align: right; padding: 0 12px">
                <!-- 基準日+17-入荷予定数 -->
                <div>{{ item.inScheduleQuantity17 }}</div>
                <!-- 基準日+17-入荷予定数 -->
                <!-- 基準日+17-在庫数 -->
                <div v-if="item.scheduleQuantitycheck17 >= 0">{{ item.scheduleQuantity17 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity17 }}</div>
                <!-- 基準日+17-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+17 -->
          <!-- 基準日+18 -->
          <template v-slot:[`item.inScheduleQuantity18`]="{ item }">
            <v-row :class="item.class">
              <v-col style="text-align: right; padding: 0 12px">
                <!-- 基準日+18-入荷予定数 -->
                <div>{{ item.inScheduleQuantity18 }}</div>
                <!-- 基準日+18-入荷予定数 -->
                <!-- 基準日+18-在庫数 -->
                <div v-if="item.scheduleQuantitycheck18 >= 0">{{ item.scheduleQuantity18 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity18 }}</div>
                <!-- 基準日+18-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+18 -->
          <!-- 基準日+19 -->
          <template v-slot:[`item.inScheduleQuantity19`]="{ item }">
            <v-row :class="item.class">
              <v-col style="text-align: right; padding: 0 12px">
                <!-- 基準日+19-入荷予定数 -->
                <div>{{ item.inScheduleQuantity19 }}</div>
                <!-- 基準日+19-入荷予定数 -->
                <!-- 基準日+19-在庫数 -->
                <div v-if="item.scheduleQuantitycheck19 >= 0">{{ item.scheduleQuantity19 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity19 }}</div>
                <!-- 基準日+19-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+19 -->
          <!-- 基準日+20 -->
          <template v-slot:[`item.inScheduleQuantity20`]="{ item }">
            <v-row :class="item.class">
              <v-col style="text-align: right; padding: 0 12px">
                <!-- 基準日+20-入荷予定数 -->
                <div>{{ item.inScheduleQuantity20 }}</div>
                <!-- 基準日+20-入荷予定数 -->
                <!-- 基準日+20-在庫数 -->
                <div v-if="item.scheduleQuantitycheck20 >= 0">{{ item.scheduleQuantity20 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity20 }}</div>
                <!-- 基準日+20-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+20 -->
          <!-- 基準日+21 -->
          <template v-slot:[`item.inScheduleQuantity21`]="{ item }">
            <v-row :class="item.class">
              <v-col style="text-align: right; padding: 0 12px">
                <!-- 基準日+21-入荷予定数 -->
                <div>{{ item.inScheduleQuantity21 }}</div>
                <!-- 基準日+21-入荷予定数 -->
                <!-- 基準日+21-在庫数 -->
                <div v-if="item.scheduleQuantitycheck21 >= 0">{{ item.scheduleQuantity21 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity21 }}</div>
                <!-- 基準日+21-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+21 -->
          <!-- 基準日+22 -->
          <template v-slot:[`item.inScheduleQuantity22`]="{ item }">
            <v-row :class="item.class">
              <v-col style="text-align: right; padding: 0 12px">
                <!-- 基準日+22-入荷予定数 -->
                <div>{{ item.inScheduleQuantity22 }}</div>
                <!-- 基準日+22-入荷予定数 -->
                <!-- 基準日+22-在庫数 -->
                <div v-if="item.scheduleQuantitycheck22 >= 0">{{ item.scheduleQuantity22 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity22 }}</div>
                <!-- 基準日+22-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+22 -->
          <!-- 基準日+23 -->
          <template v-slot:[`item.inScheduleQuantity23`]="{ item }">
            <v-row :class="item.class">
              <v-col style="text-align: right; padding: 0 12px">
                <!-- 基準日+23-入荷予定数 -->
                <div>{{ item.inScheduleQuantity23 }}</div>
                <!-- 基準日+23-入荷予定数 -->
                <!-- 基準日+23-在庫数 -->
                <div v-if="item.scheduleQuantitycheck23 >= 0">{{ item.scheduleQuantity23 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity23 }}</div>
                <!-- 基準日+23-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+23 -->
          <!-- 基準日+24 -->
          <template v-slot:[`item.inScheduleQuantity24`]="{ item }">
            <v-row :class="item.class">
              <v-col style="text-align: right; padding: 0 12px">
                <!-- 基準日+24-入荷予定数 -->
                <div>{{ item.inScheduleQuantity24 }}</div>
                <!-- 基準日+24-入荷予定数 -->
                <!-- 基準日+24-在庫数 -->
                <div v-if="item.scheduleQuantitycheck24 >= 0">{{ item.scheduleQuantity24 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity24 }}</div>
                <!-- 基準日+24-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+24 -->
          <!-- 基準日+25 -->
          <template v-slot:[`item.inScheduleQuantity25`]="{ item }">
            <v-row :class="item.class">
              <v-col style="text-align: right; padding: 0 12px">
                <!-- 基準日+25-入荷予定数 -->
                <div>{{ item.inScheduleQuantity25 }}</div>
                <!-- 基準日+25-入荷予定数 -->
                <!-- 基準日+25-在庫数 -->
                <div v-if="item.scheduleQuantitycheck25 >= 0">{{ item.scheduleQuantity25 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity25 }}</div>
                <!-- 基準日+25-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+25 -->
          <!-- 基準日+26 -->
          <template v-slot:[`item.inScheduleQuantity26`]="{ item }">
            <v-row :class="item.class">
              <v-col style="text-align: right; padding: 0 12px">
                <!-- 基準日+26-入荷予定数 -->
                <div>{{ item.inScheduleQuantity26 }}</div>
                <!-- 基準日+26-入荷予定数 -->
                <!-- 基準日+26-在庫数 -->
                <div v-if="item.scheduleQuantitycheck26 >= 0">{{ item.scheduleQuantity26 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity26 }}</div>
                <!-- 基準日+26-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+26 -->
          <!-- 基準日+27 -->
          <template v-slot:[`item.inScheduleQuantity27`]="{ item }">
            <v-row :class="item.class">
              <v-col style="text-align: right; padding: 0 12px">
                <!-- 基準日+27-入荷予定数 -->
                <div>{{ item.inScheduleQuantity27 }}</div>
                <!-- 基準日+27-入荷予定数 -->
                <!-- 基準日+27-在庫数 -->
                <div v-if="item.scheduleQuantitycheck27 >= 0">{{ item.scheduleQuantity27 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity27 }}</div>
                <!-- 基準日+27-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+27 -->
          <!-- 基準日+28 -->
          <template v-slot:[`item.inScheduleQuantity28`]="{ item }">
            <v-row :class="item.class">
              <v-col style="text-align: right; padding: 0 12px">
                <!-- 基準日+28-入荷予定数 -->
                <div>{{ item.inScheduleQuantity28 }}</div>
                <!-- 基準日+28-入荷予定数 -->
                <!-- 基準日+28-在庫数 -->
                <div v-if="item.scheduleQuantitycheck28 >= 0">{{ item.scheduleQuantity28 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity28 }}</div>
                <!-- 基準日+28-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+28 -->
          <!-- 基準日+29 -->
          <template v-slot:[`item.inScheduleQuantity29`]="{ item }">
            <v-row :class="item.class">
              <v-col style="text-align: right; padding: 0 12px">
                <!-- 基準日+29-入荷予定数 -->
                <div>{{ item.inScheduleQuantity29 }}</div>
                <!-- 基準日+29-入荷予定数 -->
                <!-- 基準日+29-在庫数 -->
                <div v-if="item.scheduleQuantitycheck29 >= 0">{{ item.scheduleQuantity29 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity29 }}</div>
                <!-- 基準日+29-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+29 -->
          <!-- 基準日+30 -->
          <template v-slot:[`item.inScheduleQuantity30`]="{ item }">
            <v-row :class="item.class">
              <v-col style="text-align: right; padding: 0 12px">
                <!-- 基準日+30-入荷予定数 -->
                <div>{{ item.inScheduleQuantity30 }}</div>
                <!-- 基準日+30-入荷予定数 -->
                <!-- 基準日+30-在庫数 -->
                <div v-if="item.scheduleQuantitycheck30 >= 0">{{ item.scheduleQuantity30 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity30 }}</div>
                <!-- 基準日+30-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+30 -->
          <!-- 検索結果明細情報 -->
        </v-data-table>

        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
      </v-container>
      <!-- ナビゲーション -->
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <!-- 警告ダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :changeFlag="confirmDialog.changeFlag"
      />
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :homePageFlag="infoDialog.homePageFlag"
      />
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { i18n } from "../../lang/lang.js";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import NavBar from "../../components/NavBar.vue";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";
import ConfirmDialog from "@/components/ConfirmDialog";
import SimpleDialog from "@/components/SimpleDialog";

export default {
  name: appConfig.SCREEN_ID.P_SRG_004,
  components: {
    Loading,
    sideMenu,
    NavBar,
    ConfirmDialog,
    SimpleDialog,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    u_10: false,
    rules: {
      supplierRequired: (value) => !!value || i18n.tc("check.chk_input"),
      displayRequired: (value) => !!value || i18n.tc("check.chk_select_displayPeriod"),
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      dataFormatRules: (value) => !!value || i18n.tc("check.chk_outputFormatDiv"),
      outPutPatternRules: (value) => !!value || i18n.tc("check.chk_outputPattern"),
    },
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: true,
    },
    // 確認メッセージ
    confirmDialog: {
      isOpen: false,
      message: "",
      redMessage: "",
      okAction: () => {},
    },
    calculationCount: 0,
    // 出力ボタンダイアログ
    updateDialogMessage: false,
    // 出力ボタンダイアログメッセージ
    backMessage: "",
    // 検索時点の日時
    year: "",
    today: "",
    nowTime: "",
    search: "",
    // ページング
    itemsPerPageList: [100, 250, 500],
    page: 1,
    pageCount: 0,
    totalCount: 0,
    itemsPerPage: 500,
    itemsPerPageBtn: "500",
    // 選択された取引先
    suppliersSelected: "",
    // 選択された推移
    transactionTypeSelected: "",
    //選択された表示期間
    displayPeriodSelected: "",
    //データ出力時ダイアログ(初期値)
    dataOutPutDialog: false,
    //選択されたデータ形式
    dataFormatSelected: "01",
    //出力単位
    outPutPatternSelected: "01",
    // メニュー
    openMenu: null,
    // 詳細ポップアップ
    dialogDetails: false,
    // データ出力ボタン活性,非活性
    outputBtnActive: true,
    // ソート
    sortItem: "",
    // 初期データ
    defaultData: [],
    /* 検索条件保持用 */
    suppliersSelectedSearch: "",
    referenceDateCalSerch: "",
    transactionTypeSelectedSearch: "",
    displayPeriodSelectedSearch: "",
    /* 検索条件保持用 */

    // ヘッダ
    headerItems: [],
    inputList: [],
    // 基準日カレンダー
    referenceDateCal: "",
    // 基準日
    referenceDate: "",
    referenceDateMenu: false,
    // 取引先プルダウン
    supplierList: [],
    //データ形式プルダウン
    dataFormatList: [],
    //出力単位プルダウン
    outPutPatternList: [],
    //表示期間プルダウン
    displayPeriodList: [],
    //推移プルダウン
    transactionList: [],
    //表記機関初期表示情報
    displayPeriodLnitial: [],
    suppliersSelectedName: "",
    qualityDiv: "",
    itemName: "",
    itemStandardSid: "",
    // 品番品名プルダウン
    productCodeList: [],
    //ピンチ以外用
    input_data: {
      isOpen: false,
      userProductCnCd: "76640-T60 -T010-M1-02",
      userProductName: "ARM&BLADE,WSHLD(RH-DR)",
      accessoryProduct: "",
      userProductCnCode: "76640-T60 -T010-M1-02",
      productCnCode: "",
      inScheduleQuantity00: "400",
      inScheduleQuantity01: "400",
      inScheduleQuantity02: "400",
      inScheduleQuantity03: "400",
      inScheduleQuantity04: "400",
      inScheduleQuantity05: "0",
      inScheduleQuantity06: "0",
      inScheduleQuantity07: "400",
      inScheduleQuantity08: "400",
      inScheduleQuantity09: "400",
      inScheduleQuantity10: "400",
      inScheduleQuantity11: "400",
      inScheduleQuantity12: "400",
      inScheduleQuantity13: "0",
      inScheduleQuantity14: "0",
      inScheduleQuantity15: "0",
      inScheduleQuantity16: "0",
      inScheduleQuantity17: "0",
      inScheduleQuantity18: "0",
      inScheduleQuantity19: "0",
      inScheduleQuantity20: "0",
      inScheduleQuantity21: "0",
      inScheduleQuantity22: "0",
      inScheduleQuantity23: "0",
      inScheduleQuantity24: "0",
      inScheduleQuantity25: "0",
      inScheduleQuantity26: "0",
      inScheduleQuantity27: "0",
      inScheduleQuantity28: "0",
      inScheduleQuantity29: "0",
      inScheduleQuantity30: "0",
      scheduleQuantitycheck00: 0,
      scheduleQuantitycheck01: 0,
      scheduleQuantitycheck02: 0,
      scheduleQuantitycheck03: 0,
      scheduleQuantitycheck04: 0,
      scheduleQuantitycheck05: 0,
      scheduleQuantitycheck06: 0,
      scheduleQuantitycheck07: 0,
      scheduleQuantitycheck08: 0,
      scheduleQuantitycheck09: 0,
      scheduleQuantitycheck10: 0,
      scheduleQuantitycheck11: 0,
      scheduleQuantitycheck12: 0,
      scheduleQuantitycheck13: 0,
      scheduleQuantitycheck14: 0,
      scheduleQuantitycheck15: 0,
      scheduleQuantitycheck16: 0,
      scheduleQuantitycheck17: 0,
      scheduleQuantitycheck18: 0,
      scheduleQuantitycheck19: 0,
      scheduleQuantitycheck20: 0,
      scheduleQuantitycheck21: 0,
      scheduleQuantitycheck22: 0,
      scheduleQuantitycheck23: 0,
      scheduleQuantitycheck24: 0,
      scheduleQuantitycheck25: 0,
      scheduleQuantitycheck26: 0,
      scheduleQuantitycheck27: 0,
      scheduleQuantitycheck28: 0,
      scheduleQuantitycheck29: 0,
      scheduleQuantitycheck30: 0,
      scheduleQuantity00: " ",
      scheduleQuantity01: " ",
      scheduleQuantity02: " ",
      scheduleQuantity03: " ",
      scheduleQuantity04: " ",
      scheduleQuantity05: " ",
      scheduleQuantity06: " ",
      scheduleQuantity07: " ",
      scheduleQuantity08: " ",
      scheduleQuantity09: " ",
      scheduleQuantity10: " ",
      scheduleQuantity11: " ",
      scheduleQuantity12: " ",
      scheduleQuantity13: " ",
      scheduleQuantity14: " ",
      scheduleQuantity15: " ",
      scheduleQuantity16: " ",
      scheduleQuantity17: " ",
      scheduleQuantity18: " ",
      scheduleQuantity19: " ",
      scheduleQuantity20: " ",
      scheduleQuantity21: " ",
      scheduleQuantity22: " ",
      scheduleQuantity23: " ",
      scheduleQuantity24: " ",
      scheduleQuantity25: " ",
      scheduleQuantity26: " ",
      scheduleQuantity27: " ",
      scheduleQuantity28: " ",
      scheduleQuantity29: " ",
      scheduleQuantity30: " ",
      userFlg: true,
      class: "user-head",
    },
    input_data1: {
      isOpen: false,
      userProductCnCd: "76640-T60 -T010-M1-02",
      userProductName: "ARM&BLADE,WSHLD(RH-DR)",
      accessoryProduct: "",
      productCnCd: "76640-T60 T010-M1-02",
      productName: "ARM&BLADE,WSHLD(RH-DR)",
      userProductCnCode: "76640-T60 -T010-M1-02",
      productCnCode: "76640-T60 T010-M1-02",
      inScheduleQuantity00: "400",
      inScheduleQuantity01: "400",
      inScheduleQuantity02: "400",
      inScheduleQuantity03: "400",
      inScheduleQuantity04: "400",
      inScheduleQuantity07: "400",
      inScheduleQuantity08: "400",
      inScheduleQuantity09: "400",
      inScheduleQuantity10: "400",
      inScheduleQuantity11: "400",
      inScheduleQuantity12: "400",
      scheduleQuantitycheck00: 0,
      scheduleQuantitycheck01: 0,
      scheduleQuantitycheck02: 0,
      scheduleQuantitycheck03: 0,
      scheduleQuantitycheck04: 0,
      scheduleQuantitycheck05: 0,
      scheduleQuantitycheck06: 0,
      scheduleQuantitycheck07: 0,
      scheduleQuantitycheck08: 0,
      scheduleQuantitycheck09: 0,
      scheduleQuantitycheck10: 0,
      scheduleQuantitycheck11: 0,
      scheduleQuantitycheck12: 0,
      scheduleQuantitycheck13: 0,
      scheduleQuantitycheck14: 0,
      scheduleQuantitycheck15: 0,
      scheduleQuantitycheck16: 0,
      scheduleQuantitycheck17: 0,
      scheduleQuantitycheck18: 0,
      scheduleQuantitycheck19: 0,
      scheduleQuantitycheck20: 0,
      scheduleQuantitycheck21: 0,
      scheduleQuantitycheck22: 0,
      scheduleQuantitycheck23: 0,
      scheduleQuantitycheck24: 0,
      scheduleQuantitycheck25: 0,
      scheduleQuantitycheck26: 0,
      scheduleQuantitycheck27: 0,
      scheduleQuantitycheck28: 0,
      scheduleQuantitycheck29: 0,
      scheduleQuantitycheck30: 0,
      userFlg: false,
      class: "",
    },
    dummy_input: {
      isOpen: false,
      userProductCnCd: "57110-32R -J060-M1",
      userProductName: "MODULATOR ASSY VSA",
      accessoryProduct: "",
      userProductCnCode: "57110-32R -J060-M1",
      productCnCode: "",
      inScheduleQuantity00: "100",
      inScheduleQuantity01: "100",
      inScheduleQuantity02: "50",
      inScheduleQuantity03: "0",
      inScheduleQuantity04: "200",
      inScheduleQuantity05: "100",
      inScheduleQuantity06: "100",
      inScheduleQuantity07: "0",
      inScheduleQuantity08: "0",
      inScheduleQuantity09: "0",
      inScheduleQuantity10: "0",
      inScheduleQuantity11: "0",
      inScheduleQuantity12: "0",
      inScheduleQuantity13: "0",
      inScheduleQuantity14: "0",
      inScheduleQuantity15: "0",
      inScheduleQuantity16: "0",
      inScheduleQuantity17: "0",
      inScheduleQuantity18: "0",
      inScheduleQuantity19: "0",
      inScheduleQuantity20: "0",
      inScheduleQuantity21: "0",
      inScheduleQuantity22: "0",
      inScheduleQuantity23: "0",
      inScheduleQuantity24: "0",
      inScheduleQuantity25: "0",
      inScheduleQuantity26: "0",
      inScheduleQuantity27: "0",
      inScheduleQuantity28: "0",
      inScheduleQuantity29: "0",
      inScheduleQuantity30: "0",
      scheduleQuantitycheck00: 0,
      scheduleQuantitycheck01: -100,
      scheduleQuantitycheck02: -150,
      scheduleQuantitycheck03: -150,
      scheduleQuantitycheck04: -350,
      scheduleQuantitycheck05: -450,
      scheduleQuantitycheck06: -450,
      scheduleQuantitycheck07: -450,
      scheduleQuantitycheck08: -450,
      scheduleQuantitycheck09: -450,
      scheduleQuantitycheck10: -450,
      scheduleQuantitycheck11: -450,
      scheduleQuantitycheck12: -450,
      scheduleQuantitycheck13: -450,
      scheduleQuantitycheck14: -450,
      scheduleQuantitycheck15: -450,
      scheduleQuantitycheck16: -450,
      scheduleQuantitycheck17: -450,
      scheduleQuantitycheck18: -450,
      scheduleQuantitycheck19: -450,
      scheduleQuantitycheck20: -450,
      scheduleQuantitycheck21: -450,
      scheduleQuantitycheck22: -450,
      scheduleQuantitycheck23: -450,
      scheduleQuantitycheck24: -450,
      scheduleQuantitycheck25: -450,
      scheduleQuantitycheck26: -450,
      scheduleQuantitycheck27: -450,
      scheduleQuantitycheck28: -450,
      scheduleQuantitycheck29: -450,
      scheduleQuantitycheck30: -450,
      scheduleQuantity00: " ",
      scheduleQuantity01: "-100",
      scheduleQuantity02: "-150",
      scheduleQuantity03: "-150",
      scheduleQuantity04: "-350",
      scheduleQuantity05: "-450",
      scheduleQuantity06: "-450",
      scheduleQuantity07: "-450",
      scheduleQuantity08: "-450",
      scheduleQuantity09: "-450",
      scheduleQuantity10: "-450",
      scheduleQuantity11: "-450",
      scheduleQuantity12: "-450",
      scheduleQuantity13: "-450",
      scheduleQuantity14: "-450",
      scheduleQuantity15: "-450",
      scheduleQuantity16: "-450",
      scheduleQuantity17: "-450",
      scheduleQuantity18: "-450",
      scheduleQuantity19: "-450",
      scheduleQuantity20: "-450",
      scheduleQuantity21: "-450",
      scheduleQuantity22: "-450",
      scheduleQuantity23: "-450",
      scheduleQuantity24: "-450",
      scheduleQuantity25: "-450",
      scheduleQuantity26: "-450",
      scheduleQuantity27: "-450",
      scheduleQuantity28: "-450",
      scheduleQuantity29: "-450",
      scheduleQuantity30: "-450",
      userFlg: true,
      class: "user-head",
    },
    dummy_input1: {
      isOpen: false,
      userProductCnCd: "36160-TXA -J130-M1",
      userProductName: "CAMERA ASSY,MONOCULAR",
      accessoryProduct: "",
      userProductCnCode: "36160-TXA -J130-M1",
      productCnCode: "",
      inScheduleQuantity00: "100",
      inScheduleQuantity01: "0",
      inScheduleQuantity02: "100",
      inScheduleQuantity03: "200",
      inScheduleQuantity04: "0",
      inScheduleQuantity05: "50",
      inScheduleQuantity06: "100",
      inScheduleQuantity07: "200",
      inScheduleQuantity08: "0",
      inScheduleQuantity09: "0",
      inScheduleQuantity10: "0",
      inScheduleQuantity11: "0",
      inScheduleQuantity12: "0",
      inScheduleQuantity13: "0",
      inScheduleQuantity14: "0",
      inScheduleQuantity15: "0",
      inScheduleQuantity16: "0",
      inScheduleQuantity17: "0",
      inScheduleQuantity18: "0",
      inScheduleQuantity19: "0",
      inScheduleQuantity20: "0",
      inScheduleQuantity21: "0",
      inScheduleQuantity22: "0",
      inScheduleQuantity23: "0",
      inScheduleQuantity24: "0",
      inScheduleQuantity25: "0",
      inScheduleQuantity26: "0",
      inScheduleQuantity27: "0",
      inScheduleQuantity28: "0",
      inScheduleQuantity29: "0",
      inScheduleQuantity30: "0",
      scheduleQuantitycheck00: 0,
      scheduleQuantitycheck01: 0,
      scheduleQuantitycheck02: 0,
      scheduleQuantitycheck03: 0,
      scheduleQuantitycheck04: 0,
      scheduleQuantitycheck05: 0,
      scheduleQuantitycheck06: -100,
      scheduleQuantitycheck07: -300,
      scheduleQuantitycheck08: -300,
      scheduleQuantitycheck09: -300,
      scheduleQuantitycheck10: -300,
      scheduleQuantitycheck11: -300,
      scheduleQuantitycheck12: -300,
      scheduleQuantitycheck13: -300,
      scheduleQuantitycheck14: -300,
      scheduleQuantitycheck15: -300,
      scheduleQuantitycheck16: -300,
      scheduleQuantitycheck17: -300,
      scheduleQuantitycheck18: -300,
      scheduleQuantitycheck19: -300,
      scheduleQuantitycheck20: -300,
      scheduleQuantitycheck21: -300,
      scheduleQuantitycheck22: -300,
      scheduleQuantitycheck23: -300,
      scheduleQuantitycheck24: -300,
      scheduleQuantitycheck25: -300,
      scheduleQuantitycheck26: -300,
      scheduleQuantitycheck27: -300,
      scheduleQuantitycheck28: -300,
      scheduleQuantitycheck29: -300,
      scheduleQuantitycheck30: -300,
      scheduleQuantity00: " ",
      scheduleQuantity01: " ",
      scheduleQuantity02: " ",
      scheduleQuantity03: " ",
      scheduleQuantity04: " ",
      scheduleQuantity05: " ",
      scheduleQuantity06: "-100",
      scheduleQuantity07: "-300",
      scheduleQuantity08: "-300",
      scheduleQuantity09: "-300",
      scheduleQuantity10: "-300",
      scheduleQuantity11: "-300",
      scheduleQuantity12: "-300",
      scheduleQuantity13: "-300",
      scheduleQuantity14: "-300",
      scheduleQuantity15: "-300",
      scheduleQuantity16: "-300",
      scheduleQuantity17: "-300",
      scheduleQuantity18: "-300",
      scheduleQuantity19: "-300",
      scheduleQuantity20: "-300",
      scheduleQuantity21: "-300",
      scheduleQuantity22: "-300",
      scheduleQuantity23: "-300",
      scheduleQuantity24: "-300",
      scheduleQuantity25: "-300",
      scheduleQuantity26: "-300",
      scheduleQuantity27: "-300",
      scheduleQuantity28: "-300",
      scheduleQuantity29: "-300",
      scheduleQuantity30: "-300",
      userFlg: true,
      class: "user-head",
    },
    dummy_input2: {
      isOpen: false,
      userProductCnCd: "39680-T0A -R420-M1",
      userProductName: "SENSOR ASSY, PARKING",
      accessoryProduct: "NH830M",
      userProductCnCode: "39680-T0A -R420-M1 NH830M",
      productCnCode: "",
      inScheduleQuantity00: "100",
      inScheduleQuantity01: "100",
      inScheduleQuantity02: "50",
      inScheduleQuantity03: "0",
      inScheduleQuantity04: "200",
      inScheduleQuantity05: "100",
      inScheduleQuantity06: "100",
      inScheduleQuantity07: "0",
      inScheduleQuantity08: "0",
      inScheduleQuantity09: "0",
      inScheduleQuantity10: "0",
      inScheduleQuantity11: "0",
      inScheduleQuantity12: "0",
      inScheduleQuantity13: "0",
      inScheduleQuantity14: "0",
      inScheduleQuantity15: "0",
      inScheduleQuantity16: "0",
      inScheduleQuantity17: "0",
      inScheduleQuantity18: "0",
      inScheduleQuantity19: "0",
      inScheduleQuantity20: "0",
      inScheduleQuantity21: "0",
      inScheduleQuantity22: "0",
      inScheduleQuantity23: "0",
      inScheduleQuantity24: "0",
      inScheduleQuantity25: "0",
      inScheduleQuantity26: "0",
      inScheduleQuantity27: "0",
      inScheduleQuantity28: "0",
      inScheduleQuantity29: "0",
      inScheduleQuantity30: "0",
      scheduleQuantitycheck00: 0,
      scheduleQuantitycheck01: -100,
      scheduleQuantitycheck02: -150,
      scheduleQuantitycheck03: -150,
      scheduleQuantitycheck04: -350,
      scheduleQuantitycheck05: -450,
      scheduleQuantitycheck06: -450,
      scheduleQuantitycheck07: -450,
      scheduleQuantitycheck08: -450,
      scheduleQuantitycheck09: -450,
      scheduleQuantitycheck10: -450,
      scheduleQuantitycheck11: -450,
      scheduleQuantitycheck12: -450,
      scheduleQuantitycheck13: -450,
      scheduleQuantitycheck14: -450,
      scheduleQuantitycheck15: -450,
      scheduleQuantitycheck16: -450,
      scheduleQuantitycheck17: -450,
      scheduleQuantitycheck18: -450,
      scheduleQuantitycheck19: -450,
      scheduleQuantitycheck20: -450,
      scheduleQuantitycheck21: -450,
      scheduleQuantitycheck22: -450,
      scheduleQuantitycheck23: -450,
      scheduleQuantitycheck24: -450,
      scheduleQuantitycheck25: -450,
      scheduleQuantitycheck26: -450,
      scheduleQuantitycheck27: -450,
      scheduleQuantitycheck28: -450,
      scheduleQuantitycheck29: -450,
      scheduleQuantitycheck30: -450,
      scheduleQuantity00: " ",
      scheduleQuantity01: "-100",
      scheduleQuantity02: "-150",
      scheduleQuantity03: "-150",
      scheduleQuantity04: "-350",
      scheduleQuantity05: "-450",
      scheduleQuantity06: "-450",
      scheduleQuantity07: "-450",
      scheduleQuantity08: "-450",
      scheduleQuantity09: "-450",
      scheduleQuantity10: "-450",
      scheduleQuantity11: "-450",
      scheduleQuantity12: "-450",
      scheduleQuantity13: "-450",
      scheduleQuantity14: "-450",
      scheduleQuantity15: "-450",
      scheduleQuantity16: "-450",
      scheduleQuantity17: "-450",
      scheduleQuantity18: "-450",
      scheduleQuantity19: "-450",
      scheduleQuantity20: "-450",
      scheduleQuantity21: "-450",
      scheduleQuantity22: "-450",
      scheduleQuantity23: "-450",
      scheduleQuantity24: "-450",
      scheduleQuantity25: "-450",
      scheduleQuantity26: "-450",
      scheduleQuantity27: "-450",
      scheduleQuantity28: "-450",
      scheduleQuantity29: "-450",
      scheduleQuantity30: "-450",
      userFlg: true,
      class: "user-head",
    },
    dummy_input3: {
      isOpen: false,
      userProductCnCd: "39680-T0A -R420-M1",
      userProductName: "SENSOR ASSY, PARKING",
      accessoryProduct: "NH877P",
      userProductCnCode: "39680-T0A -R420-M1 NH877P",
      productCnCode: "",
      inScheduleQuantity00: "100",
      inScheduleQuantity01: "0",
      inScheduleQuantity02: "100",
      inScheduleQuantity03: "200",
      inScheduleQuantity04: "0",
      inScheduleQuantity05: "50",
      inScheduleQuantity06: "100",
      inScheduleQuantity07: "200",
      inScheduleQuantity08: "0",
      inScheduleQuantity09: "0",
      inScheduleQuantity10: "0",
      inScheduleQuantity11: "0",
      inScheduleQuantity12: "0",
      inScheduleQuantity13: "0",
      inScheduleQuantity14: "0",
      inScheduleQuantity15: "0",
      inScheduleQuantity16: "0",
      inScheduleQuantity17: "0",
      inScheduleQuantity18: "0",
      inScheduleQuantity19: "0",
      inScheduleQuantity20: "0",
      inScheduleQuantity21: "0",
      inScheduleQuantity22: "0",
      inScheduleQuantity23: "0",
      inScheduleQuantity24: "0",
      inScheduleQuantity25: "0",
      inScheduleQuantity26: "0",
      inScheduleQuantity27: "0",
      inScheduleQuantity28: "0",
      inScheduleQuantity29: "0",
      inScheduleQuantity30: "0",
      scheduleQuantitycheck00: 0,
      scheduleQuantitycheck01: 0,
      scheduleQuantitycheck02: 0,
      scheduleQuantitycheck03: 0,
      scheduleQuantitycheck04: 0,
      scheduleQuantitycheck05: 0,
      scheduleQuantitycheck06: -100,
      scheduleQuantitycheck07: -300,
      scheduleQuantitycheck08: -300,
      scheduleQuantitycheck09: -300,
      scheduleQuantitycheck10: -300,
      scheduleQuantitycheck11: -300,
      scheduleQuantitycheck12: -300,
      scheduleQuantitycheck13: -300,
      scheduleQuantitycheck14: -300,
      scheduleQuantitycheck15: -300,
      scheduleQuantitycheck16: -300,
      scheduleQuantitycheck17: -300,
      scheduleQuantitycheck18: -300,
      scheduleQuantitycheck19: -300,
      scheduleQuantitycheck20: -300,
      scheduleQuantitycheck21: -300,
      scheduleQuantitycheck22: -300,
      scheduleQuantitycheck23: -300,
      scheduleQuantitycheck24: -300,
      scheduleQuantitycheck25: -300,
      scheduleQuantitycheck26: -300,
      scheduleQuantitycheck27: -300,
      scheduleQuantitycheck28: -300,
      scheduleQuantitycheck29: -300,
      scheduleQuantitycheck30: -300,
      scheduleQuantity00: " ",
      scheduleQuantity01: " ",
      scheduleQuantity02: " ",
      scheduleQuantity03: " ",
      scheduleQuantity04: " ",
      scheduleQuantity05: " ",
      scheduleQuantity06: "-300",
      scheduleQuantity07: "-300",
      scheduleQuantity08: "-300",
      scheduleQuantity09: "-300",
      scheduleQuantity10: "-300",
      scheduleQuantity11: "-300",
      scheduleQuantity12: "-300",
      scheduleQuantity13: "-300",
      scheduleQuantity14: "-300",
      scheduleQuantity15: "-300",
      scheduleQuantity16: "-300",
      scheduleQuantity17: "-300",
      scheduleQuantity18: "-300",
      scheduleQuantity19: "-300",
      scheduleQuantity20: "-300",
      scheduleQuantity21: "-300",
      scheduleQuantity22: "-300",
      scheduleQuantity23: "-300",
      scheduleQuantity24: "-300",
      scheduleQuantity25: "-300",
      scheduleQuantity26: "-300",
      scheduleQuantity27: "-300",
      scheduleQuantity28: "-300",
      scheduleQuantity29: "-300",
      scheduleQuantity30: "-300",
      userFlg: true,
      class: "user-head",
    },
    dummy_input4: {
      isOpen: false,
      userProductCnCd: "39680-TZA -J011-M1",
      userProductName: "SENSOR ASSY PARKING",
      accessoryProduct: "NH731P",
      userProductCnCode: "39680-TZA -J011-M1 NH731P",
      productCnCode: "",
      inScheduleQuantity00: "100",
      inScheduleQuantity01: "100",
      inScheduleQuantity02: "50",
      inScheduleQuantity03: "0",
      inScheduleQuantity04: "200",
      inScheduleQuantity05: "100",
      inScheduleQuantity06: "100",
      inScheduleQuantity07: "0",
      inScheduleQuantity08: "0",
      inScheduleQuantity09: "0",
      inScheduleQuantity10: "0",
      inScheduleQuantity11: "0",
      inScheduleQuantity12: "0",
      inScheduleQuantity13: "0",
      inScheduleQuantity14: "0",
      inScheduleQuantity15: "0",
      inScheduleQuantity16: "0",
      inScheduleQuantity17: "0",
      inScheduleQuantity18: "0",
      inScheduleQuantity19: "0",
      inScheduleQuantity20: "0",
      inScheduleQuantity21: "0",
      inScheduleQuantity22: "0",
      inScheduleQuantity23: "0",
      inScheduleQuantity24: "0",
      inScheduleQuantity25: "0",
      inScheduleQuantity26: "0",
      inScheduleQuantity27: "0",
      inScheduleQuantity28: "0",
      inScheduleQuantity29: "0",
      inScheduleQuantity30: "0",
      scheduleQuantitycheck00: 0,
      scheduleQuantitycheck01: -100,
      scheduleQuantitycheck02: -150,
      scheduleQuantitycheck03: -150,
      scheduleQuantitycheck04: -350,
      scheduleQuantitycheck05: -450,
      scheduleQuantitycheck06: -450,
      scheduleQuantitycheck07: -450,
      scheduleQuantitycheck08: -450,
      scheduleQuantitycheck09: -450,
      scheduleQuantitycheck10: -450,
      scheduleQuantitycheck11: -450,
      scheduleQuantitycheck12: -450,
      scheduleQuantitycheck13: -450,
      scheduleQuantitycheck14: -450,
      scheduleQuantitycheck15: -450,
      scheduleQuantitycheck16: -450,
      scheduleQuantitycheck17: -450,
      scheduleQuantitycheck18: -450,
      scheduleQuantitycheck19: -450,
      scheduleQuantitycheck20: -450,
      scheduleQuantitycheck21: -450,
      scheduleQuantitycheck22: -450,
      scheduleQuantitycheck23: -450,
      scheduleQuantitycheck24: -450,
      scheduleQuantitycheck25: -450,
      scheduleQuantitycheck26: -450,
      scheduleQuantitycheck27: -450,
      scheduleQuantitycheck28: -450,
      scheduleQuantitycheck29: -450,
      scheduleQuantitycheck30: -450,
      scheduleQuantity00: " ",
      scheduleQuantity01: "-100",
      scheduleQuantity02: "-150",
      scheduleQuantity03: "-150",
      scheduleQuantity04: "-350",
      scheduleQuantity05: "-450",
      scheduleQuantity06: "-450",
      scheduleQuantity07: "-450",
      scheduleQuantity08: "-450",
      scheduleQuantity09: "-450",
      scheduleQuantity10: "-450",
      scheduleQuantity11: "-450",
      scheduleQuantity12: "-450",
      scheduleQuantity13: "-450",
      scheduleQuantity14: "-450",
      scheduleQuantity15: "-450",
      scheduleQuantity16: "-450",
      scheduleQuantity17: "-450",
      scheduleQuantity18: "-450",
      scheduleQuantity19: "-450",
      scheduleQuantity20: "-450",
      scheduleQuantity21: "-450",
      scheduleQuantity22: "-450",
      scheduleQuantity23: "-450",
      scheduleQuantity24: "-450",
      scheduleQuantity25: "-450",
      scheduleQuantity26: "-450",
      scheduleQuantity27: "-450",
      scheduleQuantity28: "-450",
      scheduleQuantity29: "-450",
      scheduleQuantity30: "-450",
      userFlg: true,
      class: "user-head",
    },
    dummy_input5: {
      isOpen: false,
      userProductCnCd: "39680-TZA -J011-M1",
      userProductName: "SENSOR ASSY PARKING",
      accessoryProduct: "NH883P",
      userProductCnCode: "39680-TZA -J011-M1 NH883P",
      productCnCode: "",
      inScheduleQuantity00: "100",
      inScheduleQuantity01: "100",
      inScheduleQuantity02: "50",
      inScheduleQuantity03: "0",
      inScheduleQuantity04: "200",
      inScheduleQuantity05: "100",
      inScheduleQuantity06: "100",
      inScheduleQuantity07: "0",
      inScheduleQuantity08: "0",
      inScheduleQuantity09: "0",
      inScheduleQuantity10: "0",
      inScheduleQuantity11: "0",
      inScheduleQuantity12: "0",
      inScheduleQuantity13: "0",
      inScheduleQuantity14: "0",
      inScheduleQuantity15: "0",
      inScheduleQuantity16: "0",
      inScheduleQuantity17: "0",
      inScheduleQuantity18: "0",
      inScheduleQuantity19: "0",
      inScheduleQuantity20: "0",
      inScheduleQuantity21: "0",
      inScheduleQuantity22: "0",
      inScheduleQuantity23: "0",
      inScheduleQuantity24: "0",
      inScheduleQuantity25: "0",
      inScheduleQuantity26: "0",
      inScheduleQuantity27: "0",
      inScheduleQuantity28: "0",
      inScheduleQuantity29: "0",
      inScheduleQuantity30: "0",
      scheduleQuantitycheck00: 0,
      scheduleQuantitycheck01: -100,
      scheduleQuantitycheck02: -150,
      scheduleQuantitycheck03: -150,
      scheduleQuantitycheck04: -350,
      scheduleQuantitycheck05: -450,
      scheduleQuantitycheck06: -450,
      scheduleQuantitycheck07: -450,
      scheduleQuantitycheck08: -450,
      scheduleQuantitycheck09: -450,
      scheduleQuantitycheck10: -450,
      scheduleQuantitycheck11: -450,
      scheduleQuantitycheck12: -450,
      scheduleQuantitycheck13: -450,
      scheduleQuantitycheck14: -450,
      scheduleQuantitycheck15: -450,
      scheduleQuantitycheck16: -450,
      scheduleQuantitycheck17: -450,
      scheduleQuantitycheck18: -450,
      scheduleQuantitycheck19: -450,
      scheduleQuantitycheck20: -450,
      scheduleQuantitycheck21: -450,
      scheduleQuantitycheck22: -450,
      scheduleQuantitycheck23: -450,
      scheduleQuantitycheck24: -450,
      scheduleQuantitycheck25: -450,
      scheduleQuantitycheck26: -450,
      scheduleQuantitycheck27: -450,
      scheduleQuantitycheck28: -450,
      scheduleQuantitycheck29: -450,
      scheduleQuantitycheck30: -450,
      scheduleQuantity00: " ",
      scheduleQuantity01: "-100",
      scheduleQuantity02: "-150",
      scheduleQuantity03: "-150",
      scheduleQuantity04: "-350",
      scheduleQuantity05: "-450",
      scheduleQuantity06: "-450",
      scheduleQuantity07: "-450",
      scheduleQuantity08: "-450",
      scheduleQuantity09: "-450",
      scheduleQuantity10: "-450",
      scheduleQuantity11: "-450",
      scheduleQuantity12: "-450",
      scheduleQuantity13: "-450",
      scheduleQuantity14: "-450",
      scheduleQuantity15: "-450",
      scheduleQuantity16: "-450",
      scheduleQuantity17: "-450",
      scheduleQuantity18: "-450",
      scheduleQuantity19: "-450",
      scheduleQuantity20: "-450",
      scheduleQuantity21: "-450",
      scheduleQuantity22: "-450",
      scheduleQuantity23: "-450",
      scheduleQuantity24: "-450",
      scheduleQuantity25: "-450",
      scheduleQuantity26: "-450",
      scheduleQuantity27: "-450",
      scheduleQuantity28: "-450",
      scheduleQuantity29: "-450",
      scheduleQuantity30: "-450",
      userFlg: true,
      class: "user-head",
    },
    dummy_input6: {
      userProductCnCd: "57110-32R -J060-M1",
      userProductName: "MODULATOR ASSY VSA",
      productCnCd: "39670TKRN000M1",
      productName: "SENSOR ASSY PARKING",
      accessoryProduct: "",
      userProductCnCode: "57110-32R -J060-M1",
      productCnCode: "39670TKRN000M1",
      inScheduleQuantity00: "100",
      inScheduleQuantity01: "",
      inScheduleQuantity02: "",
      inScheduleQuantity04: "",
      inScheduleQuantity05: "",
      inScheduleQuantity06: "",
      scheduleQuantitycheck00: 0,
      scheduleQuantitycheck01: -100,
      scheduleQuantitycheck02: -150,
      scheduleQuantitycheck03: -150,
      scheduleQuantitycheck04: -350,
      scheduleQuantitycheck05: -450,
      scheduleQuantitycheck06: -450,
      scheduleQuantitycheck07: -450,
      scheduleQuantitycheck08: -450,
      scheduleQuantitycheck09: -450,
      scheduleQuantitycheck10: -450,
      scheduleQuantitycheck11: -450,
      scheduleQuantitycheck12: -450,
      scheduleQuantitycheck13: -450,
      scheduleQuantitycheck14: -450,
      scheduleQuantitycheck15: -450,
      scheduleQuantitycheck16: -450,
      scheduleQuantitycheck17: -450,
      scheduleQuantitycheck18: -450,
      scheduleQuantitycheck19: -450,
      scheduleQuantitycheck20: -450,
      scheduleQuantitycheck21: -450,
      scheduleQuantitycheck22: -450,
      scheduleQuantitycheck23: -450,
      scheduleQuantitycheck24: -450,
      scheduleQuantitycheck25: -450,
      scheduleQuantitycheck26: -450,
      scheduleQuantitycheck27: -450,
      scheduleQuantitycheck28: -450,
      scheduleQuantitycheck29: -450,
      scheduleQuantitycheck30: -450,
      userFlg: false,
    },
    dummy_input7: {
      isOpen: false,
      userProductCnCd: "39680-T0A -R420-M1",
      userProductName: "SENSOR ASSY, PARKING",
      accessoryProduct: "NH830M",
      productCnCd: "39680T0AR420M1",
      productName: "GARN ASSY,T/GATE SPOILER",
      userProductCnCode: "39680-T0A -R420-M1 NH830M",
      productCnCode: "39680T0AR420M1 NH830M",
      inScheduleQuantity00: "100",
      scheduleQuantitycheck00: 0,
      scheduleQuantitycheck01: -100,
      scheduleQuantitycheck02: -150,
      scheduleQuantitycheck03: -150,
      scheduleQuantitycheck04: -350,
      scheduleQuantitycheck05: -450,
      scheduleQuantitycheck06: -450,
      scheduleQuantitycheck07: -450,
      scheduleQuantitycheck08: -450,
      scheduleQuantitycheck09: -450,
      scheduleQuantitycheck10: -450,
      scheduleQuantitycheck11: -450,
      scheduleQuantitycheck12: -450,
      scheduleQuantitycheck13: -450,
      scheduleQuantitycheck14: -450,
      scheduleQuantitycheck15: -450,
      scheduleQuantitycheck16: -450,
      scheduleQuantitycheck17: -450,
      scheduleQuantitycheck18: -450,
      scheduleQuantitycheck19: -450,
      scheduleQuantitycheck20: -450,
      scheduleQuantitycheck21: -450,
      scheduleQuantitycheck22: -450,
      scheduleQuantitycheck23: -450,
      scheduleQuantitycheck24: -450,
      scheduleQuantitycheck25: -450,
      scheduleQuantitycheck26: -450,
      scheduleQuantitycheck27: -450,
      scheduleQuantitycheck28: -450,
      scheduleQuantitycheck29: -450,
      scheduleQuantitycheck30: -450,
      userFlg: false,
      class: "",
    },
    dummy_input8: {
      isOpen: false,
      userProductCnCd: "39680-T0A -R420-M1",
      userProductName: "SENSOR ASSY, PARKING",
      accessoryProduct: "NH830M",
      productCnCd: "39680T0AR420M1",
      productName: "GARN ASSY,T/GATE SPOILER",
      userProductCnCode: "39680-T0A -R420-M1 NH877P",
      productCnCode: "39680T0AR420M1 NH830M",
      inScheduleQuantity00: "100",
      scheduleQuantitycheck00: 0,
      scheduleQuantitycheck01: -100,
      scheduleQuantitycheck02: -150,
      scheduleQuantitycheck03: -150,
      scheduleQuantitycheck04: -350,
      scheduleQuantitycheck05: -450,
      scheduleQuantitycheck06: -450,
      scheduleQuantitycheck07: -450,
      scheduleQuantitycheck08: -450,
      scheduleQuantitycheck09: -450,
      scheduleQuantitycheck10: -450,
      scheduleQuantitycheck11: -450,
      scheduleQuantitycheck12: -450,
      scheduleQuantitycheck13: -450,
      scheduleQuantitycheck14: -450,
      scheduleQuantitycheck15: -450,
      scheduleQuantitycheck16: -450,
      scheduleQuantitycheck17: -450,
      scheduleQuantitycheck18: -450,
      scheduleQuantitycheck19: -450,
      scheduleQuantitycheck20: -450,
      scheduleQuantitycheck21: -450,
      scheduleQuantitycheck22: -450,
      scheduleQuantitycheck23: -450,
      scheduleQuantitycheck24: -450,
      scheduleQuantitycheck25: -450,
      scheduleQuantitycheck26: -450,
      scheduleQuantitycheck27: -450,
      scheduleQuantitycheck28: -450,
      scheduleQuantitycheck29: -450,
      scheduleQuantitycheck30: -450,
      userFlg: false,
      class: "",
    },
    dummy_input9: {
      isOpen: false,
      userProductCnCd: "39680-T0A -R420-M1",
      userProductName: "SENSOR ASSY, PARKING",
      accessoryProduct: "NH877P",
      productCnCd: "39680T0AR420M2",
      productName: "GARN ASSY,T/GATE SPOILER",
      userProductCnCode: "39680-T0A -R420-M1 NH877P",
      productCnCode: "39680T0AR420M2 NH830M",
      inScheduleQuantity02: "100",
      inScheduleQuantity03: "200",
      scheduleQuantitycheck00: 0,
      scheduleQuantitycheck01: -100,
      scheduleQuantitycheck02: -150,
      scheduleQuantitycheck03: -150,
      scheduleQuantitycheck04: -350,
      scheduleQuantitycheck05: -450,
      scheduleQuantitycheck06: -450,
      scheduleQuantitycheck07: -450,
      scheduleQuantitycheck08: -450,
      scheduleQuantitycheck09: -450,
      scheduleQuantitycheck10: -450,
      scheduleQuantitycheck11: -450,
      scheduleQuantitycheck12: -450,
      scheduleQuantitycheck13: -450,
      scheduleQuantitycheck14: -450,
      scheduleQuantitycheck15: -450,
      scheduleQuantitycheck16: -450,
      scheduleQuantitycheck17: -450,
      scheduleQuantitycheck18: -450,
      scheduleQuantitycheck19: -450,
      scheduleQuantitycheck20: -450,
      scheduleQuantitycheck21: -450,
      scheduleQuantitycheck22: -450,
      scheduleQuantitycheck23: -450,
      scheduleQuantitycheck24: -450,
      scheduleQuantitycheck25: -450,
      scheduleQuantitycheck26: -450,
      scheduleQuantitycheck27: -450,
      scheduleQuantitycheck28: -450,
      scheduleQuantitycheck29: -450,
      scheduleQuantitycheck30: -450,
      userFlg: false,
      class: "",
    },
    dummy_input10: {
      isOpen: false,
      userProductCnCd: "39680-T0A -R420-M1",
      userProductName: "SENSOR ASSY, PARKING",
      accessoryProduct: "NH877P",
      productCnCd: "39680T0AR420M3",
      productName: "GARN ASSY,T/GATE SPOILER",
      userProductCnCode: "39680-T0A -R420-M1 NH877P",
      productCnCode: "39680T0AR420M3 NH830M",
      inScheduleQuantity05: "50",
      scheduleQuantitycheck00: 0,
      scheduleQuantitycheck01: -100,
      scheduleQuantitycheck02: -150,
      scheduleQuantitycheck03: -150,
      scheduleQuantitycheck04: -350,
      scheduleQuantitycheck05: -450,
      scheduleQuantitycheck06: -450,
      scheduleQuantitycheck07: -450,
      scheduleQuantitycheck08: -450,
      scheduleQuantitycheck09: -450,
      scheduleQuantitycheck10: -450,
      scheduleQuantitycheck11: -450,
      scheduleQuantitycheck12: -450,
      scheduleQuantitycheck13: -450,
      scheduleQuantitycheck14: -450,
      scheduleQuantitycheck15: -450,
      scheduleQuantitycheck16: -450,
      scheduleQuantitycheck17: -450,
      scheduleQuantitycheck18: -450,
      scheduleQuantitycheck19: -450,
      scheduleQuantitycheck20: -450,
      scheduleQuantitycheck21: -450,
      scheduleQuantitycheck22: -450,
      scheduleQuantitycheck23: -450,
      scheduleQuantitycheck24: -450,
      scheduleQuantitycheck25: -450,
      scheduleQuantitycheck26: -450,
      scheduleQuantitycheck27: -450,
      scheduleQuantitycheck28: -450,
      scheduleQuantitycheck29: -450,
      scheduleQuantitycheck30: -450,
      userFlg: false,
      class: "",
    },
  }),
  methods: {
    // テーブルの余白調整
    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 100;
      this.page = 1;
      this.apigetStock("get-search");
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.apigetStock("get-search");
    },
    // 初期化
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);
      this.referenceDate = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      this.referenceDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      this.getInitData();

      // this.tableDate(new Date());
    },
    // 初期データ取得
    getInitData() {
      //表示期間をコードマスタから取得
      const displayPeriod = getParameter.getCodeMst(appConfig.CODETYPE.DISPLAY_PERIOD);
      const displayPeriodLl = getParameter.getCodeMst(appConfig.CODETYPE.DISPLAY_PERIOD_LNITIAL);
      const dataFormatDivList = getParameter.getCodeMst(appConfig.CODETYPE.DATAFORMAT_DIV);
      const outPutPatternDivList = getParameter.getCodeMst(
        appConfig.CODETYPE.CSV_OUTPUTPATTERN_DIV
      );
      const transactionList = getParameter.getCodeMst(appConfig.CODETYPE.TRANSACTION_TYPE);
      Promise.all([
        displayPeriod,
        displayPeriodLl,
        dataFormatDivList,
        outPutPatternDivList,
        transactionList,
      ])
        .then((result) => {
          // 画面の初期値を設定します。
          this.displayPeriodList = result[0];
          this.displayPeriodLnitial = result[1];
          this.dataFormatList = result[2];
          this.outPutPatternList = result[3];
          this.transactionList = result[4];
          //表示期間初期値設定
          for (var i = 0; i < this.displayPeriodLnitial.length; i++) {
            if (this.displayPeriodLnitial[i].value == "02") {
              this.displayPeriodSelected = this.displayPeriodLnitial[i].text;
            }
          }
          //headerItems内削除
          this.headerItems.splice(0, this.headerItems.length);
          this.headerItems.push(
            {
              text: i18n.tc(""),
              value: "pulldown",
              width: "50px",
              align: "center",
              class: "pulldown-fixed",
              sortable: false,
              padding: "0px",
            },
            // ユーザー品番/品名
            {
              text: i18n.tc("label.lbl_userProductCd") + "/" + i18n.tc("label.lbl_productCodeName"),
              value: "userProductCnCd",
              width: "250px",
              class: "userProduct-fixed",
              align: "center",
              sortable: false,
            },
            //警告
            {
              text: i18n.tc("label.lbl_alert"),
              value: "alert",
              width: "100px",
              class: "alert-fixed",
              align: "center",
              sortable: false,
            },
            //メーカー品番/品名
            {
              text:
                i18n.tc("label.lbl_makerProductCd") + "/" + i18n.tc("label.lbl_productCodeName"),
              value: "productCnCd",
              width: "250px",
              class: "product-fixed",
              align: "center",
              sortable: false,
            },
            //付帯品番
            {
              text: i18n.tc("label.lbl_accessoryProduct"),
              value: "accessoryProduct",
              width: "200px",
              class: "accessoryProduct-fixed",
              align: "center",
              sortable: false,
            },
            // 項目
            {
              text: i18n.tc("label.lbl_items"),
              value: "itemKbn",
              width: "70px",
              class: "items-fixed",
              align: "center",
              sortable: false,
            }
          );
          this.tableDate(new Date());
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
          this.getCustomInfo();
        });
    },
    /**
     * 初期データ取得
     */
    getCustomInfo() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const supplierList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(this.referenceDate)
      );
      return Promise.all([supplierList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.supplierList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // メニューダイアログ
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 警告ダイアログ、OKボタン押下
     */
    updateOk() {
      this.updateDialogMessage = false;
    },
    // 取引先コードセット処理
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },
    // 推移セット処理
    setStsArrival() {
      for (var i = 0; i < this.transactionList.length; i++) {
        if (this.transactionList[i].value == this.transactionTypeSelected) {
          return this.transactionList[i].text;
        }
      }
    },
    //計算ボタン押下
    apigetCalculation() {
      if (this.$refs.form.validate()) {
        // ローディング画面表示ON
        this.loadingCounter = 1;
        const config = this.$httpClient.createRequestBodyConfig();
        config.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SUP_020;
        //APIに渡す値をconfig.reqIdvにセット==================================//
        //ユーザー取引先
        config.reqIdv.userClientSid = this.suppliersSelected;
        //基準日
        if (this.referenceDateCal) {
          let dateRange = this.referenceDateCal + " 00:00:00.000";
          config.reqIdv.processDate = dateTimeHelper.convertUTC(dateRange);
        }
        //表示期間
        if (this.displayPeriodSelected) {
          config.reqIdv.displayCode = this.displayPeriodSelected;
        }

        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .securePost(
              appConfig.API_URL.BIZ_STOCKS_TRANSITION_CALCULATION,
              config,
              appConfig.APP_CONFIG
            )
            .then((response) => {
              const jsonData = JSON.parse(JSON.stringify(response.data));
              //検索初期化
              this.inputList = [];
              this.pageCount = 0;
              this.totalCount = 0;
              // 正常時
              if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                this.infoDialog.message = messsageUtil.getMessage("P-SUP-020_001_C");
                this.infoDialog.title = "結果";
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                this.loadingCounter = 0;
              } else {
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = "結果";
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                reject(resolve);
              }
            })
            .catch((resolve) => {
              console.error("apigetStock() Resolve", resolve);
              this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject(resolve);
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },
    /**
     * プラスボタン押下時
     */
    toggleOpen(index) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const config = this.$httpClient.createGetApiRequestConfig();
      //APIに渡す値をconfig.paramsにセット==================================//
      //ユーザー取引先
      config.params.userClientSid = this.suppliersSelectedSearch;
      //基準日
      config.params.processDate = this.referenceDateCalSerch;
      //推移ステータス
      config.params.transitionDiv = this.transactionTypeSelectedSearch;
      //表示期間
      config.params.displayCode = this.displayPeriodSelectedSearch;
      //ユーザー品番
      config.params.userItemCd = this.inputList[index].userProductCnCode;
      //ユーザー品番SID
      config.params.userItemSid = this.inputList[index].userItemSid;
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_STOKCS_TRANSITION_DETAIL, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              this.inputList[index].isOpen = true;
              //this.transition = jsonData.resIdv.stocksTransitionDetail;

              jsonData.resIdv.stocksTransitionDetail.forEach((row) => {
                //データセット
                this.inputList.push({
                  isOpen: false,
                  userFlg: false,
                  class: "",
                  priorityNo: row.priorityNo,
                  userProductCnCd: this.inputList[index].userProductCnCd,
                  userProductName: this.inputList[index].userProductName,
                  accessoryProduct: row.itemCd.slice(-50).trimRight(),
                  productCnCd: row.itemCd.substring(0, 50).trimRight(),
                  productName: row.itemName,
                  itemStandardSid: row.itemStandardSid,
                  userProductCnCode: this.inputList[index].userProductCnCode,
                  productCnCode: row.itemCd,
                  inScheduleQuantity00:
                    row.transitionStocks1 != null && row.transitionStocks1 != 0
                      ? commonUtil.formatToCurrency(Number(row.transitionStocks1))
                      : "",
                  scheduleQuantitycheck00: 0,
                  inScheduleQuantity01:
                    row.transitionStocks2 != null && row.transitionStocks2 != 0
                      ? commonUtil.formatToCurrency(Number(row.transitionStocks2))
                      : "",
                  scheduleQuantitycheck01: 0,
                  inScheduleQuantity02:
                    row.transitionStocks3 != null && row.transitionStocks3 != 0
                      ? commonUtil.formatToCurrency(Number(row.transitionStocks3))
                      : "",
                  scheduleQuantitycheck02: 0,
                  inScheduleQuantity03:
                    row.transitionStocks4 != null && row.transitionStocks4 != 0
                      ? commonUtil.formatToCurrency(Number(row.transitionStocks4))
                      : "",
                  scheduleQuantitycheck03: 0,
                  inScheduleQuantity04:
                    row.transitionStocks5 != null && row.transitionStocks5 != 0
                      ? commonUtil.formatToCurrency(Number(row.transitionStocks5))
                      : "",
                  scheduleQuantitycheck04: 0,
                  inScheduleQuantity05:
                    row.transitionStocks6 != null && row.transitionStocks6 != 0
                      ? commonUtil.formatToCurrency(Number(row.transitionStocks6))
                      : "",
                  scheduleQuantitycheck05: 0,
                  inScheduleQuantity06:
                    row.transitionStocks7 != null && row.transitionStocks7 != 0
                      ? commonUtil.formatToCurrency(Number(row.transitionStocks7))
                      : "",
                  scheduleQuantitycheck06: 0,
                  inScheduleQuantity07:
                    row.transitionStocks8 != null && row.transitionStocks8 != 0
                      ? commonUtil.formatToCurrency(Number(row.transitionStocks8))
                      : "",
                  scheduleQuantitycheck07: 0,
                  inScheduleQuantity08:
                    row.transitionStocks9 != null && row.transitionStocks9 != 0
                      ? commonUtil.formatToCurrency(Number(row.transitionStocks9))
                      : "",
                  scheduleQuantitycheck08: 0,
                  inScheduleQuantity09:
                    row.transitionStocks10 != null && row.transitionStocks10 != 0
                      ? commonUtil.formatToCurrency(Number(row.transitionStocks10))
                      : "",
                  scheduleQuantitycheck09: 0,
                  inScheduleQuantity10:
                    row.transitionStockss11 != null && row.transitionStockss11 != 0
                      ? commonUtil.formatToCurrency(Number(row.transitionStocks11))
                      : "",
                  scheduleQuantitycheck10: 0,
                  inScheduleQuantity11:
                    row.transitionStocks12 != null && row.transitionStocks12 != 0
                      ? commonUtil.formatToCurrency(Number(row.transitionStocks12))
                      : "",
                  scheduleQuantitycheck11: 0,
                  inScheduleQuantity12:
                    row.transitionStocks13 != null && row.transitionStocks13 != 0
                      ? commonUtil.formatToCurrency(Number(row.transitionStocks13))
                      : "",
                  scheduleQuantitycheck12: 0,
                  inScheduleQuantity13:
                    row.transitionStocks14 != null && row.transitionStocks14 != 0
                      ? commonUtil.formatToCurrency(Number(row.transitionStocks14))
                      : "",
                  scheduleQuantitycheck13: 0,
                  inScheduleQuantity14:
                    row.transitionStocks15 != null && row.transitionStocks15 != 0
                      ? commonUtil.formatToCurrency(Number(row.transitionStocks15))
                      : "",
                  scheduleQuantitycheck14: 0,
                  inScheduleQuantity15:
                    row.transitionStocks16 != null && row.transitionStocks16 != 0
                      ? commonUtil.formatToCurrency(Number(row.transitionStocks16))
                      : "",
                  scheduleQuantitycheck15: 0,
                  inScheduleQuantity16:
                    row.transitionStocks17 != null && row.transitionStocks17 != 0
                      ? commonUtil.formatToCurrency(Number(row.transitionStocks17))
                      : "",
                  scheduleQuantitycheck16: 0,
                  inScheduleQuantity17:
                    row.transitionStocks18 != null && row.transitionStocks18 != 0
                      ? commonUtil.formatToCurrency(Number(row.transitionStocks18))
                      : "",
                  scheduleQuantitycheck17: 0,
                  inScheduleQuantity18:
                    row.transitionStocks19 != null && row.transitionStocks19 != 0
                      ? commonUtil.formatToCurrency(Number(row.transitionStocks19))
                      : "",
                  scheduleQuantitycheck18: 0,
                  inScheduleQuantity19:
                    row.transitionStocks20 != null && row.transitionStocks20 != 0
                      ? commonUtil.formatToCurrency(Number(row.transitionStocks20))
                      : "",
                  scheduleQuantitycheck19: 0,
                  inScheduleQuantity20:
                    row.transitionStocks21 != null && row.transitionStocks21 != 0
                      ? commonUtil.formatToCurrency(Number(row.transitionStocks21))
                      : "",
                  scheduleQuantitycheck20: 0,
                  inScheduleQuantity21:
                    row.transitionStocks22 != null && row.transitionStocks22 != 0
                      ? commonUtil.formatToCurrency(Number(row.transitionStocks22))
                      : "",
                  scheduleQuantitycheck21: 0,
                  inScheduleQuantity22:
                    row.transitionStocks23 != null && row.transitionStocks23 != 0
                      ? commonUtil.formatToCurrency(Number(row.transitionStocks23))
                      : "",
                  scheduleQuantitycheck22: 0,
                  inScheduleQuantity23:
                    row.transitionStocks24 != null && row.transitionStocks24 != 0
                      ? commonUtil.formatToCurrency(Number(row.transitionStocks24))
                      : "",
                  scheduleQuantitycheck23: 0,
                  inScheduleQuantity24:
                    row.transitionStocks25 != null && row.transitionStocks25 != 0
                      ? commonUtil.formatToCurrency(Number(row.transitionStocks25))
                      : "",
                  scheduleQuantitycheck24: 0,
                  inScheduleQuantity25:
                    row.transitionStocks26 != null && row.transitionStocks26 != 0
                      ? commonUtil.formatToCurrency(Number(row.transitionStocks26))
                      : "",
                  scheduleQuantitycheck25: 0,
                  inScheduleQuantity26:
                    row.transitionStocks27 != null && row.transitionStocks27 != 0
                      ? commonUtil.formatToCurrency(Number(row.transitionStocks27))
                      : "",
                  scheduleQuantitycheck26: 0,
                  inScheduleQuantity27:
                    row.transitionStocks28 != null && row.transitionStocks28 != 0
                      ? commonUtil.formatToCurrency(Number(row.transitionStocks28))
                      : "",
                  scheduleQuantitycheck27: 0,
                  inScheduleQuantity28:
                    row.transitionStocks29 != null && row.transitionStocks29 != 0
                      ? commonUtil.formatToCurrency(Number(row.transitionStocks29))
                      : "",
                  scheduleQuantitycheck28: 0,
                  inScheduleQuantity29:
                    row.transitionStocks30 != null && row.transitionStocks30 != 0
                      ? commonUtil.formatToCurrency(Number(row.transitionStocks30))
                      : "",
                  scheduleQuantitycheck29: 0,
                  inScheduleQuantity30:
                    row.transitionStocks31 != null && row.transitionStocks31 != 0
                      ? commonUtil.formatToCurrency(Number(row.transitionStocks31))
                      : "",
                  scheduleQuantitycheck30: 0,
                });
              });
              //データ再ソート
              this.inputList.sort(function (a, b) {
                if (a.userProductCnCode > b.userProductCnCode) return 1;
                if (a.userProductCnCode < b.userProductCnCode) return -1;
                if (a.priorityNo > b.priorityNo) return 1;
                if (a.priorityNo < b.priorityNo) return -1;
              });
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject(resolve);
            }
          })
          .catch((resolve) => {
            console.error("apigetStock() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * マイナスボタン押下時
     */
    toggleClose(index) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const itemCd = this.inputList[index].userProductCnCode;
      this.inputList[index].isOpen = false;
      const newArray = this.inputList.filter(function (n) {
        return n.userProductCnCode !== itemCd || n.userFlg !== false;
      });
      this.inputList = newArray;
      // ローディング画面表示ON
      this.loadingCounter = 0;
    },

    // 在庫推移検索
    apigetStock(val) {
      var now = new Date();
      this.today = dateTimeHelper.toStringDate("YYYY/MM/DD", now);
      this.nowTime = dateTimeHelper.toStringDate("hh:mm", now);
      // 現在時刻を表示

      if (this.$refs.form.validate()) {
        // ローディング画面表示ON
        this.loadingCounter = 1;
        //APIに渡す値をconfig.paramsにセット==================================//
        const config = this.$httpClient.createGetApiRequestConfig();
        // ユーザー取引先
        if (this.suppliersSelected) {
          config.params.userClientSid = this.suppliersSelected;
          this.suppliersSelectedSearch = this.suppliersSelected;
        }

        // 基準日
        if (this.referenceDateCal) {
          let dateRange = this.referenceDateCal + " 00:00:00.000";
          config.params.processDate = dateTimeHelper.convertUTC(dateRange);
          this.referenceDateCalSerch = dateTimeHelper.convertUTC(dateRange);
        }

        //推移ステータス
        if (this.transactionTypeSelected) {
          config.params.warningDiv = this.transactionTypeSelected;
          this.transactionTypeSelectedSearch = this.transactionTypeSelected;
        } else {
          config.params.warningDiv = "00";
          this.transactionTypeSelectedSearch = "00";
        }

        //表示期間
        if (this.displayPeriodSelected) {
          config.params.displayCode = this.displayPeriodSelected;
          this.displayPeriodSelectedSearch = this.displayPeriodSelected;
        } else {
          this.displayPeriodSelectedSearch = "";
        }

        // ソート
        if (this.sortItem != "") {
          config.params.reqComSortItem = this.sortItem;
        }

        // ページャー処理
        if (val == "get-search") {
          config.params.reqComPageIndex = "1";
          this.page = 1;
        } else {
          // ページャー処理
          if (this.page >= 2 && this.inputList.length == 0) {
            config.params.reqComPageIndex = "1";
            this.page = 1;
          } else {
            config.params.reqComPageIndex = this.page;
          }
        }

        // ページング
        config.params.reqComPaginationFlg = "1";
        config.params.reqComPageLimit = this.itemsPerPage;
        //APIに渡す値をconfig.paramsにセット==================================//

        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .secureGet(appConfig.API_URL.BIZ_STOKCS_TRANSITION, config)
            .then((response) => {
              const jsonData = JSON.parse(JSON.stringify(response.data));

              //headerItems内削除
              this.headerItems.splice(0, this.headerItems.length);

              //headerItems設定-----------------------------------------//
              this.headerItems.push({
                text: i18n.tc(""),
                value: "pulldown",
                width: "50px",
                align: "center",
                class: "pulldown-fixed",
                sortable: false,
                padding: "0px",
              });

              // 正常時
              if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                if (jsonData.resCom.resComPagination.totalRecord == 0) {
                  this.headerItems.push(
                    // ユーザー品番/品名
                    {
                      text:
                        i18n.tc("label.lbl_userProductCd") +
                        "/" +
                        i18n.tc("label.lbl_productCodeName"),
                      value: "userProductCnCd",
                      width: "250px",
                      class: "userProduct-fixed",
                      align: "center",
                      sortable: false,
                    },
                    //警告
                    {
                      text: i18n.tc("label.lbl_alert"),
                      value: "alert",
                      width: "100px",
                      class: "alert-fixed",
                      align: "center",
                      sortable: false,
                    },
                    //メーカー品番/品名
                    {
                      text:
                        i18n.tc("label.lbl_makerProductCd") +
                        "/" +
                        i18n.tc("label.lbl_productCodeName"),
                      value: "productCnCd",
                      width: "250px",
                      class: "product-fixed",
                      align: "center",
                      sortable: false,
                    },
                    //付帯品番
                    {
                      text: i18n.tc("label.lbl_accessoryProduct"),
                      value: "accessoryProduct",
                      width: "200px",
                      class: "accessoryProduct-fixed",
                      align: "center",
                      sortable: false,
                    },
                    // 項目
                    {
                      text: i18n.tc("label.lbl_items"),
                      value: "itemKbn",
                      width: "70px",
                      class: "items-fixed",
                      align: "center",
                      sortable: false,
                    }
                  );
                } else {
                  this.headerItems.push(
                    // ユーザー品番/品名
                    {
                      text:
                        i18n.tc("label.lbl_userProductCd") +
                        "/" +
                        i18n.tc("label.lbl_productCodeName"),
                      value: "userProductCnCd",
                      width: "282px",
                      class: "userProduct-fixed-search",
                      align: "center",
                      sortable: false,
                    },
                    //警告
                    {
                      text: i18n.tc("label.lbl_alert"),
                      value: "alert",
                      width: "100px",
                      class: "alert-fixed-serch",
                      align: "center",
                      sortable: false,
                    },
                    //メーカー品番/品名
                    {
                      text:
                        i18n.tc("label.lbl_makerProductCd") +
                        "/" +
                        i18n.tc("label.lbl_productCodeName"),
                      value: "productCnCd",
                      width: "282px",
                      class: "product-fixed-search",
                      align: "center",
                      sortable: false,
                    },
                    //付帯品番
                    {
                      text: i18n.tc("label.lbl_accessoryProduct"),
                      value: "accessoryProduct",
                      width: "200px",
                      class: "accessoryProduct-fixed-search",
                      align: "center",
                      sortable: false,
                    },
                    // 項目
                    {
                      text: i18n.tc("label.lbl_items"),
                      value: "itemKbn",
                      width: "70px",
                      class: "items-fixed-search",
                      align: "center",
                      sortable: false,
                    }
                  );
                }
              } else {
                this.headerItems.push(
                  // ユーザー品番/品名
                  {
                    text:
                      i18n.tc("label.lbl_userProductCd") +
                      "/" +
                      i18n.tc("label.lbl_productCodeName"),
                    value: "userProductCnCd",
                    width: "250px",
                    class: "userProduct-fixed",
                    align: "center",
                    sortable: false,
                  },
                  //警告
                  {
                    text: i18n.tc("label.lbl_alert"),
                    value: "alert",
                    width: "100px",
                    class: "alert-fixed",
                    align: "center",
                    sortable: false,
                  },
                  //メーカー品番/品名
                  {
                    text:
                      i18n.tc("label.lbl_makerProductCd") +
                      "/" +
                      i18n.tc("label.lbl_productCodeName"),
                    value: "productCnCd",
                    width: "250px",
                    class: "product-fixed",
                    align: "center",
                    sortable: false,
                  },
                  //付帯品番
                  {
                    text: i18n.tc("label.lbl_accessoryProduct"),
                    value: "accessoryProduct",
                    width: "150px",
                    class: "accessoryProduct-fixed",
                    align: "center",
                    sortable: false,
                  },
                  // 項目
                  {
                    text: i18n.tc("label.lbl_items"),
                    value: "itemKbn",
                    width: "125px",
                    class: "items-fixed",
                    align: "center",
                    sortable: false,
                  }
                );
              }

              this.tableDate(new Date(this.referenceDateCal + " 23:59:59"));
              //headerItems設定-----------------------------------------//

              // 正常時
              if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                //データセット
                this.inputList = [];
                this.pageCount = jsonData.resCom.resComPagination.totalPage;
                this.totalCount = jsonData.resCom.resComPagination.totalRecord;
                if (jsonData.resCom.resComPagination.totalRecord == 0) {
                  this.infoDialog.message = messsageUtil.getMessage("P-SUP-020_002_E");
                  this.infoDialog.title = "結果";
                  this.infoDialog.isOpen = true;
                  this.infoDialog.screenFlag = true;
                  this.outputBtnActive = true;
                  return;
                }
                const list = [];

                jsonData.resIdv.stocksTransition.forEach((row) => {
                  list.push({
                    isOpen: false,
                    priorityNo: 0,
                    //詳細表示制約
                    itemFlg: row.displayDiv == "02" ? true : false,
                    //警告
                    alert: row.warningDiv != "00" ? "ピンチ" : "",
                    // ユーザー品番SID
                    userItemSid: row.userItemSid,
                    // ユーザー品番
                    userProductCnCd: row.userItemCd.substring(0, 50).trimRight(),
                    //ユーザー付帯品番
                    accessoryProduct: row.userItemCd.slice(-50).trimRight(),
                    //ユーザー品番 + ユーザー付帯品番
                    userProductCnCode: row.userItemCd,
                    //ユーザー品名
                    userProductName: row.userItemName,
                    //メーカー品番
                    productCnCd: "",
                    //メーカー品名
                    productName: "",
                    //メーカー品番 + メーカー付帯品番
                    productCnCode: "",
                    inScheduleQuantity00: commonUtil.formatToCurrency(
                      Number(row.deliveryInstructionStock1 ?? "0")
                    ), // 基準日-搬入計画
                    scheduleQuantity00:
                      row.transitionStocks1 != null && row.transitionStocks1 != 0
                        ? commonUtil.formatToCurrency(Number(row.transitionStocks1))
                        : " ", // 基準日-推移
                    scheduleQuantitycheck00: row.transitionStocks1 ?? 0, //基準日-推移(チェック用)
                    inScheduleQuantity01: commonUtil.formatToCurrency(
                      Number(row.deliveryInstructionStock2 ?? "0")
                    ), // 基準日+1-搬入計画
                    scheduleQuantity01:
                      row.transitionStocks2 != null && row.transitionStocks2 != 0
                        ? commonUtil.formatToCurrency(Number(row.transitionStocks2))
                        : " ", // 基準日+1-推移
                    scheduleQuantitycheck01: row.transitionStocks2 ?? 0, //基準日+1-推移(チェック用)
                    inScheduleQuantity02: commonUtil.formatToCurrency(
                      Number(row.deliveryInstructionStock3 ?? "0")
                    ), // 基準日+2-搬入計画
                    scheduleQuantity02:
                      row.transitionStocks3 != null && row.transitionStocks3 != 0
                        ? commonUtil.formatToCurrency(Number(row.transitionStocks3))
                        : " ", // 基準日+2-推移
                    scheduleQuantitycheck02: row.transitionStocks3 ?? 0, //基準日+2-推移(チェック用)
                    inScheduleQuantity03: commonUtil.formatToCurrency(
                      Number(row.deliveryInstructionStock4 ?? "0")
                    ), // 基準日+3-搬入計画
                    scheduleQuantity03:
                      row.transitionStocks4 != null && row.transitionStocks4 != 0
                        ? commonUtil.formatToCurrency(Number(row.transitionStocks4))
                        : " ", // 基準日+3-推移
                    scheduleQuantitycheck03: row.transitionStocks4 ?? 0, //基準日+3-推移(チェック用)
                    inScheduleQuantity04: commonUtil.formatToCurrency(
                      Number(row.deliveryInstructionStock5 ?? "0")
                    ), // 基準日+4-搬入計画
                    scheduleQuantity04:
                      row.transitionStocks5 != null && row.transitionStocks5 != 0
                        ? commonUtil.formatToCurrency(Number(row.transitionStocks5))
                        : " ", // 基準日+4-推移
                    scheduleQuantitycheck04: row.transitionStocks5 ?? 0, //基準日+4-推移(チェック用)
                    inScheduleQuantity05: commonUtil.formatToCurrency(
                      Number(row.deliveryInstructionStock6 ?? "0")
                    ), // 基準日+5-搬入計画
                    scheduleQuantity05:
                      row.transitionStocks6 != null && row.transitionStocks6 != 0
                        ? commonUtil.formatToCurrency(Number(row.transitionStocks6))
                        : " ", // 基準日+5-推移
                    scheduleQuantitycheck05: row.transitionStocks6 ?? 0, //基準日+5-推移(チェック用)
                    inScheduleQuantity06: commonUtil.formatToCurrency(
                      Number(row.deliveryInstructionStock7 ?? "0")
                    ), // 基準日+6-搬入計画
                    scheduleQuantity06:
                      row.transitionStocks7 != null && row.transitionStocks7 != 0
                        ? commonUtil.formatToCurrency(Number(row.transitionStocks7))
                        : " ", // 基準日+6-推移
                    scheduleQuantitycheck06: row.transitionStocks7 ?? 0, //基準日+6-推移(チェック用)
                    inScheduleQuantity07: commonUtil.formatToCurrency(
                      Number(row.deliveryInstructionStock8 ?? "0")
                    ), // 基準日+7-搬入計画
                    scheduleQuantity07:
                      row.transitionStocks8 != null && row.transitionStocks8 != 0
                        ? commonUtil.formatToCurrency(Number(row.transitionStocks8))
                        : " ", // 基準日+7-推移
                    scheduleQuantitycheck07: row.transitionStocks8 ?? 0, //基準日+7-推移(チェック用)
                    inScheduleQuantity08: commonUtil.formatToCurrency(
                      Number(row.deliveryInstructionStock9 ?? "0")
                    ), // 基準日+8-搬入計画
                    scheduleQuantity08:
                      row.transitionStocks9 != null && row.transitionStocks9 != 0
                        ? commonUtil.formatToCurrency(Number(row.transitionStocks9))
                        : " ", // 基準日+8-推移
                    scheduleQuantitycheck08: row.transitionStocks9 ?? 0, //基準日+8-推移(チェック用)
                    inScheduleQuantity09: commonUtil.formatToCurrency(
                      Number(row.deliveryInstructionStock10 ?? "0")
                    ), // 基準日+9-搬入計画
                    scheduleQuantity09:
                      row.transitionStocks10 != null && row.transitionStocks10 != 0
                        ? commonUtil.formatToCurrency(Number(row.transitionStocks10))
                        : " ", // 基準日+9-推移
                    scheduleQuantitycheck09: row.transitionStocks10 ?? 0, //基準日+9-推移(チェック用)
                    inScheduleQuantity10: commonUtil.formatToCurrency(
                      Number(row.deliveryInstructionStock11 ?? "0")
                    ), // 基準日+10-搬入計画
                    scheduleQuantity10:
                      row.transitionStocks11 != null && row.transitionStocks11 != 0
                        ? commonUtil.formatToCurrency(Number(row.transitionStocks11))
                        : " ", // 基準日+10-推移
                    scheduleQuantitycheck10: row.transitionStocks11 ?? 0, //基準日+10-推移(チェック用)
                    inScheduleQuantity11: commonUtil.formatToCurrency(
                      Number(row.deliveryInstructionStock12 ?? "0")
                    ), // 基準日+11-搬入計画
                    scheduleQuantity11:
                      row.transitionStocks12 != null && row.transitionStocks12 != 0
                        ? commonUtil.formatToCurrency(Number(row.transitionStocks12))
                        : " ", // 基準日+11-推移
                    scheduleQuantitycheck11: row.transitionStocks12 ?? 0, //基準日+11-推移(チェック用)
                    inScheduleQuantity12: commonUtil.formatToCurrency(
                      Number(row.deliveryInstructionStock13 ?? "0")
                    ), // 基準日+12-搬入計画
                    scheduleQuantity12:
                      row.transitionStocks13 != null && row.transitionStocks13 != 0
                        ? commonUtil.formatToCurrency(Number(row.transitionStocks13))
                        : " ", // 基準日+12-推移
                    scheduleQuantitycheck12: row.transitionStocks13 ?? 0, //基準日+12-推移(チェック用)
                    inScheduleQuantity13: commonUtil.formatToCurrency(
                      Number(row.deliveryInstructionStock14 ?? "0")
                    ), // 基準日+13-搬入計画
                    scheduleQuantity13:
                      row.transitionStocks14 != null && row.transitionStocks14 != 0
                        ? commonUtil.formatToCurrency(Number(row.transitionStocks14))
                        : " ", // 基準日+13-推移
                    scheduleQuantitycheck13: row.transitionStocks14 ?? 0, //基準日+13-推移(チェック用)
                    inScheduleQuantity14: commonUtil.formatToCurrency(
                      Number(row.deliveryInstructionStock15 ?? "0")
                    ), // 基準日+14-搬入計画
                    scheduleQuantity14:
                      row.transitionStocks15 != null && row.transitionStocks15 != 0
                        ? commonUtil.formatToCurrency(Number(row.transitionStocks15))
                        : " ", // 基準日+14-推移
                    scheduleQuantitycheck14: row.transitionStocks15 ?? 0, //基準日+14-推移(チェック用)
                    inScheduleQuantity15: commonUtil.formatToCurrency(
                      Number(row.deliveryInstructionStock16 ?? "0")
                    ), // 基準日+15-搬入計画
                    scheduleQuantity15:
                      row.transitionStocks16 != null && row.transitionStocks16 != 0
                        ? commonUtil.formatToCurrency(Number(row.transitionStocks16))
                        : " ", // 基準日+15-推移
                    scheduleQuantitycheck15: row.transitionStocks16 ?? 0, //基準日+15-推移(チェック用)
                    inScheduleQuantity16: commonUtil.formatToCurrency(
                      Number(row.deliveryInstructionStock17 ?? "0")
                    ), // 基準日+16-搬入計画
                    scheduleQuantity16:
                      row.transitionStocks17 != null && row.transitionStocks17 != 0
                        ? commonUtil.formatToCurrency(Number(row.transitionStocks17))
                        : " ", // 基準日+16-推移
                    scheduleQuantitycheck16: row.transitionStocks17 ?? 0, //基準日+16-推移(チェック用)
                    inScheduleQuantity17: commonUtil.formatToCurrency(
                      Number(row.deliveryInstructionStock18 ?? "0")
                    ), // 基準日+17-搬入計画
                    scheduleQuantity17:
                      row.transitionStocks18 != null && row.transitionStocks18 != 0
                        ? commonUtil.formatToCurrency(Number(row.transitionStocks18))
                        : " ", // 基準日+17-推移
                    scheduleQuantitycheck17: row.transitionStocks18 ?? 0, //基準日+17-推移(チェック用)
                    inScheduleQuantity18: commonUtil.formatToCurrency(
                      Number(row.deliveryInstructionStock19 ?? "0")
                    ), // 基準日+18-搬入計画
                    scheduleQuantity18:
                      row.transitionStocks19 != null && row.transitionStocks19 != 0
                        ? commonUtil.formatToCurrency(Number(row.transitionStocks19))
                        : " ", // 基準日+18-推移
                    scheduleQuantitycheck18: row.transitionStocks19 ?? 0, //基準日+18-推移(チェック用)
                    inScheduleQuantity19: commonUtil.formatToCurrency(
                      Number(row.deliveryInstructionStock20 ?? "0")
                    ), // 基準日+19-搬入計画
                    scheduleQuantity19:
                      row.transitionStocks20 != null && row.transitionStocks20 != 0
                        ? commonUtil.formatToCurrency(Number(row.transitionStocks20))
                        : " ", // 基準日+19-推移
                    scheduleQuantitycheck19: row.transitionStocks20 ?? 0, //基準日+19-推移(チェック用)
                    inScheduleQuantity20: commonUtil.formatToCurrency(
                      Number(row.deliveryInstructionStock21 ?? "0")
                    ), // 基準日+20-搬入計画
                    scheduleQuantity20:
                      row.transitionStocks21 != null && row.transitionStocks21 != 0
                        ? commonUtil.formatToCurrency(Number(row.transitionStocks21))
                        : " ", // 基準日+20-推移
                    scheduleQuantitycheck20: row.transitionStocks21 ?? 0, //基準日+20-推移(チェック用)
                    inScheduleQuantity21: commonUtil.formatToCurrency(
                      Number(row.deliveryInstructionStock22 ?? "0")
                    ), // 基準日+21-搬入計画
                    scheduleQuantity21:
                      row.transitionStocks22 != null && row.transitionStocks22 != 0
                        ? commonUtil.formatToCurrency(Number(row.transitionStocks22))
                        : " ", // 基準日+21-推移
                    scheduleQuantitycheck21: row.transitionStocks22 ?? 0, //基準日+21-推移(チェック用)
                    inScheduleQuantity22: commonUtil.formatToCurrency(
                      Number(row.deliveryInstructionStock23 ?? "0")
                    ), // 基準日+22-搬入計画
                    scheduleQuantity22:
                      row.transitionStocks23 != null && row.transitionStocks23 != 0
                        ? commonUtil.formatToCurrency(Number(row.transitionStocks23))
                        : " ", // 基準日+22-推移
                    scheduleQuantitycheck22: row.transitionStocks23 ?? 0, //基準日+22-推移(チェック用)
                    inScheduleQuantity23: commonUtil.formatToCurrency(
                      Number(row.deliveryInstructionStock24 ?? "0")
                    ), // 基準日+23-搬入計画
                    scheduleQuantity23:
                      row.transitionStocks24 != null && row.transitionStocks24 != 0
                        ? commonUtil.formatToCurrency(Number(row.transitionStocks24))
                        : " ", // 基準日+23-推移
                    scheduleQuantitycheck23: row.transitionStocks24 ?? 0, //基準日+23-推移(チェック用)
                    inScheduleQuantity24: commonUtil.formatToCurrency(
                      Number(row.deliveryInstructionStock25 ?? "0")
                    ), // 基準日+24-搬入計画
                    scheduleQuantity24:
                      row.transitionStocks25 != null && row.transitionStocks25 != 0
                        ? commonUtil.formatToCurrency(Number(row.transitionStocks25))
                        : " ", // 基準日+24-推移
                    scheduleQuantitycheck24: row.transitionStocks25 ?? 0, //基準日+24-推移(チェック用)
                    inScheduleQuantity25: commonUtil.formatToCurrency(
                      Number(row.deliveryInstructionStock26 ?? "0")
                    ), // 基準日+25-搬入計画
                    scheduleQuantity25:
                      row.transitionStocks26 != null && row.transitionStocks26 != 0
                        ? commonUtil.formatToCurrency(Number(row.transitionStocks26))
                        : " ", // 基準日+25-推移
                    scheduleQuantitycheck25: row.transitionStocks26 ?? 0, //基準日+25-推移(チェック用)
                    inScheduleQuantity26: commonUtil.formatToCurrency(
                      Number(row.deliveryInstructionStock27 ?? "0")
                    ), // 基準日+26-搬入計画
                    scheduleQuantity26:
                      row.transitionStocks27 != null && row.transitionStocks27 != 0
                        ? commonUtil.formatToCurrency(Number(row.transitionStocks27))
                        : " ", // 基準日+26-推移
                    scheduleQuantitycheck26: row.transitionStocks27 ?? 0, //基準日+26-推移(チェック用)
                    inScheduleQuantity27: commonUtil.formatToCurrency(
                      Number(row.deliveryInstructionStock28 ?? "0")
                    ), // 基準日+27-搬入計画
                    scheduleQuantity27:
                      row.transitionStocks28 != null && row.transitionStocks28 != 0
                        ? commonUtil.formatToCurrency(Number(row.transitionStocks28))
                        : " ", // 基準日+27-推移
                    scheduleQuantitycheck27: row.transitionStocks28 ?? 0, //基準日+27-推移(チェック用)
                    inScheduleQuantity28: commonUtil.formatToCurrency(
                      Number(row.deliveryInstructionStock29 ?? "0")
                    ), // 基準日+28-搬入計画
                    scheduleQuantity28:
                      row.transitionStocks29 != null && row.transitionStocks29 != 0
                        ? commonUtil.formatToCurrency(Number(row.transitionStocks29))
                        : " ", // 基準日+28-推移
                    scheduleQuantitycheck28: row.transitionStocks29 ?? 0, //基準日+28-推移(チェック用)
                    inScheduleQuantity29: commonUtil.formatToCurrency(
                      Number(row.deliveryInstructionStock30 ?? "0")
                    ), // 基準日+29-搬入計画
                    scheduleQuantity29:
                      row.transitionStocks30 != null && row.transitionStocks30 != 0
                        ? commonUtil.formatToCurrency(Number(row.transitionStocks30))
                        : " ", // 基準日+29-推移
                    scheduleQuantitycheck29: row.transitionStocks30 ?? 0, //基準日+29-推移(チェック用)
                    inScheduleQuantity30: commonUtil.formatToCurrency(
                      Number(row.deliveryInstructionStock31 ?? "0")
                    ), // 基準日+30-搬入計画
                    scheduleQuantity30:
                      row.transitionStocks31 != null && row.transitionStocks31 != 0
                        ? commonUtil.formatToCurrency(Number(row.transitionStocks31))
                        : " ", // 基準日+30-推移
                    scheduleQuantitycheck30: row.transitionStocks31 ?? 0, //基準日+30-推移(チェック用)
                    userFlg: true,
                    class: "user-head",
                  });
                });
                this.inputList = list;
                //データ再ソート
                this.inputList.sort(function (a, b) {
                  if (a.userProductCnCode > b.userProductCnCode) return 1;
                  if (a.userProductCnCode < b.userProductCnCode) return -1;
                  if (a.productCnCode > b.productCnCode) return 1;
                  if (a.productCnCode < b.productCnCode) return -1;
                });
                // 検索後はデータ出力ボタン活性
                this.outputBtnActive = false;
                resolve(response);
              } else {
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = "結果";
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                reject(resolve);
              }
            })
            .catch((resolve) => {
              this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject(resolve);
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },

    /**
     * 検索結果の日付(可変)をセット
     */
    tableDate(dt) {
      dt.setDate(dt.getDate() - 1);
      //基準日～基準日+30日分
      for (let i = 0; i < Number(this.displayPeriodSelected); i++) {
        this.headerItems.push({
          text: i18n.tc(dateTimeHelper.convertJST(dt.setDate(dt.getDate() + 1)).substr(5, 5)),
          value: "inScheduleQuantity" + ("00" + i).slice(-2),
          // value: "waitEnterQty",
          width: "150px",
          align: "center",
          sortable: false,
        });
      }
    },

    // メーカー品番発注CSV出力ボタン押下時
    outPutClick() {
      if (this.$refs.form.validate()) {
        // ローディング画面表示ON
        this.loadingCounter = 1;

        const config = this.$httpClient.createRequestBodyConfig();

        config.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SUP_020;

        // 取引先SID
        config.reqIdv.userClientSid = this.suppliersSelected;

        // 基準日
        if (this.referenceDateCal) {
          let dateRange = this.referenceDateCal + " 00:00:00.000";
          config.reqIdv.processDate = dateTimeHelper.convertUTC(dateRange);
        }

        //表示期間
        if (this.displayPeriodSelected) {
          config.reqIdv.displayCode = this.displayPeriodSelected;
        }

        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .securePost(
              appConfig.API_URL.BIZ_STOKCS_TRANSITION_DATA_FALE,
              config,
              appConfig.APP_CONFIG
            )
            .then((response) => {
              // // console.debug("btnSearch() Response", response);
              const jsonData = JSON.parse(JSON.stringify(response.data));

              // 正常時
              if (jsonData.resCom.resComCode == "000") {
                // 返却されたurlを共通関数へ呼び出し
                commonFunction.getLink(jsonData.resIdv.filePath);
                resolve(response);
              } else {
                // エラー時(件数0件またはapiエラー時)
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
              this.dataOutPutDialog = false;
            });
        });
      }
    },

    // ダイアログオープン
    openPopup() {
      this.dialogDetails = true;
    },
    // ダイアログクローズ
    closePopup() {
      this.dialogDetails = false;
    },
    /**
     * 基準日を－１日します。
     */
    prevDate() {
      let date = new Date(this.referenceDateCal);
      this.referenceDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
    },
    /**
     * 基準日を＋１日します。
     */
    nextDate() {
      let date = new Date(this.referenceDateCal);
      this.referenceDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    // 日付入力
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.referenceDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.referenceDateCal = null;
      }
    },
    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      // 変更検知用の設定
      this.referenceDateCal = "";
      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    referenceDateCal: {
      handler(val) {
        this.referenceDate = this.formatDate(val);
      },
      deep: true,
    },
    page: function (newValue) {
      this.page = newValue;
      this.apigetStock("");
    },
  },

  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

.scrollType::-webkit-scrollbar {
  display: none;
}

.scrollTypeClose {
  overflow-y: scroll;
}

.table-scroll-host {
  grid-area: content;
  overflow: scroll;
}

.text-overflow {
  // v-tooltip
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

//展開ボタン
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .pulldown-fixed {
  position: sticky !important;
  left: 0 !important;
  z-index: 6 !important;
}

//展開ボタン(固定 明細)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:first-child {
  position: sticky !important;
  left: 0 !important;
  z-index: 5 !important;
}

//ユーザー品番/品名(固定 ヘッダ)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .userProduct-fixed {
  position: sticky !important;
  left: 50px !important;
  z-index: 6 !important;
}
//ユーザー品番/品名(固定 ヘッダ 検索)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .userProduct-fixed-search {
  position: sticky !important;
  left: 68px !important;
  z-index: 6 !important;
}
//ユーザー品番/品名(固定 明細)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:nth-child(2) {
  position: sticky !important;
  left: 68px !important;
  z-index: 5 !important;
}
//警告(固定 ヘッダ)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .alert-fixed {
  position: sticky !important;
  left: 300px !important;
  z-index: 6 !important;
}
//警告(固定 ヘッダ 検索)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .alert-fixed-serch {
  position: sticky !important;
  left: 350px !important;
  z-index: 6 !important;
}
//警告(固定 明細)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:nth-child(3) {
  position: sticky !important;
  left: 350px !important;
  z-index: 5 !important;
}
//メーカー品番/品名(固定 ヘッダ)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .product-fixed {
  position: sticky !important;
  left: 400px !important;
  z-index: 6 !important;
}
//メーカー品番/品名(固定 ヘッダ 検索)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .product-fixed-search {
  position: sticky !important;
  left: 450px !important;
  z-index: 6 !important;
}
//メーカー品番/品名(固定 明細)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:nth-child(4) {
  position: sticky !important;
  left: 450px !important;
  z-index: 5 !important;
}
//付帯品番(固定 ヘッダ)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .accessoryProduct-fixed {
  position: sticky !important;
  left: 650px !important;
  z-index: 6 !important;
}
//付帯品番(固定 ヘッダ 検索)
#listData.v-data-table--fixed-header
  ::v-deep
  .v-data-table__wrapper
  .accessoryProduct-fixed-search {
  position: sticky !important;
  left: 732px !important;
  z-index: 6 !important;
}
//付帯品番(固定 明細)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:nth-child(5) {
  position: sticky !important;
  left: 732px !important;
  z-index: 5 !important;
}
//項目(固定 ヘッダ 初期表示)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .items-fixed {
  position: sticky !important;
  left: 850px !important;
  z-index: 6 !important;
}

//項目(固定 ヘッダ 検索時)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .items-fixed-search {
  position: sticky !important;
  left: 932px !important;
  z-index: 6 !important;
}

//項目(固定 明細)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:nth-child(6) {
  position: sticky !important;
  left: 932px !important;
  z-index: 5 !important;
}

//tdに背景色を設定(横固定をするため)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper tbody tr td {
  background-color: #ffffff;
}
//tdに背景色を設定(横固定をするため)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper tbody tr:has(td .user-head),
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper tbody tr td:has(.user-head) {
  background-color: #dde5f0;
}

//tdに背景色を設定(横固定をするため)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper tbody tr {
  height: 60px;
}

//tableヘッダ情報下寄せ
#listData.v-data-table--fixed-header ::v-deep th {
  vertical-align: bottom;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper {
  max-height: 562px;
}

::v-deep .negative-inventory {
  color: #dd0000;
}
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper thead tr {
  background-color: #effad9;
}

#listData.v-data-table--fixed-header
  ::v-deep
  .v-data-table__wrapper
  tbody
  tr.v-data-table__empty-wrapper
  td {
  background-color: #dde5f0;
}
</style>
