/**
 * 日付共通
 */

import { dateTimeHelper } from "./DateTimeHelper";

export default class DateUtil {
  /**
   * 与えられた日付から年月日を文字列で返します。
   */
  dateCalc(date) {
    return dateTimeHelper.dateCalc(date);
  }

  /**
   * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
   */
  formatDate(date) {
    if (!date) return null;

    // 日付の正規表現（yyyy/MM/dd）
    const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

    // 対象の文字列チェック
    if (regex.test(date)) {
      // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
      return date;
    } else {
      // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
      const [year, month, day] = date.split("-");
      return `${year}/${month}/${day}`;
    }
  }
}

export const dateUtil = new DateUtil();
