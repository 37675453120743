<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-container fluid id="sub-bar">
      <v-col>
        <div id="lbl-screen-name">{{ $t("label.lbl_targetSelection") }}</div>
      </v-col>
    </v-container>
    <v-container fluid>
      <v-row class="search-row">
        <div class="search-row-exeBtn">
          <!--ボタン領域-->
          <div class="btn-area" style="float: left">
            <!-- 選択ボタン-->
            <v-btn id="btn-pack" class="post-btn" @click="select()">
              {{ $t("btn.btn_select") }}
            </v-btn>
          </div>
          <div class="btn-area">
            <!--閉じるボタン-->
            <v-btn class="post-btn" @click="close()">{{ $t("btn.btn_close") }}</v-btn>
          </div>
        </div>
      </v-row>
    </v-container>
    <!-- データテーブル -->
    <v-container fluid>
      <v-data-table
        id="listData"
        :headers="headerItems"
        :items="inputList"
        :fixed-header="true"
        disable-sort
        disable-pagination
        :hide-default-footer="true"
        height="500px"
      >
        <!-- ラジオボックス -->
        <template v-slot:[`item.choise`]="{ item, index }">
          <input type="radio" v-model="choise" :value="index" name="activity" />
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import { i18n } from "../../lang/lang.js";
import Loading from "@/components/loading";

export default {
  name: "stkInquiryDetails",
  components: {
    Loading,
  },
  props: {
    entity: { type: Object, default: null },
    isOpenDetailDialog: { type: Boolean, default: false },
  },
  data: () => ({
    choise: 0,
    // ローディング画面表示フラグ
    loadingCounter: 0,
    headerItems: [
      // 選択
      {
        text: i18n.tc("label.lbl_choice"),
        width: "50px",
        value: "choise",
        sortable: false,
        align: "center",
      },
      // 計画コード
      {
        text: i18n.tc("label.lbl_deliveryPlanCd"),
        value: "headerDeliveryPlan",
        width: "150px",
        sortable: false,
        align: "center",
      },
      // 供給先コード
      {
        text: i18n.tc("label.lbl_destinationCd"),
        value: "destinationCd",
        width: "150px",
        align: "center",
        sortable: false,
      },
    ],
    // 仮データ
    inputList: [],
    dummy_input: {
      choise: false,
      headerDeliveryPlan: "S4AF04",
      destinationCd: "1E23",
    },
    dummy_input2: {
      choise: false,
      headerDeliveryPlan: "S4AF04",
      destinationCd: "1E24",
    },
    defaultItem: {
      shipSlipNo: "",
      shipmentQuantity: "",
      howtoRegister: "手入力",
      displayFlg: true,
    },
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
    },
  }),
  methods: {
    /**
     * 初期起動：画面を開いた際に渡された値を設定します。
     */
    init() {
      this.inputList = [];
      this.choise = 0;
      this.inputList = this.entity.selectionList;
      // this.inputList.push(Object.assign({}, this.dummy_input));
      // this.inputList.push(Object.assign({}, this.dummy_input2));
    },

    //ダイアログを閉じる
    close() {
      this.$emit("closePopup");
      this.$emit("update:isOpenDetailDialog", false);
      this.inputList = [];
    },
    select() {
      //計画コード
      this.entity.headerDeliveryPlan = this.inputList[this.choise].headerDeliveryPlan;
      //供給先コード
      this.entity.destinationCd = this.inputList[this.choise].destinationCd;
      this.$emit("selectPopup");
      this.$emit("update:isOpenDetailDialog", false);
      this.inputList = [];
    },
  },
  watch: {
    entity: {
      handler(newValue, oldValue) {
        console.info("handler", newValue, oldValue);
      },
    },
    // eslint-disable-next-line no-unused-vars
    isOpenDetailDialog: function (newValue) {
      if (newValue) {
        this.init();
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
#sub-bar {
  padding: none;
  height: 3rem;
}

.text-overflow {
  // v-tooltip
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#listData.v-data-table ::v-deep table {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>
