<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-container fluid id="sub-bar">
      <v-col>
        <div id="lbl-screen-name">{{ $t("label.lbl_sortingInfoSearch") }}</div>
      </v-col>
    </v-container>
    <v-form ref="form1" lazy-validation>
      <v-container fluid>
        <v-row class="search-row">
          <!-- ユーザー取引先プルダウン-->
          <div class="serch-textbox-useritemCd first-search-item">
            <v-autocomplete
              dense
              maxlength="100"
              v-model="userSuppliersSelected"
              :items="userSupplierList"
              :label="$t('label.lbl_userSupplier')"
              :hint="setSrhSupplier()"
              class="txt-single"
              clear-icon="mdi-close-circle"
              persistent-hint
              @change="changeUserSuppliersSelected"
              :rules="[rules.inputRequired]"
              pa-3
              :disabled="kbn == '02'"
            >
              <!-- アイテム一覧の表示 -->
              <template slot="item" slot-scope="data">
                <span class="test">
                  {{ data.item.text }}
                </span>
              </template>
              <!-- アイテム一覧の表示 -->
            </v-autocomplete>
          </div>

          <span class="require">*</span>
          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>
          <div class="search-autocomplete first-search-item">
            <v-autocomplete
              dense
              maxlength="100"
              v-model="destinationyPlant"
              :items="destinationyPlantList"
              :label="$t('label.lbl_destinationyPlant')"
              :hint="setDestinationyPlant()"
              class="txt-single"
              persistent-hint
            >
              <!-- アイテム一覧の表示 -->
              <template slot="item" slot-scope="data">
                <span class="test">
                  {{ data.item.text }}
                </span>
              </template>
              <!-- * -->
            </v-autocomplete>
          </div>
          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>
          <!-- 搬入場所-->
          <div class="search-autocomplete" style="float: left">
            <v-text-field
              outlined
              dense
              v-model="headerDeliveryDestinationy"
              :label="$t('label.lbl_deliveryDestinationy')"
              class="txt-single"
              clear-icon="mdi-close-circle"
              maxlength="10"
              clearable
            />
          </div>
          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>
          <!-- 計画コード-->
          <div class="textbox" style="float: left">
            <v-text-field
              outlined
              dense
              maxlength="10"
              v-model="headerDeliveryPlan"
              :label="$t('label.lbl_deliveryPlanCd')"
              class="txt-single"
              clear-icon="mdi-close-circle"
              clearable
            />
          </div>
        </v-row>
        <v-row class="search-row">
          <div class="date-style first-search-item">
            <!-- 搬入日-->
            <v-text-field
              outlined
              dense
              maxlength="10"
              v-model="deliveryDate"
              :label="$t('label.lbl_deliveryDate')"
              :rules="[rules.yyyymmdd]"
              @change="changeDateDelivery"
              class="txt-single date-style"
              clear-icon="mdi-close-circle"
              clearable
            >
            </v-text-field>
          </div>
          <span class="require asterisk-spacer">*</span>
          <div class="calender-btn-area" style="position: relative; left: 7px">
            <!-- カレンダー -->
            <v-menu
              v-model="dateMenuDelivery"
              :close-on-content-click="false"
              :nudge-right="-100"
              transition="scale-transition"
              offset-y
              min-width="auto"
              class="date-calendar"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" text x-small>
                  <v-icon>mdi-calendar</v-icon>
                </v-btn>
              </template>
              <v-date-picker
                v-model="deliveryDateCal"
                @input="dateMenuDelivery = false"
              ></v-date-picker>
            </v-menu>
            <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>
          <!-- 検索区分(ユーザー/メーカー)-->
          <div style="float: left">
            <v-btn-toggle
              mandatory
              v-model="userMakerSected"
              color="blue darken-2"
              id="btn-toggle-btn-padding"
            >
              <v-btn
                class="paid-btn"
                v-for="item in userMakerList"
                :key="item.value"
                :value="item.value"
                small
              >
                {{ item.text }}</v-btn
              >
            </v-btn-toggle>
          </div>
          <!-- 空白 -->
          <span class="item-spacer" style="float: left">&nbsp;</span>
          <!-- 取引先プルダウン-->
          <div class="search-autocomplete">
            <v-autocomplete
              dense
              maxlength="100"
              v-model="clientSelected"
              :items="supplierList"
              :label="$t('label.lbl_supplier')"
              :hint="setSrhClient()"
              :rules="[rules.inputRequired]"
              class="txt-single"
              persistent-hint
              @change="changeClient"
              :search-input.sync="searchClient"
            >
              <!-- アイテム一覧の表示 -->
              <template slot="item" slot-scope="data">
                <span class="test">
                  {{ data.item.text }}
                </span>
              </template>
              <!-- * -->
            </v-autocomplete>
          </div>
          <span class="require asterisk-spacer">*</span>
          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>
          <!-- メーカー工場-->
          <div class="search-autocomplete" style="float: left">
            <v-text-field
              outlined
              dense
              v-model="makerFactory"
              :label="$t('label.lbl_makerFactory')"
              class="txt-single"
              clear-icon="mdi-close-circle"
              maxlength="7"
              clearable
            />
          </div>
        </v-row>
        <v-row>
          <div class="search-row-exeBtn">
            <!--ボタン領域-->
            <div class="btn-area" style="float: left">
              <!-- 検索ボタン-->
              <v-btn id="btn-pack" class="post-btn" @click="search('get-search')">
                {{ $t("btn.btn_search") }}
              </v-btn>
              <!-- 選択ボタン-->
              <v-btn id="btn-pack" class="post-btn" @click="select()">
                {{ $t("btn.btn_select") }}
              </v-btn>
            </div>
            <div class="btn-area">
              <!--閉じるボタン-->
              <v-btn class="post-btn" @click="close()">{{ $t("btn.btn_close") }}</v-btn>
            </div>
          </div>
        </v-row>
        <v-row>
          <div class="display-search-result-row">
            <!--検索結果表示-->
            <div class="text-label" style="float: left">
              <span class="toatlNum">総件数：{{ totalCount }}件</span>
            </div>

            <!--表示件数切替部分-->
            <div style="float: right">
              <!--表示件数-->
              <div class="text-label" style="clear: both; float: left">
                <div>{{ $t("label.lbl_itemsPerPage") }}</div>
              </div>

              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>

              <!--表示件数切替ボタン-->
              <div style="float: left">
                <v-btn-toggle
                  v-model="itemsPerPageBtn"
                  color="blue darken-2"
                  class="toggle black--text"
                  right
                >
                  <v-btn class="pasing-btn" value="10" @click="u_100_on()" small>
                    {{ 10 }}
                  </v-btn>
                  <v-btn class="pasing-btn" value="50" @click="u_100_clear(50)" small>
                    {{ 50 }}
                  </v-btn>
                  <v-btn class="pasing-btn" value="100" @click="u_100_clear(100)" small>
                    {{ 100 }}
                  </v-btn>
                </v-btn-toggle>
              </div>
            </div>
          </div>
          <!-- ページング数 -->
        </v-row>
      </v-container>
    </v-form>
    <!-- データテーブル -->
    <v-container fluid>
      <v-data-table
        id="listData"
        :headers="headerItems"
        :items="inputList"
        :fixed-header="true"
        disable-sort
        :hide-default-footer="true"
        height="440px"
        :page.sync="page"
        :server-items-length="itemsPerPage"
        disable-filtering
      >
        <!-- 検索結果ヘッダ情報 -->
        <template v-slot:[`header.makerSupplierCd`]="{ header }">
          <v-row>
            <v-col style="text-align: center">
              <div style="text-align: left">納入指示情報</div>
              <div>{{ header.text }}</div>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`header.userPlant`]="{ header }">
          <v-row>
            <v-col style="text-align: center">
              <div>&nbsp;</div>
              <div>{{ header.text }}</div>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`header.destinationyPlant`]="{ header }">
          <v-row>
            <v-col style="text-align: center">
              <div>&nbsp;</div>
              <div>{{ header.text }}</div>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`header.deliveryDestinationy`]="{ header }">
          <v-row>
            <v-col style="text-align: center">
              <div>&nbsp;</div>
              <div>{{ header.text }}</div>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`header.deliveryPlanCd`]="{ header }">
          <v-row>
            <v-col style="text-align: center">
              <div>&nbsp;</div>
              <div>{{ header.text }}</div>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`header.makerSupplierCd2`]="{ header }">
          <v-row>
            <v-col style="text-align: center">
              <div style="text-align: left">WMS管理項目</div>
              <div>{{ header.text }}</div>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`header.userDeliveryDestination`]="{ header }">
          <v-row>
            <v-col style="text-align: center">
              <div>&nbsp;</div>
              <div>{{ header.text }}</div>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`header.applicationStartDate`]="{ header }">
          <v-row>
            <v-col style="text-align: center">
              <div>&nbsp;</div>
              <div>{{ header.text }}</div>
            </v-col>
          </v-row>
        </template>
        <!-- ラジオボックス -->
        <template v-slot:[`item.choise`]="{ item, index }">
          <input type="radio" v-model="choise" :value="index" name="activity" />
        </template>
        <template v-slot:[`item.makerSupplierCd`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                class="text-overflow"
                style="max-width: 200px; text-align: left"
                v-bind="attrs"
                v-on="on"
              >
                {{ item.makerSupplierCd }}
              </div>
              <div class="text-overflow" style="max-width: 200px; text-align: left">
                {{ item.makerSupplierNm }}
              </div>
            </template>
            <div>{{ item.makerSupplierCd }}</div>
            <div>{{ item.makerSupplierNm }}</div>
          </v-tooltip>
        </template>
        <template v-slot:[`item.makerSupplierCd2`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                class="text-overflow"
                style="max-width: 200px; text-align: left"
                v-bind="attrs"
                v-on="on"
              >
                {{ item.makerSupplierCd2 }}
              </div>
              <div class="text-overflow" style="max-width: 200px; text-align: left">
                {{ item.makerSupplierNm2 }}
              </div>
            </template>
            <div>{{ item.makerSupplierCd2 }}</div>
            <div>{{ item.makerSupplierNm2 }}</div>
          </v-tooltip>
        </template>
        <template v-slot:[`item.userDeliveryDestination`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                class="text-overflow"
                style="max-width: 200px; text-align: left"
                v-bind="attrs"
                v-on="on"
              >
                {{ item.userDeliveryDestination }}
              </div>
              <div class="text-overflow" style="max-width: 200px; text-align: left">
                {{ item.userDeliveryDestinationNm }}
              </div>
            </template>
            <div>{{ item.userDeliveryDestination }}</div>
            <div>{{ item.userDeliveryDestinationNm }}</div>
          </v-tooltip>
        </template>
      </v-data-table>
      <!-- ページネーション -->
      <div class="text-center pt-2">
        <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
      </div>

      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <!-- 警告ダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ backTitle }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { i18n } from "../../lang/lang.js";
import Loading from "@/components/loading";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { dateUtil } from "../../assets/scripts/js/DateUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { getParameterProcurement } from "../../assets/scripts/js/GetParameterProcurement";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";

export default {
  name: "stkInquiryDetails",
  components: {
    Loading,
  },
  props: {
    entity: { type: Object, default: null },
    detailDialogData: {},
    hoge1: {
      default: "",
    },
    isOpenDetailDialog: { type: Boolean, default: false },
  },
  data: () => ({
    choise: null,
    updateDialogMessage: false,
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 取引先
    userSuppliersSelected: "",
    userSupplierList: [],
    destinationyPlant: "",
    headerDeliveryDestinationy: "",
    headerDeliveryPlan: "",
    deliveryDate: "",
    dateMenuDelivery: false,
    deliveryDateCal: "", //納入日カレンダー
    userMakerSected: "01",
    userMakerList: [],
    clientSelected: "",
    supplierList: [],
    destinationyPlantList: [],
    makerFactory: "",
    searchClient: "",
    totalCount: 0, // 総件数
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 0,
    itemsPerPage: 100,
    itemsPerPageBtn: "100",
    check: false,
    check1: false,
    backTitle: "",
    backMessage: "", // 戻るボタンダイアログメッセージ
    kbn: "",
    userClientCd: "",
    headerItems: [
      // 選択
      {
        text: i18n.tc("label.lbl_choice"),
        width: "5%",
        value: "choise",
        sortable: false,
        align: "center",
      },
      // メーカー取引先
      {
        text: i18n.tc("label.lbl_makerName") + i18n.tc("label.lbl_supplier"),
        width: "15%",
        value: "makerSupplierCd",
        sortable: false,
        align: "center",
      },
      // 工場
      {
        text: i18n.tc("label.lbl_userPlant"),
        width: "8%",
        value: "userPlant",
        sortable: false,
        align: "center",
      },
      // 搬入先工場
      {
        text: i18n.tc("label.lbl_destinationyPlant"),
        width: "8%",
        value: "destinationyPlant",
        sortable: false,
        align: "center",
      },
      // 搬入場所
      {
        text: i18n.tc("label.lbl_deliveryDestinationy"),
        width: "12%",
        value: "deliveryDestinationy",
        sortable: false,
        align: "center",
      },
      // 計画コード
      {
        text: i18n.tc("label.lbl_deliveryPlanCd"),
        width: "12%",
        value: "deliveryPlanCd",
        sortable: false,
        align: "center",
      },
      // メーカー取引先
      {
        text: i18n.tc("label.lbl_makerName") + i18n.tc("label.lbl_supplier"),
        value: "makerSupplierCd2",
        width: "15%",
        sortable: false,
        align: "center",
        class: "wmscolor",
      },
      // ユーザー納入先
      {
        text: i18n.tc("label.lbl_userDeliveryDestination"),
        value: "userDeliveryDestination",
        width: "15%",
        align: "center",
        sortable: false,
        class: "wmscolor",
      },
      // 適用開始日
      {
        text: i18n.tc("label.lbl_applicationStartDate"),
        value: "applicationStartDate",
        width: "10%",
        align: "center",
        sortable: false,
        class: "wmscolor",
      },
    ],
    // 仮データ
    inputList: [],
    dummy_input: {
      makerSupplierCd: "3214",
      makerSupplierNm: "BOSCH",
      userPlant: "",
      destinationyPlant: "S",
      deliveryDestinationy: "38D302A2",
      deliveryPlanCd: "S4AF03",
      makerSupplierCd2: "32140",
      makerSupplierNm2: "ボッシュ株式会社",
      makerSupplierSid: "6000380001",
      userDeliveryDestination: "6610030",
      userDeliveryDestinationNm: "ホンダ寄居工場",
      userDeliveryDestinationSid: "6066100001",
      applicationStartDate: "2023/07/01",
    },
    dummy_input1: {
      makerSupplierCd: "3214",
      makerSupplierNm: "BOSCH",
      userPlant: "",
      destinationyPlant: "S",
      deliveryDestinationy: "38D302A3",
      deliveryPlanCd: "S4AF02",
      makerSupplierCd2: "32140",
      makerSupplierNm2: "ボッシュ株式会社",
      makerSupplierSid: "6000380001",
      userDeliveryDestination: "6610030",
      userDeliveryDestinationNm: "ホンダ寄居工場",
      userDeliveryDestinationSid: "6066100001",
      applicationStartDate: "2023/07/01",
    },
    defaultItem: {
      value: "",
      text: "",
      name: "",
    },
    defaultCarryCompItem: {
      value: "",
      text: "",
      name: "",
      userClientSid: "",
    },
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
    },
    selection: [],
    rules: {
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
    },
  }),
  methods: {
    u_100_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_100_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
      this.page = 1;
      this.search("get-search");
    },
    u_100_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.search("get-search");
    },
    /**
     * 初期起動：画面を開いた際に渡された値を設定します。
     */
    init() {
      if (this.userMakerSected == "01") {
        this.check = false;
      }
      this.userMakerSected = this.entity.userMakerSected;
      this.inputList = [];
      this.choise = null;
      this.pageCount = 0;
      this.check1 = false;
      this.itemsPerPage = 100;
      this.totalCount = 0;
      this.userSuppliersSelected = this.entity.userSuppliersSelected;
      this.userSupplierList = this.entity.userSupplierList;
      //搬入日
      this.deliveryDate = this.entity.deliveryDate;
      this.deliveryDateCal = this.entity.deliveryDateCal;
      this.headerDeliveryPlan = this.entity.headerDeliveryPlan;
      this.headerDeliveryDestinationy = this.entity.headerDeliveryDestinationy;
      this.clientSelected = this.entity.clientSelected;
      this.supplierList = this.entity.supplierList;
      this.makerFactory = this.entity.makerFactory;
      this.kbn = this.entity.kbn;
      this.destinationyPlant = "";
      if (this.clientSelected == "") {
        this.check = true;
      }
      console.log(this.$refs.form1);
      if (this.$refs.form1 != undefined) {
        // バリデーションチェックをリセット
        this.$refs.form1.resetValidation();
      }
      this.getMstCode();
      this.getDeliveryComp();
    },
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // ユーザー・メーカー区分
      const userMakerList = getParameter.getCodeMst(appConfig.CODETYPE.USER_MAKER_SELECT);
      return Promise.all([userMakerList])
        .then((result) => {
          this.userMakerList = result[0];
        })
        .catch((ex) => {
          console.log(ex);
          this.updateDialogMessage = true;
          this.backMessage = appConfig.DIALOG.title;
          this.backTitle = "結果";
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    search(val) {
      //バリデーションチェック
      if (this.$refs.form1.validate()) {
        // ローディング画面表示ON
        this.loadingCounter = 1;
        const config = this.$httpClient.createGetApiRequestConfig();
        //ユーザー取引先
        config.params.userClientSid = this.userSuppliersSelected;
        //搬入先工場
        if (this.destinationyPlant) {
          config.params.deliveryToCd = this.destinationyPlant;
        }
        //搬入場所
        if (this.headerDeliveryDestinationy) {
          config.params.deliveryPlaceCd = this.headerDeliveryDestinationy;
        }
        //計画コード
        if (this.headerDeliveryPlan) {
          config.params.planCd = this.headerDeliveryPlan;
        }
        //搬入日
        config.params.deliveryDate = this.deliveryDate;
        //検索基準
        config.params.standard = this.userMakerSected;
        //取引先
        config.params.makerClientSid = this.clientSelected;
        //メーカー工場
        if (this.makerFactory) {
          config.params.clientPlantCd = this.makerFactory;
        }
        // ページャー処理
        if (val == "get-search") {
          config.params.reqComPageIndex = "1";
          this.page = 1;
        } else {
          // ページャー処理
          if (this.page >= 2 && this.inputList.length == 0) {
            config.params.reqComPageIndex = "1";
            this.page = 1;
          } else {
            config.params.reqComPageIndex = this.page;
          }
        }

        // ページング
        config.params.reqComPaginationFlg = "1";
        config.params.reqComPageLimit = this.itemsPerPage;
        //APIに渡す値をconfig.paramsにセット==================================//
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .secureGet(appConfig.API_URL.BIZ_SORTING_INFO_SEARCH, config)
            .then((response) => {
              // console.debug("apigetStock() Response", response);
              const jsonData = JSON.parse(JSON.stringify(response.data));
              if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                this.inputList = [];
                // this.inputList.push(Object.assign({}, this.dummy_input));
                // this.inputList.push(Object.assign({}, this.dummy_input1));
                this.pageCount = jsonData.resCom.resComPagination.totalPage;
                this.totalCount = jsonData.resCom.resComPagination.totalRecord;
                const list = [];

                this.selection = jsonData.resIdv.sortingInformation;
                for (let i = 0; i < this.selection.length; i++) {
                  list.push({
                    makerSupplierCd: this.selection[i].makerClientCd,
                    makerSupplierNm: this.selection[i].makerClientNm,
                    userPlant: this.selection[i].makerPlantCd,
                    destinationyPlant: this.selection[i].carryCompCd,
                    deliveryDestinationy: this.selection[i].deliveryPlaceCd,
                    deliveryPlanCd: this.selection[i].planCd,
                    makerSupplierCd2: this.selection[i].makerClientCdWms,
                    makerSupplierNm2: this.selection[i].makerClientNmWms,
                    makerSupplierSid: this.selection[i].makerClientSidWms,
                    userDeliveryDestination: this.selection[i].userDestinationCd,
                    userDeliveryDestinationNm: this.selection[i].userDestinationNm,
                    userDeliveryDestinationSid: this.selection[i].userDestinationSid,
                    applicationStartDate: this.selection[i].validFrom,
                    processDiv: this.selection[i].processDiv,
                  });
                }
                this.inputList = list;
                resolve(response);
              } else {
                this.updateDialogMessage = true;
                this.backMessage = jsonData.resCom.resComMessage;
                this.backTitle = "結果";
                reject(resolve);
              }
            })
            .catch((resolve) => {
              console.error("apigetStock() Resolve", resolve);
              this.updateDialogMessage = true;
              this.backMessage = messsageUtil.getMessage("P-999-999_999_E");
              this.backTitle = appConfig.DIALOG.title;
              reject(resolve);
            })
            .finally(() => {
              //ローディング画面表示OFF;
              this.loadingCounter = 0;
            });
        });
      }
    },
    //ダイアログを閉じる
    close() {
      this.$emit("closePopup");
      this.$emit("update:isOpenDetailDialog", false);
      this.inputList = [];
    },
    select() {
      //選択されているか確認
      if (this.choise == null) {
        this.updateDialogMessage = true;
        this.backMessage = messsageUtil.getMessage("P-SUP-002_001_E");
        this.backTitle = i18n.tc("btn.btn_ok");
      } else {
        this.datareturn();
      }
    },
    datareturn() {
      this.entity.userSuppliersSelected = this.userSuppliersSelected;
      this.entity.userSupplierList = this.userSupplierList;
      //計画コード
      this.entity.headerDeliveryPlan = this.inputList[this.choise].deliveryPlanCd;
      //搬入場所
      this.entity.headerDeliveryDestinationy = this.inputList[this.choise].deliveryDestinationy;
      //工場
      this.entity.userPlant = this.inputList[this.choise].userPlant;
      this.entity.userDeliveryDestination = this.inputList[this.choise].userDeliveryDestination;
      this.entity.clientSelected = this.inputList[this.choise].makerSupplierSid;
      this.entity.userDeliveryDestinationList = [];
      this.defaultCarryCompItem.value = this.inputList[this.choise].userDeliveryDestination;
      this.defaultCarryCompItem.name = this.inputList[this.choise].userDeliveryDestinationNm;
      this.defaultCarryCompItem.text =
        this.inputList[this.choise].userDeliveryDestination +
        " (" +
        this.inputList[this.choise].userDeliveryDestinationNm +
        ")";
      this.defaultCarryCompItem.userClientSid =
        this.inputList[this.choise].userDeliveryDestinationSid;
      this.entity.userDeliveryDestinationList.push(Object.assign({}, this.defaultCarryCompItem));
      this.entity.supplierList = [];
      this.defaultItem.value = this.inputList[this.choise].makerSupplierSid;
      this.defaultItem.name = this.inputList[this.choise].makerSupplierNm2;
      this.defaultItem.text =
        this.inputList[this.choise].makerSupplierCd2 +
        " (" +
        this.inputList[this.choise].makerSupplierNm2 +
        ")";
      this.entity.supplierList.push(Object.assign({}, this.defaultItem));
      this.$emit("sortingPopup");
      this.$emit("update:isOpenDetailDialog", false);
      this.inputList = [];
    },
    updateOk() {
      this.updateDialogMessage = false;
    },
    /**
     * ユーザー取引先リストボックス取得処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.userSupplierList.length; i++) {
        if (this.userSupplierList[i].value == this.userSuppliersSelected) {
          return this.userSupplierList[i].name;
        }
      }
    },
    /**
     * 取引先リストボックス取得処理
     */
    setSrhClient() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.clientSelected) {
          return this.supplierList[i].name;
        }
      }
    },

    /**
     * 納入先工場コードリストボックス取得処理
     */
    setDestinationyPlant() {
      for (var i = 0; i < this.destinationyPlantList.length; i++) {
        if (this.destinationyPlantList[i].value == this.destinationyPlant) {
          return this.destinationyPlantList[i].name;
        }
      }
    },
    changeUserSuppliersSelected() {
      // 取引先を変更する場合はクリアする
      this.clientSelected = "";
      this.supplierList = [];
      this.destinationyPlant = "";
      this.destinationyPlantList = [];
      this.userProductCd = "";
      this.userProductCdList = [];

      this.getDeliveryComp();
    },

    /**
     * 搬入先工場取得
     */
    getDeliveryComp() {
      // ユーザー取引先が存在する場合
      if (this.userSuppliersSelected) {
        for (let i = 0; i < this.userSupplierList.length; i++) {
          if (this.userSupplierList[i].value == this.userSuppliersSelected) {
            this.userClientCd = this.userSupplierList[i].clientCd;
          }
        }
        // ローディング画面表示ON
        this.loadingCounter = 1;
        const destinationyPlantList = getParameterProcurement.getBizCarryCompCd(
          this.userClientCd,
          dateTimeHelper.convertUTC(this.deliveryDate)
        );
        return Promise.all([destinationyPlantList])
          .then((result) => {
            // 画面の初期値を設定します。
            this.destinationyPlantList = result[0];
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      }
    },
    /**
     * 日付入力(納入日)
     */
    changeDateDelivery(val) {
      this.deliveryDateCal = commonFunction.directInput(val);
    },
    /**
     * 基準日を－１日します。
     */
    prevDate() {
      this.deliveryDateCal = commonFunction.addDate(this.deliveryDateCal, "");
    },
    /**
     * 基準日を＋１日します。
     */
    nextDate() {
      this.deliveryDateCal = commonFunction.addDate(this.deliveryDateCal, "add");
    },
    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      return dateUtil.formatDate(date);
    },
    /**
     * プルダウンチェンジイベント
     */
    changeClient(value) {
      // 取引先を選択時
      let val = this.supplierList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.selectHintClient = val.text;
        this.searchClient = val.text;
      }
      this.supplierList = this.supplierList.filter((v) => v.value == value);

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.selectHintClient = "";
      } else {
        // 処理なし
      }
    },
  },
  watch: {
    entity: {
      handler(newValue, oldValue) {
        console.info("handler", newValue, oldValue);
      },
    },
    userMakerSected() {
      if (this.check) {
        this.clientSelected = "";
        this.supplierList = [];
      } else {
        this.check = true;
      }
    },
    page: function (newValue) {
      this.page = newValue;
      this.search("");
    },
    searchClient(val) {
      if (!this.check1) {
        if (val == null) {
          this.check1 = true;
        }
        return;
      }
      if (val ?? false) {
        // 取引先が選択されてない場合、処理しない
        if (
          this.userMakerSected == "01" &&
          (this.userSuppliersSelected == null || this.userSuppliersSelected == "")
        ) {
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.selectHintClient) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        // 入力桁数が定義した数以上の場合検索処理
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // 選択した会社コードを取得

          const fromList =
            this.userMakerSected == "02"
              ? getParameter.getRelatedBizPartialMatch(
                  appConfig.ISGETCLIENT.CLIENT,
                  dateTimeHelper.convertUTC(this.arrivalScheduleDate),
                  undefined,
                  undefined,
                  val,
                  sessionStorage.getItem("sales_office_sid")
                )
              : getParameter.getUserdBizPartialMatch(
                  this.userSuppliersSelected,
                  val,
                  this.officeSid
                );

          Promise.all([fromList])
            .then((result) => {
              // 画面の初期値を設定します。

              this.supplierList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.supplierList.length = 0;
          this.supplierList = [];
        }
      } else {
        this.supplierList.length = 0;
        this.supplierList = [];
        this.clientSelected = "";
      }
    },
    // eslint-disable-next-line no-unused-vars
    isOpenDetailDialog: function (newValue) {
      if (newValue) {
        this.init();
      }
    },
    deliveryDateCal(val) {
      this.deliveryDate = this.formatDate(val);
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
#sub-bar {
  padding: none;
  height: 3rem;
}

.text-overflow {
  // v-tooltip
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#listData.v-data-table ::v-deep table {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
#listData.v-data-table ::v-deep th.wmscolor {
  background: #fce4d6;
}
</style>
