<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <!-- 関係先マスタ一覧 -->
      <NavBar tittle="label.lbl_relatedPartiesList" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <!-- １行目スタート -->
          <v-row class="search-row">
            <!-- 関係先区分 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="relatedPartiesKbn"
                :items="relatedPartiesKbnList"
                :label="$t('label.lbl_relatedPartiesKbn')"
                :rules="[rules.inputRequired]"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                pa-3
              >
                <template slot="item" slot-scope="data" class="nal">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <span class="item-spacer">&nbsp;</span>
            <!-- 顧客区分 -->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="clientKbn"
                :items="clientKbnList"
                :label="$t('label.lbl_clientKbn')"
                :hint="setSrhClient()"
                :rules="[rules.inputRequired]"
                @change="changeClientKbn(clientKbn)"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                pa-3
              >
                <template slot="item" slot-scope="data" class="nal">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <span class="item-spacer">&nbsp;</span>
            <!-- 取引先 -->
            <div class="search-autocomplete" v-if="clientKbn == '02'">
              <v-autocomplete
                dense
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="setSrhSupplier()"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data" class="nal">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="item-spacer">&nbsp;</span>

            <!-- コード -->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="clientBaseCode"
                :maxlength="clientBaselength"
                :label="clientBaseName"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :disabled="!clientKbn"
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 名称 -->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="clientBaseNm"
                maxlength="100"
                :label="name + $t('label.lbl_name')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :disabled="!clientKbn"
              />
            </div>
          </v-row>
          <v-row>
            <!-- 郵便番号 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="post"
                :items="postList"
                :label="$t('label.lbl_postCode')"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data" class="nal">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="item-spacer">&nbsp;&nbsp;</span>
            <!-- 基準日 -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="validFrom"
                class="txt-single date-style"
                :label="$t('label.lbl_refernceDate')"
                @change="changeDateFrom"
                :rules="[rules.inputRequired, rules.yyyymmdd]"
                clear-icon="mdi-close-circle"
                maxlength="10"
                clearable
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="validFromMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="validFromCal" @input="validFromMenu = false" />
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <span class="item-spacer">&nbsp;</span>
          </v-row>
          <v-row>
            <div class="search-row-exeBtn">
              <!--ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!--検索ボタン-->
                <v-btn class="get-btn" @click="btnSearch('get-search')">
                  {{ $t("btn.btn_search") }}</v-btn
                >
                <!--画面クリアボタン-->
                <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>
              <div class="btn-area">
                <!--新規登録ボタン-->
                <v-btn color="primary" id="btn-search" class="api-btn" @click="initRegist">{{
                  $t("btn.btn_iniRegist")
                }}</v-btn>
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span>総件数：{{ totalCount }}件</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  {{ $t("label.lbl_itemsPerPage") }}
                </div>
                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>

                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                    mandatory
                  >
                    <v-btn class="pasing-btn" value="100" @click="u_100_on()" small>
                      {{ 100 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="250" @click="u_100_clear(250)" small>
                      {{ 250 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="500" @click="u_100_clear(500)" small>
                      {{ 500 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
          <!-- ここから二行目 -->
        </v-container>
        <v-container fluid>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            :page.sync="page"
            :server-items-length="itemsPerPage"
            disable-filtering
            :options.sync="sortOptions"
            :hide-default-footer="true"
            height="480px"
          >
            <!-- 取引先 -->
            <template v-slot:[`item.supplierCd`]="{ item }">
              <v-row>
                <v-col v-if="item.supplierCd">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="text-overflow" style="max-width: 247px" v-bind="attrs" v-on="on">
                        {{ item.supplierCd }}
                      </div>
                      <div class="text-overflow" style="max-width: 247px" v-bind="attrs" v-on="on">
                        {{ item.supplierNm }}
                      </div>
                    </template>
                    <div>{{ item.supplierCd }}</div>
                    <div>{{ item.supplierNm }}</div>
                  </v-tooltip>
                </v-col>
                <v-col v-else>
                  <div>{{ item.supplierCd }}</div>
                  <div>{{ item.supplierNm }}</div>
                </v-col>
              </v-row>
            </template>
            <!-- 拠点 -->
            <template v-slot:[`item.baseCd`]="{ item }">
              <div>{{ item.baseCd }}</div>
              <div class="listNo-style" v-if="item.baseName">{{ item.baseName }}</div>
            </template>
            <!-- 住所 -->
            <template v-slot:[`item.address`]="{ item }">
              <div class="listNo-style">{{ item.address }}</div>
            </template>
            <!-- 詳細ボタン -->
            <template v-slot:[`item.details`]="{ item }">
              <v-btn small @click="openRelatedDetail(item)" text>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
          </div>
        </v-container>
      </v-form>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :okAction="postApi"
        :redMessage="ConfirmDialog.redMessage"
        :screenFlag="ConfirmDialog.screenFlag"
        :changeFlag="ConfirmDialog.changeFlag"
      />
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :okAction="postApi"
        :firstPageFlag="infoDialog.firstPageFlag"
        :locaMstFlg="infoDialog.locaMstFlg"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
      />
      <CheckDialog
        :isShow.sync="checkDialog.isOpen"
        :message="checkDialog.message"
        :screenFlag="checkDialog.screenFlag"
        :okAction="checkDialog.okAction"
        :redMessage="checkDialog.redMessage"
        :changeFlag="checkDialog.changeFlag"
      />
    </v-sheet>
  </div>
</template>

<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import ConfirmDialog from "../../components/ConfirmDialog";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { i18n } from "../../lang/lang.js";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import CheckDialog from "@/components/ConfirmDialog";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";

export default {
  name: appConfig.SCREEN_ID.P_MST_018,
  components: {
    ConfirmDialog,
    Loading,
    SimpleDialog,
    sideMenu,
    CheckDialog,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  mixins: [commonMixin, commonRules],
  data: () => ({
    //関係先区分
    relatedPartiesKbn: "01",
    relatedPartiesKbnList: [],
    //顧客区分
    clientKbn: "",
    clientKbnList: [],
    //取引先
    suppliersSelected: "",
    supplierList: [],
    //取引先・拠点コード
    clientBaseCode: "",
    clientBaseName: i18n.tc("label.lbl_supplier") + "/" + i18n.tc("label.lbl_baseCd"),
    clientBaselength: "0",
    //取引先・拠点名称
    clientBaseNm: "",
    clientBaselabel: "",
    //名称
    name: "",
    //基準日
    validFrom: "",
    validFromCal: "",
    validFromMenu: false,
    //郵便番号
    post: "",
    postList: [],
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // インデックス番号
    index: 0,
    // メニュー
    openMenu: null,
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // チェックボタンチェック後ダイアログ
    checkDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    inputList: [],
    // 初期データ
    defaultData: [],
    checkDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    // 総件数
    totalCount: 0,
    // ページング
    itemsPerPageList: [100, 250, 500],
    page: 1,
    pageCount: 1,
    itemsPerPage: 500,
    itemsPerPageBtn: "500",
    // ソート
    sortItem: "",
    sortOptions: {},
    // 検索条件保持
    relatedPartiesKbnSearch: "",
    clientKbnSearch: "",
    suppliersSelectedSearch: "",
    clientBaseCodeSearch: "",
    clientBaseNmSearch: "",
    validFromSearch: "",
    postSearch: "",
    // テーブルヘッダ項目
    headerItems: [
      //取引先コード
      {
        text: i18n.tc("label.lbl_supplier"),
        value: "supplierCd",
        width: "15%",
        align: "left",
        class: "vertical",
        sortable: true,
      },
      //拠点コード
      {
        text: i18n.tc("label.lbl_base"),
        value: "baseCd",
        width: "35%",
        align: "left",
        class: "vertical",
        sortable: true,
      },
      //拠点名略称
      {
        text: i18n.tc("label.lbl_base") + i18n.tc("label.lbl_itemNameShort"),
        value: "baseNameShort",
        width: "15%",
        align: "left",
        class: "vertical",
        sortable: true,
      },
      //住所
      {
        text: i18n.tc("label.lbl_popAddress"),
        value: "address",
        width: "20%",
        align: "left",
        sortable: false,
      },
      //輸送会社名
      {
        text: i18n.tc("label.lbl_shippingCompanyName"),
        value: "carrierCompName",
        width: "10%",
        align: "left",
        class: "vertical",
        sortable: false,
      },
      //詳細
      {
        text: i18n.tc("label.lbl_Detail"),
        value: "details",
        width: "5%",
        align: "center",
        sortable: false,
      },
    ],
  }),
  methods: {
    // ページング
    u_100_check() {
      if (this.itemsPerPage < 101) {
        this.u_10 = true;
      }
    },
    u_100_on() {
      this.u_10 = true;
      this.itemsPerPage = 100;
      this.btnSearch("get-search");
    },
    u_100_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.btnSearch("get-search");
    },
    // 初期化
    init() {
      this.loadingCounter = 1;
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);
      this.clientKbn = "02";
      this.changeClientKbn(this.clientKbn);

      //取引先、コードマスタ取得
      this.getCodeMst();
      if (
        this.$route.params.displayFlg == "2" &&
        this.$route.params.name == appConfig.SCREEN_ID.P_MST_017
      ) {
        this.validFrom = this.$route.params.validFrom;
        this.validFromCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(this.$route.params.validFrom.substr(0, 10))
        );
        this.post = this.$route.params.post;
      } else {
        const search = sessionStorage.getItem("MstRelatedPartiesList-search");
        if (search == "true") {
          this.validFrom = sessionStorage.getItem("MstRelatedPartiesList-validFrom");
          this.validFromCal = dateTimeHelper.dateCalc(
            dateTimeHelper.convertUTC2JST(sessionStorage.getItem("MstRelatedPartiesList-validFrom"))
          );
          this.post = sessionStorage.getItem("MstRelatedPartiesList-post");
        } else {
          //基準日
          this.validFrom = dateTimeHelper.convertJST().substr(0, 10);
          this.validFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        }
      }
    },

    /**
     * 取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },

    /**
     * 顧客区分リストボックス取得処理
     */
    setSrhClient() {
      for (var i = 0; i < this.clientKbnList.length; i++) {
        if (this.clientKbnList[i].value == this.clientKbn) {
          this.name = this.clientKbnList[i].text;
        }
      }
    },

    // コードマスタ
    getCodeMst() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 関係先区分
      const relatedPartiesKbnCode = getParameter.getCodeMst(appConfig.CODETYPE.RELATED_PARTY_DIV);
      // 顧客区分
      const clientKbnCode = getParameter.getCodeMst(appConfig.CODETYPE.CUSTOMER_DIV);
      // 未登録
      const postListCode = getParameter.getCodeMst(appConfig.CODETYPE.DETAILS_DIV);
      // 取引先
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(null)
      );

      Promise.all([relatedPartiesKbnCode, clientKbnCode, clientList, postListCode])
        .then((result) => {
          this.relatedPartiesKbnList = result[0];
          this.clientKbnList = result[1];
          this.supplierList = result[2];
          this.postList = result[3][0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          if (
            this.$route.params.displayFlg == "2" &&
            this.$route.params.name == appConfig.SCREEN_ID.P_MST_017
          ) {
            // this.relatedPartiesKbn = this.$route.params.relatedPartiesKbn;
            // this.clientKbn = this.$route.params.clientKbn;
            this.suppliersSelected = this.$route.params.suppliersSelected;
            this.clientBaseCode = this.$route.params.clientBaseCode;
            this.clientBaseNm = this.$route.params.clientBaseNm;
            this.btnSearch("");
          } else {
            const search = sessionStorage.getItem("MstRelatedPartiesList-search");
            if (search == "true") {
              //this.relatedPartiesKbn = sessionStorage.getItem("MstRelatedPartiesList-relatedPartiesKbn");
              //this.clientKbn = sessionStorage.getItem("MstRelatedPartiesList-clientKbn");
              this.suppliersSelected = sessionStorage.getItem(
                "MstRelatedPartiesList-suppliersSelected"
              );
              this.clientBaseCode = sessionStorage.getItem("MstRelatedPartiesList-clientBaseCode");
              this.clientBaseNm = sessionStorage.getItem("MstRelatedPartiesList-clientBaseNm");
              this.btnSearch("");
            } else {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            }
          }
        });
    },

    /**
     * 期限日を－１日します。
     */
    prevDate() {
      let date = new Date(this.validFromCal);
      this.validFromCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
    },

    /**
     * 期限日を＋１日します。
     */
    nextDate() {
      let date = new Date(this.validFromCal);
      this.validFromCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 日付入力(期限日)
     */
    changeDateFrom(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.validFromCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.validFromCal = null;
      }
    },

    /**
     * 検索ボタン
     */
    btnSearch(val) {
      if (this.$refs.form.validate()) {
        // ローディング画面表示ON
        this.loadingCounter = 1;
        const config = this.$httpClient.createGetApiRequestConfig();
        if (this.suppliersSelected) {
          let officeCd = this.supplierList.find(
            (element) => element.value === this.suppliersSelected
          ).clientSid;
          //会社コード
          config.params.compSid = officeCd;
        }
        //基準日
        config.params.referenceDate = dateTimeHelper.convertUTC(this.validFrom);
        config.params.relatedPartyDiv = this.relatedPartiesKbn;
        config.params.clientKbn = this.clientKbn;
        if (this.clientBaseCode) {
          config.params.clientBaseCode = this.clientBaseCode;
        }
        if (this.clientBaseNm) {
          config.params.clientBaseName = this.clientBaseNm;
        }
        if (this.post) {
          config.params.postCodeKbn = this.post;
        }

        if (val == "get-search") {
          config.params.reqComPageIndex = 1;
          this.page = 1;
        } else {
          // ページャー処理
          if (this.page >= 2 && this.inputList.length == 0) {
            config.params.reqComPageIndex = 1;
            this.page = 1;
          } else {
            config.params.reqComPageIndex = this.page;
          }
        }

        // ソート
        if (this.sortItem != "") {
          config.params.reqComSortItem = this.sortItem;
        }

        config.params.reqComPaginationFlg = "1";
        config.params.reqComPageLimit = this.itemsPerPage;

        //検索条件保存処理
        this.relatedPartiesKbnSearch = this.relatedPartiesKbn;
        this.clientKbnSearch = this.clientKbn;
        this.suppliersSelectedSearch = this.suppliersSelected;
        this.clientBaseCodeSearch = this.clientBaseCode;
        this.clientBaseNmSearch = this.clientBaseNm;
        this.validFromSearch = this.validFrom;
        this.postSearch = this.post;
        //sessionへ保持
        sessionStorage.setItem("MstRelatedPartiesList-relatedPartiesKbn", this.relatedPartiesKbn);
        sessionStorage.setItem("MstRelatedPartiesList-clientKbn", this.clientKbn);
        sessionStorage.setItem(
          "MstRelatedPartiesList-suppliersSelected",
          this.suppliersSelected ? this.suppliersSelected : ""
        );
        sessionStorage.setItem(
          "MstRelatedPartiesList-clientBaseCode",
          this.clientBaseCode ? this.clientBaseCode : ""
        );
        sessionStorage.setItem(
          "MstRelatedPartiesList-clientBaseNm",
          this.clientBaseNm ? this.clientBaseNm : ""
        );
        sessionStorage.setItem("MstRelatedPartiesList-validFrom", this.validFrom);
        sessionStorage.setItem("MstRelatedPartiesList-search", true);
        sessionStorage.setItem("MstRelatedPartiesList-post", this.post ? this.post : "");
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .secureGet(appConfig.API_URL.MST_RELATED_PARTY_LIST, config)
            .then((response) => {
              const jsonData = JSON.parse(JSON.stringify(response.data));
              // 正常時
              if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                const list = [];
                jsonData.resIdv.relatedPartys.forEach((row) => {
                  const from = dateTimeHelper.convertUTC2JST(row.validFrom).substr(0, 10);
                  const to = dateTimeHelper.convertUTC2JST(row.validTo).substr(0, 10);
                  const address = row.address != null ? row.address : "";
                  const addressDetail =
                    row.addressDetail != null
                      ? row.addressDetail.replace(address, "").trimRight()
                      : "";
                  list.push({
                    supplierCd: row.compCd,
                    supplierNm: row.compName,
                    baseCd: row.baseCd,
                    baseName: row.relatedPartyName,
                    baseNameShort: row.relatedPartyNameShort,
                    address: address + addressDetail,
                    validityPeriod: from + "-" + to,
                    carrierCompName:
                      row.deliveryTraderName != "" ? row.deliveryTraderName : row.carrierCompCdName,
                    relatedPartySid: row.relatedPartySid,
                  });
                });
                this.inputList = list;
                this.totalCount = commonUtil.formatToCurrency(Number(jsonData.resCom.resComCount));
                this.pageCount = jsonData.resCom.resComPagination.totalPage;
              } else {
                // エラー時
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;

                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },
    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.checkDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.checkDialog.isOpen = true;
      this.checkDialog.screenFlag = true;
      this.checkDialog.changeFlag = false;
      this.checkDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      this.validFrom = "";
      this.validTo = "";
      sessionStorage.setItem("MstRelatedPartiesList-search", false);
      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },
    postApi() {
      //
    },
    // 新規登録ボタン押下
    initRegist() {
      this.$router.push({
        name: appConfig.SCREEN_ID.P_MST_017,
        params: {
          displayFlg: "1",
        },
      });
    },
    /**
     * 詳細ボタン押下時
     */
    openRelatedDetail(item) {
      this.$router.push({
        name: appConfig.SCREEN_ID.P_MST_017,
        params: {
          // 検索条件を渡す
          relatedPartiesKbn: this.relatedPartiesKbnSearch,
          clientKbn: this.clientKbnSearch,
          suppliersSelected: this.suppliersSelectedSearch,
          clientBaseCode: this.clientBaseCodeSearch,
          clientBaseNm: this.clientBaseNmSearch,
          validFrom: this.validFromSearch,
          post: this.postSearch,
          // 選択したデータを渡す
          relatedPartySid: item.relatedPartySid,
          baseCd: item.baseCd,
          compSid: item.compSid,
          displayFlg: "2",
          name: appConfig.SCREEN_ID.P_MST_018,
        },
      });
    },
    changeClientKbn(val) {
      this.clientBaseCode = "";
      this.suppliersSelected = "";
      if (val == "01") {
        this.clientBaseName = i18n.tc("label.lbl_supplierCd");
        this.clientBaselength = "7";
      } else if (val == "02") {
        this.clientBaseName = i18n.tc("label.lbl_baseCd");
        this.clientBaselength = "30";
      } else {
        this.clientBaseName = i18n.tc("label.lbl_supplier") + "/" + i18n.tc("label.lbl_baseCd");
        this.clientBaselength = "0";
      }
    },
  },
  mounted() {
    this.init();
  },
  computed: {},
  watch: {
    validFromCal(val) {
      this.validFrom = this.formatDate(val);
    },
    page: function (newValue) {
      this.page = newValue;
      this.btnSearch("");
    },
    sortOptions: {
      handler(items) {
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_MST_018_SORT[sortBy].replaceAll("{0}", ascdesc); // + ascdesc;
          this.btnSearch();
        }
      },
      deep: true,
    },
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.search-textbox-remark {
  width: 41.5rem;
  margin-left: 15px;
}

.calender {
  display: flex;
}

// ロケグループ入力テキストボックス
.add-textbox {
  width: 10rem;
  padding: 20px 0 0 10px;
}

// 実行ボタンがある行
.search-row-exeBtns {
  width: 100%;
  margin-right: 12px;
}

// 検索項目間の空白
.item-spacers {
  margin-left: 50px;
  margin-right: 6px;
}

.isD {
  background-color: $color-warning !important;
}

.locationAlign {
  margin: 9px 0 0 0;
  height: 3.6rem;
  text-align: left;
}

// #listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper tbody td {
//   vertical-align: top;
// }
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper tbody td.vertical {
  vertical-align: top;
}
</style>
