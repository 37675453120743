<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_userItemMst" />
      <!-- （画面名）ユーザ品番マスタ-->
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="search-row">
            <!-- ユーザー取引先プルダウン-->
            <div class="serch-textbox-useritemCd first-search-item">
              <v-autocomplete
                dense
                maxlength="100"
                v-model="userSuppliersSelected"
                :items="userSupplierList"
                :label="$t('label.lbl_userSupplier')"
                :hint="setSrhSupplier()"
                class="txt-single"
                clear-icon="mdi-close-circle"
                persistent-hint
                :rules="[rules.inputRequired]"
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- アイテム一覧の表示 -->
              </v-autocomplete>
            </div>

            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- ユーザ基本品番 -->
            <div class="textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                maxlength="50"
                v-model="userBaseProductCd"
                :label="$t('label.lbl_userBaseProductCd')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- ユーザ品番 -->
            <div class="textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                maxlength="50"
                v-model="userProductCd"
                :label="$t('label.lbl_userProductCd')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
          </v-row>
          <v-row>
            <div class="search-row-exeBtn">
              <!--ボタン領域-->
              <div class="btn-area" style="float: left">
                <!-- 検索ボタン-->
                <v-btn id="btn-pack" class="post-btn" @click="search('get-search')">
                  {{ $t("btn.btn_search") }}
                </v-btn>
                <!-- 画面クリアボタン-->
                <v-btn id="btn-pack" class="post-btn" @click="clearScreen">
                  {{ $t("btn.btn_clear") }}
                </v-btn>
              </div>
              <div class="btn-area">
                <!-- 追加ボタン -->
                <v-btn color="primary" class="api-btn" v-on:click="add" :disabled="isDoAddButton">
                  {{ $t("btn.btn_add") }}
                </v-btn>
                <!--修正選択ボタン -->
                <v-btn
                  :disabled="this.serchFlag != true || inputList.length == 0"
                  color="primary"
                  class="api-btn"
                  v-on:click="correctionCheck"
                >
                  {{ $t("btn.btn_fix") + $t("btn.btn_select") }}
                </v-btn>
                <!-- 削除ボタン -->
                <v-btn
                  :disabled="this.serchFlag != true || inputList.length == 0"
                  id="btn-pack"
                  class="post-btn"
                  @click="deleteCheck()"
                >
                  {{ $t("btn.btn_delete") }}
                </v-btn>
                <!--登録/修正ボタン-->
                <v-btn
                  v-if="this.updateFlg == false"
                  :disabled="this.serchFlag != false || inputList.length == 0"
                  class="post-btn"
                  @click="insert()"
                  >{{ insertBtn }}</v-btn
                >
                <v-btn
                  v-if="this.updateFlg == true"
                  :disabled="this.serchFlag != false || inputList.length == 0"
                  class="post-btn"
                  @click="update()"
                  >{{ $t("btn.btn_fix") }}</v-btn
                >
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span class="toatlNum">総件数：{{ totalCount }}件</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right; padding-left: 20px">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  <div>{{ $t("label.lbl_itemsPerPage") }}</div>
                </div>

                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>
                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                  >
                    <v-btn class="pasing-btn" value="100" @click="u_100_on()" small>
                      {{ 100 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="250" @click="u_100_clear(250)" small>
                      {{ 250 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="500" @click="u_100_clear(500)" small>
                      {{ 500 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
            <!-- ページング数 -->
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-form ref="editedList" lazy-validation>
        <v-container fluid>
          <v-data-table
            id="listData"
            :class="tableData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            :page.sync="page"
            :ripple="false"
            :server-items-length="itemsPerPage"
            disable-filtering
            :hide-default-footer="true"
            :options.sync="sortOptions"
          >
            <!-- ヘッダ情報 -->
            <!-- ユーザー品番 -->
            <template v-if="this.serchFlag == false" v-slot:[`header.userProductCd`]="{ header }">
              <v-row>
                <v-col>
                  <div>{{ header.text }}<span class="require asterisk-spacer">*</span></div>
                </v-col>
              </v-row>
            </template>
            <!-- 基本品番 -->
            <template
              v-if="this.serchFlag == false"
              v-slot:[`header.userBaseProductCd`]="{ header }"
            >
              <v-row>
                <v-col>
                  <div>{{ header.text }}<span class="require asterisk-spacer">*</span></div>
                </v-col>
              </v-row>
            </template>
            <!-- ユーザー品名 -->
            <template
              v-if="this.serchFlag == false"
              v-slot:[`header.userProductCodeName`]="{ header }"
            >
              <v-row>
                <v-col>
                  <div>{{ header.text }}<span class="require asterisk-spacer">*</span></div>
                </v-col>
              </v-row>
            </template>
            <!-- ヘッダ情報 -->
            <!-- 選択 -->
            <template v-slot:[`item.choise`]="{ item, index }">
              <input
                type="checkbox"
                v-model="item.choise"
                :disabled="tableCheck"
                style="transform: scale(2)"
                @change="checkList(item, index)"
              />
            </template>
            <!-- ユーザー品番 -->
            <template v-slot:[`item.userProductCd`]="{ item, index }">
              <div v-if="item.enabledFlg == true">
                <v-text-field
                  class="txt-single"
                  dense
                  outlined
                  v-model="item.userProductCd"
                  :rules="[
                    rules.inputRequiredTable(
                      item.userProductCd,
                      item.userBaseProductCd,
                      item.userProductCodeName,
                      item.accessoryProduct
                    ),
                    rules.inputRequiredItem(
                      item.userProductCd,
                      item.accessoryProduct,
                      inputList,
                      index
                    ),
                  ]"
                  maxlength="50"
                  clear-icon="mdi-close-circle"
                  clearable
                  persistent-hint
                  margin-bottom="0"
                  style="padding-top: 10px"
                />
              </div>
              <div v-else-if="item.userProductCd.length > 13">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <div class="text-overflow">{{ item.userProductCd }}</div>
                    </div>
                  </template>
                  <div class="listNo-style">{{ item.userProductCd }}</div>
                </v-tooltip>
              </div>
              <div v-else>{{ item.userProductCd }}</div>
            </template>
            <!-- 付帯品番 -->
            <template v-slot:[`item.accessoryProduct`]="{ item, index }">
              <div v-if="item.enabledFlg == true">
                <v-text-field
                  class="txt-single"
                  dense
                  outlined
                  maxlength="50"
                  v-model="item.accessoryProduct"
                  clear-icon="mdi-close-circle"
                  clearable
                  margin-bottom="0"
                  style="padding-top: 10px"
                  :rules="[
                    rules.inputRequiredItem(
                      item.userProductCd,
                      item.accessoryProduct,
                      inputList,
                      index
                    ),
                  ]"
                />
              </div>
              <div v-else-if="item.accessoryProduct.length > 13">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <div class="text-overflow">{{ item.accessoryProduct }}</div>
                    </div>
                  </template>
                  <div class="listNo-style">{{ item.accessoryProduct }}</div>
                </v-tooltip>
              </div>
              <div v-else>{{ item.accessoryProduct }}</div>
            </template>
            <!-- 基本品番 -->
            <template v-slot:[`item.userBaseProductCd`]="{ item }">
              <div v-if="item.enabledFlg == true">
                <v-text-field
                  class="txt-single"
                  dense
                  outlined
                  v-model="item.userBaseProductCd"
                  :rules="[
                    rules.inputRequiredTable(
                      item.userBaseProductCd,
                      item.userProductCodeName,
                      item.userProductCd,
                      item.accessoryProduct
                    ),
                  ]"
                  maxlength="50"
                  clear-icon="mdi-close-circle"
                  clearable
                  margin-bottom="0"
                  style="padding-top: 10px"
                />
              </div>
              <div v-else-if="item.userBaseProductCd.length > 13">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <div class="text-overflow">{{ item.userBaseProductCd }}</div>
                    </div>
                  </template>
                  <div class="listNo-style">{{ item.userBaseProductCd }}</div>
                </v-tooltip>
              </div>
              <div v-else>{{ item.userBaseProductCd }}</div>
            </template>
            <!-- ユーザー品名 -->
            <template v-slot:[`item.userProductCodeName`]="{ item }">
              <div v-if="item.enabledFlg == true">
                <v-text-field
                  class="txt-single"
                  dense
                  outlined
                  v-model="item.userProductCodeName"
                  :rules="[
                    rules.inputRequiredTable(
                      item.userProductCodeName,
                      item.userProductCd,
                      item.userBaseProductCd,
                      item.accessoryProduct
                    ),
                  ]"
                  maxlength="100"
                  clear-icon="mdi-close-circle"
                  clearable
                  margin-bottom="0"
                  style="padding-top: 10px"
                />
              </div>
              <div v-else-if="item.userProductCodeName.length > 17">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <div class="text-overflow-itemname">{{ item.userProductCodeName }}</div>
                    </div>
                  </template>
                  <div class="listNo-style">{{ item.userProductCodeName }}</div>
                </v-tooltip>
              </div>
              <div v-else>{{ item.userProductCodeName }}</div>
            </template>
            <!-- 詳細ボタン -->
            <template v-slot:[`item.detail`]="{}">
              <v-btn small disabled text>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <!-- リンク -->
            <template v-slot:[`item.linkMstAdd`]="{ item }">
              <div v-if="item.linkstyle == true">
                <a style="color: red" @click="linkPut(item)">{{ item.linkMstAdd }}</a>
              </div>
              <div v-else>
                <a style="color: #0040ff" @click="linkPut(item)">{{ item.linkMstAdd }}</a>
              </div>
            </template>
          </v-data-table>
          <!-- ページネーション -->
          <div class="text-center pt-2">
            <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
          </div>
        </v-container>
      </v-form>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <!-- 確認メッセージダイアログモーダル-->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :homePageFlag="infoDialog.homePageFlag"
      />
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :changeFlag="confirmDialog.changeFlag"
      />
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <!-- 警告ダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="alertDialog" :max-width="800">
        <!-- 警告ダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ checkMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="alertCancel"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import ConfirmDialog from "@/components/ConfirmDialog";

export default {
  name: appConfig.MENU_ID.P_SUP_USER_ITEM_MASTER,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    NavBar,
    ConfirmDialog,
  },
  props: {
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    checkFlg: false,
    // ローディング画面表示フラグ
    loadingCounter: 0,
    u_100: false,
    rules: {
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      necessityCheck: (value, value1) => !!value || !value1 || i18n.tc("check.chk_input"),
      inputRequiredStock: (value, value1, value2) => {
        if (value2) {
          if (value == "" || value == null) {
            if (value1 != "" && value1 != null) {
              if (value1 == "01" || value1 == "02") {
                return i18n.tc("check.chk_input");
              }
            }
          }
        }
        return true;
      },
      inputRequiredGuarantee: (value, value1, value2) => {
        if (value2) {
          if (value == "" || value == null) {
            if (value1 != "" && value1 != null) {
              if (value1 == "01" || value1 == "03") {
                return i18n.tc("check.chk_input");
              }
            }
          }
        }
        return true;
      },
      inputRequiredTable: (value, value1, value2, value3) => {
        if (
          (value1 != "" && value1 != null) ||
          (value2 != "" && value2 != null) ||
          (value3 != "" && value3 != null)
        ) {
          if (value == "" || value == null) {
            return i18n.tc("check.chk_input");
          }
        }
        return true;
      },
      inputRequiredItem: (value, value1, list, index) => {
        var check1 = "";
        var check2 = "";
        if (value != null && value != undefined) {
          check1 = value;
        }
        if (value1 != null && value1 != undefined) {
          check2 = value1;
        }
        if (check1 != "" || check2 != "") {
          for (var i = 0; i < list.length; i++) {
            if (index != i) {
              if (list[i].userProductCd == check1 && list[i].accessoryProduct == check2) {
                return i18n.tc("check.chk_input_item_accessory");
              }
            }
          }
        }
        return true;
      },
    },

    // 取引先
    userSuppliersSelected: "",
    userSupplierList: [],
    userBaseProductCd: "",
    userProductCd: "",
    isDoAddButton: false,
    inputList: [], // データテーブル表示データ
    totalCount: 0, // 総件数
    openMenu: null, // メニュー
    userSuppliersSerch: "",
    serchFlag: true,
    // 警告ダイアログ
    alertDialog: false,
    checkMessage: "",
    // ページング
    itemsPerPageList: [100, 250, 500],
    checkindexList: [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 500,
    itemsPerPageBtn: "500",
    //検索条件保持用
    userSuppliersSelectedSerach: "",
    userBaseProductCdSerach: "",
    userProductCdSerach: "",
    // 確認メッセージ
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
    },

    tableCheck: false,
    insertFlg: false,

    // バリデーションチェックメッセージダイアログ
    alertMessage: "",
    alertSupplierMessage: "",
    alertClientMessage: "",
    timeAlertMessage: "",
    insertBtn: "",
    clearFlg: false, // クリアフラグ

    updateDialogMessage: false, // 戻るボタンダイアログ
    backMessage: "", // 戻るボタンダイアログメッセージ
    // ソート
    sortItem: "",
    sortOptions: {},
    updateFlg: false,

    chengeSortBy: "",
    chengeAscdesc: "",
    tableData: "tableData",
    // ヘッダ
    headerItems: [
      // 選択
      {
        text: i18n.tc("label.lbl_choice"),
        width: "4%",
        value: "choise",
        sortable: false,
        align: "center",
      },
      // ユーザー品番
      {
        text: i18n.tc("label.lbl_userProductCd"),
        width: "20%",
        value: "userProductCd",
        sortable: true,
        align: "left",
      },
      // 付帯品番
      {
        text: i18n.tc("label.lbl_accessoryProduct"),
        width: "18%",
        value: "accessoryProduct",
        sortable: true,
        align: "left",
      },
      // ユーザー基本品番
      {
        text: i18n.tc("label.lbl_userBaseProductCd"),
        width: "20%",
        value: "userBaseProductCd",
        sortable: true,
        align: "left",
      },
      // ユーザー品名
      {
        text: i18n.tc("label.lbl_userProductCodeName"),
        width: "20%",
        value: "userProductCodeName",
        sortable: true,
        align: "left",
      },
      // 詳細
      {
        text: i18n.tc("label.lbl_Detail"),
        width: "7%",
        value: "detail",
        sortable: false,
        align: "center",
      },
      //リンク
      {
        text: i18n.tc("label.lbl_linkMstAdd"),
        width: "12%",
        value: "linkMstAdd",
        sortable: false,
        align: "center",
      },
    ],
    defaultItem: {
      choise: false, // 選択
      userProductCd: "", // ユーザー品番
      accessoryProduct: "", // ユーザー品番付属情報（色番等）
      userBaseProductCd: "", // ユーザー基本品番
      userProductCodeName: "", // ユーザー品名
      detail: "", // 詳細
      linkMstAdd: "-", // リンク
      userItemSid: null, // ユーザ品番SID
      updateDatetime: "", // 更新日時
      enabledFlg: true, // 有効フラグ
      clientSid: null, // 取引先SID
      updateFlg: 0, // 更新フラグ
      linkstyle: false,
    },
  }),
  created() {},

  methods: {
    u_100_check() {
      if (this.itemsPerPage < 101) {
        this.u_100 = true;
      }
    },
    u_100_on() {
      this.u_100 = true;
      this.itemsPerPage = 100;
      this.page = 1;
      this.search(false);
    },
    u_100_clear(val) {
      this.u_100 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.search(false);
    },

    /**
     * 初期データ取得
     */
    getCustomInfo(jstInScheduleDate) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const supplierList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(jstInScheduleDate),
        void 0,
        void 0
      );
      return Promise.all([supplierList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.userSupplierList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 警告ダイアログ、OKボタン押下
     */
    updateOk() {
      this.updateDialogMessage = false;
    },

    /**
     * 警告ダイアログ、OKボタン押下
     */
    alertCancel() {
      this.alertDialog = false;
    },

    /**
     * 削除ボタン押下時
     */
    deleteCheck() {
      //チェックボックスの件数チェック
      let count = 0;
      for (let i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].choise) {
          count++;
          break;
        }
      }
      //チェック件数が1件以上の場合
      if (count > 0) {
        this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
          i18n.tc("btn.btn_delete"),
        ]);
        this.confirmDialog.isOpen = true;
        this.confirmDialog.screenFlag = true;
        this.confirmDialog.changeFlag = false;
        this.confirmDialog.okAction = this.deleteOrder;
      } else {
        //エラーメッセージ表示
        this.alertDialog = true;
        this.checkMessage = messsageUtil.getMessage("P-EXT-003_001_E");
        return;
      }
    },

    /**
     * 削除ボタン押下時(POST)
     */
    deleteOrder() {
      this.loadingCounter = 1;
      // 初期表示の設定
      let deleteArray = [];
      let deleteList = this.inputList.filter((element) => {
        return element.choise === true;
      });
      for (let i = 0; i < deleteList.length; i++) {
        let item = deleteList[i];
        deleteArray.push({
          clientSid: item.clientSid,
          userItemSid: item.userItemSid,
          userItemCd: item.userProductCdOld,
          userItemBaseCd: item.userBaseProductCdOld,
          userName: item.userName,
          userItemName: item.userProductCodeName,
          userItemCd2: item.userItemCd2,
          deleteFlg: "1",
          updateDatetime: item.updateDatetime,
        });
      }
      const config = this.$httpClient.createRequestBodyConfig();
      config.reqIdv.userItem1 = deleteArray;
      config.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SUP_019;

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.MST_UPDATE_USER_ITEM_INFO, config, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              this.infoDialog.message = messsageUtil.getMessage("P-MST-014_001_C");
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = false;
              // 画面遷移ボタン
              this.infoDialog.homePageFlag = false;
              this.infoDialog.firstPageFlag = true;
              this.reSearch();
            } else {
              // エラーメッセージ表示
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.isOpen = true;
              this.infoDialog.title = appConfig.DIALOG.title;
              // OK NGボタン
              this.infoDialog.firstPageFlag = true;
            }
          })
          .catch(() => {
            this.infoDialog.message = messsageUtil.getMessage("A-999-999_001_E");
            this.infoDialog.isOpen = true;
            this.infoDialog.title = appConfig.DIALOG.title;
            // OK NGボタン
            this.infoDialog.firstPageFlag = true;
            reject(messsageUtil.getMessage("A-999-999_001_E"));
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * 追加ボタン押下時
     */
    add() {
      // 追加ボタンをdisabledに
      this.isDoAddButton = true;
      //検索したデータが存在するかチェック
      if (this.serchFlag || this.updateFlg) {
        this.inputList = [];
        this.checkindexList = [];
        this.tableheadUpdate(false);
        //検索フラグ更新
        this.serchFlag = false;
        //チェックボックスフラグ更新
        this.tableCheck = true;
      }
      this.updateFlg = false;
      //行追加
      for (var i = 0; i < 10; i++) {
        this.inputList.push(Object.assign({}, this.defaultItem));
      }
      this.pageCount = 1;
      this.totalCount = this.inputList.length;
      // xミリ秒待機したのちに、追加ボタンをenabledに
      setTimeout(this.enabledAddButton, 500);
    },
    /**
     * tablehead内容更新
     */
    tableheadUpdate(val) {
      //headerItems内削除
      this.headerItems.splice(0, this.headerItems.length);
      // ヘッダ
      this.headerItems.push(
        // 選択
        {
          text: i18n.tc("label.lbl_choice"),
          width: "4%",
          value: "choise",
          sortable: false,
          align: "center",
        },
        // ユーザー品番
        {
          text: i18n.tc("label.lbl_userProductCd"),
          width: "20%",
          value: "userProductCd",
          sortable: val,
          align: "left",
        },
        // 付帯品番
        {
          text: i18n.tc("label.lbl_accessoryProduct"),
          width: "18%",
          value: "accessoryProduct",
          sortable: val,
          align: "left",
        },
        // ユーザー基本品番
        {
          text: i18n.tc("label.lbl_userBaseProductCd"),
          width: "20%",
          value: "userBaseProductCd",
          sortable: val,
          align: "left",
        },
        // ユーザー品名
        {
          text: i18n.tc("label.lbl_userProductCodeName"),
          width: "20%",
          value: "userProductCodeName",
          sortable: val,
          align: "left",
        },
        // 詳細
        {
          text: i18n.tc("label.lbl_Detail"),
          width: "7%",
          value: "detail",
          sortable: false,
          align: "center",
        },
        //リンク
        {
          text: i18n.tc("label.lbl_linkMstAdd"),
          width: "12%",
          value: "linkMstAdd",
          sortable: false,
          align: "center",
        }
      );
    },

    /**
     * 追加ボタンを有効化する
     */
    enabledAddButton() {
      this.$refs.editedList.validate();
      this.isDoAddButton = false;
    },

    /**
     * 登録ボタン押下
     */
    insert() {
      //バリデーションチェック
      if (this.$refs.form.validate()) {
        if (this.$refs.editedList.validate()) {
          //入力されているかチェック
          let count = 0;
          for (let i = 0; i < this.inputList.length; i++) {
            if (this.inputList[i].userProductCd) {
              count++;
            }
          }
          if (count == 0) {
            //エラーメッセージ表示
            this.alertDialog = true;
            this.checkMessage = messsageUtil.getMessage("P-SUP-019_001_E");
            return;
          }
          this.confirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
          this.confirmDialog.isOpen = true;
          this.confirmDialog.screenFlag = true;
          this.confirmDialog.changeFlag = false;
          this.confirmDialog.okAction = this.insertOrder;
        }
      }
    },
    /**
     * 登録(POST)
     */
    insertOrder() {
      //読み込み中ON
      this.loadingCounter = 1;
      // 初期表示の設定
      let insertArray = [];
      let insertList = this.inputList.filter((element) => {
        return element.userProductCd;
      });
      for (let i = 0; i < insertList.length; i++) {
        let item = insertList[i];
        insertArray.push({
          clientSid: this.userSuppliersSelected,
          userItemSid: null,
          userItemCd: item.userProductCd.padEnd(50, " ") + item.accessoryProduct.padEnd(50, " "),
          userItemBaseCd:
            item.userBaseProductCd.padEnd(50, " ") + item.accessoryProduct.padEnd(50, " "),
          //userName: "",
          userItemName: item.userProductCodeName,
          userItemCd2: item.userProductCd + item.accessoryProduct,
          deleteFlg: "",
          updateDatetime: null,
        });
      }
      const config = this.$httpClient.createRequestBodyConfig();
      config.reqIdv.userItem1 = insertArray;
      config.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SUP_019;

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.MST_INSERT_USER_ITEM_INFO, config, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              //画面クリア
              this.insertFlg = true;
              this.clearScreenOkClick();
            } else {
              // エラーメッセージ表示
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.isOpen = true;
              this.infoDialog.title = appConfig.DIALOG.title;
              // OK NGボタン
              this.infoDialog.firstPageFlag = true;
            }
          })
          .catch(() => {
            this.infoDialog.message = messsageUtil.getMessage("A-999-999_001_E");
            this.infoDialog.isOpen = true;
            this.infoDialog.title = appConfig.DIALOG.title;
            // OK NGボタン
            this.infoDialog.firstPageFlag = true;
            reject(messsageUtil.getMessage("A-999-999_001_E"));
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * チェックボックス押下処理
     */
    checkList(item, index) {
      if (item.choise) {
        this.checkindexList.push(index);
      } else {
        this.checkindexList = this.checkindexList.filter(function (items) {
          return items !== index;
        });
      }
    },
    /**
     * 修正選択ボタン押下時
     */
    correctionCheck() {
      //チェックボックスの件数チェック
      if (this.checkindexList.length == 0) {
        //エラーメッセージ表示
        this.alertDialog = true;
        this.checkMessage = messsageUtil.getMessage("P-EXT-003_001_E");
        return;
      }
      this.tableheadUpdate(false);
      //検索フラグ更新
      this.serchFlag = false;
      //チェックボックスフラグ更新
      this.tableCheck = true;
      this.updateFlg = true;
      //対象行修正可能に変更
      for (let i = 0; i < this.checkindexList.length; i++) {
        this.inputList[this.checkindexList[i]].enabledFlg = true;
      }
      setTimeout(this.enabledAddButton, 500);
    },

    /**
     * 修正ボタン押下
     */
    update() {
      //バリデーションチェック
      if (this.$refs.form.validate()) {
        if (this.$refs.editedList.validate()) {
          this.confirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
          this.confirmDialog.isOpen = true;
          this.confirmDialog.screenFlag = true;
          this.confirmDialog.changeFlag = false;
          this.confirmDialog.okAction = this.updateOrder;
        }
      }
    },

    /**
     * 修正ボタン押下(POST)
     */
    updateOrder() {
      this.loadingCounter = 1;
      // 初期表示の設定
      let updateArray = [];
      let updateList = this.inputList.filter((element) => {
        return element.choise === true;
      });
      for (let i = 0; i < updateList.length; i++) {
        let item = updateList[i];
        updateArray.push({
          clientSid: item.clientSid,
          userItemSid: item.userItemSid,
          userItemCd: item.userProductCd.padEnd(50, " ") + item.accessoryProduct.padEnd(50, " "),
          userItemBaseCd:
            item.userBaseProductCd.padEnd(50, " ") + item.accessoryProduct.padEnd(50, " "),
          userName: item.userName,
          userItemName: item.userProductCodeName,
          userItemCd2: item.userProductCd + item.accessoryProduct,
          deleteFlg: "0",
          updateDatetime: item.updateDatetime,
        });
      }
      const config = this.$httpClient.createRequestBodyConfig();
      config.reqIdv.userItem1 = updateArray;
      config.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SUP_019;

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.MST_UPDATE_USER_ITEM_INFO, config, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = false;
              // 画面遷移ボタン
              this.infoDialog.homePageFlag = false;
              this.infoDialog.firstPageFlag = true;
              this.reSearch();
              //再検索
            } else {
              // エラーメッセージ表示
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.isOpen = true;
              this.infoDialog.title = appConfig.DIALOG.title;
              // OK NGボタン
              this.infoDialog.firstPageFlag = true;
            }
          })
          .catch(() => {
            this.infoDialog.message = messsageUtil.getMessage("A-999-999_001_E");
            this.infoDialog.isOpen = true;
            this.infoDialog.title = appConfig.DIALOG.title;
            // OK NGボタン
            this.infoDialog.firstPageFlag = true;
            reject(messsageUtil.getMessage("A-999-999_001_E"));
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 再検索
     */
    reSearch() {
      this.$refs.editedList.resetValidation();
      const config = this.$httpClient.createGetApiRequestConfig();
      //ユーザー取引先
      config.params.clientSid = this.userSuppliersSelectedSerach;
      //基本品番
      config.params.userItemBaseCd = this.userBaseProductCdSerach;
      //ユーザー品番
      config.params.userItemCd = this.userProductCdSerach;

      // ページャー処理
      config.params.reqComPageIndex = this.page;

      // ページング
      config.params.reqComPaginationFlg = "1";
      config.params.reqComPageLimit = this.itemsPerPage;
      this.searchGet(config);
    },

    /**
     *  検索ボタン押下処理
     */
    search(val) {
      //バリデーションチェック
      if (this.$refs.form.validate()) {
        this.$refs.editedList.resetValidation();
        // ローディング画面表示ON
        this.loadingCounter = 1;
        const config = this.$httpClient.createGetApiRequestConfig();
        //ユーザー取引先
        config.params.clientSid = this.userSuppliersSelected;
        //基本品番
        config.params.userItemBaseCd = this.userBaseProductCd;
        //ユーザー品番
        config.params.userItemCd = this.userProductCd;

        //検索条件保存
        this.userSuppliersSelectedSerach = this.userSuppliersSelected;
        this.userBaseProductCdSerach = this.userBaseProductCd;
        this.userProductCdSerach = this.userProductCd;

        // ページャー処理
        if (val == "get-search") {
          config.params.reqComPageIndex = "1";
          this.page = 1;
        } else {
          // ページャー処理
          if (this.page >= 2 && this.inputList.length == 0) {
            config.params.reqComPageIndex = "1";
            this.page = 1;
          } else {
            config.params.reqComPageIndex = this.page;
          }
        }
        // ソート
        if (this.sortItem != "") {
          config.params.reqComSortItem = this.sortItem;
          config.params.reqComSortAscdesc = this.sortOptions[this.sortItem];
        }
        //検索条件保存
        //ユーザー取引先
        for (var i = 0; i < this.userSupplierList.length; i++) {
          if (this.userSupplierList[i].value == this.userSuppliersSelected) {
            this.userSuppliersCdSerach = this.userSupplierList[i].text;
          }
        }
        // ページング
        config.params.reqComPaginationFlg = "1";
        config.params.reqComPageLimit = this.itemsPerPage;
        this.searchGet(config);
      }
    },
    /**
     * 検索(GET)
     */
    searchGet(config) {
      //APIに渡す値をconfig.paramsにセット==================================//
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_USER_ITEM_INFO_GET, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              this.inputList = [];
              this.pageCount = jsonData.resCom.resComPagination.totalPage;
              this.totalCount = jsonData.resCom.resComPagination.totalRecord;
              const list = [];
              this.updateFlg = false;
              //headerItems内削除
              this.headerItems.splice(0, this.headerItems.length);
              // ヘッダ
              this.headerItems.push(
                // 選択
                {
                  text: i18n.tc("label.lbl_choice"),
                  width: "4%",
                  value: "choise",
                  sortable: false,
                  align: "center",
                },
                // ユーザー品番
                {
                  text: i18n.tc("label.lbl_userProductCd"),
                  width: "20%",
                  value: "userProductCd",
                  sortable: true,
                  align: "left",
                },
                // 付帯品番
                {
                  text: i18n.tc("label.lbl_accessoryProduct"),
                  width: "18%",
                  value: "accessoryProduct",
                  sortable: true,
                  align: "left",
                },
                // ユーザー基本品番
                {
                  text: i18n.tc("label.lbl_userBaseProductCd"),
                  width: "20%",
                  value: "userBaseProductCd",
                  sortable: true,
                  align: "left",
                },
                // ユーザー品名
                {
                  text: i18n.tc("label.lbl_userProductCodeName"),
                  width: "20%",
                  value: "userProductCodeName",
                  sortable: true,
                  align: "left",
                },
                // 詳細
                {
                  text: i18n.tc("label.lbl_Detail"),
                  width: "7%",
                  value: "detail",
                  sortable: false,
                  align: "center",
                },
                //リンク
                {
                  text: i18n.tc("label.lbl_linkMstAdd"),
                  width: "12%",
                  value: "linkMstAdd",
                  sortable: false,
                  align: "center",
                }
              );
              //チェックボックスフラグ更新
              this.tableCheck = false;
              this.selection = jsonData.resIdv.userItem1;
              for (let i = 0; i < this.selection.length; i++) {
                list.push({
                  choise: false,
                  userProductCd: this.selection[i].userItemCd.substring(0, 50).trimRight(), // ユーザー品番
                  userProductCdOld: this.selection[i].userItemCd, // ユーザー品番
                  accessoryProduct: this.selection[i].userItemCd.slice(-50).trimRight(), // ユーザー品番付属情報（色番等）
                  userBaseProductCd: this.selection[i].userItemBaseCd.substring(0, 50).trimRight(), // ユーザー基本品番
                  userBaseProductCdOld: this.selection[i].userItemBaseCd, // ユーザー基本品番
                  userProductCodeName: this.selection[i].userItemName, // ユーザー品名
                  detail: "", // 詳細
                  linkMstAdd: this.selection[i].linkMasterStauts == "01" ? "未登録" : "登録有", // リンク
                  userItemSid: this.selection[i].userItemSid, // ユーザ品番SID
                  updateDatetime: this.selection[i].updateDatetime, // 更新日時
                  enabledFlg: false, // 有効フラグ
                  clientSid: this.selection[i].clientSid, // 取引先SID
                  updateFlg: this.selection[i].updateFlg, // 更新フラグ
                  linkstyle: this.selection[i].linkMasterStauts == "01" ? true : false,
                  userName: this.selection[i].userName, // ユーザー名
                  userItemCd2: this.selection[i].userItemCd2, // ユーザー品番2
                });
              }
              this.checkindexList = [];
              this.inputList = list;
              if (this.inputList.length != 0) {
                this.serchFlag = true;
              }
              resolve(response);
            } else {
              this.updateDialogMessage = true;
              this.backMessage = jsonData.resCom.resComMessage;
              this.backTitle = "結果";
              reject(resolve);
            }
          })
          .catch((resolve) => {
            console.error("apigetStock() Resolve", resolve);
            this.updateDialogMessage = true;
            this.backMessage = messsageUtil.getMessage("P-999-999_999_E");
            this.backTitle = appConfig.DIALOG.title;
            reject(resolve);
          })
          .finally(() => {
            //ローディング画面表示OFF;
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 初期値
     */
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);
      //ユーザーメーカーリンク画面からの遷移の場合
      if (
        this.$route.params.name == appConfig.MENU_ID.P_SUP_USER_MAKER_LINK &&
        this.clearFlg == false
      ) {
        //初期表示の設定
        this.userSuppliersSelected = this.$route.params.userClientSid;
        this.userSuppliersSelectedSerach = this.$route.params.userClientSid;
        this.userBaseProductCd = this.$route.params.userBaseProductCdSerach;
        this.userBaseProductCdSerach = this.$route.params.userBaseProductCdSerach;
        this.userProductCd = this.$route.params.userProductCdSerach;
        this.userProductCdSerach = this.$route.params.userProductCdSerach;
        this.page = this.$route.params.page;
        this.itemsPerPage = this.$route.params.itemsPerPage;
        this.itemsPerPageBtn = this.$route.params.itemsPerPage.toString();
        this.sortItem = this.$route.params.sortItem;
        this.reSearch();
      }
      this.insertBtn = i18n.tc("btn.btn_insert");
      this.getCustomInfo();
    },

    /**
     * リンクボタン押下時
     */
    linkPut(item) {
      //ユーザーメーカーリンク画面へ遷移
      this.$router.push({
        name: appConfig.MENU_ID.P_SUP_USER_MAKER_LINK,

        params: {
          name: appConfig.MENU_ID.P_SUP_USER_ITEM_MASTER,
          //基本品番
          userBaseProductCd: item.userBaseProductCdOld.substring(0, 50).trimRight(),
          //ユーザー取引先SID
          userClientSid: this.userSuppliersSelected,
          //付帯品番
          accessoryProduct:
            item.userBaseProductCdOld.substring(0, 50).trimRight() +
            " " +
            item.userBaseProductCdOld.slice(-50).trimRight(),
          //検索条件-ユーザー取引先
          userSuppliersCdSerach: this.userSuppliersCdSerach,
          //検索条件-基本品番
          userBaseProductCdSerach: this.userBaseProductCdSerach,
          //検索条件-ユーザー品番
          userProductCdSerach: this.userProductCdSerach,
          //ページャー
          page: this.page,
          //ページング
          itemsPerPage: this.itemsPerPage,
          //ソート
          sortItem: this.sortItem,
        },
      });
    },

    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },

    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      var dialogCheckFlg = this.insertFlg;
      this.confirmDialog.isOpen = false;
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);

      this.clearFlg = true;

      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
      if (dialogCheckFlg) {
        //
        this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
        this.infoDialog.title = "結果";
        this.infoDialog.isOpen = true;
        this.infoDialog.screenFlag = true;
        // 画面遷移ボタン
        this.infoDialog.homePageFlag = false;
        this.infoDialog.firstPageFlag = true;
      }
    },

    /**
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },
    /**
     * チェックボックス取得処理
     */
    onInputCheck(item) {
      if (item.check) {
        for (let i = 0; i < this.inputList.length; i++) {
          this.inputList[i].value = true;
        }
      } else {
        for (let i = 0; i < this.inputList.length; i++) {
          this.inputList[i].check = false;
          this.inputList[i].value = false;
        }
      }
    },
    /**
     * ユーザー取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.userSupplierList.length; i++) {
        if (this.userSupplierList[i].value == this.userSuppliersSelected) {
          return this.userSupplierList[i].name;
        }
      }
    },
  },

  mounted() {
    this.init();
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    page: function (newValue) {
      this.page = newValue;
      this.search(false);
    },
    // ソート処理
    sortOptions: {
      handler(items) {
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          this.chengeSortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.chengeAscdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_SUP_019_SORT[sortBy].replaceAll("{0}", ascdesc);

          this.search(false);
        }
      },
      deep: true,
    },
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}

.autoUpdate ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
  width: 110px;
}

.serch-textbox-arrivalNo ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
  width: 153px;
}

#listData.v-data-table ::v-deep .v-data-table__wrapper {
  max-height: 592px;
  height: 100%;
}

.textbox {
  width: 11.2rem;
}

.toatlNum {
  margin-left: 10px;
}

a {
  text-decoration: underline;
}

.v-btn.v-btn--has-bg.cargos_btn {
  text-transform: none;
  background: #dee6f3;
}

.cargos_btn ::v-deep .v-btn__content {
  color: #000 !important;
  font-weight: normal !important;
}

.v-btn.v-btn--has-bg.btn_search {
  width: 5.5rem;
}
.date-style {
  width: 11rem;
}

.text-overflow {
  // v-tooltip
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-overflow-itemname {
  // v-tooltip
  width: 340px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#listData.v-data-table ::v-deep table {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
#listData.v-data-table ::v-deep th.wmscolor {
  background: #fce4d6;
}
.calender {
  display: flex;
}
tr td div.calender-btn-area {
  margin-top: 16px;
}
.v-text-field.text-box {
  margin: 0px;
  padding-top: 11px;
  height: auto;
}

::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  max-height: 97%;
}

#listData.v-data-table ::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px;
}

.listNo-style {
  word-break: break-all;
  max-width: 300px;
}
</style>
