/**
 * パスワード共通
 */
export default class PasswordUtil {
  /** パスワード生成に使用する文字 */
  CHARSET_az_AZ_09 =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!#$%()=-^¥[@]:;{}_/.0123456789";
  CHARSET_az = "abcdefghijklmnopqrstuvwxyz";
  CHARSET_AZ = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  CHARSET_09 = "0123456789";
  CHARSET_ki = "!#$%()=-^¥[@]:;{}_/.";
  CHARSET_14 = "1234";
  maximum = 5;
  passValidation = {
    regex: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!-/:-@¥[-`{-~])[a-zA-Z0-9!-/:-@¥[-`{-~]*$/,
  };
  /**
   * パスワード生成
   * @param {20} length : 生成桁数
   * @param {CHARSET_az_AZ_09} characterSet : 生成に使用する文字
   * @returns
   */
  passwordGeneration(length = 20) {
    let rand_str = "";
    let check = "";
    let CHARSET_1519 = "1234";
    for (var i = 1; i <= length; i++) {
      if (i % this.maximum != 0) {
        check = this.CHARSET_14.charAt(Math.floor(Math.random() * this.CHARSET_14.length));
      } else {
        check = CHARSET_1519.charAt(Math.floor(Math.random() * CHARSET_1519.length));
        CHARSET_1519 = CHARSET_1519.replace(check, "");
      }
      if (check == "1") {
        rand_str += this.CHARSET_az.charAt(Math.floor(Math.random() * this.CHARSET_az.length));
      } else if (check == "2") {
        rand_str += this.CHARSET_AZ.charAt(Math.floor(Math.random() * this.CHARSET_AZ.length));
      } else if (check == "3") {
        rand_str += this.CHARSET_09.charAt(Math.floor(Math.random() * this.CHARSET_09.length));
      } else if (check == "4") {
        rand_str += this.CHARSET_ki.charAt(Math.floor(Math.random() * this.CHARSET_ki.length));
      }
    }

    return rand_str;
  }
}

export const passwordUtil = new PasswordUtil();
