<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_deliveryOrderSorting" />
      <!-- （画面名）搬入指示仕分マスタ-->
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="search-row">
            <!-- ユーザー取引先プルダウン-->
            <div class="serch-textbox-useritemCd first-search-item">
              <v-autocomplete
                dense
                maxlength="100"
                v-model="userSuppliersSelected"
                :items="userSupplierList"
                :label="$t('label.lbl_userSupplier')"
                :hint="setSrhSupplier()"
                class="txt-single"
                clear-icon="mdi-close-circle"
                persistent-hint
                @change="changeUserSuppliersSelected"
                :rules="[rules.inputRequired]"
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- アイテム一覧の表示 -->
              </v-autocomplete>
            </div>

            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <div class="date-style">
              <!-- 搬入日-->
              <v-text-field
                outlined
                dense
                maxlength="10"
                v-model="deliveryDate"
                :label="$t('label.lbl_refernceDate')"
                :rules="[rules.yyyymmdd]"
                @change="changeDateDelivery"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
              >
              </v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area" style="position: relative; left: 7px">
              <!-- カレンダー -->
              <v-menu
                v-model="dateMenuDelivery"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="deliveryDateCal"
                  @input="dateMenuDelivery = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 仕分マスタ登録-->
            <v-btn-toggle
              mandatory
              v-model="sortingMst"
              color="blue darken-2"
              class="toggle black--text"
            >
              <v-btn
                mandatory
                class="res-btn"
                v-for="item in sortingMstList"
                :key="item.value"
                :value="item.value"
                small
              >
                {{ item.text }}</v-btn
              >
            </v-btn-toggle>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 在庫営業所 -->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="officeSelected"
                :items="officeList"
                :label="$t('menu.txt_stock') + $t('label.lbl_salesOffice')"
                persistent-hint
                :hint="setOffice(officeSelected)"
                margin-bottom="0"
                pa-3
                :disabled="sortingMst == '01'"
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 保証営業所 -->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="guaranteeOfficeSelected"
                :items="officeList"
                :label="$t('label.lbl_guarantee') + $t('label.lbl_salesOffice')"
                persistent-hint
                :hint="setGuaranteeOffice()"
                margin-bottom="0"
                pa-3
                :disabled="sortingMst == '01'"
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 要否 -->
            <div class="textbox">
              <v-autocomplete
                dense
                v-model="necessity"
                :items="necessityList"
                :label="$t('label.lbl_necessity')"
                persistent-hint
                margin-bottom="0"
                pa-3
                :hint="setSrhNecessity(necessity)"
                :disabled="sortingMst == '01'"
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
          </v-row>
          <v-row class="search-row" style="margin-bottom: 5px">
            <!-- 検索区分(ユーザー/メーカー)-->
            <div style="float: left" class="first-search-item">
              <v-btn-toggle
                mandatory
                v-model="userMakerSected"
                color="blue darken-2"
                id="btn-toggle-btn-padding"
              >
                <v-btn
                  class="paid-btn"
                  v-for="item in userMakerList"
                  :key="item.value"
                  :value="item.value"
                  small
                  :disabled="sortingMst == '01'"
                >
                  {{ item.text }}</v-btn
                >
              </v-btn-toggle>
            </div>
            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>
            <!-- 取引先プルダウン-->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                maxlength="100"
                v-model="clientSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="setSrhClient()"
                class="txt-single"
                persistent-hint
                @change="changeClient"
                :search-input.sync="searchClient"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- メーカー工場-->
            <div class="textbox" style="float: left">
              <v-autocomplete
                dense
                maxlength="7"
                v-model="makerFactory"
                :items="makerFactoryList"
                :label="$t('label.lbl_makerFactory')"
                :hint="setMakerFactory()"
                class="txt-single"
                persistent-hint
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 搬入場所-->
            <div class="search-autocomplete" style="float: left">
              <v-autocomplete
                dense
                maxlength="10"
                v-model="headerDeliveryDestinationy"
                :items="deliveryDestinationyList"
                :label="$t('label.lbl_deliveryDestinationy')"
                :hint="setDestinationy()"
                class="txt-single"
                persistent-hint
                @change="changeDestinationy"
                :search-input.sync="searchDestinationy"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 計画コード-->
            <div class="search-autocomplete" style="float: left">
              <v-autocomplete
                dense
                maxlength="10"
                v-model="headerDeliveryPlan"
                :items="deliveryPlanCdList"
                :label="$t('label.lbl_deliveryPlanCd')"
                :hint="setSrhPlanCd()"
                class="txt-single"
                persistent-hint
                @change="changePlanCd"
                :search-input.sync="searchPlanCd"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
          </v-row>
          <v-row>
            <div class="search-row-exeBtn">
              <!--ボタン領域-->
              <div class="btn-area" style="float: left">
                <!-- 検索ボタン-->
                <v-btn id="btn-pack" class="post-btn" @click="search('get-search')">
                  {{ $t("btn.btn_search") }}
                </v-btn>
                <!-- 画面クリアボタン-->
                <v-btn id="btn-pack" class="post-btn" @click="clearScreen">
                  {{ $t("btn.btn_clear") }}
                </v-btn>
              </div>
              <div class="btn-area">
                <!--登録ボタン-->
                <v-btn class="post-btn" @click="insert()" :disabled="totalCount == 0">{{
                  insertBtn
                }}</v-btn>
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span class="toatlNum">総件数：{{ totalCount }}件</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right; padding-left: 20px">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  <div>{{ $t("label.lbl_itemsPerPage") }}</div>
                </div>

                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>
                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                  >
                    <v-btn class="pasing-btn" value="10" @click="u_100_on()" small>
                      {{ 10 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="50" @click="u_100_clear(50)" small>
                      {{ 50 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="100" @click="u_100_clear(100)" small>
                      {{ 100 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
            <!-- ページング数 -->
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-form ref="editedList" lazy-validation>
        <v-container fluid>
          <v-data-table
            id="listData"
            :headers="headerItems"
            :items="inputList"
            :fixed-header="true"
            disable-sort
            :hide-default-footer="true"
            :page.sync="page"
            :server-items-length="itemsPerPage"
            disable-filtering
          >
            <!-- 検索結果ヘッダ情報 -->
            <template v-slot:[`header.choise`]="{ header }">
              <v-row>
                <v-col style="text-align: center">
                  <div>&nbsp;</div>
                  <div>{{ header.text }}</div>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`header.makerSupplierCd`]="{ header }">
              <v-row>
                <v-col style="text-align: center">
                  <div style="text-align: left">納入指示情報</div>
                  <div>{{ header.text }}</div>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`header.userPlant`]="{ header }">
              <v-row>
                <v-col style="text-align: center">
                  <div>&nbsp;</div>
                  <div>{{ header.text }}</div>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`header.destinationyPlant`]="{ header }">
              <v-row>
                <v-col style="text-align: center">
                  <div>&nbsp;</div>
                  <div>{{ header.text }}</div>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`header.deliveryDestinationy`]="{ header }">
              <v-row>
                <v-col style="text-align: center">
                  <div>&nbsp;</div>
                  <div>{{ header.text }}</div>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`header.deliveryPlanCd`]="{ header }">
              <v-row>
                <v-col style="text-align: center">
                  <div>&nbsp;</div>
                  <div>{{ header.text }}</div>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`header.productConfig`]="{ header }">
              <v-row>
                <v-col style="text-align: center">
                  <div>&nbsp;</div>
                  <div>{{ header.text }}</div>
                </v-col>
              </v-row>
            </template>
            <!-- 要否 -->
            <template v-slot:[`header.necessity`]="{ header }">
              <v-row>
                <v-col style="text-align: center">
                  <div style="text-align: left">WMS管理項目</div>
                  <div>{{ header.text }}<span class="require asterisk-spacer">*</span></div>
                </v-col>
              </v-row>
            </template>
            <!-- 在庫営業所 -->
            <template v-slot:[`header.stockOffice`]="{ header }">
              <v-row>
                <v-col style="text-align: center">
                  <div>&nbsp;</div>
                  <div>{{ header.text }}<span class="require asterisk-spacer">*</span></div>
                </v-col>
              </v-row>
            </template>
            <!-- 保証営業所 -->
            <template v-slot:[`header.guaranteeOffice`]="{ header }">
              <v-row>
                <v-col style="text-align: center">
                  <div>&nbsp;</div>
                  <div>{{ header.text }}<span class="require asterisk-spacer">*</span></div>
                </v-col>
              </v-row>
            </template>
            <!-- メーカー取引先 -->
            <template v-slot:[`header.makerSupplierCd2`]="{ header }">
              <v-row>
                <v-col style="text-align: center">
                  <div>&nbsp;</div>
                  <div>{{ header.text }}</div>
                </v-col>
              </v-row>
            </template>
            <!-- ユーザー納入先 -->
            <template v-slot:[`header.userDeliveryDestination`]="{ header }">
              <v-row>
                <v-col style="text-align: center">
                  <div>&nbsp;</div>
                  <div>{{ header.text }}</div>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`header.applicationStartDate`]="{ header }">
              <v-row>
                <v-col style="text-align: center">
                  <div>&nbsp;</div>
                  <div>{{ header.text }}<span class="require asterisk-spacer">*</span></div>
                </v-col>
              </v-row>
            </template>
            <!-- ラジオボックス -->
            <template v-slot:[`item.choise`]="{ item }">
              <input type="checkbox" v-model="item.choise" style="transform: scale(2)" />
            </template>
            <template v-slot:[`item.makerSupplierCd`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="text-overflow"
                    style="max-width: 125px; text-align: left"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item.makerSupplierCd }}
                  </div>
                  <div class="text-overflow" style="max-width: 125px; text-align: left">
                    {{ item.makerSupplierNm }}
                  </div>
                </template>
                <div>{{ item.makerSupplierCd }}</div>
                <div>{{ item.makerSupplierNm }}</div>
              </v-tooltip>
            </template>
            <!-- 品番設定 -->
            <template v-slot:[`item.productConfig`]="{ item, index }">
              <v-btn small @click="openUpdate(index)" text style="font-size: medium">
                <v-icon>mdi-pencil</v-icon>{{ item.productConfig }}
              </v-btn>
            </template>
            <!-- 要否 -->
            <template v-slot:[`item.necessity`]="{ item }">
              <v-autocomplete
                dense
                v-model="item.necessity"
                :items="necessityList"
                persistent-hint
                margin-bottom="0"
                pa-3
                @change="(event) => changeNecessityCd(event, item)"
                :rules="[rules.necessityCheck(item.necessity, item.choise)]"
                :hint="setSrhNecessity(item.necessity)"
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </template>
            <!-- 在庫営業所 -->
            <template v-slot:[`item.stockOffice`]="{ item, index }">
              <v-autocomplete
                dense
                v-model="item.stockOffice"
                :items="officeList"
                persistent-hint
                :hint="setOffice(item.stockOffice)"
                :rules="[rules.inputRequiredStock(item.stockOffice, item.necessity, item.choise)]"
                margin-bottom="0"
                pa-3
                @change="changeOfficeNm(index)"
                :disabled="item.necessity == '02'"
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </template>
            <!-- 保証営業所 -->
            <template v-slot:[`item.guaranteeOffice`]="{ item }">
              <v-autocomplete
                dense
                v-model="item.guaranteeOffice"
                :items="officeList"
                persistent-hint
                :hint="setOffice(item.guaranteeOffice)"
                :rules="[
                  rules.inputRequiredGuarantee(item.guaranteeOffice, item.necessity, item.choise),
                ]"
                margin-bottom="0"
                pa-3
                :disabled="item.necessity == '03'"
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </template>
            <!-- メーカー取引先 -->
            <template v-slot:[`item.makerSupplierCd2`]="{ item, index }">
              <v-autocomplete
                dense
                maxlength="100"
                v-model="item.makerSupplierCd2"
                :items="item.makerSupplierList"
                persistent-hint
                :hint="setMaker(item.makerSupplierCd2, index)"
                :search-input.sync="item.makerSupplierCd2search"
                :rules="[
                  rules.inputRequiredStock(item.makerSupplierCd2, item.necessity, item.choise),
                ]"
                @change="(event) => changeMakerNm(event, item, index)"
                margin-bottom="0"
                pa-3
                :ref="'makerSupplierCd' + index"
                :disabled="item.necessity == '02'"
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </template>
            <!-- ユーザー納入先 -->
            <template v-slot:[`item.userDeliveryDestination`]="{ item, index }">
              <v-autocomplete
                dense
                maxlength="100"
                v-model="item.userDeliveryDestination"
                :items="item.userDeliveryDestinationList"
                persistent-hint
                :hint="setDeliveryDestination(item.userDeliveryDestination, index)"
                :search-input.sync="item.userDeliveryDestinationsearch"
                :rules="[
                  rules.inputRequiredStock(
                    item.userDeliveryDestination,
                    item.necessity,
                    item.choise
                  ),
                ]"
                @change="(event) => changeDeliveryNm(event, item, index)"
                margin-bottom="0"
                pa-3
                :ref="'userDeliveryDestination' + index"
                :disabled="item.necessity == '02'"
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </template>
            <!-- 適用開始日 -->
            <template v-slot:[`item.applicationStartDate`]="{ item, index }">
              <div class="calender table-date-style">
                <v-text-field
                  outlined
                  dense
                  maxlength="10"
                  v-model="item.applicationStartDate"
                  class="txt-single table-date-style text-box"
                  @change="changeDateFrom(item.applicationStartDate, index)"
                  :rules="[
                    rules.yyyymmdd,
                    rules.dateCompareFrom(
                      item.applicationStartDate,
                      item.applicationStartDateOld,
                      item.choise
                    ),
                    rules.dateCompareTo(item.applicationStartDate, item.validToOld, item.choise),
                  ]"
                  clear-icon="mdi-close-circle"
                  clearable
                  :ref="'applicationStartDate' + index"
                ></v-text-field>
                <div class="calender-btn-area">
                  <v-menu
                    v-model="item.applicationStartMenuDate"
                    :close-on-content-click="false"
                    :nudge-right="-100"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    class="date-calendar"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" text x-small>
                        <v-icon>mdi-calendar</v-icon>
                      </v-btn>
                    </template>
                    <v-date-picker
                      v-model="item.applicationStartDateCal"
                      @input="inputCallenderFrom(item.applicationStartDateCal, index)"
                    />
                  </v-menu>
                </div>
              </div>
            </template>
            <!-- コピー -->
            <template v-slot:[`item.copyBtn`]="{ item, index }">
              <v-btn
                v-if="index != 0"
                class="post-btn"
                @click="itemCopy(item, index)"
                style="margin: 0px"
                >{{ $t("btn.btn_aboveCopy") }}</v-btn
              >
            </template>
          </v-data-table>

          <!-- ページネーション -->
          <div class="text-center pt-2">
            <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
          </div>
        </v-container>
      </v-form>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <!-- 搬入計画仕分マスタ-品番単位画面 -->
      <v-dialog v-model="sortingInfodialogSearch" :max-width="2000" persistent no-click-animation>
        <mstDeliveryOrderSortingItem
          id="selectionDialig"
          :isOpenDetailDialog.sync="sortingInfodialogSearch"
          :entity="sortingInfoSearchDialogData"
          class="overflow"
          v-on:sortingPopup="sortingPopup"
        />
      </v-dialog>

      <!-- 確認メッセージダイアログモーダル-->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :homePageFlag="infoDialog.homePageFlag"
      />
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :changeFlag="confirmDialog.changeFlag"
      />
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <!-- 警告ダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="alertDialog" :max-width="800">
        <!-- 警告ダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ checkMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="alertCancel"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { getParameterProcurement } from "../../assets/scripts/js/GetParameterProcurement";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import ConfirmDialog from "@/components/ConfirmDialog";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";
import mstDeliveryOrderSortingItem from "../procurement/MstDeliveryOrderSortingItem.vue";

export default {
  name: appConfig.MENU_ID.BIZ_DELIVERY_INSTRUCTIONS_SEARCH,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    NavBar,
    ConfirmDialog,
    mstDeliveryOrderSortingItem,
  },
  props: {
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    u_100: false,
    rules: {
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      necessityCheck: (value, value1) => !!value || !value1 || i18n.tc("check.chk_input"),
      inputRequiredStock: (value, value1, value2) => {
        if (value2) {
          if (value == "" || value == null) {
            if (value1 != "" && value1 != null) {
              if (value1 == "01" || value1 == "03") {
                return i18n.tc("check.chk_input");
              }
            }
          }
        }
        return true;
      },
      inputRequiredGuarantee: (value, value1, value2) => {
        if (value2) {
          if (value == "" || value == null) {
            if (value1 != "" && value1 != null) {
              if (value1 == "01" || value1 == "02") {
                return i18n.tc("check.chk_input");
              }
            }
          }
        }
        return true;
      },
      //日付比較チェック(From)
      dateCompareFrom: (value, value1, value2) => {
        if (value2) {
          if (value && value1) {
            if (value <= value1) {
              return i18n.tc("check.chk_inputFutureDate2", 0, [value1]);
            }
          }
        }
        return true;
      },
      //日付比較チェック(To)
      dateCompareTo: (value, value1, value2) => {
        if (value2) {
          if (value && value1) {
            if (value >= value1) {
              return i18n.tc("check.chk_inputPastDate2", 0, [value1]);
            }
          }
        }
        return true;
      },
    },

    // 取引先
    userSuppliersSelected: "",
    userSuppliersCd: "",
    userSupplierList: [],
    destinationyPlant: "",
    headerDeliveryDestinationy: "",
    deliveryDestinationyList: [],
    destinationyHint: "",
    headerDeliveryPlan: "",
    deliveryPlanCdList: [],
    deliveryPlanCdHint: "",
    searchPlanCd: "",
    deliveryDate: "",
    dateMenuDelivery: false,
    deliveryDateCal: "", //納入日カレンダー
    userMakerSected: "01",
    userMakerList: [],
    clientSelected: "",
    supplierList: [],
    clientCd: "",
    makerFactory: "",
    makerFactoryList: [],
    searchDestinationy: "",
    searchClient: "",
    sortingMst: "01",
    sortingMstList: [],
    officeSelected: "",
    guaranteeOfficeSelected: "",
    officeList: [],
    listCheckbox: [],
    necessity: "",
    necessityList: [],
    defaultData: [], // 初期データ
    inputList: [], // データテーブル表示データ
    totalCount: 0, // 総件数
    openMenu: null, // メニュー
    userSuppliersSerch: "",
    // 警告ダイアログ
    alertDialog: false,
    checkMessage: "",
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 0,
    itemsPerPage: 100,
    itemsPerPageBtn: "100",
    //検索条件保持用
    userSuppliersCdSerach: "",
    userSuppliersCdSerch: "",
    deliveryDateSerach: "",
    sortingMstSerach: "",
    sortingMstSerachKbn: "",
    officeSelectedSerach: "",
    guaranteeOfficeSelectedSerach: "",
    clientSelectedSerach: "",
    clientCdSerach: "",
    userMakerSectedSerach: "",
    // 確認メッセージ
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
    },
    //品番単位用仕分画面
    sortingInfodialogSearch: false,
    sortingInfoSearchDialogData: {
      inputList: {},
      officeList: [],
      deliveryDate: "",
      userSuppliersSelected: "",
      sortingMst: "",
      userSuppliersSid: "",
      userSupplierList: [],
      insertBtn: "",
      sortingMstSerachKbn: "",
      userSuppliersCd: "",
      deliverySort2: [],
      index: 0,
    },

    // バリデーションチェックメッセージダイアログ
    alertMessage: "",
    timeAlertMessage: "",
    insertBtn: "",

    updateDialogMessage: false, // 戻るボタンダイアログ
    backMessage: "", // 戻るボタンダイアログメッセージ

    checkFlg1: false,
    // ヘッダ
    headerItems: [
      // 選択
      {
        text: i18n.tc("label.lbl_choice"),
        width: "75px",
        value: "choise",
        sortable: false,
        align: "center",
      },
      // メーカー取引先
      {
        text: i18n.tc("label.lbl_makerName") + i18n.tc("label.lbl_supplier"),
        width: "175px",
        value: "makerSupplierCd",
        sortable: false,
        align: "center",
      },
      // 工場
      {
        text: i18n.tc("label.lbl_userPlant"),
        width: "125px",
        value: "userPlant",
        sortable: false,
        align: "center",
      },
      // 搬入先工場
      {
        text: i18n.tc("label.lbl_destinationyPlant"),
        width: "125px",
        value: "destinationyPlant",
        sortable: false,
        align: "center",
      },
      // 搬入場所
      {
        text: i18n.tc("label.lbl_deliveryDestinationy"),
        width: "150px",
        value: "deliveryDestinationy",
        sortable: false,
        align: "center",
      },
      // 計画コード
      {
        text: i18n.tc("label.lbl_deliveryPlanCd"),
        width: "150px",
        value: "deliveryPlanCd",
        sortable: false,
        align: "center",
      },
      //品番設定
      {
        text: i18n.tc("label.lbl_productCode") + i18n.tc("label.lbl_config"),
        width: "115px",
        value: "productConfig",
        sortable: false,
        align: "center",
      },
      //要否
      {
        text: i18n.tc("label.lbl_necessity"),
        width: "150px",
        value: "necessity",
        sortable: false,
        align: "center",
        class: "wmscolor",
      },
      //在庫営業所
      {
        text: i18n.tc("menu.txt_stock") + i18n.tc("label.lbl_salesOffice"),
        width: "150px",
        value: "stockOffice",
        sortable: false,
        align: "center",
        class: "wmscolor",
      },
      //保証営業所
      {
        text: i18n.tc("label.lbl_guarantee") + i18n.tc("label.lbl_salesOffice"),
        width: "150px",
        value: "guaranteeOffice",
        sortable: false,
        align: "center",
        class: "wmscolor",
      },
      // メーカー取引先
      {
        text: i18n.tc("label.lbl_makerName") + i18n.tc("label.lbl_supplier"),
        value: "makerSupplierCd2",
        width: "200px",
        sortable: false,
        align: "center",
        class: "wmscolor",
      },
      // ユーザー納入先
      {
        text: i18n.tc("label.lbl_userDeliveryDestination"),
        value: "userDeliveryDestination",
        width: "200px",
        align: "center",
        sortable: false,
        class: "wmscolor",
      },
      // 適用開始日
      {
        text: i18n.tc("label.lbl_applicationStartDate"),
        value: "applicationStartDate",
        width: "150px",
        align: "center",
        sortable: false,
        class: "wmscolor",
      },
      //コピーボタン
      {
        text: "",
        value: "copyBtn",
        width: "100px",
        align: "center",
        sortable: false,
        class: "wmscolor",
      },
    ],
  }),
  created() {},

  methods: {
    //ユーザーメーカー切り替え処理
    chengeUserMakerSected() {
      this.clientSelected = "";
      this.supplierList = [];
      this.changeClient();
    },
    u_100_check() {
      if (this.itemsPerPage < 101) {
        this.u_100 = true;
      }
    },
    u_100_on() {
      this.u_100 = true;
      this.itemsPerPage = 10;
      this.page = 1;
      this.search(false);
    },
    u_100_clear(val) {
      this.u_100 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.search(false);
    },

    changeUserSuppliersSelected() {
      // 取引先を変更する場合はクリアする
      this.clientSelected = "";
      this.supplierList = [];
      this.userProductCd = "";
      this.userProductCdList = [];
      this.makerFactory = "";
      this.makerFactoryList = [];
      this.headerDeliveryDestinationy = "";
      this.deliveryDestinationyList = [];
      this.headerDeliveryPlan = "";
      this.deliveryPlanCdList = [];
    },

    /**
     * 初期データ取得
     */
    getCustomInfo(jstInScheduleDate) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const supplierList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(jstInScheduleDate)
      );
      return Promise.all([supplierList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.userSupplierList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // ユーザー・メーカー区分
      const userMakerList = getParameter.getCodeMst(appConfig.CODETYPE.USER_MAKER_SELECT);
      // 仕分マスタ登録
      const sortingMstList = getParameter.getCodeMst(appConfig.CODETYPE.DETAILS_DIV);
      // 要否
      const necessityList = getParameter.getCodeMst(appConfig.CODETYPE.NECESSITY_KBN);

      return Promise.all([userMakerList, sortingMstList, necessityList])
        .then((result) => {
          this.userMakerList = result[0];
          this.sortingMstList = result[1];
          this.necessityList = result[2];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 営業所プルダウン
     */
    getOfficeInfo() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      // ★必須パラメータ
      config.params.isMst = "1";
      config.params.relatedPartyDiv = "02";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_RELATED, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              let list = [];
              jsonData.resIdv.relatedPartys.forEach((row) => {
                if (row.relatedPartyLanguage.length > 0) {
                  list.push({
                    text: row.relatedPartyLanguage[0].relatedPartyName ?? "",
                    value: row.relatedPartyLanguage[0].relatedPartySid ?? "",
                    name: row.relatedPartyLanguage[0].relatedPartyName ?? "",
                  });
                }
              });
              this.officeList = list;
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * プルダウンチェンジイベント(取引先)
     */
    changeClient(value) {
      // 取引先を選択時
      let val = this.supplierList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.selectHintClient = val.text;
        this.searchClient = val.text;
      }
      this.supplierList = this.supplierList.filter((v) => v.value == value);

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.selectHintClient = "";
        this.makerFactory = "";
        this.makerFactoryList = [];
      } else {
        this.makerSet();
      }
      this.destinationySet();
      this.planCdSet();
    },
    /**
     * メーカー工場検索
     */
    makerSet() {
      if (this.clientSelected) {
        // ローディング画面表示ON
        this.loadingCounter = 1;
        this.makerFactoryList = [];
        const makerList =
          this.sortingMst == "01"
            ? getParameterProcurement.getMekerFactoryCd1(
                this.userSuppliersCd,
                this.deliveryDate,
                this.clientSelected
              )
            : getParameterProcurement.getMekerFactoryCd2(
                this.userSuppliersCd,
                this.userSuppliersSelected,
                dateTimeHelper.convertUTC(this.deliveryDate),
                this.clientSelected,
                this.clientSelected,
                this.userMakerSected
              );
        return Promise.all([makerList])
          .then((result) => {
            this.makerFactoryList = result[0];
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      } else {
        this.makerFactory = "";
        this.makerFactoryList = [];
      }
    },
    /**
     * 搬入場所変更時
     */
    destinationySet() {
      if (this.headerDeliveryDestinationy ?? false) {
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (this.headerDeliveryDestinationy.trim().length === 0) {
          return;
        }
        // ローディング画面表示ON
        this.loadingCounter = 1;
        this.deliveryDestinationyList = [];
        const makerList =
          this.sortingMst == "01"
            ? getParameterProcurement.getDeliveryPlanceCd1(
                this.userSuppliersCd,
                this.deliveryDate,
                this.clientSelected,
                this.headerDeliveryDestinationy
              )
            : getParameterProcurement.getDeliveryPlanceCd2(
                this.userSuppliersCd,
                this.userSuppliersSelected,
                dateTimeHelper.convertUTC(this.deliveryDate),
                this.clientSelected,
                this.clientSelected,
                this.userMakerSected,
                this.headerDeliveryDestinationy
              );
        return Promise.all([makerList])
          .then((result) => {
            this.deliveryDestinationyList = result[0];
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      } else {
        this.deliveryDestinationyList.length = 0;
        this.deliveryDestinationyList = [];
      }
    },
    /**
     * 計画コード変更時
     */
    planCdSet() {
      if (this.headerDeliveryPlan ?? false) {
        if (this.headerDeliveryPlan.trim().length === 0) {
          return;
        }
        // 入力桁数が定義した数以上の場合検索処理
        if (this.headerDeliveryPlan.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // ローディング画面表示ON
          this.loadingCounter = 1;
          this.deliveryPlanCdList = [];
          const makerList =
            this.sortingMst == "01"
              ? getParameterProcurement.getBizPlanCd1(
                  this.userSuppliersCd,
                  this.deliveryDate,
                  this.clientSelected,
                  this.headerDeliveryPlan
                )
              : getParameterProcurement.getBizPlanCd2(
                  this.userSuppliersCd,
                  this.userSuppliersSelected,
                  dateTimeHelper.convertUTC(this.deliveryDate),
                  this.clientSelected,
                  this.clientSelected,
                  this.userMakerSected,
                  this.headerDeliveryPlan
                );
          return Promise.all([makerList])
            .then((result) => {
              this.deliveryPlanCdList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.deliveryPlanCdList.length = 0;
          this.deliveryPlanCdList = [];
        }
      }
    },
    /**
     * プルダウンチェンジイベント(搬入場所)
     */
    changeDestinationy(value) {
      // 既存の搬入場所を選択時
      let val = this.deliveryDestinationyList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.destinationyHint = val.text;
        this.searchDestinationy = val.text;
      }
      this.deliveryDestinationyList = this.deliveryDestinationyList.filter((v) => v.value == value);

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.destinationyHint = "";
      }
    },
    /**
     * プルダウンチェンジイベント(計画コード)
     */
    changePlanCd(value) {
      // 既存の計画コードを選択時
      let val = this.deliveryPlanCdList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.deliveryPlanCdHint = val.text;
        this.searchPlanCd = val.text;
      }
      this.deliveryPlanCdList = this.deliveryPlanCdList.filter((v) => v.value == value);

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.deliveryPlanCdHint = "";
      }
    },

    /**
     * プルダウンチェンジイベント(検索結果-要否)
     */
    changeNecessityCd(value, item) {
      if (value == "02") {
        item.stockOffice = "";
        item.makerSupplierCd2 = "";
        item.makerSupplierList = [];
        item.userDeliveryDestination = "";
        item.userDeliveryDestinationList = [];
      } else if (value == "03") {
        item.guaranteeOffice = "";
      }
    },
    /**
     * プルダウンチェンジイベント(検索結果-在庫営業所)
     */
    changeOfficeNm(index) {
      this.inputList[index].makerSupplierCd2 = "";
      this.inputList[index].makerSupplierList = [];
      this.inputList[index].makerSupplierCd2Hint = "";
      this.inputList[index].makerSupplierCd2search = "";
    },

    /**
     * プルダウンチェンジイベント(検索結果-メーカー取引先)
     */
    changeMakerNm(value, item, index) {
      // 既存メーカー取引先を選択時()
      let val = this.inputList[index].makerSupplierList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        item.makerSupplierCd2Hint = val.text;
        item.makerSupplierCd2search = val.text;
      }
      item.makerSupplierList = item.makerSupplierList.filter((v) => v.value == value);

      if (value == "" || value == null) {
        item.makerSupplierCd2Hint = "";
      }
    },

    /**
     * プルダウンチェンジイベント(検索結果-ユーザー納入先)
     */
    changeDeliveryNm(value, item, index) {
      // 既存ユーザー納入先を選択時()
      let val = this.inputList[index].userDeliveryDestinationList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        item.userDeliveryDestinationHint = val.text;
        item.userDeliveryDestinationsearch = val.text;
      }
      item.userDeliveryDestinationList = item.userDeliveryDestinationList.filter(
        (v) => v.value == value
      );

      if (value == "" || value == null) {
        item.userDeliveryDestinationHint = "";
        item.userDeliveryDestination = "";
      }
    },

    /**
     * 警告ダイアログ、OKボタン押下
     */
    updateOk() {
      this.updateDialogMessage = false;
    },

    /**
     * 警告ダイアログ、OKボタン押下
     */
    alertCancel() {
      this.alertDialog = false;
    },

    /**
     * 登録ボタン押下
     */
    insert() {
      //バリデーションチェック
      if (this.$refs.editedList.validate()) {
        //チェックボックスの件数チェック
        let count = 0;
        //営業所が変更されているかチェック
        let countOffice = 0;
        for (let i = 0; i < this.inputList.length; i++) {
          if (this.inputList[i].choise) {
            count++;
            //在庫営業所チェック
            if (this.inputList[i].stockOfficeOld) {
              if (this.inputList[i].stockOffice != this.inputList[i].stockOfficeOld) {
                countOffice++;
              }
            }
            //保証営業所チェック
            if (this.inputList[i].guaranteeOfficeOld) {
              if (this.inputList[i].guaranteeOffice != this.inputList[i].guaranteeOfficeOld) {
                countOffice++;
              }
            }
          }
        }
        if (count > 0) {
          if (countOffice > 0) {
            this.confirmDialog.message = messsageUtil.getMessage("P-SUP-018_001_E");
            this.confirmDialog.isOpen = true;
            this.confirmDialog.screenFlag = true;
            this.confirmDialog.changeFlag = false;
            this.confirmDialog.okAction = this.insertOrder;
            return;
          } else {
            this.confirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
            this.confirmDialog.isOpen = true;
            this.confirmDialog.screenFlag = true;
            this.confirmDialog.changeFlag = false;
            this.confirmDialog.okAction = this.insertOrder;
          }
        } else {
          //エラーメッセージ表示
          this.alertDialog = true;
          this.checkMessage = messsageUtil.getMessage("P-EXT-003_001_E");
          return;
        }
      }
    },
    /**
     * 登録(POST)
     */
    insertOrder() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const body = this.$httpClient.createRequestBodyConfig();

      // 共通IF項目 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SUP_018;
      //営業所SID
      body.reqIdv.officeSid = sessionStorage.getItem("sales_office_sid");

      //仕分マスタ登録
      let deliverySortingInfoList = [];

      //チェックされている情報を取得
      for (let i = 0; i < this.inputList.length; i++) {
        //チェックされているか判断
        if (this.inputList[i].choise) {
          let deliverySortingInfo = {};
          //納入仕分1SID
          deliverySortingInfo.deliverySort1Sid = this.inputList[i].deliverySort1Sid;
          //ユーザー取引先CD
          deliverySortingInfo.makerClientCd = this.inputList[i].makerSupplierCd;
          //工場
          deliverySortingInfo.makerPlantCd = this.inputList[i].userPlant;
          //メーカー取引先名
          deliverySortingInfo.makerName = this.inputList[i].makerSupplierNm;
          //納入先
          deliverySortingInfo.userClientCd = this.inputList[i].userClientCd;
          //搬入先工場
          deliverySortingInfo.carryCompCd = this.inputList[i].destinationyPlant;
          //搬入場所
          deliverySortingInfo.deliveryPlaceCd = this.inputList[i].deliveryDestinationy;
          //計画コード
          deliverySortingInfo.planCd = this.inputList[i].deliveryPlanCd;
          //適用開始日
          deliverySortingInfo.validFrom = dateTimeHelper.convertUTC(
            this.inputList[i].applicationStartDate
          );
          //有効終了日時
          deliverySortingInfo.validTo = this.inputList[i].validTo;
          //処理区分
          deliverySortingInfo.processDiv = this.inputList[i].necessity;
          //在庫営業所
          deliverySortingInfo.officeSid = this.inputList[i].stockOffice;
          //保証営業所
          deliverySortingInfo.outWarrantyStartOfficeSid = this.inputList[i].guaranteeOffice;
          //メーカー取引先(WMS項目)
          deliverySortingInfo.makerClientSid = this.inputList[i].makerSupplierCd2;
          //ユーザー納入先(WMS項目)
          deliverySortingInfo.userClientSid = this.inputList[i].userDeliveryDestination;
          //更新日時
          deliverySortingInfo.updateDatetime = this.inputList[i].updateDatetime;
          deliverySortingInfo.deliverySorting2InfoList = [];
          if (this.inputList[i].deliverySort2.length != 0) {
            for (let j = 0; j < this.inputList[i].deliverySort2.length; j++) {
              //チェックされているか判断
              if (this.inputList[i].deliverySort2[j].choise) {
                let deliverySorting2Info = {};
                //納入仕分2SID
                deliverySorting2Info.deliverySort2Sid =
                  this.inputList[i].deliverySort2[j].deliverySort2Sid;
                //メーカー取引先CD
                deliverySorting2Info.makerClientCd = this.inputList[i].makerSupplierCd;
                //工場
                deliverySorting2Info.makerPlantCd = this.inputList[i].userPlant;
                //メーカー名
                deliverySorting2Info.makerName = this.inputList[i].makerSupplierNm;
                //納入先
                deliverySorting2Info.userClientCd = this.inputList[i].userClientCd;
                //搬入先工場
                deliverySorting2Info.carryCompCd = this.inputList[i].destinationyPlant;
                //搬入場所
                deliverySorting2Info.deliveryPlaceCd = this.inputList[i].deliveryDestinationy;
                //計画コード
                deliverySorting2Info.planCd = this.inputList[i].deliveryPlanCd;
                //基本品番
                deliverySorting2Info.userItemBaseCd =
                  this.inputList[i].deliverySort2[j].userItemBaseCd;
                //有効開始日時
                deliverySorting2Info.validFrom = dateTimeHelper.convertUTC(
                  this.inputList[i].deliverySort2[j].applicationStartDate
                );
                //有効終了日時
                deliverySorting2Info.validTo = this.inputList[i].deliverySort2[j].validTo;
                //処理区分
                deliverySorting2Info.processDiv = this.inputList[i].deliverySort2[j].necessity;
                //営業所SID
                deliverySorting2Info.officeSid = this.inputList[i].deliverySort2[j].stockOffice;
                //保証営業所SID
                deliverySorting2Info.outWarrantyStartOfficeSid =
                  this.inputList[i].deliverySort2[j].guaranteeOffice;
                //メーカー取引先(WMS項目)
                deliverySorting2Info.makerClientSid =
                  this.inputList[i].deliverySort2[j].makerSupplierCd2;
                //ユーザー納入先(WMS項目)
                deliverySorting2Info.userClientSid =
                  this.inputList[i].deliverySort2[j].userDeliveryDestination;
                //更新日時
                deliverySorting2Info.updateDatetime =
                  this.inputList[i].deliverySort2[j].updateDatetime;
                deliverySortingInfo.deliverySorting2InfoList.push(deliverySorting2Info);
              }
            }
          }
          deliverySortingInfoList.push(deliverySortingInfo);
        }
      }
      body.reqIdv.deliverySortingInfoList = deliverySortingInfoList;

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.MST_DELIVERY_SORTING_INFO, body, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = false;
              // 画面遷移ボタン
              this.infoDialog.homePageFlag = false;
              this.infoDialog.firstPageFlag = true;
              this.search("get-search");

              // エラー時
            } else {
              reject(new Error(jsonData.resCom.resComMessage));
            }
          })
          .catch((ex) => {
            reject(ex);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      }).catch((ex) => {
        this.infoDialog.message = ex.message;
        this.infoDialog.title = appConfig.DIALOG.title;
        this.infoDialog.isOpen = true;
        this.infoDialog.firstPageFlag = true;
        this.infoDialog.homePageFlag = false;
        this.infoDialog.rcvSchListFlg = false;
        this.infoDialog.rcvSchAddFlg = false;
        this.infoDialog.outsideClickNotCloseFlg = false;
      });
    },

    /**
     *  検索ボタン押下処理
     */
    search(val) {
      //バリデーションチェック
      if (this.$refs.form.validate()) {
        //検索結果のバリデーションリセット
        this.$refs.editedList.resetValidation();
        // ローディング画面表示ON
        this.loadingCounter = 1;
        const config = this.$httpClient.createGetApiRequestConfig();
        //ユーザー取引先(納入先CD)
        config.params.deliveryCd = this.userSuppliersCd;
        //基準日
        config.params.processDate = dateTimeHelper.convertUTC(this.deliveryDate);
        //登録区分
        config.params.registDiv = this.sortingMst;
        //在庫営業所
        if (this.officeSelected) {
          config.params.officeSid = this.officeSelected;
        }
        //保証営業所
        if (this.guaranteeOfficeSelected) {
          config.params.outWarrantyStartOfficeSid = this.guaranteeOfficeSelected;
        }
        //検索基準
        config.params.userMakerDiv = this.userMakerSected;
        //取引先
        if (this.userMakerSected && this.clientSelected) {
          if (this.userMakerSected == "01") {
            config.params.clientCd = this.clientSelected;
          } else if (this.userMakerSected == "02") {
            config.params.clientSid = this.clientSelected;
          }
        }
        //メーカー工場
        if (this.makerFactory) {
          config.params.makerPlantCd = this.makerFactory;
        }
        //搬入場所
        if (this.headerDeliveryDestinationy) {
          config.params.deliveryPlaceCd = this.headerDeliveryDestinationy;
        }
        //計画コード
        if (this.headerDeliveryPlan) {
          config.params.planCd = this.headerDeliveryPlan;
        }
        //要否
        if (this.necessity) {
          config.params.processDiv = this.necessity;
        }
        // ページャー処理
        if (val == "get-search") {
          config.params.reqComPageIndex = "1";
          this.page = 1;
        } else {
          // ページャー処理
          if (this.page >= 2 && this.inputList.length == 0) {
            config.params.reqComPageIndex = "1";
            this.page = 1;
          } else {
            config.params.reqComPageIndex = this.page;
          }
        }
        //登録・修正ボタン文言変更
        if (this.sortingMst == "01") {
          this.insertBtn = i18n.tc("btn.btn_insert");
        } else {
          this.insertBtn = i18n.tc("btn.btn_fix");
        }
        //検索条件保存
        //ユーザー取引先
        this.userSuppliersSerch = this.userSuppliersSelected;
        this.userSuppliersCdSerch = this.userSuppliersCd;
        for (var i = 0; i < this.userSupplierList.length; i++) {
          if (this.userSupplierList[i].value == this.userSuppliersSelected) {
            this.userSuppliersCdSerach = this.userSupplierList[i].text;
          }
        }
        //基準日
        this.deliveryDateSerach = this.deliveryDate;
        //登録/未登録
        this.sortingMstSerachKbn = this.sortingMst;
        for (var b = 0; b < this.sortingMstList.length; b++) {
          if (this.sortingMstList[b].value == this.sortingMst) {
            this.sortingMstSerach = this.sortingMstList[b].text;
          }
        }
        //在庫営業所
        this.officeSelectedSerach = this.officeSelected;
        //保証営業所
        this.guaranteeOfficeSelectedSerach = this.guaranteeOfficeSelected;
        //取引先
        this.clientSelectedSerach = this.clientSelected;
        this.clientCdSerach = "";
        //ユーザー/メーカー
        this.userMakerSectedSerach = this.userMakerSected;
        // ページング
        config.params.reqComPaginationFlg = "1";
        config.params.reqComPageLimit = this.itemsPerPage;
        //APIに渡す値をconfig.paramsにセット==================================//
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .secureGet(appConfig.API_URL.MST_GET_DELIVERY_SORTING1, config)
            .then((response) => {
              const jsonData = JSON.parse(JSON.stringify(response.data));
              if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                this.inputList = [];
                this.pageCount = jsonData.resCom.resComPagination.totalPage;
                this.totalCount = jsonData.resCom.resComPagination.totalRecord;
                const list = [];

                this.selection = jsonData.resIdv.deliverySorting1;
                for (let i = 0; i < this.selection.length; i++) {
                  if (this.sortingMst == "02") {
                    list.push({
                      userClientCd: this.userSuppliersCd,
                      makerSupplierCd: this.selection[i].makerClientCd,
                      makerSupplierNm: this.selection[i].makerName,
                      userPlant: this.selection[i].makerPlantCd,
                      destinationyPlant: this.selection[i].carryCompCd,
                      deliveryDestinationy: this.selection[i].deliveryPlaceCd,
                      deliveryPlanCd: this.selection[i].planCd,
                      makerSupplierList:
                        this.selection[i].process_div == "02"
                          ? []
                          : [
                              {
                                text:
                                  `${this.selection[i].makerClientCode}` +
                                  `（${this.selection[i].makerClientName}）`,
                                value: this.selection[i].makerClientSid,
                                name: this.selection[i].makerClientName,
                              },
                            ],
                      makerSupplierCd2: this.selection[i].makerClientSid,
                      makerSupplierCd2Hint:
                        this.selection[i].process_div == "02"
                          ? ""
                          : `${this.selection[i].makerClientCode}` +
                            `（${this.selection[i].makerClientName}）`,
                      makerSupplierCd2search:
                        this.selection[i].process_div == "02"
                          ? ""
                          : `${this.selection[i].makerClientCode}` +
                            `（${this.selection[i].makerClientName}）`,
                      userDeliveryDestinationList:
                        this.selection[i].process_div == "02"
                          ? []
                          : [
                              {
                                text:
                                  `${this.selection[i].userOfficeCd}` +
                                  `（${this.selection[i].userOfficeName}）`,
                                value: this.selection[i].userClientSid,
                                name: this.selection[i].userOfficeName,
                              },
                            ],
                      userDeliveryDestination: this.selection[i].userClientSid,
                      userDeliveryDestinationHint:
                        this.selection[i].process_div == "02"
                          ? ""
                          : `${this.selection[i].userOfficeCd}` +
                            `（${this.selection[i].userOfficeName}）`,
                      userDeliveryDestinationsearch:
                        this.selection[i].process_div == "02"
                          ? ""
                          : `${this.selection[i].userOfficeCd}` +
                            `（${this.selection[i].userOfficeName}）`,
                      applicationStartDate: dateTimeHelper
                        .convertUTC2JST(this.selection[i].validFrom)
                        .substr(0, 10),
                      applicationStartDateCal: dateTimeHelper.dateCalc(
                        dateTimeHelper.convertUTC2JST(this.selection[i].validFrom)
                      ),
                      applicationStartDateOld: commonFunction
                        .addDate(dateTimeHelper.convertUTC2JST(this.selection[i].validFrom), "")
                        .toString()
                        .replaceAll("-", "/")
                        .substr(0, 10),
                      validTo: this.selection[i].validTo,
                      validToOld: commonFunction
                        .addDate(this.selection[i].validTo, "add")
                        .toString()
                        .replaceAll("-", "/")
                        .substr(0, 10),
                      stockOffice: this.selection[i].officeSid,
                      stockOfficeOld: this.selection[i].officeSid,
                      guaranteeOffice: this.selection[i].outWarrantyStartOfficeSid,
                      guaranteeOfficeOld: this.selection[i].outWarrantyStartOfficeSid,
                      deliverySort1Sid: this.selection[i].deliverySort1Sid,
                      necessity: this.selection[i].process_div,
                      productConfig: this.selection[i].itemSettingdiv == "01" ? "有" : "無",
                      choise: false,
                      deliverySort2: [],
                      updateDatetime: this.selection[i].updateDatetime,
                    });
                  } else {
                    list.push({
                      userClientCd: this.userSuppliersCd,
                      makerSupplierCd: this.selection[i].makerClientCd,
                      makerSupplierNm: this.selection[i].makerName,
                      userPlant: this.selection[i].makerPlantCd,
                      destinationyPlant: this.selection[i].carryCompCd,
                      deliveryDestinationy: this.selection[i].deliveryPlaceCd,
                      deliveryPlanCd: this.selection[i].planCd,
                      makerSupplierCd2: "",
                      userDeliveryDestination: "",
                      applicationStartDate: this.deliveryDate,
                      applicationStartDateOld: null,
                      applicationStartDateCal: this.deliveryDateCal,
                      validTo: null,
                      validToOld: null,
                      stockOffice: "",
                      guaranteeOffice: "",
                      stockOfficeOld: "",
                      guaranteeOfficeOld: "",
                      necessity: "01",
                      makerSupplierList: [],
                      makerSupplierCd2search: "",
                      makerSupplierCd2Hint: "",
                      userDeliveryDestinationList: [],
                      userDeliveryDestinationsearch: "",
                      userDeliveryDestinationHint: "",
                      deliverySort1Sid: this.selection[i].deliverySort1Sid,
                      productConfig: "無",
                      choise: false,
                      deliverySort2: [],
                      updateDatetime: null,
                    });
                  }
                }
                this.inputList = list;
                resolve(response);
              } else {
                this.updateDialogMessage = true;
                this.backMessage = jsonData.resCom.resComMessage;
                this.backTitle = "結果";
                reject(resolve);
              }
            })
            .catch((resolve) => {
              this.updateDialogMessage = true;
              this.backMessage = messsageUtil.getMessage("P-999-999_999_E");
              this.backTitle = appConfig.DIALOG.title;
              reject(resolve);
            })
            .finally(() => {
              //ローディング画面表示OFF;
              this.loadingCounter = 0;
            });
        });
      }
    },

    /**
     * 初期値
     */
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);
      this.insertBtn = i18n.tc("btn.btn_insert");
      //基準日
      this.deliveryDate = dateTimeHelper.convertJST().substr(0, 10);
      this.deliveryDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      //営業所一覧取得
      this.getOfficeInfo();
      const codeList = this.getMstCode();
      Promise.all([codeList])
        .then(() => {
          // 画面の初期値を設定します。
          this.getCustomInfo();
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
      this.$watch(
        () => this.userMakerSected,
        () => {
          this.chengeUserMakerSected();
        }
      );
    },

    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },

    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      this.confirmDialog.isOpen = false;
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);

      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },

    /**
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 納入日を－１日します。
     */
    prevDate() {
      this.deliveryDateCal = commonFunction.addDate(this.deliveryDateCal, "");
    },
    /**
     * 納入日を＋１日します。
     */
    nextDate() {
      this.deliveryDateCal = commonFunction.addDate(this.deliveryDateCal, "add");
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 日付入力(納入日)
     */
    changeDateDelivery(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.deliveryDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.deliveryDateCal = null;
      }
    },

    /**
     * 日付入力(適用開始日)
     */
    changeDateFrom(val, index) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }

      if (dateTimeHelper.validDate(strDt, "/")) {
        // 選択した行の有効開始日も更新
        this.inputList[index].applicationStartDate = strDt;
        this.inputList[index].applicationStartDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      }
    },

    inputCallenderFrom(validStartDateCal, index) {
      this.inputList[index].applicationStartDate = this.formatDate(validStartDateCal);
      this.inputList[index].applicationStartMenuDate = false;
    },
    /**
     * ユーザー取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.userSupplierList.length; i++) {
        if (this.userSupplierList[i].value == this.userSuppliersSelected) {
          this.userSuppliersCd = this.userSupplierList[i].clientCd;
          return this.userSupplierList[i].name;
        }
      }
    },
    /**
     * 取引先リストボックス取得処理
     */
    setSrhClient() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.clientSelected) {
          return this.supplierList[i].name;
        }
      }
    },
    /**
     * 要否リストボックス取得処理
     */
    setSrhNecessity(val) {
      for (var i = 0; i < this.necessityList.length; i++) {
        if (this.necessityList[i].value == val) {
          return this.necessityList[i].text;
        }
      }
    },
    /**
     * メーカー工場リストボックス取得処理
     */
    setMakerFactory() {
      for (var i = 0; i < this.makerFactoryList.length; i++) {
        if (this.makerFactoryList[i].value == this.makerFactory) {
          return this.makerFactoryList[i].text;
        }
      }
    },
    /**
     * 搬入場所リストボックス取得処理
     */
    setDestinationy() {
      for (var i = 0; i < this.deliveryDestinationyList.length; i++) {
        if (this.deliveryDestinationyList[i].value == this.headerDeliveryDestinationy) {
          return this.deliveryDestinationyList[i].value;
        }
      }
    },
    /**
     * 計画コードリストボックス取得処理
     */
    setSrhPlanCd() {
      for (var i = 0; i < this.deliveryPlanCdList.length; i++) {
        if (this.deliveryPlanCdList[i].value == this.headerDeliveryPlan) {
          return this.deliveryPlanCdList[i].value;
        }
      }
    },
    /**
     * 在庫営業所リストボックス取得処理
     */
    setOffice(val) {
      for (var i = 0; i < this.officeList.length; i++) {
        if (this.officeList[i].value == val) {
          return this.officeList[i].name;
        }
      }
    },
    /**
     * メーカー取引先リストボックス取得処理
     */
    setMaker(val, index) {
      for (var i = 0; i < this.inputList[index].makerSupplierList.length; i++) {
        if (this.inputList[index].makerSupplierList[i].value == val) {
          return this.inputList[index].makerSupplierList[i].name;
        }
      }
    },
    /**
     * ユーザー納入先リストボックス取得処理
     */
    setDeliveryDestination(val, index) {
      for (var i = 0; i < this.inputList[index].userDeliveryDestinationList.length; i++) {
        if (this.inputList[index].userDeliveryDestinationList[i].value == val) {
          return this.inputList[index].userDeliveryDestinationList[i].name;
        }
      }
    },
    /**
     * 保証営業所リストボックス取得処理
     */
    setGuaranteeOffice() {
      for (var i = 0; i < this.officeList.length; i++) {
        if (this.officeList[i].value == this.guaranteeOfficeSelected) {
          return this.officeList[i].name;
        }
      }
    },
    /**
     * 上記コピー
     */
    itemCopy(item, index) {
      const value = this.inputList[index - 1];
      if (value ?? false) {
        item.stockOffice = value.stockOffice;
        item.guaranteeOffice = value.guaranteeOffice;
        item.makerSupplierCd2 = value.makerSupplierCd2;
        item.makerSupplierList = value.makerSupplierList;
        item.makerSupplierCd2Hint = value.makerSupplierCd2Hint;
        item.makerSupplierCd2search = value.makerSupplierCd2search;
        item.userDeliveryDestination = value.userDeliveryDestination;
        item.userDeliveryDestinationList = value.userDeliveryDestinationList;
        item.userDeliveryDestinationHint = value.userDeliveryDestinationHint;
        item.userDeliveryDestinationsearch = value.userDeliveryDestinationsearch;
        item.applicationStartDate = value.applicationStartDate;
        item.applicationStartDateCal = value.applicationStartDateCal;
        item.necessity = value.necessity;
        item.productConfig = value.productConfig;
        item.choise = true;
      }
    },
    openUpdate(index) {
      this.sortingInfoSearchDialogData.inputList = this.inputList[index];
      //営業所プルダウンlist
      this.sortingInfoSearchDialogData.officeList = this.officeList;
      //基準日
      this.sortingInfoSearchDialogData.deliveryDate = this.deliveryDateSerach;
      //ユーザー取引先
      this.sortingInfoSearchDialogData.userSuppliersSelected = this.userSuppliersCdSerach;
      this.sortingInfoSearchDialogData.userSuppliersSid = this.userSuppliersSerch;
      //
      this.sortingInfoSearchDialogData.sortingMst = this.sortingMstSerach;
      this.sortingInfoSearchDialogData.sortingMstSerachKbn = this.sortingMstSerachKbn;
      this.sortingInfoSearchDialogData.userSupplierList = this.userSupplierList;
      this.sortingInfoSearchDialogData.insertBtn = this.insertBtn;
      this.sortingInfoSearchDialogData.userSuppliersCd = this.userSuppliersCdSerch;
      this.sortingInfoSearchDialogData.deliverySort2 = this.inputList[index].deliverySort2;
      this.sortingInfoSearchDialogData.index = index;
      this.sortingInfodialogSearch = true;
    },
    sortingPopup() {
      var index = this.sortingInfoSearchDialogData.index;
      this.inputList[index].deliverySort2 = this.sortingInfoSearchDialogData.deliverySort2;
      this.inputList[index].choise = true;
    },
  },

  mounted() {
    this.init();
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    /**
     * 取引先リストボックス取得処理
     */
    searchClient(val) {
      if (val == null) {
        return;
      }
      if (this.checkFlg1) {
        this.checkFlg1 = false;
        return;
      }
      if (val ?? false) {
        // 取引先が選択されてない場合、処理しない
        if (
          this.userMakerSected == "01" &&
          (this.userSuppliersSelected == null || this.userSuppliersSelected == "")
        ) {
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.selectHintClient) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        // 入力桁数が定義した数以上の場合検索処理
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // 選択した会社コードを取得

          const fromList =
            this.userMakerSected == "02"
              ? getParameter.getRelatedBizPartialMatch(
                  appConfig.ISGETCLIENT.CLIENT,
                  dateTimeHelper.convertUTC(this.deliveryDate),
                  undefined,
                  undefined,
                  val,
                  sessionStorage.getItem("sales_office_sid")
                )
              : getParameter.getUserdBizPartialMatch(
                  this.userSuppliersSelected,
                  val,
                  this.officeSid,
                  this.sortingMst
                );

          Promise.all([fromList])
            .then((result) => {
              // 画面の初期値を設定します。

              this.supplierList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.supplierList.length = 0;
          this.supplierList = [];
        }
      }
    },
    /**
     * 搬入場所リストボックス取得処理
     */
    searchDestinationy(val) {
      if (val == null) {
        return;
      }
      if (val ?? false) {
        // 取引先が選択されてない場合、処理しない
        if (this.userSuppliersSelected == null || this.userSuppliersSelected == "") {
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.destinationyHint) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        // 入力桁数が定義した数以上の場合検索処理
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // ローディング画面表示ON
          this.loadingCounter = 1;
          this.deliveryDestinationyList = [];
          const makerList =
            this.sortingMst == "01"
              ? getParameterProcurement.getDeliveryPlanceCd1(
                  this.userSuppliersCd,
                  this.deliveryDate,
                  this.clientSelected,
                  val
                )
              : getParameterProcurement.getDeliveryPlanceCd2(
                  this.userSuppliersCd,
                  this.userSuppliersSelected,
                  dateTimeHelper.convertUTC(this.deliveryDate),
                  this.clientSelected,
                  this.clientSelected,
                  this.userMakerSected,
                  val
                );
          return Promise.all([makerList])
            .then((result) => {
              this.deliveryDestinationyList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.deliveryDestinationyList.length = 0;
          this.deliveryDestinationyList = [];
        }
      }
    },

    /**
     * 計画コードリストボックス取得処理
     */
    searchPlanCd(val) {
      if (val == null) {
        return;
      }
      if (val ?? false) {
        // 取引先が選択されてない場合、処理しない
        if (this.userSuppliersSelected == null || this.userSuppliersSelected == "") {
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.deliveryPlanCdHint) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        // 入力桁数が定義した数以上の場合検索処理
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // ローディング画面表示ON
          this.loadingCounter = 1;
          this.deliveryPlanCdList = [];
          const makerList =
            this.sortingMst == "01"
              ? getParameterProcurement.getBizPlanCd1(
                  this.userSuppliersCd,
                  this.deliveryDate,
                  this.clientSelected,
                  val
                )
              : getParameterProcurement.getBizPlanCd2(
                  this.userSuppliersCd,
                  this.userSuppliersSelected,
                  dateTimeHelper.convertUTC(this.deliveryDate),
                  this.clientSelected,
                  this.clientSelected,
                  this.userMakerSected,
                  val
                );
          return Promise.all([makerList])
            .then((result) => {
              this.deliveryPlanCdList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.deliveryPlanCdList.length = 0;
          this.deliveryPlanCdList = [];
        }
      }
    },

    //仕分マスタ切り替え処理
    sortingMst: function (newVal) {
      var userMaker = this.userMakerSected;
      if (newVal == "01") {
        this.officeSelected = "";
        this.guaranteeOfficeSelected = "";
        this.userMakerSected = "01";
        this.necessity = "";
      }
      if (userMaker == this.userMakerSected) {
        this.makerSet();
        if (!this.clientSelected) {
          this.destinationySet();
          this.planCdSet();
        }
      } else {
        this.destinationySet();
        this.planCdSet();
      }
    },
    inputList: function (inputL) {
      inputL.forEach((value) => {
        if (value.isWatchAdded === void 0) {
          this.$watch(
            () => value.makerSupplierCd2search,
            (val) => {
              if (val ?? false) {
                // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
                if (val == value.makerSupplierCd2Hint) {
                  return;
                }
                if (value.stockOffice == null || value.stockOffice == "") {
                  return;
                }
                // 入力桁数が定義した数以上の場合検索処理
                if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
                  const fromList = getParameter.getRelatedBizPartialMatch(
                    appConfig.ISGETCLIENT.CLIENT,
                    dateTimeHelper.convertUTC(this.deliveryDate),
                    undefined,
                    undefined,
                    val,
                    value.stockOffice
                  );
                  Promise.all([fromList])
                    .then((result) => {
                      // 画面の初期値を設定します。
                      value.makerSupplierList = result[0];
                    })
                    .catch((ex) => {
                      this.infoDialog.message = ex;
                      this.infoDialog.title = appConfig.DIALOG.title;
                      this.infoDialog.isOpen = true;
                    })
                    .finally(() => {
                      // ローディング画面表示OFF
                      this.loadingCounter = 0;
                    });
                } else {
                  value.makerSupplierList.length = 0;
                  value.makerSupplierList = [];
                }
              }
            }
          );
          this.$watch(
            () => value.userDeliveryDestinationsearch,
            (val) => {
              if (val ?? false) {
                // 取引先が選択されてない場合、処理しない
                if (this.userSuppliersSerch == null || this.userSuppliersSerch == "") {
                  return;
                }
                // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
                if (val == value.userDeliveryDestinationHint) {
                  return;
                }
                // 入力桁数が定義した数以上の場合検索処理
                if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
                  // 選択した会社コードを取得
                  let officeCd = this.userSupplierList.find(
                    (element) => element.value === this.userSuppliersSerch
                  ).clientSid;
                  const fromList = getParameter.getRelatedBizPartialMatch(
                    appConfig.ISGETCLIENT.FROMTO,
                    dateTimeHelper.convertUTC(this.deliveryDate),
                    undefined,
                    officeCd,
                    val
                  );

                  Promise.all([fromList])
                    .then((result) => {
                      // 画面の初期値を設定します。
                      const list = [];
                      value.userDeliveryDestinationList = list.concat(result[0]);
                    })
                    .catch((ex) => {
                      this.infoDialog.message = ex;
                      this.infoDialog.title = appConfig.DIALOG.title;
                      this.infoDialog.isOpen = true;
                    })
                    .finally(() => {
                      // ローディング画面表示OFF
                      this.loadingCounter = 0;
                    });
                } else {
                  value.userDeliveryDestinationList.length = 0;
                  value.userDeliveryDestinationList = [];
                }
              }
            }
          );
        }
        value.isWatchAdded = true;
      });
    },

    dialog(val) {
      val || this.close();
    },
    deliveryDateCal(val) {
      this.deliveryDate = this.formatDate(val);
    },
    page: function (newValue) {
      this.page = newValue;
      this.search(false);
    },
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}

.autoUpdate ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
  width: 110px;
}

.serch-textbox-arrivalNo ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
  width: 153px;
}

#listData.v-data-table ::v-deep .v-data-table__wrapper {
  max-height: 538px;
  height: 100%;
}

.textbox {
  width: 11.2rem;
}

.toatlNum {
  margin-left: 10px;
}

a {
  text-decoration: underline;
}

.v-btn.v-btn--has-bg.cargos_btn {
  text-transform: none;
  background: #dee6f3;
}

.cargos_btn ::v-deep .v-btn__content {
  color: #000 !important;
  font-weight: normal !important;
}

.v-btn.v-btn--has-bg.btn_search {
  width: 5.5rem;
}
.date-style {
  width: 11rem;
}

.text-overflow {
  // v-tooltip
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#listData.v-data-table ::v-deep table {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
#listData.v-data-table ::v-deep th.wmscolor {
  background: #fce4d6;
}
.calender {
  display: flex;
}
tr td div.calender-btn-area {
  margin-top: 16px;
}
.v-text-field.text-box {
  margin: 0px;
  padding-top: 11px;
  height: auto;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper tbody tr td {
  background-color: #ffffff;
}

#listData.v-data-table--fixed-header ::v-deep tr:nth-child(odd) td {
  background-color: #dde5f0 !important;
  height: 2rem;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper th:first-child {
  position: sticky !important;
  left: 0 !important;
  z-index: 6 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:first-child {
  position: sticky !important;
  left: 0 !important;
  z-index: 5 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper th:nth-child(2) {
  position: sticky !important;
  left: 75px !important;
  z-index: 6 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:nth-child(2) {
  position: sticky !important;
  left: 75px !important;
  z-index: 5 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper th:nth-child(3) {
  position: sticky !important;
  left: 250px !important;
  z-index: 6 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:nth-child(3) {
  position: sticky !important;
  left: 250px !important;
  z-index: 5 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper th:nth-child(4) {
  position: sticky !important;
  left: 375px !important;
  z-index: 6 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:nth-child(4) {
  position: sticky !important;
  left: 375px !important;
  z-index: 5 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper th:nth-child(5) {
  position: sticky !important;
  left: 500px !important;
  z-index: 6 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:nth-child(5) {
  position: sticky !important;
  left: 500px !important;
  z-index: 5 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper th:nth-child(6) {
  position: sticky !important;
  left: 650px !important;
  z-index: 6 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:nth-child(6) {
  position: sticky !important;
  left: 650px !important;
  z-index: 5 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper th:nth-child(7) {
  position: sticky !important;
  left: 800px !important;
  z-index: 6 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:nth-child(7) {
  position: sticky !important;
  left: 800px !important;
  z-index: 5 !important;
}

::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  max-height: 97%;
}
</style>
