<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-container fluid id="sub-bar">
      <v-col>
        <div id="lbl-screen-name">{{ $t("label.lbl_deliveryOrderDataImportError") }}</div>
      </v-col>
    </v-container>
    <v-container fluid>
      <v-row class="search-row">
        <!--ユーザー取引先-->
        <div class="search-autocomplete first-search-item" style="width: 450px">
          <v-text-field
            dense
            readonly
            v-model="userSuppliersSelectedName"
            :label="$t('label.lbl_userSupplier')"
            class="txt-single"
            persistent-hint
            margin-bottom="0"
            pa-3
          ></v-text-field>
        </div>
        <!-- 空白 -->
        <span class="item-spacer" style="float: left">&nbsp;</span>
        <!--搬入先工場-->
        <div class="search-autocomplete first-search-item" style="width: 450px">
          <v-text-field
            dense
            readonly
            v-model="destinationyPlantSelected"
            :label="$t('label.lbl_destinationyPlant')"
            class="txt-single"
            persistent-hint
            margin-bottom="0"
            pa-3
          ></v-text-field>
        </div>
        <!-- 空白 -->
        <span class="item-spacer" style="float: left">&nbsp;</span>
        <!--取引先-->
        <div class="search-autocomplete first-search-item" style="width: 450px">
          <v-text-field
            dense
            readonly
            v-model="suppliersSelectedName"
            :label="$t('label.lbl_supplier')"
            class="txt-single"
            persistent-hint
            margin-bottom="0"
            pa-3
          ></v-text-field>
        </div>
      </v-row>
      <v-row>
        <!-- 閉じるボタン -->
        <div class="btn-area" style="width: 99%">
          <v-btn style="float: right" @click="close()" class="other-btn"
            >{{ $t("btn.btn_close") }}
          </v-btn>
        </div>
      </v-row>
    </v-container>
    <!-- データテーブル -->
    <v-container fluid>
      <v-data-table
        id="listData"
        :headers="headerItems"
        :items="inputList"
        :fixed-header="true"
        :options.sync="sortOptions"
        disable-pagination
        :hide-default-footer="true"
        height="500px"
      >
        <!-- 入荷No -->
        <template v-slot:[`item.slipNo`]="{ item }">
          <div class="listNo-style">{{ item.slipNo }}</div>
        </template>

        <!--エラーデータ -->
        <template v-slot:[`item.errorData`]="{ item }">
          <div class="listNo-style">{{ item.errorData }}</div>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import { i18n } from "../../lang/lang.js";
import Loading from "@/components/loading";
import { appConfig } from "../../assets/scripts/js/AppConfig";

export default {
  name: appConfig.SCREEN_ID.P_RCV_010,
  components: {
    Loading,
  },
  props: {
    entity: { type: Object, default: null },
    detailDialogData: {},
    hoge1: {
      default: "",
    },
    isOpenDetailDialog: { type: Boolean, default: false },
  },
  data: () => ({
    // ソート
    sortOptions: {},
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // ユーザー取引先表示名
    userSuppliersSelectedName: "",
    // 取引先表示名
    suppliersSelectedName: "",
    // 搬入先工場
    destinationyPlantSelected: "",
    stockList: [],
    isText: false,
    headerItems: [
      // No
      {
        text: i18n.tc("label.lbl_No"),
        width: "5%",
        value: "No",
        sortable: true,
        align: "right",
      },
      // 伝票No
      {
        text: i18n.tc("label.lbl_billingSlipNo"),
        value: "slipNo",
        width: "20%",
        sortable: true,
        align: "left",
      },
      // 行
      {
        text: i18n.tc("label.lbl_rows"),
        value: "rows",
        width: "6%",
        align: "right",
        sortable: true,
      },
      // 列
      {
        text: i18n.tc("label.lbl_column"),
        value: "column",
        width: "6%",
        align: "right",
        sortable: true,
      },
      // エラー内容
      {
        text: i18n.tc("label.lbl_errorContent"),
        value: "errorContent",
        width: "20%",
        align: "left",
        sortable: true,
      },
      // エラーデータ
      {
        text: i18n.tc("label.lbl_errorData"),
        value: "errorData",
        width: "20%",
        align: "left",
        sortable: true,
      },
    ],
    // 明細リスト
    inputList: [],
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
    },
  }),
  methods: {
    /**
     * 初期起動：画面を開いた際に渡された値を設定します。
     */
    makeData() {
      this.inputList = [];
      for (var i = 0; i < this.entity.list.length; i++) {
        this.inputList.push({
          No: "",
          slipNo: this.entity.list[i].listNo,
          rows: this.entity.list[i].row,
          column: this.entity.list[i].column,
          errorContent: this.entity.list[i].message,
          errorData: this.entity.list[i].value,
        });
      }
      // 連番
      for (var n = 0; n < this.inputList.length; n++) {
        this.inputList[n].No = n + 1;
      }
    },
    /**
     * 初期起動：画面を開いた際に渡された値を設定します。
     */
    init() {
      this.suppliersSelectedName = this.entity.suppliersSelectedName;
      this.userSuppliersSelectedName = this.entity.userSuppliersSelectedName;
      this.destinationyPlantSelected = this.entity.destinationyPlantSelected;
      this.isText = this.entity.isText;
      console.log(this.destinationyPlantSelected);
      this.makeData();
    },
    //ダイアログを閉じる
    close() {
      this.$emit("closePopup");
      this.$emit("update:isOpenDetailDialog", false);
      this.inputList = [];
    },
  },
  watch: {
    entity: {
      handler(newValue, oldValue) {
        console.info("handler", newValue, oldValue);
      },
    },
    // eslint-disable-next-line no-unused-vars
    isOpenDetailDialog: function (newValue) {
      if (newValue) {
        this.init();
      }
    },
    sortOptions: {
      handler(items) {
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? "desc" : "asc";
          this.sortItem = sortBy + " " + ascdesc;
          if (sortBy == "No") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                return a.No - b.No;
              } else {
                return b.No - a.No;
              }
            });
          } else if (sortBy == "slipNo") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.slipNo < b.slipNo) {
                  return -1;
                } else if (a.slipNo > b.slipNo) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.slipNo > b.slipNo) {
                  return -1;
                } else if (a.slipNo < b.slipNo) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "rows") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                return a.rows - b.rows;
              } else {
                return b.rows - a.rows;
              }
            });
          } else if (sortBy == "column") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                return a.column - b.column;
              } else {
                return b.column - a.column;
              }
            });
          } else if (sortBy == "errorContent") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.errorContent < b.errorContent) {
                  return -1;
                } else if (a.errorContent > b.errorContent) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.errorContent > b.errorContent) {
                  return -1;
                } else if (a.errorContent < b.errorContent) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "errorData") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.errorData < b.errorData) {
                  return -1;
                } else if (a.errorData > b.errorData) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.errorData > b.errorData) {
                  return -1;
                } else if (a.errorData < b.errorData) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          }
          return this.inputList;
        }
      },
      deep: true,
    },
  },
  beforeUpdate() {
    // エラー列が「開始位置～終了位置」形式の場合、ヘッダ名を「列」から「文字数」に変更
    this.headerItems.find((i) => i.value == "column").text = this.isText
      ? i18n.tc("label.lbl_character_digits")
      : i18n.tc("label.lbl_column");
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
#sub-bar {
  padding: none;
  height: 3rem;
}

// .pad {
//   padding-left: 0;
//   padding-right: 0;
// }

.text-overflow {
  // v-tooltip
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
