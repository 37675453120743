<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-container fluid id="sub-bar">
      <v-col>
        <div id="lbl-screen-name">{{ $t("label.lbl_dataImportError") }}</div>
      </v-col>
    </v-container>
    <v-container fluid>
      <v-row class="search-row">
        <!-- 営業所 -->
        <div class="search-autocomplete first-search-item">
          <v-text-field
            dense
            v-model="officeSelected"
            :label="$t('label.lbl_salesOffice')"
            class="txt-single search-autocomplete"
            readonly
          >
          </v-text-field>
        </div>
        <!-- 閉じるボタン -->
        <div class="btn-area" style="float: right; padding-left: 1400px">
          <v-btn @click="close()" class="other-btn">{{ $t("btn.btn_close") }} </v-btn>
        </div>
      </v-row>
    </v-container>
    <!-- データテーブル -->
    <v-container fluid>
      <v-data-table
        id="listData"
        :headers="headerItems"
        :items="inputList"
        :fixed-header="true"
        :items-per-page="itemsPerPage"
        :options.sync="sortOptions"
        :hide-default-footer="true"
        height="500px"
        :page.sync="page"
      >
      </v-data-table>
      <!-- ページネーション -->
      <div class="text-center pt-2">
        <v-pagination
          v-model="page"
          :length="Math.ceil(inputList.length / itemsPerPage)"
          :total-visible="7"
        ></v-pagination>
      </div>
    </v-container>
  </div>
</template>

<script>
import { i18n } from "../../lang/lang.js";
import Loading from "@/components/loading";
import { appConfig } from "../../assets/scripts/js/AppConfig";

export default {
  name: appConfig.SCREEN_ID.P_MST_015,
  components: {
    Loading,
  },
  props: {
    entity: { type: Object, default: null },
    detailDialogData: {},
    hoge1: {
      default: "",
    },
    isOpenDetailDialog: { type: Boolean, default: false },
  },
  data: () => ({
    // ページング
    page: 1,
    pageCount: 0,
    itemsPerPage: 100,
    // 営業所
    officeSelected: "",
    // 取込種別
    importType: "",
    // ソート
    sortOptions: {},
    // ローディング画面表示フラグ
    loadingCounter: 0,
    headerItems: [],
    // 明細リスト
    inputList: [],
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
    },
  }),
  methods: {
    /**
     * 初期起動：画面を開いた際に渡された値を設定します。
     */
    makeData() {
      this.inputList = [];
      const xlsx = require("xlsx");
      for (var i = 0; i < this.entity.list.length; i++) {
        let clm = this.entity.list[i].column;
        let row = this.entity.list[i].row;
        let cell = xlsx.utils.encode_cell({ c: clm, r: row });

        if (this.entity.importType == "01" && this.entity.list[i].errFlg != "1") {
          this.inputList.push({
            errKey: this.entity.list[i].errKey,
            cell: cell,
            errorContent: this.entity.list[i].message,
            errorData: this.entity.list[i].value,
          });
        } else if (this.entity.importType == "01" && this.entity.list[i].errFlg == "1") {
          let cell =
            xlsx.utils.encode_cell({ c: clm, r: row }) +
            "," +
            xlsx.utils.encode_cell({ c: clm + 1, r: row });

          this.inputList.push({
            errKey: this.entity.list[i].errKey,
            cell: cell,
            errorContent: this.entity.list[i].message,
            errorData: this.entity.list[i].value,
          });
        } else if (
          this.entity.importType == "03" ||
          this.entity.importType == "04" ||
          this.entity.importType == "02" ||
          this.entity.importType == "05" ||
          this.entity.importType == "06"
        ) {
          let errKey = this.entity.list[i].errKey + "（" + this.entity.list[0].sheetName + "）";
          this.inputList.push({
            errKey: errKey,
            cell: cell,
            errorContent: this.entity.list[i].message,
            errorData: this.entity.list[i].value,
          });
        }
      }
      //headerItems内削除
      this.headerItems.splice(0, this.headerItems.length);
      //headerItems設定
      this.setHeaderItems();
    },

    setHeaderItems() {
      this.headerItems = [
        // セル
        {
          text: i18n.tc("label.lbl_cell"),
          value: "cell",
          width: "10%",
          align: "left",
          sortable: true,
        },
        // エラー内容
        {
          text: i18n.tc("label.lbl_errorContent"),
          value: "errorContent",
          width: "35%",
          align: "left",
          sortable: true,
        },
        // エラーデータ
        {
          text: i18n.tc("label.lbl_errorData"),
          value: "errorData",
          width: "35%",
          align: "left",
          sortable: true,
        },
      ];

      // ユーザマスタ
      if (this.entity.importType == "01") {
        // ユーザーID
        this.headerItems.unshift({
          text: i18n.tc("label.lbl_userId"),
          value: "errKey",
          width: "20%",
          sortable: true,
          align: "left",
        });
        // 商品マスタ(商品マスタ）
      } else if (this.entity.importType == "02" && this.entity.list[0].sheetType == "1") {
        // 品番
        this.headerItems.unshift({
          text: i18n.tc("label.lbl_productCode"),
          value: "errKey",
          width: "20%",
          sortable: true,
          align: "left",
        });
        // 商品マスタ(治具マスタ)
      } else if (this.entity.importType == "02" && this.entity.list[0].sheetType == "2") {
        // 治具コード
        this.headerItems.unshift({
          text: i18n.tc("label.lbl_jigCode"),
          value: "errKey",
          width: "20%",
          sortable: true,
          align: "left",
        });
        // 倉庫マスタ(倉庫マスタ)
      } else if (this.entity.importType == "03" && this.entity.list[0].sheetType == "1") {
        // 倉庫コード
        this.headerItems.unshift({
          text: i18n.tc("label.lbl_warehouseCd"),
          value: "errKey",
          width: "20%",
          sortable: true,
          align: "left",
        });
        // 倉庫マスタ(ロケーションマスタ)
      } else if (this.entity.importType == "03" && this.entity.list[0].sheetType == "2") {
        // ロケ
        this.headerItems.unshift({
          text: i18n.tc("label.lbl_location"),
          value: "errKey",
          width: "20%",
          sortable: true,
          align: "left",
        });
        // 関係先マスタ(会社マスタ)
      } else if (this.entity.importType == "04" && this.entity.list[0].sheetType == "1") {
        // 会社
        this.headerItems.unshift({
          text: i18n.tc("label.lbl_company"),
          value: "errKey",
          width: "20%",
          sortable: true,
          align: "left",
        });
        // 関係先マスタ(営業所、取引先、拠点)
      } else if (
        this.entity.importType == "04" &&
        (this.entity.list[0].sheetType == "2" ||
          this.entity.list[0].sheetType == "3" ||
          this.entity.list[0].sheetType == "5")
      ) {
        // 関係先（営業所、取引先、拠点）
        this.headerItems.unshift({
          text: i18n.tc("label.lbl_relatedParty"),
          value: "errKey",
          width: "20%",
          sortable: true,
          align: "left",
        });
        // 路線業者マスタ
      } else if (this.entity.importType == "04" && this.entity.list[0].sheetType == "4") {
        // 路線CD
        this.headerItems.unshift({
          text: i18n.tc("label.lbl_routeCd"),
          value: "errKey",
          width: "20%",
          sortable: true,
          align: "left",
        });
      } else if (this.entity.importType == "05") {
        // 業務No
        this.headerItems.unshift({
          text: i18n.tc("label.lbl_bussinessNo"),
          value: "errKey",
          width: "20%",
          sortable: true,
          align: "left",
        });
      } else if (this.entity.importType == "06" && this.entity.list[0].sheetType == "1") {
        // 単価Gr
        this.headerItems.unshift({
          text: i18n.tc("label.lbl_unitPriceGr"),
          value: "errKey",
          width: "20%",
          sortable: true,
          align: "left",
        });
      }
    },

    /**
     * 初期起動：画面を開いた際に渡された値を設定します。
     */
    init() {
      // 営業所セット
      this.officeSelected = this.entity.officeSelected;
      this.makeData();
    },
    //ダイアログを閉じる
    close() {
      this.$emit("closePopup");
      this.$emit("update:isOpenDetailDialog", false);
      this.inputList = [];
    },
  },
  watch: {
    entity: {
      handler(newValue, oldValue) {
        console.info("handler", newValue, oldValue);
      },
    },
    isOpenDetailDialog: function (newValue) {
      if (newValue) {
        this.init();
      }
    },
    sortOptions: {
      handler(items) {
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? "desc" : "asc";
          this.sortItem = sortBy + " " + ascdesc;
          if (sortBy == "userId") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.userId < b.userId) {
                  return -1;
                } else if (a.userId > b.userId) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.userId > b.userId) {
                  return -1;
                } else if (a.userId < b.userId) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "cell") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                return a.cell - b.cell;
              } else {
                return b.cell - a.cell;
              }
            });
          } else if (sortBy == "errorContent") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.errorContent < b.errorContent) {
                  return -1;
                } else if (a.errorContent > b.errorContent) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.errorContent > b.errorContent) {
                  return -1;
                } else if (a.errorContent < b.errorContent) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "errorData") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.errorData < b.errorData) {
                  return -1;
                } else if (a.errorData > b.errorData) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.errorData > b.errorData) {
                  return -1;
                } else if (a.errorData < b.errorData) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          }
          return this.inputList;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
#sub-bar {
  padding: none;
  height: 3rem;
}

.text-overflow {
  // v-tooltip
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
