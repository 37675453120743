<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_stockTransitionProcurement" />
      <!-- 検索項目 -->
      <v-form ref="form">
        <v-container fluid>
          <!-- １行目スタート -->
          <v-row class="search-row">
            <!-- 取引先 -->
            <div class="search-autocomplete first-search-item" style="float: left">
              <v-autocomplete
                dense
                id="get-supplier"
                v-model="suppliersSelected"
                maxlength="10"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                :rules="[rules.supplierRequired]"
                persistent-hint
                :hint="setSrhSupplier()"
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span></template
                >
              </v-autocomplete>
            </div>
            <span class="require" style="float: left">*</span>

            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!-- 基準日-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="referenceDate"
                maxlength="10"
                :label="$t('label.lbl_refernceDate')"
                @change="changeDate"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
                :disabled="true"
              ></v-text-field>
            </div>
            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!-- 品番/品名 -->
            <div class="search-autocomplete" style="float: left">
              <!-- <v-combobox -->
              <v-autocomplete
                dense
                v-model="productCodeSelected"
                :items="productCodeList"
                @change="(event) => changeProductNm(event)"
                :hint="setProductCode()"
                :search-input.sync="search"
                maxlength="100"
                :label="$t('label.lbl_productCnCd')"
                persistent-hint
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span></template
                >
              </v-autocomplete>
              <!-- </v-combobox> -->
            </div>

            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!-- 推移ステータス-->
            <div class="search-autocomplete" style="float: left">
              <v-autocomplete
                dense
                v-model="transactionTypeSelected"
                maxlength="3"
                :items="transactionList"
                :label="$t('label.lbl_transition')"
                :rules="[rules.transactionRequired]"
                class="txt-single"
                persistent-hint
                ><template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span></template
                >
              </v-autocomplete>
            </div>
            <span class="require" style="float: left">*</span>
            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>
            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>
            <!-- 表示期間ステータス-->
            <div class="search-autocomplete" style="float: left">
              <v-autocomplete
                dense
                v-model="displayPeriodSelected"
                maxlength="5"
                :items="displayPeriodList"
                :label="$t('label.lbl_display_period')"
                :rules="[rules.displayRequired]"
                class="txt-single"
                persistent-hint
                ><template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span></template
                >
              </v-autocomplete>
            </div>
            <span class="require" style="float: left">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
          </v-row>
          <v-row>
            <div class="search-row-exeBtn">
              <!--ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 計算ボタン -->
                <v-btn
                  color="primary"
                  id="get-calculation"
                  class="api-btn"
                  @click="getDateInput()"
                  >{{ $t("btn.btn_calculation") }}</v-btn
                >
                <!-- 検索ボタン -->
                <v-btn
                  color="primary"
                  id="get-search"
                  class="api-btn"
                  @click="searchTransiion('get-search')"
                  >{{ $t("btn.btn_search") }}</v-btn
                >
                <!--画面クリアボタン-->
                <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>
              <!--データ出力ボタン開始-->
              <div class="btn-search-area" style="float: right">
                <!-- データ出力ボタン -->
                <v-btn
                  color="primary"
                  id="get-outPut"
                  :disabled="outputBtnActive"
                  class="api-btn"
                  @click="outPutClick"
                >
                  {{ $t("btn.btn_outPutData") }}
                </v-btn>
              </div>
              <!--データ出力ボタン終了-->
            </div>
          </v-row>

          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span>総件数：{{ totalCount }}件</span>
              </div>
              <!-- 検索時間表示部分 -->
              <div class="text-label" style="float: left">
                <span v-if="inputList.length != 0" class="nowTime">
                  （{{ createDatetime }}時点）
                </span>
              </div>
              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  {{ $t("label.lbl_itemsPerPage") }}
                </div>
                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>
                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                  >
                    <v-btn class="pasing-btn" value="100" @click="u_100_on()" small>
                      {{ 100 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="250" @click="u_100_clear(250)" small>
                      {{ 250 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="500" @click="u_100_clear(500)" small>
                      {{ 500 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
          <!-- 2行目ここまで -->
        </v-container>
      </v-form>
      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          class="tableData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :server-items-length="itemsPerPage"
          disable-filtering
          :hide-default-footer="true"
          :options.sync="sortOptions"
          height="100%"
        >
          <!-- 検索結果ヘッダ情報 -->
          <template v-slot:[`header.remainingNotInScheduleQuantity`]="{ header }">
            <v-row>
              <v-col style="text-align: right">
                <div>繰越</div>
                <div>{{ header.text }}</div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`header.inScheduleQuantity00`]="{ header }">
            <v-row>
              <v-col style="text-align: right">
                <div>基準日</div>
                <div>{{ header.text }}</div>
              </v-col>
            </v-row>
          </template>
          <!-- 検索結果ヘッダ情報 -->
          <!-- 検索結果明細情報 -->
          <!-- 品番/品名 -->
          <template v-slot:[`item.productCnCd`]="{ item }">
            <v-row>
              <v-col style="text-align: left">
                <!-- 品番 -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-overflow" v-bind="attrs" v-on="on">
                      {{ item.productCnCd }}
                    </div>
                  </template>
                  <div>{{ item.productCnCd }}</div>
                </v-tooltip>
                <!-- 品番 -->
                <!-- 付帯品番 -->
                <div v-if="item.incidental != ''">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="text-overflow" v-bind="attrs" v-on="on">
                        {{ item.incidental }}
                      </div>
                    </template>
                    <div>{{ item.incidental }}</div>
                  </v-tooltip>
                </div>
                <div v-else>&nbsp;</div>
                <!-- 付帯品番 -->
                <!-- 品名 -->
                <div v-if="item.productName != ''">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="text-overflow" v-bind="attrs" v-on="on">
                        {{ item.productName }}
                      </div>
                    </template>
                    <div>{{ item.productName }}</div>
                  </v-tooltip>
                </div>
                <div v-else>&nbsp;</div>
                <!-- 品名 -->
              </v-col>
            </v-row>
          </template>
          <!-- 品番/品名 -->
          <!-- 警告 -->
          <template v-slot:[`item.alert`]="{ item }">
            <v-row :class="item.class">
              <v-col style="font-weight: bold; color: #ff0000">
                <div>{{ item.alert }}</div>
              </v-col>
            </v-row>
          </template>
          <!-- 警告 -->
          <!-- 項目 -->
          <template v-slot:[`item.items`]="{}">
            <v-row>
              <v-col style="text-align: left">
                <!-- 入荷予定数 -->
                <div>入荷予定数</div>
                <!-- 入荷予定数 -->
                <!-- 搬入計画数 -->
                <div>搬入計画数</div>
                <!-- 搬入計画数 -->
                <!-- 在庫数 -->
                <div>在庫数</div>
                <!-- 在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 項目 -->
          <!-- 基準日以前残 -->
          <template v-slot:[`item.remainingNotInScheduleQuantity`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日以前残-入荷予定数 -->
                <div>
                  {{ item.remainingNotInScheduleQuantity }}
                </div>
                <!-- 基準日以前残-入荷予定数 -->
                <!-- 基準日以前残-出荷予定数 -->
                <div>
                  {{ item.remainingNotOutScheduleQuantity }}
                </div>
                <!-- 基準日以前残-出荷予定数 -->
                <!-- 基準日以前残-在庫数 -->
                <div v-if="item.remainingInventoryQuantirycheck >= 0">
                  {{ item.remainingInventoryQuantiry }}
                </div>
                <div v-else class="negative-inventory">
                  {{ item.remainingInventoryQuantiry }}
                </div>
                <!-- 基準日以前残-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日以前残 -->
          <!-- 基準日 -->
          <template v-slot:[`item.inScheduleQuantity00`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日-入荷予定数 -->
                <div>{{ item.inScheduleQuantity00 }}</div>
                <!-- 基準日-入荷予定数 -->
                <!-- 基準日-出荷予定数 -->
                <div>{{ item.outScheduleQuantity00 }}</div>
                <!-- 基準日-出荷予定数 -->
                <!-- 基準日-在庫数 -->
                <div v-if="item.scheduleQuantitycheck00 >= 0">{{ item.scheduleQuantity00 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity00 }}</div>
                <!-- 基準日-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日 -->
          <!-- 基準日+1 -->
          <template v-slot:[`item.inScheduleQuantity01`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日+1-入荷予定数 -->
                <div>{{ item.inScheduleQuantity01 }}</div>
                <!-- 基準日+1-入荷予定数 -->
                <!-- 基準日+1-出荷予定数 -->
                <div>{{ item.outScheduleQuantity01 }}</div>
                <!-- 基準日+1-出荷予定数 -->
                <!-- 基準日+1-在庫数 -->
                <div v-if="item.scheduleQuantitycheck01 >= 0">{{ item.scheduleQuantity01 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity01 }}</div>
                <!-- 基準日+1-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+1 -->
          <!-- 基準日+02 -->
          <template v-slot:[`item.inScheduleQuantity02`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日+02-入荷予定数 -->
                <div>{{ item.inScheduleQuantity02 }}</div>
                <!-- 基準日+02-入荷予定数 -->
                <!-- 基準日+02-出荷予定数 -->
                <div>{{ item.outScheduleQuantity02 }}</div>
                <!-- 基準日+02-出荷予定数 -->
                <!-- 基準日+02-在庫数 -->
                <div v-if="item.scheduleQuantitycheck02 >= 0">{{ item.scheduleQuantity02 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity02 }}</div>
                <!-- 基準日+02-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+02 -->
          <!-- 基準日+03 -->
          <template v-slot:[`item.inScheduleQuantity03`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日+03-入荷予定数 -->
                <div>{{ item.inScheduleQuantity03 }}</div>
                <!-- 基準日+03-入荷予定数 -->
                <!-- 基準日+03-出荷予定数 -->
                <div>{{ item.outScheduleQuantity03 }}</div>
                <!-- 基準日+03-出荷予定数 -->
                <!-- 基準日+03-在庫数 -->
                <div v-if="item.scheduleQuantitycheck03 >= 0">{{ item.scheduleQuantity03 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity03 }}</div>
                <!-- 基準日+03-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+03 -->
          <!-- 基準日+04 -->
          <template v-slot:[`item.inScheduleQuantity04`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日+04-入荷予定数 -->
                <div>{{ item.inScheduleQuantity04 }}</div>
                <!-- 基準日+04-入荷予定数 -->
                <!-- 基準日+04-出荷予定数 -->
                <div>{{ item.outScheduleQuantity04 }}</div>
                <!-- 基準日+04-出荷予定数 -->
                <!-- 基準日+04-在庫数 -->
                <div v-if="item.scheduleQuantitycheck04 >= 0">{{ item.scheduleQuantity04 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity04 }}</div>
                <!-- 基準日+04-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+04 -->
          <!-- 基準日+05 -->
          <template v-slot:[`item.inScheduleQuantity05`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日+05-入荷予定数 -->
                <div>{{ item.inScheduleQuantity05 }}</div>
                <!-- 基準日+05-入荷予定数 -->
                <!-- 基準日+05-出荷予定数 -->
                <div>{{ item.outScheduleQuantity05 }}</div>
                <!-- 基準日+05-出荷予定数 -->
                <!-- 基準日+05-在庫数 -->
                <div v-if="item.scheduleQuantitycheck05 >= 0">{{ item.scheduleQuantity05 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity05 }}</div>
                <!-- 基準日+05-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+05 -->
          <!-- 基準日+06 -->
          <template v-slot:[`item.inScheduleQuantity06`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日+06-入荷予定数 -->
                <div>{{ item.inScheduleQuantity06 }}</div>
                <!-- 基準日+06-入荷予定数 -->
                <!-- 基準日+06-出荷予定数 -->
                <div>{{ item.outScheduleQuantity06 }}</div>
                <!-- 基準日+06-出荷予定数 -->
                <!-- 基準日+06-在庫数 -->
                <div v-if="item.scheduleQuantitycheck06 >= 0">{{ item.scheduleQuantity06 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity06 }}</div>
                <!-- 基準日+06-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+06 -->
          <!-- 基準日+07 -->
          <template v-slot:[`item.inScheduleQuantity07`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日+07-入荷予定数 -->
                <div>{{ item.inScheduleQuantity07 }}</div>
                <!-- 基準日+07-入荷予定数 -->
                <!-- 基準日+07-出荷予定数 -->
                <div>{{ item.outScheduleQuantity07 }}</div>
                <!-- 基準日+07-出荷予定数 -->
                <!-- 基準日+07-在庫数 -->
                <div v-if="item.scheduleQuantitycheck07 >= 0">{{ item.scheduleQuantity07 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity07 }}</div>
                <!-- 基準日+07-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+07 -->
          <!-- 基準日+08 -->
          <template v-slot:[`item.inScheduleQuantity08`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日+08-入荷予定数 -->
                <div>{{ item.inScheduleQuantity08 }}</div>
                <!-- 基準日+08-入荷予定数 -->
                <!-- 基準日+08-出荷予定数 -->
                <div>{{ item.outScheduleQuantity08 }}</div>
                <!-- 基準日+08-出荷予定数 -->
                <!-- 基準日+08-在庫数 -->
                <div v-if="item.scheduleQuantitycheck08 >= 0">{{ item.scheduleQuantity08 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity08 }}</div>
                <!-- 基準日+08-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+08 -->
          <!-- 基準日+09 -->
          <template v-slot:[`item.inScheduleQuantity09`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日+09-入荷予定数 -->
                <div>{{ item.inScheduleQuantity09 }}</div>
                <!-- 基準日+09-入荷予定数 -->
                <!-- 基準日+09-出荷予定数 -->
                <div>{{ item.outScheduleQuantity09 }}</div>
                <!-- 基準日+09-出荷予定数 -->
                <!-- 基準日+09-在庫数 -->
                <div v-if="item.scheduleQuantitycheck09 >= 0">{{ item.scheduleQuantity09 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity09 }}</div>
                <!-- 基準日+09-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+09 -->
          <!-- 基準日+10 -->
          <template v-slot:[`item.inScheduleQuantity10`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日+10-入荷予定数 -->
                <div>{{ item.inScheduleQuantity10 }}</div>
                <!-- 基準日+10-入荷予定数 -->
                <!-- 基準日+10-出荷予定数 -->
                <div>{{ item.outScheduleQuantity10 }}</div>
                <!-- 基準日+10-出荷予定数 -->
                <!-- 基準日+10-在庫数 -->
                <div v-if="item.scheduleQuantitycheck10 >= 0">{{ item.scheduleQuantity10 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity10 }}</div>
                <!-- 基準日+10-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+10 -->
          <!-- 基準日+11 -->
          <template v-slot:[`item.inScheduleQuantity11`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日+11-入荷予定数 -->
                <div>{{ item.inScheduleQuantity11 }}</div>
                <!-- 基準日+11-入荷予定数 -->
                <!-- 基準日+11-出荷予定数 -->
                <div>{{ item.outScheduleQuantity11 }}</div>
                <!-- 基準日+11-出荷予定数 -->
                <!-- 基準日+11-在庫数 -->
                <div v-if="item.scheduleQuantitycheck11 >= 0">{{ item.scheduleQuantity11 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity11 }}</div>
                <!-- 基準日+11-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+11 -->
          <!-- 基準日+12 -->
          <template v-slot:[`item.inScheduleQuantity12`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日+12-入荷予定数 -->
                <div>{{ item.inScheduleQuantity12 }}</div>
                <!-- 基準日+12-入荷予定数 -->
                <!-- 基準日+12-出荷予定数 -->
                <div>{{ item.outScheduleQuantity12 }}</div>
                <!-- 基準日+12-出荷予定数 -->
                <!-- 基準日+12-在庫数 -->
                <div v-if="item.scheduleQuantitycheck12 >= 0">{{ item.scheduleQuantity12 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity12 }}</div>
                <!-- 基準日+12-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+12 -->
          <!-- 基準日+13 -->
          <template v-slot:[`item.inScheduleQuantity13`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日+13-入荷予定数 -->
                <div>{{ item.inScheduleQuantity13 }}</div>
                <!-- 基準日+13-入荷予定数 -->
                <!-- 基準日+13-出荷予定数 -->
                <div>{{ item.outScheduleQuantity13 }}</div>
                <!-- 基準日+13-出荷予定数 -->
                <!-- 基準日+13-在庫数 -->
                <div v-if="item.scheduleQuantitycheck13 >= 0">{{ item.scheduleQuantity13 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity13 }}</div>
                <!-- 基準日+13-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+13 -->
          <!-- 基準日+14 -->
          <template v-slot:[`item.inScheduleQuantity14`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日+14-入荷予定数 -->
                <div>{{ item.inScheduleQuantity14 }}</div>
                <!-- 基準日+14-入荷予定数 -->
                <!-- 基準日+14-出荷予定数 -->
                <div>{{ item.outScheduleQuantity14 }}</div>
                <!-- 基準日+14-出荷予定数 -->
                <!-- 基準日+14-在庫数 -->
                <div v-if="item.scheduleQuantitycheck14 >= 0">{{ item.scheduleQuantity14 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity14 }}</div>
                <!-- 基準日+14-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+14 -->
          <!-- 基準日+15 -->
          <template v-slot:[`item.inScheduleQuantity15`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日+15-入荷予定数 -->
                <div>{{ item.inScheduleQuantity15 }}</div>
                <!-- 基準日+15-入荷予定数 -->
                <!-- 基準日+15-出荷予定数 -->
                <div>{{ item.outScheduleQuantity15 }}</div>
                <!-- 基準日+15-出荷予定数 -->
                <!-- 基準日+15-在庫数 -->
                <div v-if="item.scheduleQuantitycheck15 >= 0">{{ item.scheduleQuantity15 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity15 }}</div>
                <!-- 基準日+15-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+15 -->
          <!-- 基準日+16 -->
          <template v-slot:[`item.inScheduleQuantity16`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日+16-入荷予定数 -->
                <div>{{ item.inScheduleQuantity16 }}</div>
                <!-- 基準日+16-入荷予定数 -->
                <!-- 基準日+16-出荷予定数 -->
                <div>{{ item.outScheduleQuantity16 }}</div>
                <!-- 基準日+16-出荷予定数 -->
                <!-- 基準日+16-在庫数 -->
                <div v-if="item.scheduleQuantitycheck16 >= 0">{{ item.scheduleQuantity16 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity16 }}</div>
                <!-- 基準日+16-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+16 -->
          <!-- 基準日+17 -->
          <template v-slot:[`item.inScheduleQuantity17`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日+17-入荷予定数 -->
                <div>{{ item.inScheduleQuantity17 }}</div>
                <!-- 基準日+17-入荷予定数 -->
                <!-- 基準日+17-出荷予定数 -->
                <div>{{ item.outScheduleQuantity17 }}</div>
                <!-- 基準日+17-出荷予定数 -->
                <!-- 基準日+17-在庫数 -->
                <div v-if="item.scheduleQuantitycheck17 >= 0">{{ item.scheduleQuantity17 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity17 }}</div>
                <!-- 基準日+17-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+17 -->
          <!-- 基準日+18 -->
          <template v-slot:[`item.inScheduleQuantity18`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日+18-入荷予定数 -->
                <div>{{ item.inScheduleQuantity18 }}</div>
                <!-- 基準日+18-入荷予定数 -->
                <!-- 基準日+18-出荷予定数 -->
                <div>{{ item.outScheduleQuantity18 }}</div>
                <!-- 基準日+18-出荷予定数 -->
                <!-- 基準日+18-在庫数 -->
                <div v-if="item.scheduleQuantitycheck18 >= 0">{{ item.scheduleQuantity18 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity18 }}</div>
                <!-- 基準日+18-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+18 -->
          <!-- 基準日+19 -->
          <template v-slot:[`item.inScheduleQuantity19`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日+19-入荷予定数 -->
                <div>{{ item.inScheduleQuantity19 }}</div>
                <!-- 基準日+19-入荷予定数 -->
                <!-- 基準日+19-出荷予定数 -->
                <div>{{ item.outScheduleQuantity19 }}</div>
                <!-- 基準日+19-出荷予定数 -->
                <!-- 基準日+19-在庫数 -->
                <div v-if="item.scheduleQuantitycheck19 >= 0">{{ item.scheduleQuantity19 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity19 }}</div>
                <!-- 基準日+19-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+19 -->
          <!-- 基準日+20 -->
          <template v-slot:[`item.inScheduleQuantity20`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日+20-入荷予定数 -->
                <div>{{ item.inScheduleQuantity20 }}</div>
                <!-- 基準日+20-入荷予定数 -->
                <!-- 基準日+20-出荷予定数 -->
                <div>{{ item.outScheduleQuantity20 }}</div>
                <!-- 基準日+20-出荷予定数 -->
                <!-- 基準日+20-在庫数 -->
                <div v-if="item.scheduleQuantitycheck20 >= 0">{{ item.scheduleQuantity20 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity20 }}</div>
                <!-- 基準日+20-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+20 -->
          <!-- 基準日+21 -->
          <template v-slot:[`item.inScheduleQuantity21`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日+21-入荷予定数 -->
                <div>{{ item.inScheduleQuantity21 }}</div>
                <!-- 基準日+21-入荷予定数 -->
                <!-- 基準日+21-出荷予定数 -->
                <div>{{ item.outScheduleQuantity21 }}</div>
                <!-- 基準日+21-出荷予定数 -->
                <!-- 基準日+21-在庫数 -->
                <div v-if="item.scheduleQuantitycheck21 >= 0">{{ item.scheduleQuantity21 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity21 }}</div>
                <!-- 基準日+21-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+21 -->
          <!-- 基準日+22 -->
          <template v-slot:[`item.inScheduleQuantity22`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日+22-入荷予定数 -->
                <div>{{ item.inScheduleQuantity22 }}</div>
                <!-- 基準日+22-入荷予定数 -->
                <!-- 基準日+22-出荷予定数 -->
                <div>{{ item.outScheduleQuantity22 }}</div>
                <!-- 基準日+22-出荷予定数 -->
                <!-- 基準日+22-在庫数 -->
                <div v-if="item.scheduleQuantitycheck22 >= 0">{{ item.scheduleQuantity22 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity22 }}</div>
                <!-- 基準日+22-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+22 -->
          <!-- 基準日+23 -->
          <template v-slot:[`item.inScheduleQuantity23`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日+23-入荷予定数 -->
                <div>{{ item.inScheduleQuantity23 }}</div>
                <!-- 基準日+23-入荷予定数 -->
                <!-- 基準日+23-出荷予定数 -->
                <div>{{ item.outScheduleQuantity23 }}</div>
                <!-- 基準日+23-出荷予定数 -->
                <!-- 基準日+23-在庫数 -->
                <div v-if="item.scheduleQuantitycheck23 >= 0">{{ item.scheduleQuantity23 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity23 }}</div>
                <!-- 基準日+23-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+23 -->
          <!-- 基準日+24 -->
          <template v-slot:[`item.inScheduleQuantity24`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日+24-入荷予定数 -->
                <div>{{ item.inScheduleQuantity24 }}</div>
                <!-- 基準日+24-入荷予定数 -->
                <!-- 基準日+24-出荷予定数 -->
                <div>{{ item.outScheduleQuantity24 }}</div>
                <!-- 基準日+24-出荷予定数 -->
                <!-- 基準日+24-在庫数 -->
                <div v-if="item.scheduleQuantitycheck24 >= 0">{{ item.scheduleQuantity24 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity24 }}</div>
                <!-- 基準日+24-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+24 -->
          <!-- 基準日+25 -->
          <template v-slot:[`item.inScheduleQuantity25`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日+25-入荷予定数 -->
                <div>{{ item.inScheduleQuantity25 }}</div>
                <!-- 基準日+25-入荷予定数 -->
                <!-- 基準日+25-出荷予定数 -->
                <div>{{ item.outScheduleQuantity25 }}</div>
                <!-- 基準日+25-出荷予定数 -->
                <!-- 基準日+25-在庫数 -->
                <div v-if="item.scheduleQuantitycheck25 >= 0">{{ item.scheduleQuantity25 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity25 }}</div>
                <!-- 基準日+25-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+25 -->
          <!-- 基準日+26 -->
          <template v-slot:[`item.inScheduleQuantity26`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日+26-入荷予定数 -->
                <div>{{ item.inScheduleQuantity26 }}</div>
                <!-- 基準日+26-入荷予定数 -->
                <!-- 基準日+26-出荷予定数 -->
                <div>{{ item.outScheduleQuantity26 }}</div>
                <!-- 基準日+26-出荷予定数 -->
                <!-- 基準日+26-在庫数 -->
                <div v-if="item.scheduleQuantitycheck26 >= 0">{{ item.scheduleQuantity26 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity26 }}</div>
                <!-- 基準日+26-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+26 -->
          <!-- 基準日+27 -->
          <template v-slot:[`item.inScheduleQuantity27`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日+27-入荷予定数 -->
                <div>{{ item.inScheduleQuantity27 }}</div>
                <!-- 基準日+27-入荷予定数 -->
                <!-- 基準日+27-出荷予定数 -->
                <div>{{ item.outScheduleQuantity27 }}</div>
                <!-- 基準日+27-出荷予定数 -->
                <!-- 基準日+27-在庫数 -->
                <div v-if="item.scheduleQuantitycheck27 >= 0">{{ item.scheduleQuantity27 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity27 }}</div>
                <!-- 基準日+27-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+27 -->
          <!-- 基準日+28 -->
          <template v-slot:[`item.inScheduleQuantity28`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日+28-入荷予定数 -->
                <div>{{ item.inScheduleQuantity28 }}</div>
                <!-- 基準日+28-入荷予定数 -->
                <!-- 基準日+28-出荷予定数 -->
                <div>{{ item.outScheduleQuantity28 }}</div>
                <!-- 基準日+28-出荷予定数 -->
                <!-- 基準日+28-在庫数 -->
                <div v-if="item.scheduleQuantitycheck28 >= 0">{{ item.scheduleQuantity28 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity28 }}</div>
                <!-- 基準日+28-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+28 -->
          <!-- 基準日+29 -->
          <template v-slot:[`item.inScheduleQuantity29`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日+29-入荷予定数 -->
                <div>{{ item.inScheduleQuantity29 }}</div>
                <!-- 基準日+29-入荷予定数 -->
                <!-- 基準日+29-出荷予定数 -->
                <div>{{ item.outScheduleQuantity29 }}</div>
                <!-- 基準日+29-出荷予定数 -->
                <!-- 基準日+29-在庫数 -->
                <div v-if="item.scheduleQuantitycheck29 >= 0">{{ item.scheduleQuantity29 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity29 }}</div>
                <!-- 基準日+29-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+29 -->
          <!-- 基準日+30 -->
          <template v-slot:[`item.inScheduleQuantity30`]="{ item }">
            <v-row>
              <v-col style="text-align: right">
                <!-- 基準日+30-入荷予定数 -->
                <div>{{ item.inScheduleQuantity30 }}</div>
                <!-- 基準日+30-入荷予定数 -->
                <!-- 基準日+30-出荷予定数 -->
                <div>{{ item.outScheduleQuantity30 }}</div>
                <!-- 基準日+30-出荷予定数 -->
                <!-- 基準日+30-在庫数 -->
                <div v-if="item.scheduleQuantitycheck30 >= 0">{{ item.scheduleQuantity30 }}</div>
                <div v-else class="negative-inventory">{{ item.scheduleQuantity30 }}</div>
                <!-- 基準日+30-在庫数 -->
              </v-col>
            </v-row>
          </template>
          <!-- 基準日+30 -->
          <!-- 検索結果明細情報 -->
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
      </v-container>
      <!-- ナビゲーション -->
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <!-- 確認メッセージダイアログモーダル-->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
      />
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :changeFlag="confirmDialog.changeFlag"
      />
      <v-dialog v-model="dateInputDialog" :max-width="400" origin:top @click:outside="close">
        <!-- 計算時日付入力用ダイアログ -->
        <v-card>
          <v-card-title id="sub-bar">
            <span id="lbl-screen-name">{{ $t("label.lbl_refernceDateInput") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-form ref="numIssueVali" lazy-validation>
                <v-row class="search-row" style="margin-bottom: 5px">
                  <!-- 基準日-->
                  <div class="date-style">
                    <v-text-field
                      outlined
                      dense
                      v-model="referenceDate"
                      maxlength="10"
                      :label="$t('label.lbl_refernceDate')"
                      @change="changeDate"
                      class="txt-single date-style"
                      :rules="[rules.yyyymmdd]"
                      clear-icon="mdi-close-circle"
                      clearable
                    ></v-text-field>
                    <!-- :rules="[rules.yyyymmdd, rules.dateCheck]" -->
                  </div>
                  <div class="calender-btn-area">
                    <v-menu
                      v-model="referenceDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="-100"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      class="date-calendar"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" text x-small>
                          <v-icon>mdi-calendar</v-icon>
                        </v-btn>
                      </template>
                      <v-date-picker
                        v-model="referenceDateCal"
                        @input="referenceDateMenu = false"
                        max="2099-12-31"
                      ></v-date-picker>
                    </v-menu>
                    <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </div>
                  <!--データ形式-->
                </v-row>
                <v-row style="margin-bottom: 5px">
                  <div class="search-row-exeBtn">
                    <div class="btn-area">
                      <!-- 閉じるボタン -->
                      <v-btn
                        color="primary"
                        id="btn-search"
                        class="other-btn"
                        @click="closeDateInput()"
                      >
                        {{ $t("btn.btn_close") }}</v-btn
                      >

                      <!-- 計算ボタン -->
                      <v-btn
                        color="primary"
                        id="btn-search"
                        class="other-btn"
                        @click="getCalculation()"
                      >
                        {{ $t("btn.btn_calculation") }}</v-btn
                      >
                    </div>
                  </div>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- キュー状況ダイアログ -->
      <v-dialog v-model="dialogQueInfoStatus" :max-width="800" persistent="" no-click-animation>
        <v-card>
          <v-card-title id="sub-bar">
            <span id="lbl-screen-name">{{ $t("label.lbl_stock_transition_calculation") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <!-- キュー状況テーブルデータ -->
              <v-data-table
                id="QueInfoStatusData"
                fixed-header
                :headers="queInfoHeader"
                :items="queInfoList"
                disable-sort
                :hide-default-footer="true"
              >
                <!-- 指示作成状況メッセージ -->
                <template v-slot:[`item.queInfoMessage`]="{ item }">
                  <span>
                    <!-- 指示作成状況メッセージを表示 -->
                    {{ item.queInfoMessage }}
                  </span>
                </template>
              </v-data-table>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!--ボタン領域-->
            <div class="search-row-exeBtn">
              <div class="btn-area">
                <!-- 閉じるボタン -->
                <v-btn class="other-btn" @click="dialogQueInfoStatus = false">
                  {{ $t("btn.btn_close") }}
                </v-btn>
              </div>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { i18n } from "../../lang/lang.js";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
// import StockInquiryDetails from "../stock/StockInquiryDetails.vue";
import NavBar from "../../components/NavBar.vue";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { postParameter } from "../../assets/scripts/js/PostParameter";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";
import ConfirmDialog from "@/components/ConfirmDialog";
import SimpleDialog from "@/components/SimpleDialog";

export default {
  name: appConfig.SCREEN_ID.P_SRG_004,
  components: {
    Loading,
    sideMenu,
    // StockInquiryDetails,
    NavBar,
    ConfirmDialog,
    SimpleDialog,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    u_100: false,
    rules: {
      supplierRequired: (value) => !!value || i18n.tc("check.chk_inputSupplier"),
      transactionRequired: (value) => !!value || i18n.tc("check.chk_select_transaction"),
      displayRequired: (value) => !!value || i18n.tc("check.chk_select_displayPeriod"),
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      /**
       * 日付の当日以降チェック
       * @param {*} value チェック対象日付（yyyy-MM-dd）
       */
      dateCheck(value) {
        if (dateTimeHelper.isDateAfterTheDay(value)) {
          // 当日以降の場合は処理なし
          return true;
        } else {
          // 当日より前日付の場合はエラー
          return i18n.tc("check.chk_DateAfterTheDay");
        }
      },
      dataFormatRules: (value) => !!value || i18n.tc("check.chk_outputFormatDiv"),
      outPutPatternRules: (value) => !!value || i18n.tc("check.chk_outputPattern"),
    },
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: true,
    },
    // 確認メッセージ
    confirmDialog: {
      isOpen: false,
      message: "",
      redMessage: "",
      okAction: () => {},
    },
    // 出力ボタンダイアログメッセージ
    backMessage: "",
    // 検索時点の日時
    year: "",
    today: "",
    nowTime: "",
    search: "",
    // ページング
    itemsPerPageList: [100, 250, 500],
    page: 1,
    pageCount: 0,
    totalCount: 0,
    itemsPerPage: 500,
    itemsPerPageBtn: "500",
    // 選択された取引先
    suppliersSelected: "",
    // 選択された推移
    transactionTypeSelected: "01",
    // 選択された品番/品名
    productCodeSelected: "",
    //選択された表示期間
    displayPeriodSelected: "",
    //選択されたデータ形式
    dataFormatSelected: "01",
    //出力単位
    outPutPatternSelected: "01",
    // メニュー
    openMenu: null,
    // 詳細ポップアップ
    dialogDetails: false,
    // データ出力ボタン活性,非活性
    outputBtnActive: true,
    //データ出力時ダイアログ(初期値)
    dateInputDialog: false,
    // ソート
    sortItem: "",
    sortOptions: {},
    // 初期データ
    defaultData: [],

    // ヘッダ
    headerItems: [
      // 品番/品名
      {
        text: i18n.tc("label.lbl_userProductCdNameAndAccessoryProduct"),
        value: "productCnCd",
        width: "322",
        class: "product-fixed",
        align: "left",
        sortable: false,
      },
      // 警告
      {
        text: i18n.tc("label.lbl_alert"),
        value: "alert",
        width: "150px",
        class: "alert-fixed",
        align: "center",
        sortable: false,
      },
      // 項目
      {
        text: i18n.tc("label.lbl_items"),
        value: "items",
        width: "150px",
        class: "items-fixed",
        align: "left",
        sortable: false,
      },
    ],
    inputList: [],
    // 検索結果
    transitionList: [],
    // 基準日カレンダー
    referenceDateCal: "",
    // 基準日
    referenceDate: "",
    referenceDateMenu: false,
    // 取引先プルダウン
    supplierList: [],
    // 推移プルダウン
    //DivList: [],
    //データ形式プルダウン
    dataFormatList: [],
    //出力単位プルダウン
    outPutPatternList: [],
    //表示期間プルダウン
    displayPeriodList: [],
    //推移プルダウン
    transactionList: [],
    //表記機関初期表示情報
    displayPeriodLnitial: [],
    suppliersSelectedName: "",
    lot: "",
    //qualityDiv: "",
    itemName: "",
    itemStandardSid: "",
    transitionDiv: "00",
    // 品番品名プルダウン
    productCodeList: [],
    dummy_input: {
      productNm: "製品名",
      productCd: "a",
      productAcc: "製品付帯",
      productQty: "0",
      caseSu: "0",
      ballSu: "0",
      baraSu: "0",
      lot: "lot",
      expireDate: "2022/01/13",
    },
    // 状況自動更新フラグ
    autoUpdateFlag: "off",
    // キュー状況ダイアログ
    dialogQueInfoStatus: false,
    // リクエストID
    requestId: "",
    // タイムID
    timeId: "",
    // キュー状況ヘッダー
    queInfoHeader: [
      {
        text: i18n.tc("label.lbl_message"),
        value: "queInfoMessage",
        align: "left",
        width: "15 % ",
      },
    ],
    // キュー状況データ
    queInfoList: [],
  }),
  methods: {
    // テーブルの余白調整
    u_100_check() {
      if (this.itemsPerPage < 101) {
        this.u_100 = true;
      }
    },
    u_100_on() {
      this.u_100 = true;
      this.itemsPerPage = 100;
      this.page = 1;
      this.apigetStock("get-search");
    },
    u_100_clear(val) {
      this.u_100 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.apigetStock("get-search");
    },
    // 初期化
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);
      this.getInitData();
      this.itemsPerPage = 100;
      this.itemsPerPageBtn = "100";
    },
    // 初期データ取得
    getInitData() {
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(null)
      );
      //コードマスタから取得
      //const qualityDivList = getParameter.getCodeMst(appConfig.CODETYPE.IN_QUALITY_DIV);
      //表示期間をコードマスタから取得
      const displayPeriod = getParameter.getCodeMst(appConfig.CODETYPE.DISPLAY_PERIOD);
      const displayPeriodLl = getParameter.getCodeMst(appConfig.CODETYPE.DISPLAY_PERIOD_LNITIAL);
      const dataFormatDivList = getParameter.getCodeMst(appConfig.CODETYPE.DATAFORMAT_DIV);
      const outPutPatternDivList = getParameter.getCodeMst(
        appConfig.CODETYPE.CSV_OUTPUTPATTERN_DIV
      );
      const transactionList = getParameter.getCodeMst(appConfig.CODETYPE.TRANSACTION_DIV);
      Promise.all([
        clientList,
        //qualityDivList,
        displayPeriod,
        displayPeriodLl,
        dataFormatDivList,
        outPutPatternDivList,
        transactionList,
      ])
        .then((result) => {
          // 画面の初期値を設定します。
          this.supplierList = result[0];
          //this.qualityList = result[1];
          this.displayPeriodList = result[1];
          this.displayPeriodLnitial = result[2];
          this.dataFormatList = result[3];
          this.outPutPatternList = result[4];
          this.transactionList = result[5];
          //表示期間初期値設定
          for (var i = 0; i < this.displayPeriodLnitial.length; i++) {
            if (this.displayPeriodLnitial[i].value == "01") {
              this.displayPeriodSelected = this.displayPeriodLnitial[i].text;
            }
          }
          this.tableDate(new Date());
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
          // クリアフラグを解除
          this.clearFlg = false;
        });
    },
    // メニューダイアログ
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    // 取引先コードセット処理
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },
    getClientName() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].text;
        }
      }
    },
    // 品番セット処理
    setProductCode() {
      for (var i = 0; i < this.productCodeList.length; i++) {
        if (this.productCodeList[i].value == this.productCodeSelected) {
          return this.productCodeList[i].name;
        }
      }
    },
    // 推移セット処理
    setStsArrival() {
      for (var i = 0; i < this.transactionList.length; i++) {
        if (this.transactionList[i].value == this.transactionTypeSelected) {
          return this.transactionList[i].text;
        }
      }
    },

    //計算ボタン押下時：基準日入力ダイアログ表示
    getDateInput() {
      // 基準日が空の場合は現在日付を設定
      if (this.$refs.form.validate()) {
        if (this.referenceDateCal == "") {
          this.referenceDate = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
          this.referenceDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        }
        this.dateInputDialog = true;
      }
    },

    // 在庫情報出力画面：ポップアップ閉じる
    closeDateInput() {
      this.dateInputDialog = false;
      this.referenceDateSet();
    },

    // 計算処理
    getCalculation() {
      if (this.$refs.form.validate()) {
        const config = this.$httpClient.createRequestBodyConfig();
        config.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SUP_021;
        // P_SUP_021
        // ローディング画面表示ON
        this.loadingCounter = 1;

        //APIに渡す値をconfig.paramsにセット==================================//
        // 取引先
        if (this.suppliersSelected) {
          config.reqIdv.clientSid = this.suppliersSelected;
        }
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .securePost(
              appConfig.API_URL.BIZ_DELIVERYSTOCKTRANSITION_EXCLUSION,
              config,
              appConfig.APP_CONFIG
            )
            .then((response) => {
              const jsonData = JSON.parse(JSON.stringify(response.data));
              if (jsonData.resCom.resComCode != "000") {
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                // ローディング画面表示OFF
                this.loadingCounter = 0;
                this.referenceDateSet();
              } else {
                const config2 = this.$httpClient.createRequestBodyConfig();
                config2.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SUP_021;
                // リクエストIDを取得
                this.requestId = config2.reqCom.reqComRequestId;
                //APIに渡す値をconfig.paramsにセット==================================//
                // 取引先
                if (this.suppliersSelected) {
                  config2.reqIdv.clientSid = this.suppliersSelected;
                }

                // 商品標準SID
                if (this.productCodeSelected) {
                  config2.reqIdv.itemStandardSid = this.productCodeSelected;
                } else {
                  config2.reqIdv.itemStandardSid = "";
                }

                //表示期間
                if (this.displayPeriodSelected) {
                  config2.reqIdv.displayCode = this.displayPeriodSelected;
                }

                // 基準日
                if (this.referenceDateCal) {
                  let dateRange = this.referenceDateCal + " 00:00:00.000";
                  config2.reqIdv.referenceDate = dateTimeHelper.convertUTC(dateRange);
                }
                return postParameter
                  .postMstQueInfo(config2)
                  .then(() => {
                    // キュー状況モダールを表示
                    this.openDialogQueInfoStatus();
                  })
                  .catch((ex) => {
                    this.infoDialog.message = ex;
                    this.infoDialog.title = appConfig.DIALOG.title;
                    this.infoDialog.isOpen = true;
                    this.infoDialog.firstPageFlag = true;
                    // ローディング画面表示OFF
                    this.loadingCounter = 0;
                    this.referenceDateSet();
                  })
                  .finally(() => {});
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              this.referenceDateSet();
              reject();
            })
            .finally(() => {
              this.dateInputDialog = false;
            });
        });
      }
    },

    // 在庫推移検索
    searchTransiion(val) {
      // 計算済みのデータがない取引先の場合はエラー
      if (!this.referenceDateCal) {
        if (this.$refs.form.validate()) {
          this.infoDialog.message = messsageUtil.getMessage("P-SUP-020_001_E");
          this.infoDialog.title = "検索結果";
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          // 画面を初期化
          this.clearScreenOkClick();
        }
      } else {
        this.apigetStock(val);
      }
    },
    // 在庫推移検索
    apigetStock(val) {
      var now = new Date();
      this.today = dateTimeHelper.toStringDate("YYYY/MM/DD", now);
      this.nowTime = dateTimeHelper.toStringDate("hh:mm", now);
      if (this.$refs.form.validate()) {
        const config = this.$httpClient.createGetApiRequestConfig();

        // ローディング画面表示ON
        this.loadingCounter = 1;

        //APIに渡す値をconfig.paramsにセット==================================//
        // 取引先
        if (this.suppliersSelected) {
          config.params.clientSid = this.suppliersSelected;
        }

        // 基準日
        if (this.referenceDateCal) {
          let dateRange = this.referenceDateCal + " 00:00:00.000";
          config.params.referenceDate = dateTimeHelper.convertUTC(dateRange);
        } else {
          //基準日が設定されていない状態で検索処理を行う場合は処理を中断
          this.loadingCounter = 0;
          return false;
        }

        // 品番/品名(コード)
        if (this.productCodeSelected) {
          // config.params.itemCd = this.productCodeSelected;
          config.params.itemStandardSid = this.productCodeSelected;
        }

        //推移ステータス
        if (this.transactionTypeSelected) {
          config.params.transitionDiv = this.transactionTypeSelected;
        }

        //表示期間
        if (this.displayPeriodSelected) {
          config.params.displayPeriod = this.displayPeriodSelected;
        }

        // ソート
        if (this.sortItem != "") {
          config.params.reqComSortItem = this.sortItem;
        }

        // ページャー処理
        if (val == "get-search") {
          config.params.reqComPageIndex = "1";
          this.page = 1;
        } else {
          // ページャー処理
          if (this.page >= 2 && this.inputList.length == 0) {
            config.params.reqComPageIndex = "1";
            this.page = 1;
          } else {
            config.params.reqComPageIndex = this.page;
          }
        }

        // ページング
        config.params.reqComPaginationFlg = "1";
        config.params.reqComPageLimit = this.itemsPerPage;
        // console.debug("apigetStock() Config", config);
        //APIに渡す値をconfig.paramsにセット==================================//

        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .secureGet(appConfig.API_URL.BIZ_DELIVERYSTOCKTRANSITION, config)
            .then((response) => {
              // console.debug("apigetStock() Response", response);
              const jsonData = JSON.parse(JSON.stringify(response.data));

              //headerItems内削除
              this.headerItems.splice(0, this.headerItems.length);

              //headerItems設定-----------------------------------------//
              this.headerItems.push(
                // 品番/品名
                {
                  text: i18n.tc("label.lbl_userProductCdNameAndAccessoryProduct"),
                  value: "productCnCd",
                  width: "322px",
                  class: "product-fixed",
                  align: "left",
                  sortable: false,
                }
              );

              // 正常時
              this.headerItems.push(
                // 警告
                {
                  text: i18n.tc("label.lbl_alert"),
                  value: "alert",
                  width: "150px",
                  class: "alert-fixed",
                  align: "center",
                  sortable: false,
                },
                // 項目
                {
                  text: i18n.tc("label.lbl_items"),
                  value: "items",
                  width: "150px",
                  class: "items-fixed",
                  align: "left",
                  sortable: false,
                }
              );

              this.tableDate(new Date(this.referenceDateCal + " 23:59:59"));
              //headerItems設定-----------------------------------------//

              // 正常時
              if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                this.pageCount = jsonData.resCom.resComPagination.totalPage;
                this.totalCount = jsonData.resCom.resComPagination.totalRecord;
                const list = [];

                this.transition = jsonData.resIdv.inventoryTransition;

                //計算・検索後にデータが存在しない場合は画面を初期化してメッセージを表示
                if (!this.transition.length > 0) {
                  this.infoDialog.message = messsageUtil.getMessage("P-BIL-003_005_E");
                  this.infoDialog.title = "検索結果";
                  this.infoDialog.isOpen = true;
                  this.infoDialog.screenFlag = true;

                  // 画面を初期化
                  this.clearScreenOkClick();
                  return false;
                }

                this.createDatetime = dateTimeHelper.convertUTC2JST(
                  this.transition[0].createDatetime
                );
                this.createDatetime = this.createDatetime.substring(0, 16).trim();
                for (let i = 0; i < this.transition.length; i++) {
                  list.push({
                    productCnCdShow: this.transition[i].itemCd,
                    productCnCd: this.transition[i].itemCd.substring(0, 50).trim(), // 品番
                    incidental: this.transition[i].itemCd.slice(-50).trim(), // 品名
                    productName: this.transition[i].itemName, // 品名
                    alert: this.transition[i].warningDiv != "0" ? "ピンチ" : "", //警告
                    statusQuality: this.transition[i].qualityDiv, // ステータス(品質区分)
                    remainingInventoryQuantiry: commonUtil.formatToCurrency(
                      Number(this.transition[i].remainingInventoryQuantity)
                    ), // 基準日以前残-在庫数
                    remainingInventoryQuantirycheck: this.transition[i].remainingInventoryQuantity, //基準日以前残(チェック用)
                    inScheduleQuantity00: commonUtil.formatToCurrency(
                      Number(this.transition[i].inScheduleQuantity00)
                    ), // 基準日-入荷予定数
                    outScheduleQuantity00: commonUtil.formatToCurrency(
                      Number(this.transition[i].outScheduleQuantity00)
                    ), // 基準日-出荷予定数
                    scheduleQuantity00: commonUtil.formatToCurrency(
                      Number(this.transition[i].scheduleQuantity00)
                    ), // 基準日-在庫数
                    scheduleQuantitycheck00: this.transition[i].scheduleQuantity00, //基準日-在庫数(チェック用)
                    inScheduleQuantity01: commonUtil.formatToCurrency(
                      Number(this.transition[i].inScheduleQuantity01)
                    ), // 基準日+1-入荷予定数
                    outScheduleQuantity01: commonUtil.formatToCurrency(
                      Number(this.transition[i].outScheduleQuantity01)
                    ), // 基準日+1-出荷予定数
                    scheduleQuantity01: commonUtil.formatToCurrency(
                      Number(this.transition[i].scheduleQuantity01)
                    ), // 基準日+1-在庫数
                    scheduleQuantitycheck01: this.transition[i].scheduleQuantity01, //基準日+1-在庫数(チェック用)
                    inScheduleQuantity02: commonUtil.formatToCurrency(
                      Number(this.transition[i].inScheduleQuantity02)
                    ), // 基準日+2-入荷予定数
                    outScheduleQuantity02: commonUtil.formatToCurrency(
                      Number(this.transition[i].outScheduleQuantity02)
                    ), // 基準日+2-出荷予定数
                    scheduleQuantity02: commonUtil.formatToCurrency(
                      Number(this.transition[i].scheduleQuantity02)
                    ), // 基準日+2-在庫数
                    scheduleQuantitycheck02: this.transition[i].scheduleQuantity02, //基準日+2-在庫数(チェック用)
                    inScheduleQuantity03: commonUtil.formatToCurrency(
                      Number(this.transition[i].inScheduleQuantity03)
                    ), // 基準日+03-入荷予定数
                    outScheduleQuantity03: commonUtil.formatToCurrency(
                      Number(this.transition[i].outScheduleQuantity03)
                    ), // 基準日+03-出荷予定数
                    scheduleQuantity03: commonUtil.formatToCurrency(
                      Number(this.transition[i].scheduleQuantity03)
                    ), // 基準日+03-在庫数
                    scheduleQuantitycheck03: this.transition[i].scheduleQuantity03, //基準日+03-在庫数(チェック用)
                    inScheduleQuantity04: commonUtil.formatToCurrency(
                      Number(this.transition[i].inScheduleQuantity04)
                    ), // 基準日+04-入荷予定数
                    outScheduleQuantity04: commonUtil.formatToCurrency(
                      Number(this.transition[i].outScheduleQuantity04)
                    ), // 基準日+04-出荷予定数
                    scheduleQuantity04: commonUtil.formatToCurrency(
                      Number(this.transition[i].scheduleQuantity04)
                    ), // 基準日+04-在庫数
                    scheduleQuantitycheck04: this.transition[i].scheduleQuantity04, //基準日+04-在庫数(チェック用)
                    inScheduleQuantity05: commonUtil.formatToCurrency(
                      Number(this.transition[i].inScheduleQuantity05)
                    ), // 基準日+05-入荷予定数
                    outScheduleQuantity05: commonUtil.formatToCurrency(
                      Number(this.transition[i].outScheduleQuantity05)
                    ), // 基準日+05-出荷予定数
                    scheduleQuantity05: commonUtil.formatToCurrency(
                      Number(this.transition[i].scheduleQuantity05)
                    ), // 基準日+05-在庫数
                    scheduleQuantitycheck05: this.transition[i].scheduleQuantity05, //基準日+05-在庫数(チェック用)
                    inScheduleQuantity06: commonUtil.formatToCurrency(
                      Number(this.transition[i].inScheduleQuantity06)
                    ), // 基準日+06-入荷予定数
                    outScheduleQuantity06: commonUtil.formatToCurrency(
                      Number(this.transition[i].outScheduleQuantity06)
                    ), // 基準日+06-出荷予定数
                    scheduleQuantity06: commonUtil.formatToCurrency(
                      Number(this.transition[i].scheduleQuantity06)
                    ), // 基準日+06-在庫数
                    scheduleQuantitycheck06: this.transition[i].scheduleQuantity06, //基準日+06-在庫数(チェック用)
                    inScheduleQuantity07: commonUtil.formatToCurrency(
                      Number(this.transition[i].inScheduleQuantity07)
                    ), // 基準日+07-入荷予定数
                    outScheduleQuantity07: commonUtil.formatToCurrency(
                      Number(this.transition[i].outScheduleQuantity07)
                    ), // 基準日+07-出荷予定数
                    scheduleQuantity07: commonUtil.formatToCurrency(
                      Number(this.transition[i].scheduleQuantity07)
                    ), // 基準日+07-在庫数
                    scheduleQuantitycheck07: this.transition[i].scheduleQuantity07, //基準日+07-在庫数(チェック用)
                    inScheduleQuantity08: commonUtil.formatToCurrency(
                      Number(this.transition[i].inScheduleQuantity08)
                    ), // 基準日+08-入荷予定数
                    outScheduleQuantity08: commonUtil.formatToCurrency(
                      Number(this.transition[i].outScheduleQuantity08)
                    ), // 基準日+08-出荷予定数
                    scheduleQuantity08: commonUtil.formatToCurrency(
                      Number(this.transition[i].scheduleQuantity08)
                    ), // 基準日+08-在庫数
                    scheduleQuantitycheck08: this.transition[i].scheduleQuantity08, //基準日+08-在庫数(チェック用)
                    inScheduleQuantity09: commonUtil.formatToCurrency(
                      Number(this.transition[i].inScheduleQuantity09)
                    ), // 基準日+09-入荷予定数
                    outScheduleQuantity09: commonUtil.formatToCurrency(
                      Number(this.transition[i].outScheduleQuantity09)
                    ), // 基準日+09-出荷予定数
                    scheduleQuantity09: commonUtil.formatToCurrency(
                      Number(this.transition[i].scheduleQuantity09)
                    ), // 基準日+09-在庫数
                    scheduleQuantitycheck09: this.transition[i].scheduleQuantity09, //基準日+09-在庫数(チェック用)
                    inScheduleQuantity10: commonUtil.formatToCurrency(
                      Number(this.transition[i].inScheduleQuantity10)
                    ), // 基準日+10-入荷予定数
                    outScheduleQuantity10: commonUtil.formatToCurrency(
                      Number(this.transition[i].outScheduleQuantity10)
                    ), // 基準日+10-出荷予定数
                    scheduleQuantity10: commonUtil.formatToCurrency(
                      Number(this.transition[i].scheduleQuantity10)
                    ), // 基準日+10-在庫数
                    scheduleQuantitycheck10: this.transition[i].scheduleQuantity10, //基準日+10-在庫数(チェック用)
                    inScheduleQuantity11: commonUtil.formatToCurrency(
                      Number(this.transition[i].inScheduleQuantity11)
                    ), // 基準日+11-入荷予定数
                    outScheduleQuantity11: commonUtil.formatToCurrency(
                      Number(this.transition[i].outScheduleQuantity11)
                    ), // 基準日+11-出荷予定数
                    scheduleQuantity11: commonUtil.formatToCurrency(
                      Number(this.transition[i].scheduleQuantity11)
                    ), // 基準日+11-在庫数
                    scheduleQuantitycheck11: this.transition[i].scheduleQuantity11, //基準日+11-在庫数(チェック用)
                    inScheduleQuantity12: commonUtil.formatToCurrency(
                      Number(this.transition[i].inScheduleQuantity12)
                    ), // 基準日+12-入荷予定数
                    outScheduleQuantity12: commonUtil.formatToCurrency(
                      Number(this.transition[i].outScheduleQuantity12)
                    ), // 基準日+12-出荷予定数
                    scheduleQuantity12: commonUtil.formatToCurrency(
                      Number(this.transition[i].scheduleQuantity12)
                    ), // 基準日+12-在庫数
                    scheduleQuantitycheck12: this.transition[i].scheduleQuantity12, //基準日+12-在庫数(チェック用)
                    inScheduleQuantity13: commonUtil.formatToCurrency(
                      Number(this.transition[i].inScheduleQuantity13)
                    ), // 基準日+13-入荷予定数
                    outScheduleQuantity13: commonUtil.formatToCurrency(
                      Number(this.transition[i].outScheduleQuantity13)
                    ), // 基準日+13-出荷予定数
                    scheduleQuantity13: commonUtil.formatToCurrency(
                      Number(this.transition[i].scheduleQuantity13)
                    ), // 基準日+13-在庫数
                    scheduleQuantitycheck13: this.transition[i].scheduleQuantity13, //基準日+13-在庫数(チェック用)
                    inScheduleQuantity14: commonUtil.formatToCurrency(
                      Number(this.transition[i].inScheduleQuantity14)
                    ), // 基準日+14-入荷予定数
                    outScheduleQuantity14: commonUtil.formatToCurrency(
                      Number(this.transition[i].outScheduleQuantity14)
                    ), // 基準日+14-出荷予定数
                    scheduleQuantity14: commonUtil.formatToCurrency(
                      Number(this.transition[i].scheduleQuantity14)
                    ), // 基準日+14-在庫数
                    scheduleQuantitycheck14: this.transition[i].scheduleQuantity14, //基準日+14-在庫数(チェック用)
                    inScheduleQuantity15: commonUtil.formatToCurrency(
                      Number(this.transition[i].inScheduleQuantity15)
                    ), // 基準日+15-入荷予定数
                    outScheduleQuantity15: commonUtil.formatToCurrency(
                      Number(this.transition[i].outScheduleQuantity15)
                    ), // 基準日+15-出荷予定数
                    scheduleQuantity15: commonUtil.formatToCurrency(
                      Number(this.transition[i].scheduleQuantity15)
                    ), // 基準日+15-在庫数
                    scheduleQuantitycheck15: this.transition[i].scheduleQuantity15, //基準日+15-在庫数(チェック用)
                    inScheduleQuantity16: commonUtil.formatToCurrency(
                      Number(this.transition[i].inScheduleQuantity16)
                    ), // 基準日+16-入荷予定数
                    outScheduleQuantity16: commonUtil.formatToCurrency(
                      Number(this.transition[i].outScheduleQuantity16)
                    ), // 基準日+16-出荷予定数
                    scheduleQuantity16: commonUtil.formatToCurrency(
                      Number(this.transition[i].scheduleQuantity16)
                    ), // 基準日+16-在庫数
                    scheduleQuantitycheck16: this.transition[i].scheduleQuantity16, //基準日+16-在庫数(チェック用)
                    inScheduleQuantity17: commonUtil.formatToCurrency(
                      Number(this.transition[i].inScheduleQuantity17)
                    ), // 基準日+17-入荷予定数
                    outScheduleQuantity17: commonUtil.formatToCurrency(
                      Number(this.transition[i].outScheduleQuantity17)
                    ), // 基準日+17-出荷予定数
                    scheduleQuantity17: commonUtil.formatToCurrency(
                      Number(this.transition[i].scheduleQuantity17)
                    ), // 基準日+17-在庫数
                    scheduleQuantitycheck17: this.transition[i].scheduleQuantity17, //基準日+17-在庫数(チェック用)
                    inScheduleQuantity18: commonUtil.formatToCurrency(
                      Number(this.transition[i].inScheduleQuantity18)
                    ), // 基準日+18-入荷予定数
                    outScheduleQuantity18: commonUtil.formatToCurrency(
                      Number(this.transition[i].outScheduleQuantity18)
                    ), // 基準日+18-出荷予定数
                    scheduleQuantity18: commonUtil.formatToCurrency(
                      Number(this.transition[i].scheduleQuantity18)
                    ), // 基準日+18-在庫数
                    scheduleQuantitycheck18: this.transition[i].scheduleQuantity18, //基準日+18-在庫数(チェック用)
                    inScheduleQuantity19: commonUtil.formatToCurrency(
                      Number(this.transition[i].inScheduleQuantity19)
                    ), // 基準日+19-入荷予定数
                    outScheduleQuantity19: commonUtil.formatToCurrency(
                      Number(this.transition[i].outScheduleQuantity19)
                    ), // 基準日+19-出荷予定数
                    scheduleQuantity19: commonUtil.formatToCurrency(
                      Number(this.transition[i].scheduleQuantity19)
                    ), // 基準日+19-在庫数
                    scheduleQuantitycheck19: this.transition[i].scheduleQuantity19, //基準日+19-在庫数(チェック用)
                    inScheduleQuantity20: commonUtil.formatToCurrency(
                      Number(this.transition[i].inScheduleQuantity20)
                    ), // 基準日+20-入荷予定数
                    outScheduleQuantity20: commonUtil.formatToCurrency(
                      Number(this.transition[i].outScheduleQuantity20)
                    ), // 基準日+20-出荷予定数
                    scheduleQuantity20: commonUtil.formatToCurrency(
                      Number(this.transition[i].scheduleQuantity20)
                    ), // 基準日+20-在庫数
                    scheduleQuantitycheck20: this.transition[i].scheduleQuantity20, //基準日+20-在庫数(チェック用)
                    inScheduleQuantity21: commonUtil.formatToCurrency(
                      Number(this.transition[i].inScheduleQuantity21)
                    ), // 基準日+21-入荷予定数
                    outScheduleQuantity21: commonUtil.formatToCurrency(
                      Number(this.transition[i].outScheduleQuantity21)
                    ), // 基準日+21-出荷予定数
                    scheduleQuantity21: commonUtil.formatToCurrency(
                      Number(this.transition[i].scheduleQuantity21)
                    ), // 基準日+21-在庫数
                    scheduleQuantitycheck21: this.transition[i].scheduleQuantity21, //基準日+21-在庫数(チェック用)
                    inScheduleQuantity22: commonUtil.formatToCurrency(
                      Number(this.transition[i].inScheduleQuantity22)
                    ), // 基準日+22-入荷予定数
                    outScheduleQuantity22: commonUtil.formatToCurrency(
                      Number(this.transition[i].outScheduleQuantity22)
                    ), // 基準日+22-出荷予定数
                    scheduleQuantity22: commonUtil.formatToCurrency(
                      Number(this.transition[i].scheduleQuantity22)
                    ), // 基準日+22-在庫数
                    scheduleQuantitycheck22: this.transition[i].scheduleQuantity22, //基準日+22-在庫数(チェック用)
                    inScheduleQuantity23: commonUtil.formatToCurrency(
                      Number(this.transition[i].inScheduleQuantity23)
                    ), // 基準日+23-入荷予定数
                    outScheduleQuantity23: commonUtil.formatToCurrency(
                      Number(this.transition[i].outScheduleQuantity23)
                    ), // 基準日+23-出荷予定数
                    scheduleQuantity23: commonUtil.formatToCurrency(
                      Number(this.transition[i].scheduleQuantity23)
                    ), // 基準日+23-在庫数
                    scheduleQuantitycheck23: this.transition[i].scheduleQuantity23, //基準日+23-在庫数(チェック用)
                    inScheduleQuantity24: commonUtil.formatToCurrency(
                      Number(this.transition[i].inScheduleQuantity24)
                    ), // 基準日+24-入荷予定数
                    outScheduleQuantity24: commonUtil.formatToCurrency(
                      Number(this.transition[i].outScheduleQuantity24)
                    ), // 基準日+24-出荷予定数
                    scheduleQuantity24: commonUtil.formatToCurrency(
                      Number(this.transition[i].scheduleQuantity24)
                    ), // 基準日+24-在庫数
                    scheduleQuantitycheck24: this.transition[i].scheduleQuantity24, //基準日+24-在庫数(チェック用)
                    inScheduleQuantity25: commonUtil.formatToCurrency(
                      Number(this.transition[i].inScheduleQuantity25)
                    ), // 基準日+25-入荷予定数
                    outScheduleQuantity25: commonUtil.formatToCurrency(
                      Number(this.transition[i].outScheduleQuantity25)
                    ), // 基準日+25-出荷予定数
                    scheduleQuantity25: commonUtil.formatToCurrency(
                      Number(this.transition[i].scheduleQuantity25)
                    ), // 基準日+25-在庫数
                    scheduleQuantitycheck25: this.transition[i].scheduleQuantity25, //基準日+25-在庫数(チェック用)
                    inScheduleQuantity26: commonUtil.formatToCurrency(
                      Number(this.transition[i].inScheduleQuantity26)
                    ), // 基準日+26-入荷予定数
                    outScheduleQuantity26: commonUtil.formatToCurrency(
                      Number(this.transition[i].outScheduleQuantity26)
                    ), // 基準日+26-出荷予定数
                    scheduleQuantity26: commonUtil.formatToCurrency(
                      Number(this.transition[i].scheduleQuantity26)
                    ), // 基準日+26-在庫数
                    scheduleQuantitycheck26: this.transition[i].scheduleQuantity26, //基準日+26-在庫数(チェック用)
                    inScheduleQuantity27: commonUtil.formatToCurrency(
                      Number(this.transition[i].inScheduleQuantity27)
                    ), // 基準日+27-入荷予定数
                    outScheduleQuantity27: commonUtil.formatToCurrency(
                      Number(this.transition[i].outScheduleQuantity27)
                    ), // 基準日+27-出荷予定数
                    scheduleQuantity27: commonUtil.formatToCurrency(
                      Number(this.transition[i].scheduleQuantity27)
                    ), // 基準日+27-在庫数
                    scheduleQuantitycheck27: this.transition[i].scheduleQuantity27, //基準日+27-在庫数(チェック用)
                    inScheduleQuantity28: commonUtil.formatToCurrency(
                      Number(this.transition[i].inScheduleQuantity28)
                    ), // 基準日+28-入荷予定数
                    outScheduleQuantity28: commonUtil.formatToCurrency(
                      Number(this.transition[i].outScheduleQuantity28)
                    ), // 基準日+28-出荷予定数
                    scheduleQuantity28: commonUtil.formatToCurrency(
                      Number(this.transition[i].scheduleQuantity28)
                    ), // 基準日+28-在庫数
                    scheduleQuantitycheck28: this.transition[i].scheduleQuantity28, //基準日+28-在庫数(チェック用)
                    inScheduleQuantity29: commonUtil.formatToCurrency(
                      Number(this.transition[i].inScheduleQuantity29)
                    ), // 基準日+29-入荷予定数
                    outScheduleQuantity29: commonUtil.formatToCurrency(
                      Number(this.transition[i].outScheduleQuantity29)
                    ), // 基準日+29-出荷予定数
                    scheduleQuantity29: commonUtil.formatToCurrency(
                      Number(this.transition[i].scheduleQuantity29)
                    ), // 基準日+29-在庫数
                    scheduleQuantitycheck29: this.transition[i].scheduleQuantity29, //基準日+29-在庫数(チェック用)
                    inScheduleQuantity30: commonUtil.formatToCurrency(
                      Number(this.transition[i].inScheduleQuantity30)
                    ), // 基準日+30-入荷予定数
                    outScheduleQuantity30: commonUtil.formatToCurrency(
                      Number(this.transition[i].outScheduleQuantity30)
                    ), // 基準日+30-出荷予定数
                    scheduleQuantity30: commonUtil.formatToCurrency(
                      Number(this.transition[i].scheduleQuantity30)
                    ), // 基準日+30-在庫数
                    scheduleQuantitycheck30: this.transition[i].scheduleQuantity30, //基準日+30-在庫数(チェック用)
                  });
                }
                this.inputList = list;
                // 検索後はデータ出力ボタン活性
                this.outputBtnActive = false;
                resolve(response);
              } else {
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = "結果";
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                reject(resolve);
              }
            })
            .catch((resolve) => {
              console.error("apigetStock() Resolve", resolve);
              this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject(resolve);
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },

    /**
     * 検索結果の日付(可変)をセット
     */
    tableDate(dt) {
      this.headerItems.push(
        // 基準日-1
        {
          text: i18n.tc(dateTimeHelper.convertJST(dt.setDate(dt.getDate() - 1)).substr(5, 5)),
          value: "remainingNotInScheduleQuantity",
          // value: "recieveQty",
          width: "165px",
          align: "right",
          sortable: false,
        }
      );
      //基準日～基準日+30日分
      for (let i = 0; i < Number(this.displayPeriodSelected); i++) {
        this.headerItems.push({
          text: i18n.tc(dateTimeHelper.convertJST(dt.setDate(dt.getDate() + 1)).substr(5, 5)),
          value: "inScheduleQuantity" + ("00" + i).slice(-2),
          // value: "waitEnterQty",
          width: "175px",
          align: "right",
          sortable: false,
        });
      }
    },

    // 在庫情報出力画面：データ出力ボタン押下時
    outPutClick() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createRequestBodyConfig();

      config.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SRG_004;

      // 取引先SID
      config.reqIdv.clientSid = this.suppliersSelected;

      // 商品標準SID
      if (this.productCodeSelected) {
        config.reqIdv.itemStandardSid = this.productCodeSelected;
      }

      //表示期間
      if (this.displayPeriodSelected) {
        config.reqIdv.displayPeriod = this.displayPeriodSelected;
      }

      // 基準日
      if (this.referenceDateCal) {
        let dateRange = this.referenceDateCal + " 00:00:00.000";
        config.reqIdv.referenceDate = dateTimeHelper.convertUTC(dateRange);
      }

      // 推移区分
      if (this.transactionTypeSelected) {
        config.reqIdv.transitionDiv = this.transactionTypeSelected;
      }

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(
            appConfig.API_URL.BIZ_DELIVERYSTOCKTRANSITION_DOWNLOAD,
            config,
            appConfig.APP_CONFIG
          )
          .then((response) => {
            // // console.debug("btnSearch() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              // 返却されたurlを共通関数へ呼び出し
              commonFunction.getLink(jsonData.resIdv.downloadUrl);
              resolve(response);
            } else {
              // エラー時(件数0件またはapiエラー時)
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.isOpen = true;
              this.infoDialog.title = appConfig.DIALOG.title;
              // OK NGボタン
              this.infoDialog.firstPageFlag = true;
              resolve(jsonData.resCom.resComMessage);
            }
          })
          .catch((ex) => {
            console.error("GetParameter getUserBiz Err", ex);
            this.infoDialog.message = messsageUtil.getMessage("A-999-999_001_E");
            this.infoDialog.isOpen = true;
            this.infoDialog.title = appConfig.DIALOG.title;
            // OK NGボタン
            this.infoDialog.firstPageFlag = true;
            reject(messsageUtil.getMessage("A-999-999_001_E"));
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * プルダウンチェンジイベント
     */
    changeProductNm(value) {
      // // 既存品番/品名を選択時
      let val = this.productCodeList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHint = val.text;
        this.search = val.text;
      }
      this.productCodeList = this.productCodeList.filter((v) => v.value == value);
    },

    /**
     * 基準日を－１日します。
     */
    prevDate() {
      let date = new Date(this.referenceDateCal);
      this.referenceDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
    },
    /**
     * 基準日を＋１日します。
     */
    nextDate() {
      let date = new Date(this.referenceDateCal);
      this.referenceDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    // 日付入力
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.referenceDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.referenceDateCal = null;
      }
    },
    /**
     * カレンダーの当日以降のみ選択できるようにするメソッド
     * @param {*} val カレンダーの日付
     */
    // allowedDates(val) {
    //   return dateTimeHelper.isDateAfterTheDay(val);
    // },

    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      //headerItems内削除
      this.headerItems.splice(0, this.headerItems.length);
      this.headerItems.push(
        // 品番/品名
        {
          text: i18n.tc("label.lbl_userProductCdNameAndAccessoryProduct"),
          value: "productCnCd",
          width: "322px",
          class: "product-fixed",
          align: "left",
          sortable: false,
        },
        // 警告
        {
          text: i18n.tc("label.lbl_alert"),
          value: "alert",
          width: "150px",
          class: "alert-fixed",
          align: "center",
          sortable: false,
        },
        // 項目
        {
          text: i18n.tc("label.lbl_items"),
          value: "items",
          width: "150px",
          class: "items-fixed",
          align: "left",
          sortable: false,
        }
      );
      // クリアフラグ
      this.clearFlg = true;
      // 変更検知用の設定
      this.referenceDateCal = "";
      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },
    /**
     * キュー状況ダイアログ表示処理
     */
    openDialogQueInfoStatus() {
      // キュー状況ダイアログデータ
      this.queInfoList = [
        {
          queInfoMessage: i18n.tc("label.lbl_messageStart"),
        },
      ];
      this.dialogQueInfoStatus = true;
      this.queInfoStatus();
    },

    // キューの進捗表示
    async queInfoStatus() {
      // 既存処理、終了
      if (this.timeId) {
        clearInterval(this.timeId);
      }
      this.timeId = setInterval(this.reqQueInfoStatusResult, 5000);
    },

    /**
     * キュー状況API
     */
    reqQueInfoStatusResult() {
      this.resultList = [];
      return new Promise((resolve, reject) => {
        getParameter
          .getMstQueInfo({ requestId: this.requestId })
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              if (jsonData.resIdv.queInfo.completeFlg == "1") {
                clearInterval(this.timeId);
                this.queInfoList.push({
                  queInfoMessage: i18n.tc("label.lbl_messageFinished"),
                });
                this.exclusionEnd();
                this.loadingCounter = 0;
              }
            } else {
              // エラー時
              return Promise.reject(jsonData.resCom.resComMessage);
            }
          })
          .catch((ex) => {
            clearInterval(this.timeId);
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            // OK NGボタン
            this.infoDialog.firstPageFlag = true;
            this.loadingCounter = 0;
            reject(resolve);
          });
      });
    },

    // 排他制御終了
    exclusionEnd() {
      if (this.$refs.form.validate()) {
        const config = this.$httpClient.createRequestBodyConfig();
        config.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SUP_021;
        // P_SUP_021
        // ローディング画面表示ON
        this.loadingCounter = 1;

        //APIに渡す値をconfig.paramsにセット==================================//
        // 取引先
        if (this.suppliersSelected) {
          config.reqIdv.clientSid = this.suppliersSelected;
        }

        // リクエストIDを取得
        config.reqIdv.requestId = this.requestId;
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .securePost(
              appConfig.API_URL.BIZ_DELIVERYSTOCKTRANSITION_EXCLUSION_END,
              config,
              appConfig.APP_CONFIG
            )
            .then(() => {})
            .catch((ex) => {
              console.error("GetParameter getUserBiz Err", ex);
              this.infoDialog.message = messsageUtil.getMessage("A-999-999_001_E");
              this.infoDialog.isOpen = true;
              this.infoDialog.title = appConfig.DIALOG.title;
              // OK NGボタン
              this.infoDialog.firstPageFlag = true;
              reject(messsageUtil.getMessage("A-999-999_001_E"));
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
              // 検索処理を実行
              this.apigetStock("get-search");
            });
        });
      }
    },
    referenceDateSet() {
      //品番/品名リストクリア
      this.productCodeSelected = "";
      this.productCodeList = [];
      const config = this.$httpClient.createGetApiRequestConfig();

      // ローディング画面表示ON
      this.loadingCounter = 1;

      //APIに渡す値をconfig.paramsにセット==================================//
      // 取引先
      if (this.suppliersSelected) {
        config.params.clientSid = this.suppliersSelected;
      }
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_DELIVERYSTOCKTRANSITION_REFERENCEDATE, config)
          .then((response) => {
            // console.debug("apigetStock() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              this.referenceDate = "";
              this.referenceDateCal = "";
              jsonData.resIdv.referenceDate.forEach((row) => {
                this.referenceDate = dateTimeHelper.dateCalc(row.referenceDate);
                this.referenceDateCal = dateTimeHelper.dateCalc(row.referenceDate);
              });
            }
          })
          .catch((resolve) => {
            console.error("apigetStock() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    close() {
      this.dateInputDialog = false;
      this.referenceDateSet();
    },
  },
  computed: {},
  watch: {
    //取引先変更時
    suppliersSelected() {
      this.referenceDateSet();
    },
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    referenceDateCal: {
      handler(val) {
        this.referenceDate = this.formatDate(val);
      },
      deep: true,
    },
    page: function (newValue) {
      //画面クリア時の場合はページ移動後に検索処理を行わない
      if (this.clearFlg) {
        return;
      }
      this.page = newValue;
      this.apigetStock("");
    },
    sortOptions: {
      handler(items) {
        // console.debug("watch sortByApi", items);
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_SRG_004_SORT[sortBy].replaceAll("{0}", ascdesc); // + ascdesc;
          this.apigetStock("");
        }
      },
      deep: true,
    },

    search(val) {
      if (val ?? false) {
        // 取引先が選択されてない場合、処理しない
        if (this.suppliersSelected == null || this.suppliersSelected == "") {
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHint) {
          return;
        }
        // 入力桁数が定義した数異常の場合検索処理
        if (val.length >= appConfig.CNTCHARITEM) {
          const itemsBiz = getParameter.getItemsBizAddName2(
            this.suppliersSelected,
            dateTimeHelper.convertUTC(),
            val
          );

          Promise.all([itemsBiz])
            .then((result) => {
              // 画面の初期値を設定します。
              const list = [];
              this.productCodeList = list.concat(result[0]);
              this.productCodeList.unshift({ name: "", text: "", value: "" });
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.productCodeList.length = 0;
        }
      }
    },
  },

  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

.scrollType::-webkit-scrollbar {
  display: none;
}

.scrollTypeClose {
  overflow-y: scroll;
}

.table-scroll-host {
  grid-area: content;
  overflow: scroll;
}

.text-overflow {
  // v-tooltip
  width: 290px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

//品番/品名(固定 ヘッダ)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .product-fixed {
  position: sticky !important;
  left: 0 !important;
  z-index: 6 !important;
}
//品番/品名(固定 明細)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:first-child {
  position: sticky !important;
  left: 0 !important;
  z-index: 5 !important;
}

//tdに背景色を設定(横固定をするため)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper tbody tr td {
  background-color: #ffffff;
}
//tdに背景色を設定(横固定をするため)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper tbody tr:nth-child(odd) td {
  background-color: #dde5f0;
}
//ステータス(固定 ヘッダ 初期表示)
/*
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .quality-fixed {
  position: sticky !important;
  left: 290px !important;
  z-index: 6 !important;
}

//ステータス(固定 ヘッダ 検索時)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .quality-fixed-search {
  position: sticky !important;
  left: 322px !important;
  z-index: 6 !important;
}

//ステータス(固定 明細)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:nth-child(2) {
  position: sticky !important;
  left: 322px !important;
  z-index: 6 !important;
}
*/
//警告(固定 ヘッダ)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .alert-fixed {
  position: sticky !important;
  left: 290px !important;
  z-index: 6 !important;
}
//警告(固定 ヘッダ 検索)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .alert-fixed {
  position: sticky !important;
  left: 322px !important;
  z-index: 6 !important;
}
//警告(固定 明細)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:nth-child(2) {
  position: sticky !important;
  left: 322px !important;
  z-index: 5 !important;
}

//項目(固定 ヘッダ 初期表示)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .items-fixed {
  position: sticky !important;
  left: 440px !important;
  z-index: 6 !important;
}

//項目(固定 ヘッダ 検索時)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .items-fixed {
  position: sticky !important;
  left: 472px !important;
  z-index: 6 !important;
}

//項目(固定 明細)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:nth-child(3) {
  position: sticky !important;
  left: 472px !important;
  z-index: 5 !important;
}

//tableヘッダ情報下寄せ
#listData.v-data-table--fixed-header ::v-deep th {
  vertical-align: bottom;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper {
  max-height: 562px;
}

::v-deep .negative-inventory {
  color: #dd0000;
}

#QueInfoStatusData {
  &.v-data-table--fixed-header ::v-deep {
    tr:nth-child(odd) {
      background-color: #dde5f0;
      height: 2rem;
    }

    //テーブル項目部分
    // th::after {
    //   content: "*" !important;
    //   color: red;
    // }
    th {
      font-size: large !important;
      color: black;
      background: #effad9;
      height: 2rem !important;

      &.asta::after {
        content: "*" !important;
        color: red;
      }

      &:not(:nth-child(0)) {
        &::before {
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    //テーブルデータ部分
    td {
      font-size: large;
    }
  }
}
</style>
