import { render, staticRenderFns } from "./MstUserProductList.vue?vue&type=template&id=17d98670&scoped=true"
import script from "./MstUserProductList.vue?vue&type=script&lang=js"
export * from "./MstUserProductList.vue?vue&type=script&lang=js"
import style0 from "./MstUserProductList.vue?vue&type=style&index=0&id=17d98670&prod&lang=css"
import style1 from "./MstUserProductList.vue?vue&type=style&index=1&id=17d98670&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17d98670",
  null
  
)

export default component.exports