<template>
  <span>
    <div>
      <!-- 営業所 -->
      <v-autocomplete
        dense
        v-model="officeSelected"
        :value="value"
        :items="officeList"
        :label="$t('label.lbl_salesOffice')"
        :rules="officeSelecterRules"
        :disabled="!enabled"
        :readonly="readOnry"
        persistent-hint
        margin-bottom="0"
        pa-3
        @change="updateValue"
      >
        <template slot="item" slot-scope="data">
          <span class="auto-txt-size"> {{ data.item.text }} </span>
        </template>
      </v-autocomplete>
    </div>
    <!-- 確認メッセージダイアログモーダル-->
    <SimpleDialog
      :isShow.sync="infoDialog.isOpen"
      :title="infoDialog.title"
      :message="infoDialog.message"
      :firstPageFlag="infoDialog.firstPageFlag"
      :cntapprovalFlg="infoDialog.cntapprovalFlg"
    />
  </span>
</template>

<script>
import { i18n } from "../../lang/lang.js";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import SimpleDialog from "@/components/SimpleDialog";
export default {
  components: { SimpleDialog },
  props: {
    value: { type: String, required: true },
    rules: {
      type: Array,
      default: function () {
        return [true];
      },
    },
    isRequired: { type: Boolean, default: false },
    enabled: { type: Boolean, default: true },
    readOnry: { type: Boolean, default: false },
  },
  data: () => ({
    // 確認メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      cntapprovalFlg: false,
    },
    // データ取得中
    isLoading: false,
    // 営業所プルダウン
    officeSelected: "",
    officeList: [],

    // 入力制御
    defaultRules: {
      inputRequired: (value, isRequired) => !isRequired || !!value || i18n.tc("check.chk_input"),
    },
    officeSelecterRules: [],
  }),
  methods: {
    /**
     * 初期起動：画面を開いた際に渡された値を設定します。
     */
    init() {
      this.officeSelected = this.value;
      // 営業所一覧の取得
      this.getOffceList();
      this.officeSelecterRules = [
        this.defaultRules.inputRequired(this.officeSelected, this.isRequired),
      ].concat(this.rules);
    },

    /**
     * 営業所一覧の取得
     */
    getOffceList() {
      this.isLoading = true;
      this.$emit("loading", 1);

      const config = this.$httpClient.createGetApiRequestConfig();

      // ★必須パラメータ
      config.params.isMst = "1";
      config.params.relatedPartyDiv = "02";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_RELATED, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("getCustomInfo() Response", response);
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              let list = [];
              // 未選択許可の場合、空行を追加
              if (!this.isRequired) {
                list.push({ text: "", name: "", value: "" });
              }
              jsonData.resIdv.relatedPartys.forEach((row) => {
                if (row.relatedPartyLanguage.length > 0) {
                  list.push({
                    text: row.relatedPartyLanguage[0].relatedPartyName ?? "",
                    value: row.relatedPartyLanguage[0].relatedPartySid ?? "",
                    name: row.relatedPartyLanguage[0].relatedPartyName ?? "",
                  });
                }
              });
              this.officeList = list;
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
          })
          .finally(() => {
            this.isLoading = false;
            this.$emit("loading", 0);
          });
      });
    },

    /**
     * 親へデータの受け渡す
     * @param {*} e
     */
    updateValue(e) {
      this.$emit("input", e);
    },
  },
  computed: {},
  watch: {
    value(val) {
      this.officeSelected = val;
    },
    officeSelected(val) {
      // 親へデータの受け渡す
      this.$emit("input", val);
    },
  },
  created() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.validation {
  color: red;
  text-align: center;
  margin-bottom: 3%;
}
</style>
