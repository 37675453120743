<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-container fluid id="sub-bar">
      <v-col>
        <div id="lbl-screen-name">{{ $t("label.lbl_deliveryOrderSorting") }}</div>
      </v-col>
    </v-container>
    <v-form ref="form1" lazy-validation>
      <v-container fluid>
        <v-row class="search-row">
          <!-- ユーザー取引先プルダウン-->
          <div class="serch-textbox-useritemCd first-search-item">
            <v-text-field
              dense
              maxlength="100"
              v-model="userSuppliersSelected"
              :label="$t('label.lbl_userSupplier')"
              class="txt-single"
              clear-icon="mdi-close-circle"
              persistent-hint
              pa-3
              readonly
            />
          </div>

          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>
          <!-- 基準日-->
          <div class="date-style">
            <v-text-field
              dense
              maxlength="10"
              v-model="deliveryDate"
              :label="$t('label.lbl_refernceDate')"
              class="txt-single"
              clear-icon="mdi-close-circle"
              persistent-hint
              pa-3
              readonly
            />
          </div>
          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>
          <!-- 仕分マスタ登録-->
          <div class="date-style">
            <v-text-field
              dense
              maxlength="3"
              v-model="sortingMst"
              :label="$t('label.lbl_sortingMstInsert')"
              class="txt-single"
              clear-icon="mdi-close-circle"
              persistent-hint
              pa-3
              readonly
            />
          </div>
        </v-row>
        <v-row class="search-row-sorting">
          <!-- メーカー取引先 -->
          <div class="serch-textbox-useritemCd first-search-item">
            <v-text-field
              dense
              maxlength="100"
              v-model="clientSelected"
              :label="$t('label.lbl_makerName') + $t('label.lbl_supplier')"
              class="txt-single"
              clear-icon="mdi-close-circle"
              persistent-hint
              pa-3
              readonly
            />
          </div>
          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>
          <!-- メーカー工場-->
          <div class="date-style">
            <v-text-field
              dense
              maxlength="7"
              v-model="makerFactory"
              :label="$t('label.lbl_makerFactory')"
              class="txt-single"
              clear-icon="mdi-close-circle"
              persistent-hint
              pa-3
              readonly
            />
          </div>
          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>
          <!-- 搬入先工場 -->
          <div class="date-style">
            <v-text-field
              dense
              maxlength="7"
              v-model="userDeliveryDestination"
              :label="$t('label.lbl_destinationyPlant')"
              class="txt-single"
              clear-icon="mdi-close-circle"
              persistent-hint
              pa-3
              readonly
            />
          </div>
          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>
          <!-- 搬入場所-->
          <div class="search-autocomplete">
            <v-text-field
              dense
              maxlength="10"
              v-model="headerDeliveryDestinationy"
              :label="$t('label.lbl_deliveryDestinationy')"
              class="txt-single"
              clear-icon="mdi-close-circle"
              persistent-hint
              pa-3
              readonly
            />
          </div>
          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>
          <!-- 計画コード-->
          <div class="search-autocomplete">
            <v-text-field
              dense
              maxlength="10"
              v-model="headerDeliveryPlan"
              :label="$t('label.lbl_deliveryPlanCd')"
              class="txt-single"
              clear-icon="mdi-close-circle"
              persistent-hint
              pa-3
              readonly
            />
          </div>
        </v-row>
        <v-row class="search-row-sorting">
          <!-- 在庫営業所 -->
          <div class="search-autocomplete first-search-item">
            <v-text-field
              dense
              v-model="officeSelected"
              :label="$t('menu.txt_stock') + $t('label.lbl_salesOffice')"
              class="txt-single"
              clear-icon="mdi-close-circle"
              persistent-hint
              pa-3
              readonly
            />
          </div>
          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>
          <!-- 保証営業所-->
          <div class="search-autocomplete">
            <v-text-field
              dense
              v-model="guaranteeOfficeSelected"
              :label="$t('label.lbl_guarantee') + $t('label.lbl_salesOffice')"
              class="txt-single"
              clear-icon="mdi-close-circle"
              persistent-hint
              pa-3
              readonly
            />
          </div>
        </v-row>
        <v-row>
          <div class="search-row-exeBtn">
            <!--ボタン領域-->
            <div class="btn-area" style="float: left">
              <!-- 閉じるボタン -->
              <v-btn class="post-btn" @click="close()">{{ $t("btn.btn_close") }}</v-btn>
            </div>
            <div class="btn-area">
              <!-- 登録/修正ボタン -->
              <v-btn class="post-btn" @click="insert()">{{ insertBtn }}</v-btn>
            </div>
          </div>
        </v-row>
        <v-row>
          <div class="display-search-result-row">
            <!--検索結果表示-->
            <div class="text-label" style="float: left">
              <span class="toatlNum">総件数：{{ totalCount }}件</span>
            </div>

            <!--表示件数切替部分-->
            <div style="float: right">
              <!--表示件数-->
              <div class="text-label" style="clear: both; float: left">
                <div>{{ $t("label.lbl_itemsPerPage") }}</div>
              </div>

              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>

              <!--表示件数切替ボタン-->
              <div style="float: left">
                <v-btn-toggle
                  v-model="itemsPerPageBtn"
                  color="blue darken-2"
                  class="toggle black--text"
                  right
                >
                  <v-btn class="pasing-btn" value="10" @click="u_100_on()" small>
                    {{ 10 }}
                  </v-btn>
                  <v-btn class="pasing-btn" value="50" @click="u_100_clear(50)" small>
                    {{ 50 }}
                  </v-btn>
                  <v-btn class="pasing-btn" value="100" @click="u_100_clear(100)" small>
                    {{ 100 }}
                  </v-btn>
                </v-btn-toggle>
              </div>
            </div>
          </div>
          <!-- ページング数 -->
        </v-row>
      </v-container>
    </v-form>
    <!-- データテーブル -->
    <v-form ref="editedList" lazy-validation>
      <v-container fluid>
        <v-data-table
          id="listData"
          :headers="headerItems"
          :items="inputModalList"
          :fixed-header="true"
          disable-sort
          :hide-default-footer="true"
          :page.sync="page"
          :server-items-length="itemsPerPage"
          disable-filtering
          height="440px"
        >
          <!-- 検索結果ヘッダ情報 -->
          <template v-slot:[`header.choise`]="{ header }">
            <v-row>
              <v-col style="text-align: center">
                <div>&nbsp;</div>
                <div>{{ header.text }}</div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`header.baseProductCd`]="{ header }">
            <v-row>
              <v-col style="text-align: center">
                <div style="text-align: left">納入指示情報</div>
                <div>{{ header.text }}</div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`header.accessoryProduct`]="{ header }">
            <v-row>
              <v-col style="text-align: center">
                <div>&nbsp;</div>
                <div>{{ header.text }}</div>
              </v-col>
            </v-row>
          </template>
          <!-- 要否 -->
          <template v-slot:[`header.necessity`]="{ header }">
            <v-row>
              <v-col style="text-align: center">
                <div style="text-align: left">WMS管理項目</div>
                <div>{{ header.text }}<span class="require asterisk-spacer">*</span></div>
              </v-col>
            </v-row>
          </template>
          <!-- 在庫営業所 -->
          <template v-slot:[`header.stockOffice`]="{ header }">
            <v-row>
              <v-col style="text-align: center">
                <div>&nbsp;</div>
                <div>{{ header.text }}<span class="require asterisk-spacer">*</span></div>
              </v-col>
            </v-row>
          </template>
          <!-- 保証営業所 -->
          <template v-slot:[`header.guaranteeOffice`]="{ header }">
            <v-row>
              <v-col style="text-align: center">
                <div>&nbsp;</div>
                <div>{{ header.text }}<span class="require asterisk-spacer">*</span></div>
              </v-col>
            </v-row>
          </template>
          <!-- メーカー取引先 -->
          <template v-slot:[`header.makerSupplierCd2`]="{ header }">
            <v-row>
              <v-col style="text-align: center">
                <div>&nbsp;</div>
                <div>{{ header.text }}</div>
              </v-col>
            </v-row>
          </template>
          <!-- ユーザー納入先 -->
          <template v-slot:[`header.userDeliveryDestination`]="{ header }">
            <v-row>
              <v-col style="text-align: center">
                <div>&nbsp;</div>
                <div>{{ header.text }}</div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`header.applicationStartDate`]="{ header }">
            <v-row>
              <v-col style="text-align: center">
                <div>&nbsp;</div>
                <div>{{ header.text }}<span class="require asterisk-spacer">*</span></div>
              </v-col>
            </v-row>
          </template>
          <!-- ラジオボックス -->
          <template v-slot:[`item.choise`]="{ item }">
            <input type="checkbox" v-model="item.choise" style="transform: scale(2)" />
          </template>
          <template v-slot:[`item.baseProductCd`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="text-overflow"
                  style="max-width: 225px; text-align: left"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ item.baseProductCd }}
                </div>
                <div class="text-overflow" style="max-width: 225px; text-align: left">
                  {{ item.productCodeName }}
                </div>
              </template>
              <div>{{ item.baseProductCd }}</div>
              <div>{{ item.productCodeName }}</div>
            </v-tooltip>
          </template>
          <!-- 要否 -->
          <template v-slot:[`item.necessity`]="{ item }">
            <v-autocomplete
              dense
              v-model="item.necessity"
              :items="necessityList"
              persistent-hint
              margin-bottom="0"
              pa-3
              @change="(event) => changeNecessityCd(event, item)"
              :rules="[rules.necessityCheck(item.necessity, item.choise)]"
              :hint="setSrhNecessity(item.necessity)"
            >
              <template slot="item" slot-scope="data">
                <span class="auto-txt-size"> {{ data.item.text }} </span>
              </template>
            </v-autocomplete>
          </template>
          <!-- 在庫営業所 -->
          <template v-slot:[`item.stockOffice`]="{ item, index }">
            <v-autocomplete
              dense
              v-model="item.stockOffice"
              :items="officeList"
              persistent-hint
              :hint="setOffice(item.stockOffice)"
              @change="changeOfficeNm(index)"
              :rules="[rules.inputRequiredStock(item.stockOffice, item.necessity, item.choise)]"
              margin-bottom="0"
              pa-3
              :disabled="item.necessity == '02'"
            >
              <template slot="item" slot-scope="data">
                <span class="auto-txt-size"> {{ data.item.text }} </span>
              </template>
            </v-autocomplete>
          </template>
          <!-- 保証営業所 -->
          <template v-slot:[`item.guaranteeOffice`]="{ item }">
            <v-autocomplete
              dense
              v-model="item.guaranteeOffice"
              :items="officeList"
              persistent-hint
              :hint="setOffice(item.guaranteeOffice)"
              :rules="[
                rules.inputRequiredGuarantee(item.guaranteeOffice, item.necessity, item.choise),
              ]"
              margin-bottom="0"
              pa-3
              :disabled="item.necessity == '03'"
            >
              <template slot="item" slot-scope="data">
                <span class="auto-txt-size"> {{ data.item.text }} </span>
              </template>
            </v-autocomplete>
          </template>
          <!-- メーカー取引先 -->
          <template v-slot:[`item.makerSupplierCd2`]="{ item, index }">
            <v-autocomplete
              dense
              maxlength="100"
              v-model="item.makerSupplierCd2"
              :items="item.makerSupplierList"
              persistent-hint
              :hint="setMaker(item.makerSupplierCd2, index)"
              :search-input.sync="item.makerSupplierCd2search"
              :rules="[
                rules.inputRequiredStock(item.makerSupplierCd2, item.necessity, item.choise),
              ]"
              @change="(event) => changeMakerNm(event, item, index)"
              margin-bottom="0"
              pa-3
              :ref="'makerSupplierCd' + index"
              :disabled="item.necessity == '02'"
            >
              <template slot="item" slot-scope="data">
                <span class="auto-txt-size"> {{ data.item.text }} </span>
              </template>
            </v-autocomplete>
          </template>
          <!-- ユーザー納入先 -->
          <template v-slot:[`item.userDeliveryDestination`]="{ item, index }">
            <v-autocomplete
              dense
              maxlength="100"
              v-model="item.userDeliveryDestination"
              :items="item.userDeliveryDestinationList"
              persistent-hint
              :hint="setDeliveryDestination(item.userDeliveryDestination, index)"
              :search-input.sync="item.userDeliveryDestinationsearch"
              :rules="[
                rules.inputRequiredStock(item.userDeliveryDestination, item.necessity, item.choise),
              ]"
              @change="(event) => changeDeliveryNm(event, item, index)"
              margin-bottom="0"
              pa-3
              :ref="'userDeliveryDestination' + index"
              :disabled="item.necessity == '02'"
            >
              <template slot="item" slot-scope="data">
                <span class="auto-txt-size"> {{ data.item.text }} </span>
              </template>
            </v-autocomplete>
          </template>
          <!-- 適用開始日 -->
          <template v-slot:[`item.applicationStartDate`]="{ item, index }">
            <div class="calender table-date-style">
              <v-text-field
                outlined
                dense
                maxlength="10"
                v-model="item.applicationStartDate"
                class="txt-single table-date-style text-box"
                @change="changeDateFrom(item.applicationStartDate, index)"
                :rules="[
                  rules.yyyymmdd,
                  rules.dateCompareFrom(
                    item.applicationStartDate,
                    item.applicationStartDateOld,
                    item.choise
                  ),
                  rules.dateCompareTo(item.applicationStartDate, item.validToOld, item.choise),
                ]"
                clear-icon="mdi-close-circle"
                clearable
                :ref="'applicationStartDate' + index"
              ></v-text-field>
              <div class="calender-btn-area">
                <v-menu
                  v-model="item.applicationStartMenuDate"
                  :close-on-content-click="false"
                  :nudge-right="-100"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  class="date-calendar"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" text x-small>
                      <v-icon>mdi-calendar</v-icon>
                    </v-btn>
                  </template>
                  <v-date-picker
                    v-model="item.applicationStartDateCal"
                    @input="inputCallenderFrom(item.applicationStartDateCal, index)"
                  />
                </v-menu>
              </div>
            </div>
          </template>
          <!-- コピー -->
          <template v-slot:[`item.copyBtn`]="{ item, index }">
            <v-btn
              v-if="index != 0"
              class="post-btn"
              @click="itemCopy(item, index)"
              style="margin: 0px"
              >{{ $t("btn.btn_aboveCopy") }}</v-btn
            >
          </template>
        </v-data-table>
        <!-- ページネーション -->
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
      </v-container>
    </v-form>

    <ConfirmDialog
      :isShow.sync="confirmDialog.isOpen"
      :message="confirmDialog.message"
      :screenFlag="confirmDialog.screenFlag"
      :okAction="confirmDialog.okAction"
      :redMessage="confirmDialog.redMessage"
      :changeFlag="confirmDialog.changeFlag"
    />

    <v-dialog v-model="updateDialogMessage" :max-width="800">
      <!-- 警告ダイアログ -->
      <v-card>
        <v-card-title class="blue-grey lighten-3" primary-title>
          {{ backTitle }}
        </v-card-title>
        <v-card-text class="pa-4">
          <p>{{ backMessage }}</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="updateOk"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { i18n } from "../../lang/lang.js";
import Loading from "@/components/loading";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import ConfirmDialog from "@/components/ConfirmDialog";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";

export default {
  name: "stkInquiryDetails",
  components: {
    Loading,
    ConfirmDialog,
  },
  props: {
    entity: { type: Object, default: null },
    detailDialogData: {},
    hoge1: {
      default: "",
    },
    isOpenDetailDialog: { type: Boolean, default: false },
  },
  data: () => ({
    choise: null,
    updateDialogMessage: false,
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 取引先
    userSuppliersSelected: "",
    destinationyPlant: "",
    headerDeliveryDestinationy: "",
    headerDeliveryPlan: "",
    deliveryDate: "",
    sortingMst: "",
    officeSelected: "",
    guaranteeOfficeSelected: "",
    userDeliveryDestination: "",
    userMakerList: [],
    necessityList: [],
    officeList: [],
    clientSelected: "",
    makerFactory: "",
    totalCount: 0, // 総件数
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 0,
    itemsPerPage: 100,
    itemsPerPageBtn: "100",
    backTitle: "",
    insertBtn: "",
    backMessage: "", // 戻るボタンダイアログメッセージ
    // ヘッダ
    headerItems: [
      // 選択
      {
        text: i18n.tc("label.lbl_choice"),
        width: "75px",
        value: "choise",
        sortable: false,
        align: "center",
      },
      // 基本品番/品名
      {
        text: i18n.tc("label.lbl_baseProductCd") + "/" + i18n.tc("label.lbl_productCodeName"),
        width: "250px",
        value: "baseProductCd",
        sortable: false,
        align: "center",
      },
      // 付帯品番
      {
        text: i18n.tc("label.lbl_accessoryProduct"),
        width: "150px",
        value: "accessoryProduct",
        sortable: false,
        align: "center",
      },
      //要否
      {
        text: i18n.tc("label.lbl_necessity"),
        width: "150px",
        value: "necessity",
        sortable: false,
        align: "center",
        class: "wmscolor",
      },
      //在庫営業所
      {
        text: i18n.tc("menu.txt_stock") + i18n.tc("label.lbl_salesOffice"),
        width: "150px",
        value: "stockOffice",
        sortable: false,
        align: "center",
        class: "wmscolor",
      },
      //保証営業所
      {
        text: i18n.tc("label.lbl_guarantee") + i18n.tc("label.lbl_salesOffice"),
        width: "150px",
        value: "guaranteeOffice",
        sortable: false,
        align: "center",
        class: "wmscolor",
      },
      // メーカー取引先
      {
        text: i18n.tc("label.lbl_makerName") + i18n.tc("label.lbl_supplier"),
        value: "makerSupplierCd2",
        width: "200px",
        sortable: false,
        align: "center",
        class: "wmscolor",
      },
      // ユーザー納入先
      {
        text: i18n.tc("label.lbl_userDeliveryDestination"),
        value: "userDeliveryDestination",
        width: "200px",
        align: "center",
        sortable: false,
        class: "wmscolor",
      },
      // 適用開始日
      {
        text: i18n.tc("label.lbl_applicationStartDate"),
        value: "applicationStartDate",
        width: "150px",
        align: "center",
        sortable: false,
        class: "wmscolor",
      },
      //コピーボタン
      {
        text: "",
        value: "copyBtn",
        width: "100px",
        align: "center",
        sortable: false,
        class: "wmscolor",
      },
    ],
    // 仮データ
    inputModalList: [],
    inputTestList: [],
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
    },
    // 確認メッセージ
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    selection: [],
    rules: {
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      necessityCheck: (value, value1) => !!value || !value1 || i18n.tc("check.chk_input"),
      inputRequiredStock: (value, value1, value2) => {
        if (value2) {
          if (value == "" || value == null) {
            if (value1 != "" && value1 != null) {
              if (value1 == "01" || value1 == "03") {
                return i18n.tc("check.chk_input");
              }
            }
          }
        }
        return true;
      },
      inputRequiredGuarantee: (value, value1, value2) => {
        if (value2) {
          if (value == "" || value == null) {
            if (value1 != "" && value1 != null) {
              if (value1 == "01" || value1 == "02") {
                return i18n.tc("check.chk_input");
              }
            }
          }
        }
        return true;
      },
      //日付比較チェック(From)
      dateCompareFrom: (value, value1, value2) => {
        if (value2) {
          if (value && value1) {
            if (value <= value1) {
              return i18n.tc("check.chk_inputFutureDate2", 0, [value1]);
            }
          }
        }
        return true;
      },
      //日付比較チェック(To)
      dateCompareTo: (value, value1, value2) => {
        if (value2) {
          if (value && value1) {
            if (value >= value1) {
              return i18n.tc("check.chk_inputPastDate2", 0, [value1]);
            }
          }
        }
        return true;
      },
    },
  }),
  methods: {
    u_100_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_100_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
      this.page = 1;
      this.search("get-search");
    },
    u_100_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.search("get-search");
    },
    /**
     * 初期起動：画面を開いた際に渡された値を設定します。
     */
    init() {
      this.inputTestList = [];
      this.inputModalList = [];
      this.inputTestList.push(Object.assign({}, this.entity.inputList));

      this.pageCount = 0;
      this.itemsPerPage = 100;
      this.totalCount = 0;
      this.insertBtn = this.entity.insertBtn;
      this.userSuppliersSelected = this.entity.userSuppliersSelected;
      //営業所プルダウンlistセット
      this.officeList = this.entity.officeList;
      //搬入日
      this.deliveryDate = this.entity.deliveryDate;
      //計画コード
      this.headerDeliveryPlan = this.inputTestList[0].deliveryPlanCd;
      //搬入場所
      this.headerDeliveryDestinationy = this.inputTestList[0].deliveryDestinationy;
      //搬入先工場
      this.userDeliveryDestination = this.inputTestList[0].destinationyPlant;
      //メーカー取引先
      this.clientSelected =
        this.inputTestList[0].makerSupplierCd + "（" + this.inputTestList[0].makerSupplierNm + "）";
      //在庫営業所
      this.officeSelected = this.setOffice(this.inputTestList[0].stockOffice);
      //保証営業所
      this.guaranteeOfficeSelected = this.setOffice(this.inputTestList[0].guaranteeOffice);
      this.sortingMst = this.entity.sortingMst;
      this.makerFactory = this.inputTestList[0].userPlant;
      this.destinationyPlant = "";
      if (this.$refs.editedList != undefined) {
        // バリデーションチェックをリセット
        this.$refs.editedList.resetValidation();
      }
      //リスト取得
      this.getMstCode();
      if (this.entity.deliverySort2.length != 0) {
        this.inputModalList = this.entity.deliverySort2;
      } else {
        //品番単位データ取得
        this.search("get-search");
      }
    },
    /**
     * データ取得
     */
    search(val) {
      //検索結果のバリデーションリセット
      this.$refs.editedList.resetValidation();
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const config = this.$httpClient.createGetApiRequestConfig();
      //ユーザー取引先(納入先CD)
      config.params.deliveryCd = this.entity.userSuppliersCd;
      //基準日
      config.params.processDate = dateTimeHelper.convertUTC(this.entity.deliveryDate);
      //取引先
      config.params.clientCd = this.inputTestList[0].makerSupplierCd;
      //メーカー工場
      config.params.makerPlantCd = this.inputTestList[0].userPlant ?? "";
      //搬入先工場
      config.params.carryCompCd = this.inputTestList[0].destinationyPlant;
      //搬入場所
      config.params.deliveryPlaceCd = this.inputTestList[0].deliveryDestinationy;
      //計画コード
      config.params.planCd = this.inputTestList[0].deliveryPlanCd;
      //登録区分
      config.params.registDiv = this.entity.sortingMstSerachKbn;
      // ページャー処理
      if (val == "get-search") {
        config.params.reqComPageIndex = "1";
        this.page = 1;
      } else {
        // ページャー処理
        if (this.page >= 2 && this.inputList.length == 0) {
          config.params.reqComPageIndex = "1";
          this.page = 1;
        } else {
          config.params.reqComPageIndex = this.page;
        }
      }
      // ページング
      config.params.reqComPaginationFlg = "1";
      config.params.reqComPageLimit = this.itemsPerPage;
      //APIに渡す値をconfig.paramsにセット==================================//
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_GET_DELIVERY_SORTING2, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              this.inputModalList = [];
              this.pageCount = jsonData.resCom.resComPagination.totalPage;
              this.totalCount = jsonData.resCom.resComPagination.totalRecord;
              const list = [];
              this.selection = jsonData.resIdv.deliverySorting2;
              for (let i = 0; i < this.selection.length; i++) {
                if (this.entity.sortingMstSerachKbn == "02") {
                  if (this.selection[i].deliverySort2Sid != "") {
                    list.push({
                      userItemBaseCd: this.selection[i].userItemBaseCd,
                      baseProductCd: (this.selection[i].userItemBaseCd ?? "")
                        .substring(0, 50)
                        .trimRight(),
                      productCodeName: this.selection[i].userItemName,
                      accessoryProduct: (this.selection[i].userItemBaseCd ?? "")
                        .slice(-50)
                        .trimRight(),
                      makerSupplierList:
                        this.selection[i].processDiv == "02"
                          ? []
                          : [
                              {
                                text:
                                  `${this.selection[i].makerClientCode}` +
                                  `（${this.selection[i].makerClientName}）`,
                                value: this.selection[i].makerClientSid,
                                name: this.selection[i].makerClientName,
                              },
                            ],
                      makerSupplierCd2: this.selection[i].makerClientSid,
                      makerSupplierCd2Hint:
                        this.selection[i].processDiv == "02"
                          ? ""
                          : `${this.selection[i].makerClientCode}` +
                            `（${this.selection[i].makerClientName}）`,
                      makerSupplierCd2search:
                        this.selection[i].processDiv == "02"
                          ? ""
                          : `${this.selection[i].makerClientCode}` +
                            `（${this.selection[i].makerClientName}）`,
                      userDeliveryDestinationList:
                        this.selection[i].processDiv == "02"
                          ? []
                          : [
                              {
                                text:
                                  `${this.selection[i].userOfficeCd}` +
                                  `（${this.selection[i].userOfficeName}）`,
                                value: this.selection[i].userClientSid,
                                name: this.selection[i].userOfficeName,
                              },
                            ],
                      userDeliveryDestination: this.selection[i].userClientSid,
                      userDeliveryDestinationHint:
                        this.selection[i].processDiv == "02"
                          ? ""
                          : `${this.selection[i].userOfficeCd}` +
                            `（${this.selection[i].userOfficeName}）`,
                      userDeliveryDestinationsearch:
                        this.selection[i].processDiv == "02"
                          ? ""
                          : `${this.selection[i].userOfficeCd}` +
                            `（${this.selection[i].userOfficeName}）`,
                      applicationStartDate: dateTimeHelper
                        .convertUTC2JST(this.selection[i].validFrom ?? this.entity.deliveryDate)
                        .substring(0, 10),
                      applicationStartDateCal: dateTimeHelper.dateCalc(
                        dateTimeHelper.convertUTC2JST(
                          this.selection[i].validFrom ?? this.entity.deliveryDate
                        )
                      ),
                      applicationStartDateOld:
                        this.selection[i].validFrom == null
                          ? this.selection[i].validFrom
                          : commonFunction
                              .addDate(
                                dateTimeHelper.convertUTC2JST(this.selection[i].validFrom),
                                ""
                              )
                              .toString()
                              .replaceAll("-", "/")
                              .substr(0, 10),
                      validTo: this.selection[i].validTo,
                      validToOld:
                        this.selection[i].validTo == null
                          ? this.selection[i].validTo
                          : commonFunction
                              .addDate(this.selection[i].validTo, "add")
                              .toString()
                              .replaceAll("-", "/")
                              .substr(0, 10),
                      stockOffice: this.selection[i].officeSid ?? "",
                      stockOfficeOld: this.selection[i].officeSid ?? "",
                      guaranteeOffice: this.selection[i].outWarrantyStartOfficeSid ?? "",
                      guaranteeOfficeOld: this.selection[i].outWarrantyStartOfficeSid ?? "",
                      deliverySort1Sid: this.selection[i].deliverySort1Sid ?? "",
                      necessity: this.selection[i].processDiv ?? "",
                      choise: false,
                      deliverySort2Sid: this.selection[i].deliverySort2Sid,
                      updateDatetime: this.selection[i].updateDatetime,
                    });
                  } else {
                    list.push({
                      userItemBaseCd: this.selection[i].userItemBaseCd,
                      baseProductCd: (this.selection[i].userItemBaseCd ?? "")
                        .substring(0, 50)
                        .trimRight(),
                      productCodeName: this.selection[i].userItemName,
                      accessoryProduct: (this.selection[i].userItemBaseCd ?? "")
                        .slice(-50)
                        .trimRight(),
                      makerSupplierCd2: "",
                      userDeliveryDestination: "",
                      applicationStartDate: this.entity.deliveryDate,
                      applicationStartDateCal: dateTimeHelper.dateCalc(
                        dateTimeHelper.convertUTC2JST(this.entity.deliveryDate)
                      ),
                      validTo: this.selection[i].validTo,
                      necessity: "01",
                      makerSupplierList: [],
                      makerSupplierCd2search: "",
                      makerSupplierCd2Hint: "",
                      userDeliveryDestinationList: [],
                      userDeliveryDestinationsearch: "",
                      userDeliveryDestinationHint: "",
                      productConfig: "無",
                      choise: false,
                      deliverySort2Sid: this.selection[i].deliverySort2Sid,
                      updateDatetime: this.selection[i].updateDatetime,
                      stockOffice: this.selection[i].officeSid ?? "",
                      stockOfficeOld: this.selection[i].officeSid ?? "",
                      guaranteeOffice: this.selection[i].outWarrantyStartOfficeSid ?? "",
                      guaranteeOfficeOld: this.selection[i].outWarrantyStartOfficeSid ?? "",
                    });
                  }
                } else {
                  list.push({
                    userItemBaseCd: this.selection[i].userItemBaseCd,
                    baseProductCd: (this.selection[i].userItemBaseCd ?? "")
                      .substring(0, 50)
                      .trimRight(),
                    productCodeName: this.selection[i].userItemName,
                    accessoryProduct: (this.selection[i].userItemBaseCd ?? "")
                      .slice(-50)
                      .trimRight(),
                    makerSupplierCd2: "",
                    userDeliveryDestination: "",
                    applicationStartDate: this.entity.deliveryDate,
                    applicationStartDateCal: dateTimeHelper.dateCalc(
                      dateTimeHelper.convertUTC2JST(this.entity.deliveryDate)
                    ),
                    validTo: this.selection[i].validTo,
                    necessity: "01",
                    makerSupplierList: [],
                    makerSupplierCd2search: "",
                    makerSupplierCd2Hint: "",
                    userDeliveryDestinationList: [],
                    userDeliveryDestinationsearch: "",
                    userDeliveryDestinationHint: "",
                    productConfig: "無",
                    choise: false,
                    deliverySort2Sid: this.selection[i].deliverySort2Sid,
                    updateDatetime: this.selection[i].updateDatetime,
                    stockOffice: this.selection[i].officeSid ?? "",
                    stockOfficeOld: this.selection[i].officeSid ?? "",
                    guaranteeOffice: this.selection[i].outWarrantyStartOfficeSid ?? "",
                    guaranteeOfficeOld: this.selection[i].outWarrantyStartOfficeSid ?? "",
                  });
                }
              }
              this.inputModalList = list;
              resolve(response);
            } else {
              this.updateDialogMessage = true;
              this.backMessage = jsonData.resCom.resComMessage;
              this.backTitle = "結果";
              reject(resolve);
            }
          })
          .catch((resolve) => {
            this.updateDialogMessage = true;
            this.backMessage = messsageUtil.getMessage("P-999-999_999_E");
            this.backTitle = appConfig.DIALOG.title;
            reject(resolve);
          })
          .finally(() => {
            //ローディング画面表示OFF;
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * プルダウンチェンジイベント(検索結果-在庫営業所)
     */
    changeOfficeNm(index) {
      this.inputModalList[index].makerSupplierCd2 = "";
      this.inputModalList[index].makerSupplierList = [];
      this.inputModalList[index].makerSupplierCd2Hint = "";
      this.inputModalList[index].makerSupplierCd2search = "";
    },
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // ユーザー取引先
      // 要否
      const necessityList = getParameter.getCodeMst(appConfig.CODETYPE.NECESSITY_KBN);
      return Promise.all([necessityList])
        .then((result) => {
          this.necessityList = result[0];
        })
        .catch(() => {
          this.updateDialogMessage = true;
          this.backMessage = appConfig.DIALOG.title;
          this.backTitle = "結果";
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    //ダイアログを閉じる
    close() {
      this.$emit("closePopup");
      this.$emit("update:isOpenDetailDialog", false);
      this.inputModalList = [];
    },
    /**
     * 登録又は修正ボタン押下時
     */
    insert() {
      //バリデーションチェック
      if (this.$refs.editedList.validate()) {
        var count = 0;
        //営業所が変更されているかチェック
        let countOffice = 0;
        for (var i = 0; i < this.inputModalList.length; i++) {
          if (this.inputModalList[i].choise == true) {
            count++;
            //在庫営業所チェック
            if (this.inputModalList[i].stockOfficeOld) {
              if (this.inputModalList[i].stockOffice != this.inputModalList[i].stockOfficeOld) {
                countOffice++;
              }
            }
            //保証営業所チェック
            if (this.inputModalList[i].guaranteeOfficeOld) {
              if (
                this.inputModalList[i].guaranteeOffice != this.inputModalList[i].guaranteeOfficeOld
              ) {
                countOffice++;
              }
            }
          }
        }
        //選択されているか確認
        if (count <= 0) {
          this.updateDialogMessage = true;
          this.backMessage = messsageUtil.getMessage("P-SUP-002_001_E");
          this.backTitle = i18n.tc("btn.btn_ok");
        } else {
          if (countOffice > 0) {
            this.confirmDialog.message = messsageUtil.getMessage("P-SUP-018_001_E");
            this.confirmDialog.isOpen = true;
            this.confirmDialog.screenFlag = true;
            this.confirmDialog.changeFlag = false;
            this.confirmDialog.okAction = this.datareturn;
          } else {
            this.confirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
            this.confirmDialog.isOpen = true;
            this.confirmDialog.screenFlag = true;
            this.confirmDialog.changeFlag = false;
            this.confirmDialog.okAction = this.datareturn;
          }
        }
      }
    },
    datareturn() {
      this.entity.deliverySort2 = this.inputModalList;
      this.$emit("sortingPopup");
      this.$emit("update:isOpenDetailDialog", false);
      this.inputModalList = [];
    },
    updateOk() {
      this.updateDialogMessage = false;
    },
    /**
     * 営業所リストボックス取得処理
     */
    setOffice(val) {
      for (var i = 0; i < this.officeList.length; i++) {
        if (this.officeList[i].value == val) {
          return this.officeList[i].name;
        }
      }
    },
    /**
     * 要否リストボックス取得処理
     */
    setSrhNecessity(val) {
      for (var i = 0; i < this.necessityList.length; i++) {
        if (this.necessityList[i].value == val) {
          return this.necessityList[i].text;
        }
      }
    },
    /**
     * メーカー取引先リストボックス取得処理
     */
    setMaker(val, index) {
      for (var i = 0; i < this.inputModalList[index].makerSupplierList.length; i++) {
        if (this.inputModalList[index].makerSupplierList[i].value == val) {
          return this.inputModalList[index].makerSupplierList[i].name;
        }
      }
    },
    /**
     * ユーザー納入先リストボックス取得処理
     */
    setDeliveryDestination(val, index) {
      for (var i = 0; i < this.inputModalList[index].userDeliveryDestinationList.length; i++) {
        if (this.inputModalList[index].userDeliveryDestinationList[i].value == val) {
          return this.inputModalList[index].userDeliveryDestinationList[i].name;
        }
      }
    },

    /**
     * プルダウンチェンジイベント(検索結果-要否)
     */
    changeNecessityCd(value, item) {
      if (value == "02") {
        item.stockOffice = "";
        item.makerSupplierCd2 = "";
        item.makerSupplierList = [];
        item.userDeliveryDestination = "";
        item.userDeliveryDestinationList = [];
      } else if (value == "03") {
        item.guaranteeOffice = "";
      }
    },

    /**
     * プルダウンチェンジイベント(検索結果-メーカー取引先)
     */
    changeMakerNm(value, item, index) {
      // 既存メーカー取引先を選択時()
      let val = this.inputModalList[index].makerSupplierList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        item.makerSupplierCd2Hint = val.text;
        item.makerSupplierCd2search = val.text;
      }
      item.makerSupplierList = item.makerSupplierList.filter((v) => v.value == value);

      if (value == "" || value == null) {
        item.makerSupplierCd2Hint = "";
      }
    },

    /**
     * プルダウンチェンジイベント(検索結果-ユーザー納入先)
     */
    changeDeliveryNm(value, item, index) {
      // 既存ユーザー納入先を選択時()
      let val = this.inputModalList[index].userDeliveryDestinationList.find(
        (v) => v.value == value
      );
      if ((val ?? false) && value != "") {
        item.userDeliveryDestinationHint = val.text;
        item.userDeliveryDestinationsearch = val.text;
      }
      item.userDeliveryDestinationList = item.userDeliveryDestinationList.filter(
        (v) => v.value == value
      );

      if (value == "" || value == null) {
        item.userDeliveryDestinationHint = "";
        item.userDeliveryDestination = "";
      }
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    /**
     * 日付入力(適用開始日)
     */
    changeDateFrom(val, index) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }

      if (dateTimeHelper.validDate(strDt, "/")) {
        // 選択した行の有効開始日も更新
        this.inputModalList[index].applicationStartDate = strDt;
        this.inputModalList[index].applicationStartDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      }
    },
    inputCallenderFrom(validStartDateCal, index) {
      this.inputModalList[index].applicationStartDate = this.formatDate(validStartDateCal);
      this.inputModalList[index].applicationStartMenuDate = false;
    },
    /**
     * 上記コピー
     */
    itemCopy(item, index) {
      const value = this.inputModalList[index - 1];
      if (value ?? false) {
        item.stockOffice = value.stockOffice;
        item.guaranteeOffice = value.guaranteeOffice;
        item.makerSupplierCd2 = value.makerSupplierCd2;
        item.makerSupplierList = value.makerSupplierList;
        item.makerSupplierCd2Hint = value.makerSupplierCd2Hint;
        item.makerSupplierCd2search = value.makerSupplierCd2search;
        item.userDeliveryDestination = value.userDeliveryDestination;
        item.userDeliveryDestinationList = value.userDeliveryDestinationList;
        item.userDeliveryDestinationHint = value.userDeliveryDestinationHint;
        item.userDeliveryDestinationsearch = value.userDeliveryDestinationsearch;
        item.applicationStartDate = value.applicationStartDate;
        item.applicationStartDateCal = value.applicationStartDateCal;
        item.processDiv = value.processDiv;
        item.necessity = value.necessity;
        item.productConfig = value.productConfig;
        item.choise = true;
      }
    },
  },
  watch: {
    entity: {
      handler() {},
    },
    page: function (newValue) {
      this.page = newValue;
      this.search("");
    },
    // eslint-disable-next-line no-unused-vars
    isOpenDetailDialog: function (newValue) {
      if (newValue) {
        this.init();
      }
    },
    inputModalList: function (inputL) {
      inputL.forEach((value) => {
        if (value.isWatchAddedModal === void 0) {
          this.$watch(
            () => value.makerSupplierCd2search,
            (val) => {
              if (val ?? false) {
                // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
                if (val == value.makerSupplierCd2Hint) {
                  return;
                }
                if (value.stockOffice == null || value.stockOffice == "") {
                  return;
                }
                // 入力桁数が定義した数以上の場合検索処理
                if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
                  // ローディング画面表示ON
                  this.loadingCounter = 1;
                  const fromList = getParameter.getRelatedBizPartialMatch(
                    appConfig.ISGETCLIENT.CLIENT,
                    dateTimeHelper.convertUTC(this.deliveryDate),
                    undefined,
                    undefined,
                    val,
                    value.stockOffice
                  );
                  Promise.all([fromList])
                    .then((result) => {
                      // 画面の初期値を設定します。
                      value.makerSupplierList = result[0];
                    })
                    .catch((ex) => {
                      this.infoDialog.message = ex;
                      this.infoDialog.title = appConfig.DIALOG.title;
                      this.infoDialog.isOpen = true;
                    })
                    .finally(() => {
                      // ローディング画面表示OFF
                      this.loadingCounter = 0;
                    });
                } else {
                  value.makerSupplierList.length = 0;
                  value.makerSupplierList = [];
                }
              }
            }
          );
          this.$watch(
            () => value.userDeliveryDestinationsearch,
            (val) => {
              if (val ?? false) {
                // 取引先が選択されてない場合、処理しない
                if (this.entity.userSuppliersSid == null || this.entity.userSuppliersSid == "") {
                  return;
                }
                // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
                if (val == value.userDeliveryDestinationHint) {
                  return;
                }
                // 入力桁数が定義した数以上の場合検索処理
                if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
                  // ローディング画面表示ON
                  this.loadingCounter = 1;
                  // 選択した会社コードを取得
                  let officeCd = this.entity.userSupplierList.find(
                    (element) => element.value === this.entity.userSuppliersSid
                  ).clientSid;
                  const fromList = getParameter.getRelatedBizPartialMatch(
                    appConfig.ISGETCLIENT.FROMTO,
                    dateTimeHelper.convertUTC(this.deliveryDate),
                    undefined,
                    officeCd,
                    val
                  );

                  Promise.all([fromList])
                    .then((result) => {
                      // 画面の初期値を設定します。
                      const list = [];
                      value.userDeliveryDestinationList = list.concat(result[0]);
                    })
                    .catch((ex) => {
                      this.infoDialog.message = ex;
                      this.infoDialog.title = appConfig.DIALOG.title;
                      this.infoDialog.isOpen = true;
                    })
                    .finally(() => {
                      // ローディング画面表示OFF
                      this.loadingCounter = 0;
                    });
                } else {
                  value.userDeliveryDestinationList.length = 0;
                  value.userDeliveryDestinationList = [];
                }
              }
            }
          );
        }
        value.isWatchAddedModal = true;
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
#sub-bar {
  padding: none;
  height: 3rem;
}

.text-overflow {
  // v-tooltip
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#listData.v-data-table ::v-deep table {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
#listData.v-data-table ::v-deep th.wmscolor {
  background: #fce4d6;
}

div.row.search-row-sorting {
  margin-top: 15px;
}

.calender {
  display: flex;
}
tr td div.calender-btn-area {
  margin-top: 16px;
}

.v-text-field.text-box {
  margin: 0px;
  padding-top: 11px;
  height: auto;
}
</style>
