import { appConfig } from "./AppConfig";
import { messsageUtil } from "./MesssageUtil";
import { httpClient } from "./HttpClient";

export default class GetParameterProcurement {
  /**
   * 業務利用メーカ工場CD取得1
   */
  getMekerFactoryCd1(userClientCd, utcDate, clientCd) {
    const config = httpClient.createGetApiRequestConfig();
    //ユーザー取引先コード
    config.params.userClientCd = userClientCd;
    //基準日
    config.params.referenceDate = utcDate;
    //取引先コード
    config.params.clientCd = clientCd;

    console.debug("GetParameterProcurement getMekerFactoryCd1 Url", userClientCd, config);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.BIZ_MEKER_FACTORY_CD1, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          console.debug(
            "GetParameterProcurement getMekerFactoryCd1 Response",
            userClientCd,
            jsonData
          );
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            const list = [];
            jsonData.resIdv.sortingBizUse.forEach((row) => {
              list.push({
                text: row.makerPlantCd,
                value: row.makerPlantCd,
              });
            });
            resolve(list);
            // エラー時
          } else {
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameterProcurement getMekerFactoryCd1 Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * 業務利用メーカ工場CD取得2
   */
  getMekerFactoryCd2(userClientCd, userClientSid, utcDate, clientCd, clientSid, userMakerDiv) {
    const config = httpClient.createGetApiRequestConfig();
    //ユーザー取引先コード
    config.params.userClientCd = userClientCd;
    //ユーザー取引先SID
    config.params.userClientSid = userClientSid;
    //基準日
    config.params.referenceDate = utcDate;
    if (userMakerDiv == "01") {
      //取引先コード
      config.params.clientCd = clientCd;
    } else {
      //取引先SID
      config.params.clientSid = clientSid;
    }
    //ユーザーメーカー区分
    config.params.userMakerDiv = userMakerDiv;

    console.debug("GetParameterProcurement getMekerFactoryCd2 Url", userClientCd, config);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.BIZ_MEKER_FACTORY_CD2, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          console.debug(
            "GetParameterProcurement getMekerFactoryCd2 Response",
            userClientCd,
            jsonData
          );
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            const list = [];
            jsonData.resIdv.sortingBizUse.forEach((row) => {
              list.push({
                text: row.makerPlantCd,
                value: row.makerPlantCd,
              });
            });
            resolve(list);
            // エラー時
          } else {
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameterProcurement getMekerFactoryCd2 Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * 業務利用搬入場所取得1API
   */
  getDeliveryPlanceCd1(userClientCd, utcDate, clientCd, deliveryPlanceCd) {
    const config = httpClient.createGetApiRequestConfig();
    //ユーザー取引先コード
    config.params.userClientCd = userClientCd;
    //基準日
    config.params.referenceDate = utcDate;
    //取引先コード
    if (clientCd) {
      config.params.clientCd = clientCd;
    }
    //搬入場所コード
    config.params.deliveryPlanceCd = deliveryPlanceCd;

    console.debug("GetParameterProcurement getDeliveryPlanceCd1 Url", userClientCd, config);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.BIZ_DELIVERY_PLANCECD1, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          console.debug(
            "GetParameterProcurement getDeliveryPlanceCd1 Response",
            userClientCd,
            jsonData
          );
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            const list = [];
            jsonData.resIdv.sortingBizUse.forEach((row) => {
              list.push({
                text: row.deliveryPlaceCd,
                value: row.deliveryPlaceCd,
              });
            });
            resolve(list);
            // エラー時
          } else {
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameterProcurement getDeliveryPlanceCd1 Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * 業務利用搬入場所取得2API
   */
  getDeliveryPlanceCd2(
    userClientCd,
    userClientSid,
    utcDate,
    clientCd,
    clientSid,
    userMakerDiv,
    deliveryPlanceCd
  ) {
    const config = httpClient.createGetApiRequestConfig();
    //ユーザー取引先コード
    config.params.userClientCd = userClientCd;
    //ユーザー取引先SID
    config.params.userClientSid = userClientSid;
    //基準日
    config.params.referenceDate = utcDate;
    if (userMakerDiv == "01") {
      if (clientCd) {
        //取引先コード
        config.params.clientCd = clientCd;
      }
    } else {
      if (clientSid) {
        //取引先SID
        config.params.clientSid = clientSid;
      }
    }
    //ユーザーメーカー区分
    config.params.userMakerDiv = userMakerDiv;
    //搬入場所コード
    config.params.deliveryPlanceCd = deliveryPlanceCd;

    console.debug("GetParameterProcurement getDeliveryPlanceCd2 Url", userClientCd, config);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.BIZ_DELIVERY_PLANCECD2, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          console.debug(
            "GetParameterProcurement getDeliveryPlanceCd2 Response",
            userClientCd,
            jsonData
          );
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            const list = [];
            jsonData.resIdv.sortingBizUse.forEach((row) => {
              list.push({
                text: row.deliveryPlaceCd,
                value: row.deliveryPlaceCd,
              });
            });
            resolve(list);
            // エラー時
          } else {
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameterProcurement getDeliveryPlanceCd2 Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * 業務利用計画コード取得1API
   */
  getBizPlanCd1(userClientCd, utcDate, clientCd, planCd) {
    const config = httpClient.createGetApiRequestConfig();
    //ユーザー取引先コード
    config.params.userClientCd = userClientCd;
    //基準日
    config.params.referenceDate = utcDate;
    if (clientCd) {
      //取引先コード
      config.params.clientCd = clientCd;
    }
    //計画コード
    config.params.planCd = planCd;

    console.debug("GetParameterProcurement getBizPlanCd1 Url", userClientCd, config);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.BIZ_SORTING_PLAN_CD1, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          console.debug("GetParameterProcurement getBizPlanCd1 Response", userClientCd, jsonData);
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            const list = [];
            jsonData.resIdv.sortingBizUse.forEach((row) => {
              list.push({
                text: row.planCd,
                value: row.planCd,
              });
            });
            resolve(list);
            // エラー時
          } else {
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameterProcurement getBizPlanCd1 Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * 業務利用計画コード取得2API
   */
  getBizPlanCd2(userClientCd, userClientSid, utcDate, clientCd, clientSid, userMakerDiv, planCd) {
    const config = httpClient.createGetApiRequestConfig();
    //ユーザー取引先コード
    config.params.userClientCd = userClientCd;
    //ユーザー取引先SID
    config.params.userClientSid = userClientSid;
    //基準日
    config.params.referenceDate = utcDate;
    if (userMakerDiv == "01") {
      if (clientCd) {
        //取引先コード
        config.params.clientCd = clientCd;
      }
    } else {
      if (clientSid) {
        //取引先SID
        config.params.clientSid = clientSid;
      }
    }
    //ユーザーメーカー区分
    config.params.userMakerDiv = userMakerDiv;
    //搬入場所コード
    config.params.planCd = planCd;

    console.debug("GetParameterProcurement getBizPlanCd2 Url", userClientCd, config);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.BIZ_SORTING_PLAN_CD2, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          console.debug("GetParameterProcurement getBizPlanCd2 Response", userClientCd, jsonData);
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            const list = [];
            jsonData.resIdv.sortingBizUse.forEach((row) => {
              list.push({
                text: row.planCd,
                value: row.planCd,
              });
            });
            resolve(list);
            // エラー時
          } else {
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameterProcurement getBizPlanCd2 Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * 業務利用搬入先工場コード取得API
   */
  getBizCarryCompCd(userClientCd, utcDate) {
    const config = httpClient.createGetApiRequestConfig();
    //ユーザー取引先コード
    config.params.userClientCd = userClientCd;
    //基準日
    config.params.referenceDate = utcDate;

    console.debug("GetParameterProcurement getBizCarryCompCd Url", userClientCd, config);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.BIZ_SORTING_CALLY_COMP_CD, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          console.debug("GetParameterProcurement getBizPlanCd1 Response", userClientCd, jsonData);
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            const list = [];
            jsonData.resIdv.sortingBizUse.forEach((row) => {
              list.push({
                text: `${row.carryCompCd}` + `（${row.relatedPartyName}）`,
                value: row.carryCompCd,
                name: row.relatedPartyName,
                userClientSid: row.userClientSid,
              });
            });
            resolve(list);
            // エラー時
          } else {
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameterProcurement getBizPlanCd1 Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * 業務利用ユーザー品番マスタ取得
   */
  getUserItemsBizCdAddName(clientSid, itemCd) {
    const config = httpClient.createGetApiRequestConfig();
    config.params.userClientSid = clientSid;
    config.params.item = itemCd;
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .secureGet(appConfig.API_URL.BIZ_DELIVERY_USER_ITEM_CD, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            const list = [];
            jsonData.resIdv.userItem1.forEach((row) => {
              var itemCd = row.userItemCd.substring(0, 50).trimRight();
              var incidental = row.userItemCd.slice(-50).trimRight();
              list.push({
                text: `${itemCd}` + ` ${incidental}` + `（${row.userItemName}）`,
                value: row.userItemSid,
                name: row.userItemName,
                code: row.userItemCd,
                baseCd: row.userItemBaseCd.substring(0, 50).trimRight(),
                isNew: false,
              });
            });
            resolve(list);
            // エラー時
          } else {
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch(() => {
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }
}
export const getParameterProcurement = new GetParameterProcurement();
