<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <!-- 搬入計画データ取込 -->
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_deliveryOrderDataImport" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <!-- 検索項目 -->
          <v-row class="search-row">
            <!-- ユーザー取引先-->
            <div style="width: 400px" class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                maxlength="100"
                v-model="userSuppliersSelected"
                :items="userSupplierList"
                :label="$t('label.lbl_userSupplier')"
                :hint="setSrhUserSupplier()"
                class="txt-single"
                clear-icon="mdi-close-circle"
                persistent-hint
                @change="changeUserSuppliersSelected"
                :rules="[rules.inputRequired]"
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require" style="float: left">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 搬入先工場-->
            <div style="width: 400px" class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                maxlength="100"
                v-model="destinationyPlantSelected"
                :items="destinationyPlantList"
                :label="$t('label.lbl_destinationyPlant')"
                :hint="setDestinationyPlant()"
                class="txt-single"
                persistent-hint
                :error-messages="alertDestinationyPlantMessage"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>
            <!-- 取引先プルダウン-->
            <div style="width: 400px" class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                maxlength="100"
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="setSrhSupplier()"
                class="txt-single"
                persistent-hint
                @change="changeClient"
                :search-input.sync="searchClient"
                :error-messages="alertClientMessage"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
          </v-row>
          <v-row style="height: 10px"></v-row>
          <v-row>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 取込ファイル -->
            <div class="file-input">
              <v-file-input
                prepend-icon=""
                :label="$t('label.lbl_importFile')"
                outlined
                dense
                v-model="importfile"
                counter
                :rules="[rules.fileRequired]"
                append-outer-icon="mdi-paperclip"
                @click:append-outer="handleClickAppendOuter"
                ref="vFileInput"
              >
              </v-file-input>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 取込ボタン -->
            <v-btn class="post-btn" @click="search()">
              {{ $t("btn.btn_import") }}
            </v-btn>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- ファイル種類-->
            <div style="width: 400px" class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                maxlength="100"
                v-model="fileTypeSelected"
                :items="fileTypeList"
                :label="$t('label.lbl_fileType')"
                :hint="setFileType()"
                class="txt-single"
                persistent-hint
                :rules="[rules.inputRequired]"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require" style="float: left">*</span>
            <div style="width: 100px"></div>
            <div class="search-row-exeBtn first-search-item">
              <!--ボタン領域-->
              <div class="btn-area">
                <!-- アップロードボタン -->
                <v-btn
                  class="post-btn"
                  @click="viewConfirmDialog('btn_upload')"
                  v-bind:disabled="!activate"
                  >{{ $t("btn.btn_upload") }}</v-btn
                >

                <!-- 画面クリアボタン -->
                <v-btn class="other-btn" @click="viewConfirmDialog('btn_clear')">{{
                  $t("btn.btn_clear")
                }}</v-btn>
              </div>
            </div>
          </v-row>
          <!-- ３列目 -->
          <v-row>
            <!--数量合計領域-->

            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span class="toatlNum">総件数：{{ totalCount }}件</span>
              </div>
              <div
                style="
                  float: left;
                  display: flex;
                  align-items: center;
                  width: 67%;
                  flex-direction: row-reverse;
                "
              >
                <div
                  style="
                    margin-left: 85%;
                    font-weight: bold;
                    font-size: large;
                    border-bottom: double;
                  "
                >
                  <!-- 数量合計-->
                  {{ $t("label.lbl_sumOfInstructions") + " " + this.sumQty.toLocaleString() }}
                </div>
              </div>
              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  {{ $t("label.lbl_itemsPerPage") }}
                </div>

                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>

                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                  >
                    <v-btn class="pasing-btn" value="10" @click="u_10_on" small>
                      {{ $t("10") }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="50" @click="u_10_clear(50)" small>
                      {{ $t("50") }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="100" @click="u_10_clear(100)" small>
                      {{ $t("100") }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>
      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :server-items-length="itemsPerPage"
          disable-filtering
          :hide-default-footer="true"
          height="610px"
          :options.sync="sortOptions"
        >
          <template v-slot:[`item.inListNo`]="{ item }">
            <!-- 伝票No -->
            <div class="listNo-style">{{ item.outListNo }}</div>
          </template>
          <template v-slot:[`item.userItemCdName`]="{ item }">
            <!-- 品番/品名 -->
            <div v-if="item.userItemCd.length > 9">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-truncate" style="max-width: 400px" v-bind="attrs" v-on="on">
                    {{ item.userItemCd }}
                  </div>
                </template>
                <div>
                  {{ item.userItemCd }}
                </div>
              </v-tooltip>
            </div>
            <div v-else>
              <div>{{ item.userItemCd }}</div>
            </div>
            <div v-if="item.userItemName.length > 9">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-truncate" style="max-width: 400px" v-bind="attrs" v-on="on">
                    {{ item.userItemName }}
                  </div>
                </template>
                <div>{{ item.userItemName }}</div>
              </v-tooltip>
            </div>
            <div v-else>
              <div>{{ item.userItemName }}</div>
            </div>
          </template>
          <template v-slot:[`item.stock`]="{ item }">
            <!-- 指示数 -->
            <div style="text-align: right">{{ item.stock }}</div>
          </template>
        </v-data-table>
        <!-- 詳細画面ポップアップ -->
        <v-dialog v-model="dialogDetails" :max-width="2000" persistent no-click-animation>
          <DeliveryDataImportError
            :isOpenDetailDialog.sync="dialogDetails"
            :entity="detailDialogData"
            class="overflow"
            v-on:closePopup="closePopup"
          />
        </v-dialog>
        <!-- ページネーション -->
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :screenFlag="infoDialog.screenFlag"
        :homePageFlag="infoDialog.homePageFlag"
        :deodpListFlg="infoDialog.deodpListFlg"
        :deodpAddFlg="infoDialog.deodpAddFlg"
        :firstPageFlag="infoDialog.firstPageFlag"
      />

      <!-- メッセージダイアログ-->
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :screenFlag="ConfirmDialog.screenFlag"
        :okAction="ConfirmDialog.okAction"
        :redMessage="ConfirmDialog.redMessage"
        :changeFlag="ConfirmDialog.changeFlag"
      />
    </v-sheet>
  </div>
</template>
<script>
import Loading from "@/components/loading";
import ConfirmDialog from "@/components/ConfirmDialog";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { getParameterProcurement } from "../../assets/scripts/js/GetParameterProcurement";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import NavBar from "../../components/NavBar.vue";
import DeliveryDataImportError from "../procurement/DeliveryDataImportError.vue";
import commonMixin from "@/mixins/CommonMixin";
import commonMixinExcel from "@/mixins/CommonMixinExcel";

export default {
  name: appConfig.SCREEN_ID.P_RCV_002,
  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
    sideMenu,
    NavBar,
    DeliveryDataImportError,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  mixins: [commonMixin, commonMixinExcel],
  data: () => ({
    userSuppliersSelected: "",
    userSupplierList: [],
    destinationyPlantSelected: "",
    destinationyPlantList: [],
    alertClientMessage: "",
    userMakerList: [],
    searchClient: "",
    selectHintClient: "",
    fileTypeSelected: "",
    fileTypeList: [],
    file: {},
    fileData: {},
    loadingCounter: 0,
    sortItem: "",
    sortOptions: {},
    importfile: {},
    // 入荷予定日
    arrivalScheduleDate: "",
    arrivalScheduleDateCal: "",
    // カレンダー
    dateMenu: false,
    // ファイル名
    fileName: {},
    // S3ファイル名
    s3FileName: {},
    // 取引先
    suppliersSelected: "",
    supplierList: [],
    suppliersSelectedName: "",
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    itemsPerPageBtn: "10",
    // 責任
    isRes: "01",
    isResBadValue: "01",
    // 有償/無償
    isPaid: "01",
    // 総件数
    totalCount: 0,
    // 品質区分
    arrivalStatusSelected: "01",
    isDisabledFreeReason: true,
    isDisabledBlame: true,

    message: "Try Preview!",
    url: "",

    // 無償理由
    freeReasonSelected: "",
    // 処理区分
    processDivName: "01",
    // ファイル情報配列
    inputFileData: [],
    // 取込確定フラグ
    confirmFlg: false,
    // メニュー
    openMenu: null,
    csvData: [],
    // 表示の切り替え
    activate: false,
    // 数量合計
    sumQty: "",
    // カラム数
    headColumn: 0,
    // 詳細ポップアップ
    dialogDetails: false,
    errorDetailDialogData: [],
    // 必須チェックメッセージ
    alertDestinationyPlantMessage: "",
    // シート数
    sheetNum: 0,
    // 取込データ
    output: [],
    sheetInfo: [],
    // ヘッダ
    headerItems: [
      // No
      {
        text: "No",
        value: "No",
        width: "75px",
        sortable: true,
        align: "left",
      },
      // メーカー取引先
      {
        text: i18n.tc("label.lbl_makerSupplier"),
        value: "clientCd",
        width: "190px",
        align: "left",
        sortable: true,
      },
      // メーカー工場
      {
        text: i18n.tc("label.lbl_makerFactory"),
        value: "clientPlantCd",
        width: "170px",
        align: "left",
        sortable: true,
      },
      // 搬入先工場
      {
        text: i18n.tc("label.lbl_destinationyPlant"),
        value: "carryCompCd",
        width: "170px",
        align: "left",
        sortable: true,
      },
      // 搬入日
      {
        text: i18n.tc("label.lbl_deliveryDate"),
        value: "deliveryDate",
        width: "150px",
        align: "left",
        sortable: true,
      },
      // 搬入時刻
      {
        text: i18n.tc("label.lbl_deliveryTime"),
        value: "deliveryTime",
        width: "150px",
        align: "left",
        sortable: true,
      },
      // 搬入場所
      {
        text: i18n.tc("label.lbl_deliveryDestinationy"),
        value: "deliveryPlaceCd",
        width: "160px",
        align: "left",
        sortable: true,
      },
      // 計画コード
      {
        text: i18n.tc("label.lbl_deliveryPlanCd"),
        value: "planCd",
        width: "160px",
        align: "left",
        sortable: true,
      },
      // 供給先コード
      {
        text: i18n.tc("label.lbl_destinationCd"),
        value: "supplyToCd",
        width: "170px",
        align: "left",
        sortable: true,
      },
      // ユーザー品番/品名
      {
        text: i18n.tc("label.lbl_userProductCdName"),
        value: "userItemCdName",
        width: "210px",
        align: "left",
        sortable: true,
      },
      // 付帯品番
      {
        text: i18n.tc("label.lbl_productAccessoryCd"),
        value: "userItemSubCd",
        width: "210px",
        align: "left",
        sortable: true,
      },
      // 伝票No
      {
        text: i18n.tc("label.lbl_billingSlipNo"),
        value: "outListNo",
        width: "160px",
        align: "left",
        sortable: true,
      },
      // 指示数
      {
        text: i18n.tc("label.lbl_instNum"),
        value: "stock",
        width: "150px",
        align: "right",
        sortable: true,
      },
    ],
    inputList: [],
    tmpList: [],
    rules: {
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      fileRequired: (value) => (!!value && !!value.name) || i18n.tc("check.chk_select"),
    },
    // 詳細ERROR画面へ渡す
    detailDialogData: {
      suppliersSelectedName: "",
      list: [],
    },
  }),
  methods: {
    /**
     * 初期データ取得
     */
    getUserClientInfo(jstInScheduleDate) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const userSupplierList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(jstInScheduleDate)
      );
      return Promise.all([userSupplierList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.userSupplierList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
          this.infoDialog.screenFlag = false;
          this.infoDialog.homePageFlag = false;
          this.infoDialog.deodpListFlg = false;
          this.infoDialog.deodpAddFlg = false;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    /**
     * ユーザー取引先コードリストボックス取得処理
     */
    setSrhUserSupplier() {
      for (var i = 0; i < this.userSupplierList.length; i++) {
        if (this.userSupplierList[i].value == this.userSuppliersSelected) {
          return this.userSupplierList[i].name;
        }
      }
    },
    changeUserSuppliersSelected() {
      // 取引先を変更する場合はクリアする
      this.suppliersSelected = "";
      this.supplierList = [];
      this.destinationyPlantSelected = "";
      this.destinationyPlantList = [];
      this.fileTypeSelected = "";
      this.fileTypeList = [];

      // ユーザー取引先が存在する場合
      if (this.userSuppliersSelected) {
        for (let i = 0; i < this.userSupplierList.length; i++) {
          if (this.userSupplierList[i].value == this.userSuppliersSelected) {
            this.userClientCd = this.userSupplierList[i].clientCd;
          }
        }
        // ローディング画面表示ON
        this.loadingCounter = 1;
        const destinationyPlantList = getParameterProcurement.getBizCarryCompCd(
          this.userClientCd,
          dateTimeHelper.convertUTC(this.deliveryDate)
        );
        const fileType = this.getFileType(this.userSuppliersSelected);
        return Promise.all([destinationyPlantList, fileType])
          .then((result) => {
            // 画面の初期値を設定します。
            this.destinationyPlantList = result[0];
            this.fileTypeList = result[1];
            this.fileTypeSelected = 1 == result[1].length ? result[1][0].value : void 0;
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            this.infoDialog.screenFlag = false;
            this.infoDialog.homePageFlag = false;
            this.infoDialog.deodpListFlg = false;
            this.infoDialog.deodpAddFlg = false;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      }
    },
    /**
     * プルダウンチェンジイベント
     */
    changeClient(value) {
      // 取引先を選択時
      let val = this.supplierList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.selectHintClient = val.text;
        this.searchClient = val.text;
      }
      this.supplierList = this.supplierList.filter((v) => v.value == value);

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.selectHintClient = "";
      } else {
        // 処理なし
      }
    },
    /**
     * 納入先工場コードリストボックス取得処理
     */
    setDestinationyPlant() {
      for (var i = 0; i < this.destinationyPlantList.length; i++) {
        if (this.destinationyPlantList[i].value == this.destinationyPlantSelected) {
          return this.destinationyPlantList[i].name;
        }
      }
    },
    /**
     * ファイル種類リストボックス取得処理
     */
    setFileType() {
      for (var i = 0; i < this.fileTypeList.length; i++) {
        if (this.fileTypeList[i].value == this.fileTypeSelected) {
          return this.fileTypeList[i].name;
        }
      }
    },
    uploadFile() {
      const file = this.$refs.preview.files[0];
      this.url = URL.createObjectURL(file);
      // let filePath =
      console.log("this.url: " + this.url);
    },
    // ダイアログオープン
    openPopup() {
      this.dialogDetails = true;
    },
    // ダイアログクローズ
    closePopup() {
      this.dialogDetails = false;
    },

    /**
     * 初期表示
     */
    init() {
      this.suppliersSelected = "";
      this.txt_arrivalScheduleNo = "";
      this.txt_slipNo = "";
      this.itemsPerPage = 10;
      this.itemsPerPageBtn = "10";
      this.inputList = [];
      this.tmpList = [];
      this.freeReasonSelected = "";
      this.processDivName = "";
      this.arrivalScheduleDate = "";
      this.arrivalScheduleDateCal = "";
      // ユーザー/メーカー区分取得
      const userMakerList = getParameter.getCodeMst(appConfig.CODETYPE.USER_MAKER_SELECT);
      Promise.all([userMakerList]).then((result) => {
        this.userMakerList = result[0];
      });
      this.getUserClientInfo();
    },
    /**
     * ボタン押下時の処理
     */
    viewConfirmDialog(button) {
      let isValidationRequired;
      let message;
      let action = () => {};
      switch (button) {
        case "btn_clear":
          isValidationRequired = false;
          message = messsageUtil.getMessage("P-COM-001_006_C", [i18n.tc("btn.btn_clear")]);
          action = this.clear;
          break;
        case "btn_upload":
          isValidationRequired = true;
          message = messsageUtil.getMessage("P-COM-001_006_C", [i18n.tc("btn.btn_upload")]);
          action = this.uploadCsv;
          break;
        default:
          isValidationRequired = false;
          message = "";
      }
      if (isValidationRequired) {
        // 処理なし
        if (
          (this.destinationyPlantSelected ?? "") === "" &&
          this.tmpList.find(
            (t) => (t.carryCompCd ?? "").replaceAll(" ", "").replaceAll("　", "") === ""
          ) !== void 0
        ) {
          // 搬入先工場CDが未選択の場合
          this.alertDestinationyPlantMessage = i18n.tc("check.chk_input");
        }
        if (
          (this.suppliersSelected ?? "") === "" &&
          this.tmpList.find(
            (t) => (t.clientCd ?? "").replaceAll(" ", "").replaceAll("　", "") === ""
          ) !== void 0
        ) {
          // メーカー取引先が未選択の場合
          this.alertClientMessage = i18n.tc("check.chk_input");
        }
        if (this.alertDestinationyPlantMessage || this.alertClientMessage) {
          return;
        }
      }
      this.ConfirmDialog.message = message;
      this.ConfirmDialog.title = appConfig.DIALOG.title;
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
      this.ConfirmDialog.okAction = action;
    },
    /**
     * 画面クリア押下
     */
    clear() {
      this.confirmFlg = false;
      this.pageCount = 1;
      this.itemsPerPage = 10;
      this.itemsPerPageBtn = "10";
      this.inputList = [];
      this.tmpList = [];
      this.supplierList = [];
      this.destinationyPlantList = [];
      this.fileTypeList = [];
      this.importfile = null;
      this.totalCount = 0;
      this.activate = false;
      this.sumQty = "";
      this.userSuppliersSelected = "";
      this.fileTypeSelected = "";
      this.suppliersSelected = "";
      this.destinationyPlantSelected = "";
      // ボタン識別フラグ（falseだったら、画面クリア）
      this.confirmFlg = false;
      this.alertClientMessage = "";
      this.alertDestinationyPlantMessage = "";
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },

    /**
     * 取込ボタン押下処理
     */
    fileImport() {
      // ダイアログオブジェクトを初期化
      this.infoDialog.firstPageFlag = false;
      this.infoDialog.screenFlag = false;
      this.infoDialog.homePageFlag = false;
      this.infoDialog.deodpListFlg = false;
      this.infoDialog.deodpAddFlg = false;
      // 取込確定処理
      // 選択したファイルを読み込む
      const selectFile = this.importfile;
      // 拡張子チェック
      let fileType = selectFile.type;
      // 取り込んだファイルがCSVの場合
      if (
        fileType == "text/csv" ||
        ".csv" === selectFile.name.substring(selectFile.name.lastIndexOf(".")).toLowerCase()
      ) {
        this.importCSV(selectFile, true)
          .then((result) => {
            this.inputFileData = result;
          })
          .then(() => {
            this.postCsv();
          });
      } else if (
        fileType == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        fileType == "application/vnd.ms-excel"
      ) {
        // 取り込んだファイルがエクセルの場合
        this.importExcel(selectFile)
          .then((result) => {
            this.inputFileData = result;
          })
          .then(() => {
            this.postCsv();
          });
      } else {
        // CSV, Excel以外はテキストデータとしてファイルを読み取る
        this.importTxt(selectFile)
          .then((result) => {
            this.inputFileData = result;
          })
          .then(() => {
            this.postCsv();
          });
      }
    },
    /**
     * 取込前のバリデーションチェック
     */
    search() {
      // 初期化
      this.inputList = [];
      this.totalCount = 0;
      this.sumQty = "";
      this.alertClientMessage = "";
      this.alertDestinationyPlantMessage = "";

      if (this.$refs.form.validate()) {
        // 拡張子が有効でない場合
        if (
          this.importfile.name.substring(this.importfile.name.lastIndexOf(".")) !==
          this.fileTypeList.find((f) => f.value === this.fileTypeSelected).extension
        ) {
          this.infoDialog.message = i18n.tc("check.chk_extensionIsNotAvaillable");
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
          this.infoDialog.screenFlag = false;
          this.infoDialog.homePageFlag = false;
          this.infoDialog.deodpListFlg = false;
          this.infoDialog.deodpAddFlg = false;
          return;
        }
        // 取込確定確認POPUP処理
        this.sortOptions = {};
        this.fileImport();
      }
    },
    /**
     * ファイル種別を取得する
     */
    getFileType(clientSid) {
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.clientSid = clientSid;
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_DELIVERY_IMPORT_FILE_TYPE_GET, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const resList = [];
              jsonData.resIdv.deliveryFiles.forEach((row) => {
                // 搬入計画取込のファイル種別は9始まり
                if (row.dataDiv.charAt(0) === "9") {
                  resList.push({
                    value: row.dataDiv,
                    name: row.beFileName,
                    text: row.beFileName,
                    extension: row.beFileName.substring(row.beFileName.lastIndexOf(".")),
                  });
                }
              });
              resolve(resList);
            } else {
              reject(jsonData.resCom.resComMessage);
            }
          })
          .catch((ex) => {
            console.error("GetParameter getUserBiz Err", ex);
            reject(messsageUtil.getMessage("P-999-999_999_E"));
          });
      });
    },
    /**
     * POSTAPI(本登録POST)
     */
    uploadCsv() {
      this.confirmFlg = true;
      if (this.confirmFlg) {
        // ローディング画面表示ON
        this.loadingCounter = 1;
        const body = this.$httpClient.createRequestBodyConfig();

        //  画面ID TODO:修正必要
        body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_RCV_006;
        // ユーザー取引先SID
        body.reqIdv.userClientSid = this.userSuppliersSelected;
        // ファイル種類
        body.reqIdv.fileType = this.fileTypeSelected;
        let userClient = this.userSupplierList.find((u) => u.value === this.userSuppliersSelected);
        let client = this.supplierList.find((s) => (s.value = this.suppliersSelected));
        let destinationyPlant = this.destinationyPlantList.find(
          (d) => d.value === this.destinationyPlantSelected
        );
        // アップロードデータ
        body.reqIdv.uploadDataList = this.tmpList.map((v) => {
          v.No = void 0;
          v.deliveryDate = v.deliveryDate.replaceAll("/", "");
          v.deliveryTime = v.deliveryTime.replaceAll(":", "");
          // 取引先CDがファイルに入っていない場合は、画面の取引先を設定
          if ((v.clientCd ?? "") === "") {
            v.clientCd = client.clientCd;
            v.clientName = client.name;
          }
          if ((v.carryCompCd ?? "") === "") {
            v.carryCompCd = destinationyPlant.value;
          }
          if ((v.deliveryCd ?? "") === "") {
            console.log(userClient);
            v.deliveryCd = userClient.clientCd;
          }
          // 数量が入っていない場合
          if (v.stock == 0 || (v.stock ?? "") === "") {
            v.stock = 1;
          }
          // 収容数が入っていない場合
          if (v.containerCapacityQuantity == 0 || (v.containerCapacityQuantity ?? "") === "") {
            v.containerCapacityQuantity = 1;
          }
          // ケース数が入っていない場合
          if (v.caseQuantity == 0 || (v.caseQuantity ?? "") === "") {
            v.caseQuantity = Math.ceil(v.stock / v.containerCapacityQuantity);
          }
          // 端数が入っていない場合
          if ((v.fracQuantity ?? "") === "") {
            v.fracQuantity = Math.ceil(v.stock % v.containerCapacityQuantity);
          }
          return v;
        });

        return new Promise((resolve, reject) => {
          let isSuccess = false;
          this.$httpClient
            // 接続先のAPI_URLを入力
            .securePost(appConfig.API_URL.BIZ_DELIVERY_UPLOAD_POST, body, appConfig.APP_CONFIG)
            .then((response) => {
              // console.debug("uploadCsv() Response", response);
              const jsonData = JSON.parse(JSON.stringify(response.data));

              // 正常時
              if (jsonData.resCom.resComCode == "000") {
                isSuccess = true;
                this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
                this.infoDialog.title = "結果";
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                this.infoDialog.homePageFlag = true;
                this.infoDialog.deodpListFlg = true;
                this.infoDialog.deodpAddFlg = true;
                this.infoDialog.firstPageFlag = false;
                this.clearFlg = true;

                resolve(response);
              } else {
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                this.infoDialog.screenFlag = false;
                this.infoDialog.homePageFlag = false;
                this.infoDialog.deodpListFlg = false;
                this.infoDialog.deodpAddFlg = false;
                reject(resolve);
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.screenFlag = false;
              this.infoDialog.homePageFlag = false;
              this.infoDialog.deodpListFlg = false;
              this.infoDialog.deodpAddFlg = false;
              reject(resolve);
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
              if (isSuccess) {
                this.clear();
              }
            });
        });
      } else {
        // 画面クリア処理
        this.inputList = [];
        this.tmpList = [];
        this.importfile = null;
        this.totalCount = 0;
        this.arrivalStatusSelected = "01";
        this.activate = false;
        this.freeReasonSelected = "";
        this.processDivName = "01";
        this.itemsPerPage = 10;
        this.itemsPerPageBtn = "10";
        this.isPaid = "01";
        // バリデーションチェックをリセット
        this.$refs.vFileInput.resetValidation();
      }
    },

    /**
     * POSTAPI(仮登録POST)
     */

    postCsv() {
      // メッセージ初期化

      // ローディング画面表示ON
      this.loadingCounter = 1;
      const body = this.$httpClient.createRequestBodyConfig();

      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_RCV_006;
      // ユーザー取引先SID
      body.reqIdv.userClientSid = this.userSuppliersSelected;
      // 搬入先工場CD
      body.reqIdv.carryCompCd = this.destinationyPlantSelected;
      // ファイル種類
      body.reqIdv.fileType = this.fileTypeSelected;
      // 取引先SID
      let clientInfo = this.supplierList.find((v) => v.value === this.suppliersSelected);
      body.reqIdv.clientSid = clientInfo ? clientInfo.clientSid : void 0;
      // 取引先コード
      body.reqIdv.clientCd = clientInfo ? clientInfo.clientCd : void 0;

      // ファイル内容配列
      body.reqIdv.listData = this.inputFileData;
      // タイムゾーン
      body.reqIdv.timeZone = dateTimeHelper.getTimeZone();
      console.log("postCsv() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_DELIVERY_IMPORT_POST, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("postCsv() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              if (jsonData.resIdv.deliveryFiles.csvErrorCheckResultList.length != 0) {
                this.pageCount = 0;
                const errorList = [];
                this.errorDetailDialogData = [];
                jsonData.resIdv.deliveryFiles.csvErrorCheckResultList.forEach((row) => {
                  errorList.push({
                    listNo: row.listNo,
                    row: row.row,
                    column: row.columnStr === "0" ? "" : row.columnStr,
                    message: row.message,
                    value: row.value,
                  });
                  this.errorDetailDialogData = errorList;
                });
                // エラー一覧タブが開く
                // 詳細画面へ渡す値を設定します。
                this.detailDialogData.list = this.errorDetailDialogData;
                this.detailDialogData.userSuppliersSelectedName = this.userSupplierList.find(
                  (v) => v.value === this.userSuppliersSelected
                ).text;
                if (this.suppliersSelected) {
                  this.detailDialogData.suppliersSelectedName = this.setSrhSupplier();
                }
                if (this.destinationyPlantSelected) {
                  this.detailDialogData.destinationyPlantSelected = this.destinationyPlantList.find(
                    (v) => v.value === this.destinationyPlantSelected
                  ).text;
                }
                this.detailDialogData.isText =
                  errorList.find((e) => 0 <= (e.column ?? "").indexOf("～")) !== void 0;
                this.dialogDetails = true;
              } else {
                const list = [];
                this.pageCount = Math.ceil(jsonData.resCom.resComCount / this.itemsPerPage);
                let officeCd = sessionStorage.getItem("office_cd");
                // 画面表示項目
                let isClientBlank = false;
                let isCarryCompCdBlank = false;
                for (
                  let index = 0;
                  index < jsonData.resIdv.deliveryFiles.importDataList.length;
                  index++
                ) {
                  const row = jsonData.resIdv.deliveryFiles.importDataList[index];
                  // 取引先CDがブランクか判定
                  if (!isClientBlank) {
                    isClientBlank = (row.clientCd ?? "") === "";
                  }
                  // 搬入先工場CDがブランクか判定
                  if (!isCarryCompCdBlank) {
                    isCarryCompCdBlank = (row.carryCompCd ?? "") === "";
                  }
                  if (isClientBlank || isCarryCompCdBlank) {
                    break;
                  }
                  list.push({
                    No: index + 1,
                    // 状況
                    status: row.status,
                    // データ作成日
                    createDate: row.createDate,
                    // データ作成時間
                    createTime: row.createTime,
                    // 更新日
                    updateDate: row.updateDate,
                    // 更新時間
                    updateTime: row.updateTime,
                    // 会社CD
                    compCd: row.compCd,
                    // 業務CD
                    businessCd: row.businessCd,
                    // データ作成日
                    dataCreateDate: row.dataCreateDate,
                    // 入荷No
                    dataCreateTime: row.dataCreateTime,
                    // 取引先CD
                    clientCd: row.clientCd,
                    // 取引先名
                    clientName: row.clientName,
                    // 取引先工場CD
                    clientPlantCd: row.clientPlantCd,
                    // 搬入先CD
                    deliveryCd: row.deliveryCd
                      ? row.deliveryCd
                      : this.userSupplierList.find((u) => u.value === this.userSuppliersSelected)
                          .clientCd,
                    // 搬入先工場CD
                    carryCompCd: row.carryCompCd,
                    // 搬入場所CD
                    deliveryPlaceCd: row.deliveryPlaceCd,
                    // 搬入場所CD（印字用）
                    deliveryPlaceCdPrnt: row.deliveryPlaceCdPrnt,
                    // 搬入場所名
                    deliveryPlaceName: row.deliveryPlaceName,
                    // 計画CD
                    planCd: row.planCd,
                    // 搬入先エリアCD
                    deliveryAreaCd: row.deliveryAreaCd,
                    // 搬入先エリア名
                    deliveryAreaName: row.deliveryAreaName,
                    // 搬入区分
                    carrierDiv: row.carrierDiv,
                    // 供給先CD
                    supplyToCd: row.supplyToCd,
                    // 容器CD
                    containerCd: row.containerCd,
                    // 収容数
                    containerCapacityQuantity: row.containerCapacityQuantity,
                    // ユーザー品番
                    userItemCd: row.userItemCd,
                    // ユーザー基本品番
                    userItemBaseCd: row.userItemBaseCd,
                    // ユーザー品番付帯
                    userItemSubCd: row.userItemSubCd,
                    // ユーザー品番付属情報
                    userItemAdditionalCd: row.userItemAdditionalCd,
                    // ユーザー品名
                    userItemName: row.userItemName,
                    // 搬入指示方式
                    deliveryInstType: row.deliveryInstType,
                    // 搬入日
                    deliveryDate:
                      !row.deliveryDate || 8 > row.deliveryDate.length
                        ? row.deliveryDate
                        : row.deliveryDate.slice(0, 4) +
                          "/" +
                          row.deliveryDate.slice(4, 6) +
                          "/" +
                          row.deliveryDate.slice(6, 8),
                    // 搬入時間
                    deliveryTime:
                      !row.deliveryDate || 4 > row.deliveryDate
                        ? row.deliveryTime
                        : row.deliveryTime.slice(0, 2) + ":" + row.deliveryTime.slice(2, 4),
                    // 指示数
                    stock: row.stock,
                    // 注文No
                    orderNo: row.orderNo,
                    // 伝票No
                    outListNo: row.outListNo,
                    // 集荷指示No
                    deliveryInstNo: row.deliveryInstNo,
                    // 集荷指示差替元No
                    deliveryInstReplacementNo: row.deliveryInstReplacementNo,
                    // 集荷便No
                    deliveryBinNo: row.deliveryBinNo,
                    // 差替区分
                    replacementDiv: row.replacementDiv,
                    // ロットNo
                    lotNo: row.lotNo,
                    // ケース数
                    caseQuantity: row.caseQuantity,
                    // 端数
                    fracQuantity: row.fracQuantity,
                    // データ使用拠点
                    dateUseBase: row.dateUseBase ? row.dateUseBase : officeCd,
                    // 引取拠点
                    pickUpBase: row.pickUpBase ? row.pickUpBase : officeCd,
                    // 中継地点1
                    relayPoint01: row.relayPoint01,
                    // 中継地点2
                    relayPoint02: row.relayPoint02,
                    // 中継地点3
                    relayPoint03: row.relayPoint03,
                    // 中継地点4
                    relayPoint04: row.relayPoint04,
                    // 中継地点5
                    relayPoint05: row.relayPoint05,
                    // 最終出荷拠点
                    finalBase: row.finalBase ? row.finalBase : officeCd,
                    // 予備01
                    yobi01: row.yobi01,
                    // 予備02
                    yobi02: row.yobi02,
                    // 予備03
                    yobi03: row.yobi03,
                    // 予備04
                    yobi04: row.yobi04,
                    // 予備05
                    yobi05: row.yobi05,
                    // 予備06
                    yobi06: row.yobi06,
                    // 予備07
                    yobi07: row.yobi07,
                    // 予備08
                    yobi08: row.yobi08,
                    // 予備09
                    yobi09: row.yobi09,
                    // 予備10
                    yobi10: row.yobi10,
                    // 予備11
                    yobi11: row.yobi11,
                    // 予備12
                    yobi12: row.yobi12,
                    // 予備13
                    yobi13: row.yobi13,
                    // 予備14
                    yobi14: row.yobi14,
                    // 予備15
                    yobi15: row.yobi15,
                    // 予備16
                    yobi16: row.yobi16,
                    // 予備17
                    yobi17: row.yobi17,
                    // 予備18
                    yobi18: row.yobi18,
                    // 予備19
                    yobi19: row.yobi19,
                    // 予備20
                    yobi20: row.yobi20,
                    // 予備21
                    yobi21: row.yobi21,
                    // 予備22
                    yobi22: row.yobi22,
                    // 予備23
                    yobi23: row.yobi23,
                    // 予備24
                    yobi24: row.yobi24,
                  });
                }
                if (isClientBlank) {
                  this.alertClientMessage = i18n.tc("check.chk_input");
                }
                if (isCarryCompCdBlank) {
                  this.alertDestinationyPlantMessage = i18n.tc("check.chk_input");
                }
                if (isClientBlank || isCarryCompCdBlank) {
                  resolve(response);
                  return;
                }
                // 全ての一覧データを画面保持
                this.tmpList = JSON.parse(JSON.stringify(list));
                // 表示データは現在のページと表示件数に絞る
                this.getViewData();

                // 総件数取得
                this.totalCount = this.tmpList.length;
                // 数量合計取得
                var cnt = 0;
                this.sumQty = 0;
                for (var i = 0; i < this.tmpList.length; i++) {
                  cnt = cnt + this.tmpList[i].stock;
                }
                this.sumQty = cnt;
                resolve(response);
              }
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.screenFlag = false;
              this.infoDialog.homePageFlag = false;
              this.infoDialog.deodpListFlg = false;
              this.infoDialog.deodpAddFlg = false;
              reject(resolve);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            this.infoDialog.screenFlag = false;
            this.infoDialog.homePageFlag = false;
            this.infoDialog.deodpListFlg = false;
            this.infoDialog.deodpAddFlg = false;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].text;
        }
      }
    },
    /**
     * 取引先コード取得
     */
    getSupplierCd() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].officeCd;
        }
      }
    },

    /**
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },
    /**
     * アイコンを押下しても、エクスプローラーが開く処理
     */
    handleClickAppendOuter() {
      this.$refs.vFileInput.$el.querySelector("input").click();
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
      this.page = 1;
      this.pageCount = Math.ceil(this.tmpList.length / 10);
      this.getViewData();
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.pageCount = Math.ceil(this.tmpList.length / val);
      this.getViewData();
    },
    /**
     * 表示するデータを取得する
     */
    getViewData() {
      let startIndex = (this.page - 1) * this.itemsPerPage;
      let endIndex = startIndex + this.itemsPerPage;
      this.inputList = this.tmpList.slice(startIndex, endIndex);
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    page: function (newValue) {
      this.page = newValue;
      this.getViewData();
    },
    destinationyPlantSelected(val) {
      if ((val ?? "") !== "") {
        this.alertDestinationyPlantMessage = "";
      }
    },
    suppliersSelected(val) {
      if ((val ?? "") !== "") {
        this.alertClientMessage = "";
      }
    },
    searchClient(val) {
      if (val == null) {
        return;
      }
      if (this.checkFlg1) {
        this.checkFlg1 = false;
        return;
      }
      if (val ?? false) {
        // 取引先が選択されてない場合、処理しない
        if (this.userSuppliersSelected == null || this.userSuppliersSelected == "") {
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.selectHintClient) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        // 入力桁数が定義した数以上の場合検索処理
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // 選択した会社コードを取得

          const fromList = getParameter.getRelatedBizPartialMatch(
            appConfig.ISGETCLIENT.CLIENT,
            dateTimeHelper.convertUTC(this.arrivalScheduleDate),
            undefined,
            undefined,
            val,
            sessionStorage.getItem("sales_office_sid")
          );

          Promise.all([fromList])
            .then((result) => {
              // 画面の初期値を設定します。

              this.supplierList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.supplierList.length = 0;
          this.supplierList = [];
        }
      } else {
        this.supplierList.length = 0;
        this.supplierList = [];
        this.suppliersSelected = "";
      }
    },
    inputList(val) {
      this.activate = 0 < val.length;
    },
    sortOptions: {
      handler(items) {
        let all = JSON.parse(JSON.stringify(this.tmpList));
        if (!items || !items.sortBy || items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? "desc" : "asc";
          this.sortItem = sortBy + " " + ascdesc;
          if (sortBy == "No") {
            all.sort((a, b) => {
              if (ascdesc == "asc") {
                return a.No - b.No;
              } else {
                return b.No - a.No;
              }
            });
          } else if (sortBy == "clientCd") {
            all.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.clientCd < b.clientCd) {
                  return -1;
                } else if (a.clientCd > b.clientCd) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.clientCd > b.clientCd) {
                  return -1;
                } else if (a.clientCd < b.clientCd) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "clientPlantCd") {
            all.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.clientPlantCd < b.clientPlantCd) {
                  return -1;
                } else if (a.clientPlantCd > b.clientPlantCd) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.clientPlantCd > b.clientPlantCd) {
                  return -1;
                } else if (a.clientPlantCd < b.clientPlantCd) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "carryCompCd") {
            all.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.carryCompCd < b.carryCompCd) {
                  return -1;
                } else if (a.carryCompCd > b.carryCompCd) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.carryCompCd > b.carryCompCd) {
                  return -1;
                } else if (a.carryCompCd < b.carryCompCd) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "deliveryDate") {
            all.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.deliveryDate < b.deliveryDate) {
                  return -1;
                } else if (a.deliveryDate > b.deliveryDate) {
                  return 1;
                } else {
                  if (a.deliveryTime < b.deliveryTime) {
                    return -1;
                  } else if (a.deliveryTime > b.deliveryTime) {
                    return 1;
                  } else {
                    return 0;
                  }
                }
              } else {
                if (a.deliveryDate > b.deliveryDate) {
                  return -1;
                } else if (a.deliveryDate < b.deliveryDate) {
                  return 1;
                } else {
                  if (a.deliveryTime > b.deliveryTime) {
                    return -1;
                  } else if (a.deliveryTime < b.deliveryTime) {
                    return 1;
                  } else {
                    return 0;
                  }
                }
              }
            });
          } else if (sortBy == "deliveryTime") {
            all.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.deliveryDate < b.deliveryDate) {
                  return -1;
                } else if (a.deliveryDate > b.deliveryDate) {
                  return 1;
                } else {
                  if (a.deliveryTime < b.deliveryTime) {
                    return -1;
                  } else if (a.deliveryTime > b.deliveryTime) {
                    return 1;
                  } else {
                    return 0;
                  }
                }
              } else {
                if (a.deliveryDate > b.deliveryDate) {
                  return -1;
                } else if (a.deliveryDate < b.deliveryDate) {
                  return 1;
                } else {
                  if (a.deliveryTime > b.deliveryTime) {
                    return -1;
                  } else if (a.deliveryTime < b.deliveryTime) {
                    return 1;
                  } else {
                    return 0;
                  }
                }
              }
            });
          } else if (sortBy == "deliveryPlaceCd") {
            all.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.deliveryPlaceCd < b.deliveryPlaceCd) {
                  return -1;
                } else if (a.deliveryPlaceCd > b.deliveryPlaceCd) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.deliveryPlaceCd > b.deliveryPlaceCd) {
                  return -1;
                } else if (a.deliveryPlaceCd < b.deliveryPlaceCd) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "planCd") {
            all.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.planCd < b.planCd) {
                  return -1;
                } else if (a.planCd > b.planCd) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.planCd > b.planCd) {
                  return -1;
                } else if (a.planCd < b.planCd) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "supplyToCd") {
            all.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.supplyToCd < b.supplyToCd) {
                  return -1;
                } else if (a.supplyToCd > b.supplyToCd) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.supplyToCd > b.supplyToCd) {
                  return -1;
                } else if (a.supplyToCd < b.supplyToCd) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "userItemCdName") {
            all.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.userItemCd < b.userItemCd) {
                  return -1;
                } else if (a.userItemCd > b.userItemCd) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.userItemCd > b.userItemCd) {
                  return -1;
                } else if (a.userItemCd < b.userItemCd) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "userItemSubCd") {
            all.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.userItemSubCd < b.userItemSubCd) {
                  return -1;
                } else if (a.userItemSubCd > b.userItemSubCd) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.userItemSubCd > b.userItemSubCd) {
                  return -1;
                } else if (a.userItemSubCd < b.userItemSubCd) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "outListNo") {
            all.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.outListNo < b.outListNo) {
                  return -1;
                } else if (a.outListNo > b.outListNo) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.outListNo > b.outListNo) {
                  return -1;
                } else if (a.outListNo < b.outListNo) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "stock") {
            all.sort((a, b) => {
              let _a = Number(commonUtil.zen2han(a.stock).replaceAll(",", ""));
              let _b = Number(commonUtil.zen2han(b.stock).replaceAll(",", ""));
              if (ascdesc == "asc") {
                return _a - _b;
              } else {
                return _b - _a;
              }
            });
          }
          let startIndex = (this.page - 1) * this.itemsPerPage;
          let endIndex = startIndex + this.itemsPerPage;
          this.inputList = all.slice(startIndex, endIndex);
          return this.inputList;
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
//画面優先順位

.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

//送り先登録時、色変更
.isD {
  color: $color-error !important;
}

//備考欄、テキストボックス
.search-textbox-remark {
  width: 40rem;
}
.flex {
  display: flex;
}
.scrollType::-webkit-scrollbar {
  display: none;
}
.scrollTypeClose {
  overflow-y: scroll;
}
.table-scroll-host {
  grid-area: content;
  overflow: scroll;
}
.text-overflow {
  // v-tooltip
  width: 290px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
