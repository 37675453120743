<template>
  <span>
    <div class="search-autocomplete">
      <!-- コード -->
      <v-autocomplete
        dense
        v-model="codeSelected"
        :items="codeList"
        :label="label"
        :rules="codeSelecterRules"
        persistent-hint
        margin-bottom="0"
        pa-3
        :disabled="!enabled"
        :readonly="readOnry"
        class="txt-single"
      >
        <template slot="item" slot-scope="data">
          <span class="auto-txt-size"> {{ data.item.text }} </span>
        </template>
      </v-autocomplete>
    </div>
    <!-- 確認メッセージダイアログモーダル-->
    <SimpleDialog
      :isShow.sync="infoDialog.isOpen"
      :title="infoDialog.title"
      :message="infoDialog.message"
      :firstPageFlag="infoDialog.firstPageFlag"
      :cntapprovalFlg="infoDialog.cntapprovalFlg"
    />
  </span>
</template>

<script>
import { i18n } from "../../lang/lang.js";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import SimpleDialog from "@/components/SimpleDialog";
export default {
  components: { SimpleDialog },
  props: {
    value: { type: String, required: true },
    rules: {
      type: Array,
      default: function () {
        return [true];
      },
    },
    code: { type: String, required: true },
    label: { type: String, required: true },
    isRequired: { type: Boolean, default: false },
    enabled: { type: Boolean, default: true },
    readOnry: { type: Boolean, default: false },
  },
  data: () => ({
    // 確認メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      cntapprovalFlg: false,
    },
    // データ取得中
    isLoading: false,
    // コードプルダウン
    codeSelected: "",
    codeList: [],

    // 入力制御
    defaultRules: {
      inputRequired: (value, isRequired) => !isRequired || !!value || i18n.tc("check.chk_input"),
    },
    codeSelecterRules: [],
  }),
  methods: {
    /**
     * 初期起動：画面を開いた際に渡された値を設定します。
     */
    init() {
      this.codeSelected = this.value;
      // コード一覧の取得
      this.getOffceList();
      this.codeSelecterRules = [
        this.defaultRules.inputRequired(this.codeSelected, this.isRequired),
      ].concat(this.rules);
      console.log(this.codeSelecterRules);
    },

    /**
     * コード一覧の取得
     */
    getOffceList() {
      this.isLoading = true;
      this.$emit("loading", 1);
      return Promise.all([getParameter.getCodeMst(this.code)])
        .then((result) => {
          this.codeList = result[0];
          // 未選択許可の場合、空行を追加
          if (!this.isRequired) {
            this.codeList.unshift({ text: "", name: "", value: "" });
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          this.isLoading = false;
          this.$emit("loading", 0);
          console.log(this._events);
        });
    },
  },
  computed: {},
  watch: {
    value(val) {
      this.codeSelected = val;
    },
    codeSelected(val) {
      // 親へデータの受け渡す
      this.$emit("input", val);
    },
  },
  created() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.validation {
  color: red;
  text-align: center;
  margin-bottom: 3%;
}
</style>
