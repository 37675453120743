x
<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_userProductMastaList" />
      <v-form ref="form">
        <v-container fluid>
          <!-- １行目スタート -->
          <v-row class="search-row">
            <span class="require"></span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- ユーザ品番/ユーザ品名 -->
            <div class="search-textbox" style="float: left">
              <v-text-field
                dense
                outlined
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_userProductCd')"
                persistent-hint
                clear-icon="mdi-close-circle"
                clearable
                maxlength="50"
              >
              </v-text-field>
            </div>
            <span class="require"></span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- ユーザ品番付属CD -->
            <div class="search-textbox" style="float: left">
              <v-text-field
                dense
                outlined
                v-model="itemSelected"
                :items="supplierList"
                :label="$t('label.lbl_userProductAccessoryCd')"
                persistent-hint
                clear-icon="mdi-close-circle"
                clearable
                maxlength="50"
              >
              </v-text-field>
            </div>
            <span class="require"></span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- ユーザ基本品番 -->
            <div class="search-textbox" style="float: left">
              <v-text-field
                dense
                outlined
                v-model="temporaryRegist"
                :items="supplierList"
                :label="$t('label.lbl_userBaseProductCd')"
                persistent-hint
                clear-icon="mdi-close-circle"
                clearable
                maxlength="50"
              >
              </v-text-field>
            </div>
            <span class="require"></span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- ユーザ名 -->
            <div class="search-textbox" style="float: left">
              <v-text-field
                dense
                outlined
                v-model="detailsInfo"
                :items="supplierList"
                :label="$t('label.lbl_userName')"
                persistent-hint
                clear-icon="mdi-close-circle"
                clearable
                maxlength="50"
              >
              </v-text-field>
            </div>
          </v-row>
          <v-row>
            <div class="search-row-exeBtn">
              <!--ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 検索ボタン -->
                <v-btn
                  color="primary"
                  id="get-search"
                  class="api-btn"
                  @click="getItemList('get-search')"
                  >{{ $t("btn.btn_search") }}</v-btn
                >
                <!--画面クリアボタン-->
                <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>
              <div class="btn-area">
                <!--コピーボタン-->
                <v-btn
                  v-if="btnToggle"
                  color="primary"
                  id="btn-search"
                  class="api-btn"
                  @click="copy"
                  >{{ $t("btn.btn_copy") }}</v-btn
                >
                <!--新規登録ボタン-->
                <v-btn color="primary" id="btn-search" class="api-btn" @click="initRegist">{{
                  $t("btn.btn_iniRegist")
                }}</v-btn>
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span>総件数：{{ totalCount }}件</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  {{ $t("label.lbl_itemsPerPage") }}
                </div>
                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>

                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                  >
                    <v-btn class="pasing-btn" value="10" @click="u_10_on()" small>
                      {{ 10 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="50" @click="u_10_clear(50)" small>
                      {{ 50 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="100" @click="u_10_clear(100)" small>
                      {{ 100 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>
        <!-- データテーブル -->
        <v-container fluid>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            :page.sync="page"
            :server-items-length="itemsPerPage"
            disable-filtering
            :options.sync="sortOptions"
            :hide-default-footer="true"
            height="530px"
          >
            <!-- ヘッダーチェックボックス
            <template v-slot:[`header.check`]="{ header }">
              <input
                type="checkbox"
                :checked="isAllSelected"
                :value="header.check"
                @click="selectAllCheck"
                style="transform: scale(2)"
              />
            </template> -->

            <!-- 一覧チェックボックス -->
            <template v-slot:[`item.check`]="{ item }">
              <input
                type="checkbox"
                v-model="item.check"
                :value="listCheckbox"
                style="transform: scale(2)"
              />
            </template>
            <!-- リンクボタン -->
            <template v-slot:[`item.link`]="{ item }">
              <v-btn small @click="openUserMaker(item)" text>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <!-- 詳細ボタン -->
            <template v-slot:[`item.detail`]="{ item }">
              <v-btn small @click="openUpdateDelete(item)" text>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <!-- ユーザー品番・ユーザー品名 -->
            <template v-slot:[`item.productCnCd`]="{ item }">
              <div v-if="item.productName.length > 20">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-overflow" style="max-width: 200px" v-bind="attrs" v-on="on">
                      {{ item.productCnCd }}
                    </div>
                    <div class="text-overflow" style="max-width: 200px">{{ item.productName }}</div>
                  </template>
                  <div>{{ item.productCnCd }}</div>
                  <div>{{ item.productName }}</div>
                </v-tooltip>
              </div>
              <div v-else>
                <div>{{ item.productCnCd }}</div>
                <div>{{ item.productName }}</div>
              </div>
            </template>
            <!-- ユーザー名 -->
            <template v-slot:[`item.userName`]="{ item }">
              <div v-if="item.userName.length > 20">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-overflow" style="max-width: 200px" v-bind="attrs" v-on="on">
                      {{ item.userName }}
                    </div>
                  </template>
                  <div>{{ item.userName }}</div>
                </v-tooltip>
              </div>
              <div v-else>
                <div>{{ item.userName }}</div>
              </div>
            </template>

            <!-- 仮登録 -->
            <template v-slot:[`item.tempoRegist`]="{ item }">
              <div v-if="item.tempoRegist == '-'" style="color: black">
                <template>
                  <div>
                    {{ item.tempoRegist }}
                  </div>
                </template>
              </div>
              <div v-else-if="item.tempoRegist == '要登録'" style="color: red">
                {{ item.tempoRegist }}
              </div>
            </template>
          </v-data-table>
          <!-- 設定ボタン押下後エラー時出現エラー -->
          <v-dialog v-model="copyDialogMsg" :max-width="800">
            <v-card>
              <v-card-title class="blue-grey lighten-3" primary-title>
                {{ $t("btn.btn_ok") }}
              </v-card-title>
              <v-card-text class="pa-4">
                <p>{{ alertMessage }}</p>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="okClicked"> OK </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <div class="text-center pt-2">
            <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
          </div>
        </v-container>
      </v-form>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
      />
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :changeFlag="confirmDialog.changeFlag"
      />
    </v-sheet>
  </div>
</template>

<script>
import NavBar from "../../components/NavBar.vue";
import SimpleDialog from "@/components/SimpleDialog";
import Loading from "@/components/loading";
import sideMenu from "@/components/Menu";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { i18n } from "../../lang/lang.js";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import ConfirmDialog from "@/components/ConfirmDialog";
export default {
  name: appConfig.MENU_ID.P_MST_ITEM_MASTER_LIST,
  components: {
    Loading,
    sideMenu,
    SimpleDialog,
    NavBar,
    ConfirmDialog,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 商品詳細情報登録画面ポップアップ
    openDatailsAdd: false,
    u_10: false,
    // 検索ボタン押下後、trueにする
    // ボタン表示の切り替え
    btnToggle: false,
    // 入力チェック結果フラグ
    isInputCheck: true,

    seachSuppliersSelected: "",
    seachItemSelected: "",
    seachTemporaryRegist: "",
    seachDetailsInfo: "",

    // 総件数
    totalCount: 0,
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 1,
    itemsPerPage: 10,
    itemsPerPageBtn: "10",
    // メニュー
    openMenu: null,
    // 基準日
    referenceDate: "",
    referenceDateCal: "",
    dateMenu: false,
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    // 品番
    itemSelected: "",
    // 仮登録プルダウン
    temporaryRegist: "",
    temporaryRegistList: [],
    // 詳細情報プルダウン
    detailsInfo: "",
    detailsInfoList: [],
    // 取引先
    supplierList: [],
    suppliersSelected: "",
    // 全選択チェックボックス
    isAllSelected: false,
    // 一覧選択チェックボックス
    listCheckbox: [1, 2],
    // ダイアログ表示内容
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
    },
    // テーブルフッター項目
    inputList: [],
    // コピーリスト
    copyList: [],
    // コピー時アラートダイアログ
    copyDialogMsg: false,
    alertMessage: "",
    // ソート
    sortItem: "",
    sortOptions: {},
    // テーブルヘッダ項目
    headerItems: [
      // 選択
      {
        text: "選択",
        value: "check",
        width: "5%",
        align: "center",
        sortable: false,
      },
      // ユーザ品番/ユーザ品名
      {
        text: i18n.tc("label.lbl_userProductCd"),
        value: "productCnCd",
        width: "5%",
        align: "left",
        sortable: true,
      },
      // ユーザ品番付属CD
      {
        text: i18n.tc("label.lbl_userProductAccessoryCd"),
        value: "userProductAccessoryCd",
        width: "5%",
        align: "left",
        sortable: true,
      },
      // ユーザ基本品番
      {
        text: i18n.tc("label.lbl_userBaseProductCd"),
        value: "userBaseProductCd",
        width: "5%",
        align: "left",
        sortable: true,
      },
      // ユーザ名
      {
        text: i18n.tc("label.lbl_userName"),
        value: "userName",
        width: "5%",
        align: "left",
        sortable: true,
      },
      // リンク
      {
        text: i18n.tc("label.lbl_userLink"),
        value: "link",
        align: "center",
        width: "2%",
        sortable: false,
      },
      // 詳細
      {
        text: i18n.tc("label.lbl_Detail"),
        value: "detail",
        align: "center",
        width: "2%",
        sortable: false,
      },
    ],
    rules: {
      supplierRequired: (value) => !!value || i18n.tc("check.chk_inputSupplier"),
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
    },
  }),
  methods: {
    // ページング
    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
      this.getItemList("get-search");
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.getItemList("get-search");
    },

    // 初期化
    init() {
      this.itemsPerPage = 10;
      this.itemsPerPageBtn = "100";
      this.getCustomInfo();

      this.referenceDate = dateTimeHelper.convertJST();
      this.referenceDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      this.suppliersSelected = this.$route.params.seachSuppliersSelected;
      this.itemSelected = this.$route.params.seachItemSelected;
      this.temporaryRegist = this.$route.params.seachTemporaryRegist;
      this.detailsInfo = this.$route.params.seachDetailsInfo;
      if (this.$route.params.isTransition == true) {
        this.getItemList();
      }
      if (this.$route.params.searchFilter) {
        if (this.$route.params.searchFilter.length != 0) {
          // 画面遷移前の検索条件をセット
          // this.referenceDate = this.$route.params.searchFilter.referenceDate;
          // this.referenceDateCal = this.$route.params.searchFilter.referenceDateCal;
          // this.temporaryRegist = this.$route.params.searchFilter.temporaryRegist;
          // this.itemSelected = this.$route.params.searchFilter.itemSelected;
          // this.detailsInfo = this.$route.params.searchFilter.detailsInfo;
          this.getItemList("get-search");
        }
      }
      // 仮登録、詳細情報プルダウンセット
      this.getDummyAPull();
      this.getDetailsPull();
      this.getUnitpriceGrPull();
    },
    // ユーザーメーカーリンクマスタ一覧画面へ
    openUserMaker(item) {
      this.$router.push({
        name: appConfig.SCREEN_ID.P_SUP_015,
        params: {
          itemCd: item.productCnCd, //ユーザー品番
          userProductAccessoryCd: item.userProductAccessoryCd, //ユーザー品番付属
          userBaseProductCd: item.userBaseProductCd, //ユーザー基本品番

          // 検索条件
          // searchFilter: [
          //   {
          //     suppliersSelected: this.searchedSuppliers, //取引先
          //     itemSelected: this.itemSelected, //品番
          //     userProdustName: this.userProdustName, //詳細登録
          //     temporaryRegist: this.temporaryRegist, //仮登録
          //     referenceDate: this.referenceDate, //基準日
          //     referenceDateCal: this.referenceDateCal, //基準日
          //   },
          // ],
        },
      });
    },
    // 詳細画面へ
    openUpdateDelete(item) {
      this.$router.push({
        name: appConfig.SCREEN_ID.P_SUP_013,
        params: {
          itemCd: item.productCnCd, //ユーザー品番
          userProdustName: item.productName, //ユーザー品名
          userProductAccessoryCd: item.userProductAccessoryCd, //ユーザー品番付属
          userBaseProductCd: item.userBaseProductCd, //ユーザー基本品番
          userName: item.userName, //ユーザー名
          seachSuppliersSelected: this.seachSuppliersSelected,
          seachItemSelected: this.seachItemSelected,
          seachTemporaryRegist: this.seachTemporaryRegist,
          seachDetailsInfo: this.seachDetailsInfo,
          displayFlg: "2", //どこからの画面遷移か判別させるため
          isTransition: true,
          // 検索条件
          // searchFilter: [
          //   {
          //     suppliersSelected: this.searchedSuppliers, //取引先
          //     itemSelected: this.itemSelected, //品番
          //     userProdustName: this.userProdustName, //詳細登録
          //     temporaryRegist: this.temporaryRegist, //仮登録
          //     referenceDate: this.referenceDate, //基準日
          //     referenceDateCal: this.referenceDateCal, //基準日
          //   },
          // ],
        },
      });
    },
    // コピーボタン押下
    copy() {
      this.copyList = [];
      for (var i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].check) {
          this.copyList.push(this.inputList[i]);
        }
      }
      // 選択された行が1行じゃない場合、１行のみコピーするよう警告
      if (this.copyList.length != 1) {
        this.copyDialogMsg = true;
        this.alertMessage = i18n.tc("check.chk_copyCnt");
        this.copyList = [];
      } else {
        this.$router.push({
          name: appConfig.SCREEN_ID.P_SUP_013,
          params: {
            copyList: this.copyList,
            suppliersSelected: this.suppliersSelected,
            displayFlg: "1",
          },
        });
      }
    },
    okClicked() {
      this.copyDialogMsg = false;
    },
    // 新規登録ボタン押下
    initRegist() {
      if (this.copyList.length == 1) {
        this.$router.push({
          name: appConfig.SCREEN_ID.P_SUP_013,
          params: {
            copyList: this.copyList,
            suppliersSelected: this.suppliersSelected,
            displayFlg: "1",
          },
        });
      } else {
        this.$router.push({
          name: appConfig.SCREEN_ID.P_SUP_013,
          params: {
            displayFlg: "1",
          },
        });
      }
    },
    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      // 画面遷移後の場合
      if (this.transitionFlg) {
        this.suppliersSelected = "";
        // 遷移フラグ
        this.transitionFlg = false;
      }
      // 変更検知用の設定
      this.arrivalScheduleDateFromCal = "";
      this.arrivalScheduleDateToCal = "";
      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },
    // },

    // computed: {},
    // watch: {
    //   // eslint-disable-next-line no-unused-vars
    //   isMenu: function (newValue, oldValue) {
    //     if (newValue) {
    //       this.init();
    //     }
    //   },
    //   dialog(val) {
    //     val || this.close();
    //   },
    //   suppliersSelected() {
    //     // 入荷元リストクリア
    //     this.arrivalList = [];
    //     this.arrivalSelected = "";

    //     this.alertSupplierMessage = "";
    //   },
    //   arrivalScheduleDateTo() {
    //     this.alertMessage = "";
    //   },
    //   arrivalScheduleDateFromCal: {
    //     handler(val) {
    //       this.arrivalScheduleDateFrom = this.formatDate(val);
    //     },
    //     deep: true,
    //   },
    //   arrivalScheduleDateToCal: {
    //     handler(val) {
    //       this.arrivalScheduleDateTo = this.formatDate(val);
    //     },
    //     deep: true,
    //   },
    //   page: function (newValue) {
    //     this.page = newValue;
    //     this.getApiList(false);
    //   },

    //   sortOptions: {
    //     handler(items) {
    //       // console.debug("watch sortByApi", items);
    //       if (items.sortBy.length < 1) {
    //         this.sortItem = "";
    //       } else {
    //         var sortBy = items.sortBy[0];
    //         var ascdesc = items.sortDesc[0] ? " desc" : " asc";
    //         this.sortItem = appConfig.P_ENT_001_SORT[sortBy].replaceAll("{0}", ascdesc); // + ascdesc;
    //         this.getApiList(false);
    //       }
    //     },
    //     deep: true,
    //   },
    //   /**
    //    * 入荷元取得
    //    */
    //   getSuppliersSelected(val) {
    //     if (val ?? false) {
    //       if (this.suppliersSelected == null || this.suppliersSelected == "") {
    //         this.arrivalList = [];
    //         this.arrivalSelected = "";
    //         return;
    //       }
    //       // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
    //       if (val == this.productHintArrival) {
    //         return;
    //       }
    //       // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
    //       if (val.trim().length === 0) {
    //         return;
    //       }
    //       // 入力桁数が定義した数異常の場合検索処理
    //       if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
    //         // 選択した会社コードを取得
    //         let officeCd = this.supplierList.find(
    //           (element) => element.value === this.suppliersSelected
    //         ).clientSid;
    //         // From（入荷元）取得
    //         const fromList = getParameter.getRelatedBizPartialMatch(
    //           appConfig.ISGETCLIENT.FROMTO,
    //           dateTimeHelper.convertUTC(),
    //           undefined,
    //           officeCd,
    //           val
    //         );
    //         Promise.all([fromList])
    //           .then((result) => {
    //             // 画面の初期値を設定します。
    //             this.arrivalList = result[0];
    //           })
    //           .catch((ex) => {
    //             this.infoDialog.message = ex;
    //             this.infoDialog.title = appConfig.DIALOG.title;
    //             this.infoDialog.isOpen = true;
    //             this.infoDialog.firstPageFlag = true;
    //           })
    //           .finally(() => {
    //             // ローディング画面表示OFF
    //             this.loadingCounter = 0;
    //           });
    //       } else {
    //         this.arrivalList.length = 0;
    //         this.arrivalList = [];
    //       }
    //     }
    //   },
    // },

    // 検索ボタン押下後、商品マスタ一覧を取得
    getItemList(val) {
      // 入力チェック結果フラグ
      //let inputCheckResult = false;
      console.log(val);
      this.seachSuppliersSelected = this.suppliersSelected;
      this.seachItemSelected = this.itemSelected;
      this.seachTemporaryRegist = this.temporaryRegist;
      this.seachDetailsInfo = this.detailsInfo;
      /**
       * 登録画面からの遷移の場合、入力チェックを実施するとvalidateが定義されていないと
       * エラーがでるので回避するため、このようなコードとなっている。
       */
      // if (this.$route.params.searchFilter) {
      //   // 登録画面からの遷移の場合、入力チェックは実施しない。
      //   inputCheckResult = true;
      // } else {
      //   // 上記外の場合はチェックを行う。
      //   inputCheckResult = this.$refs.form.validate();
      // }

      //力チェックを実施
      // if (inputCheckResult) {
      this.btnToggle = true;

      //   // ローディング画面表示ON
      //   this.loadingCounter = 1;

      // const config = this.$httpClient.createGetApiRequestConfig();

      // // 営業所SID（ログインユーザ情報）
      // config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      // // 基準日
      // let dateRange = this.referenceDate + " 23:59:59.999";
      // config.params.referenceDate = dateTimeHelper.convertUTC(dateRange);
      // // 取引先SID
      // config.params.clientSid = this.suppliersSelected;
      // 品番
      // if (this.itemSelected) {
      //   config.params.itemCd = this.itemSelected;
      // }
      // // 仮登録プルダウン
      // if (this.temporaryRegist) {
      //   config.params.dummyAdd = this.temporaryRegist;
      // }
      // // 詳細情報プルダウン
      // if (this.detailsInfo) {
      //   config.params.dtlInfo = this.detailsInfo;
      // }
      // ページャー処理
      // if (val == "get-search") {
      //   config.params.reqComPageIndex = "1";
      //   this.page = 1;
      // } else {
      //   if (this.page >= 2 && this.inputList.length == 0) {
      //     config.params.reqComPageIndex = "1";
      //     this.page = 1;
      //   } else {
      //     config.params.reqComPageIndex = this.page;
      //   }
      // }
      // ページング
      // config.params.reqComPaginationFlg = "1";
      // config.params.reqComPageLimit = this.itemsPerPage;
      // // ソート
      // if (this.sortItem != "") {
      //   config.params.reqComSortItem = this.sortItem;
      // }
      // // console.debug("getItemList() Config", config);
      // return new Promise((resolve, reject) => {
      //   this.$httpClient
      // 接続先のAPI_URLを入力
      // .secureGet(appConfig.API_URL.MST_ITEMSEARCH, config)
      // .then((response) => {
      //   const jsonData = JSON.parse(JSON.stringify(response.data));
      //   // console.debug("getItemList() Response", response);

      //   // 成功
      //   if (jsonData.resCom.resComCode == "000") {
      //     this.pageCount = jsonData.resCom.resComPagination.totalPage;
      //     this.totalCount = jsonData.resCom.resComPagination.totalRecord;
      const list = [];

      //     jsonData.resIdv.itemsStandard.forEach((row) => {
      //       var dummyAdd = "";
      //       if (row.dummyAdd == "01") {
      //         dummyAdd = "要登録";
      //       } else {
      //         dummyAdd = "-";
      //       }

      //       var dtlInfo = "";
      //       if (row.dtlInfo == "01") {
      //         dtlInfo = "未登録";
      //       } else {
      //         dtlInfo = "登録済";
      //       }

      list.push({
        productSid: "1", //商品Sid
        productCnCd: "U000-1", //ユーザ品番
        productName: "HMボルト", //ユーザ品名
        userProductAccessoryCd: "黒", //ユーザ品番付属CD
        userBaseProductCd: "U000", //ユーザ基本品番
        userName: "HONDA",
        dtlInfo: "6", //リンク情報
        dtlInfoDiv: "7", //詳細情報
      });
      list.push({
        productSid: "1", //商品Sid
        productCnCd: "U000-2", //ユーザ品番
        productName: "HMボルト12345678901234567890", //ユーザ品名
        userProductAccessoryCd: "緑", //ユーザ品番付属CD
        userBaseProductCd: "U000", //ユーザ基本品番
        userName: "HONDA12345678901234567890",
        dtlInfo: "6", //リンク情報
        dtlInfoDiv: "7", //詳細情報
      });
      // });
      this.inputList = list;
      this.totalCount = list.length;
      // resolve(response);
      // // 検索値保持
      this.searchedSuppliers = this.suppliersSelected; // 取引先SID
      // } else {
      //   // エラー時
      //   this.infoDialog.message = jsonData.resCom.resComMessage;
      //   this.infoDialog.title = appConfig.DIALOG.title;
      //   this.infoDialog.isOpen = true;
      //   this.infoDialog.screenFlag = true;
      //   this.infoDialog.firstPageFlag = true;

      //     reject();
      //   // }
      // // })
      // .catch((ex) => {
      //   this.infoDialog.message = ex;
      //   this.infoDialog.title = appConfig.DIALOG.title;
      //   this.infoDialog.isOpen = true;
      //   this.infoDialog.screenFlag = true;
      //   this.infoDialog.firstPageFlag = true;

      //   reject();
      // })
      // .finally(() => {
      // ローディング画面表示OFF
      this.loadingCounter = 0;
      // });
      // });
      // }
    },
    // 取引先API接続
    getCustomInfo(jstInScheduleDate) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 取引先
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(jstInScheduleDate)
      );
      Promise.all([clientList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.supplierList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // コードマスタ取得：仮登録プルダウン
    getDummyAPull() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 仮登録
      const dummyPull = getParameter.getCodeMst(appConfig.CODETYPE.DUMMY_ADD_DIV);
      Promise.all([dummyPull])
        .then((result) => {
          // 画面の初期値を設定します。
          this.temporaryRegistList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // コードマスタ取得：詳細情報プルダウン
    getDetailsPull() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 仮登録
      const detailsPull = getParameter.getCodeMst(appConfig.CODETYPE.DETAILS_DIV);
      Promise.all([detailsPull])
        .then((result) => {
          // 画面の初期値を設定します。
          this.detailsInfoList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // コードマスタ取得：単価Grプルダウン
    getUnitpriceGrPull() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 仮登録
      const unitpriceGrPull = getParameter.getCodeMst(appConfig.CODETYPE.UNITPRICE_GR);
      Promise.all([unitpriceGrPull])
        .then((result) => {
          // 画面の初期値を設定します。
          this.unitpriceGrList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // ***プルダウンセット処理***
    // ユーザ品番/ユーザ品名
    setSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },
    // ユーザ品番付属CD
    setDetailsInfo() {
      for (var i = 0; i < this.detailsInfoList.length; i++) {
        if (this.detailsInfoList[i].value == this.detailsInfo) {
          return this.detailsInfoList[i].name;
        }
      }
    },
    // ユーザ名
    setUnitpriceGr() {
      for (var i = 0; i < this.unitpriceGrList.length; i++) {
        if (this.unitpriceGrList[i].value == this.unitpriceGr) {
          return this.unitpriceGrList[i].name;
        }
      }
    },
    // 仮登録
    seTtemporaryRegist() {
      for (var i = 0; i < this.temporaryRegistList.length; i++) {
        if (this.temporaryRegistList[i].value == this.temporaryRegist) {
          return this.temporaryRegistList[i].name;
        }
      }
    },
    /**
     * 全選択チェックボックス処理
     */
    selectAllCheck() {
      if (!this.isAllSelected) {
        this.listCheckbox = [];
        this.listCheckbox = true;
        this.isAllSelected = true;
        for (var i = 0; i < this.inputList.length; i++) {
          this.inputList[i].check = true;
        }
      } else {
        this.listCheckbox = [];
        this.listCheckbox = false;
        for (var j = 0; j < this.inputList.length; j++) {
          this.inputList[j].check = false;
        }
        this.isAllSelected = false;
      }
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    // 日付入力
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.referenceDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.referenceDateCal = null;
      }
    },
    // カレンダー
    prevDate() {
      if (this.referenceDateCal == null) {
        return;
      }
      let date = new Date(this.referenceDateCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.referenceDateCal != toDate) {
        this.referenceDateCal = toDate;
      } else {
        this.referenceDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },
    nextDate() {
      if (this.referenceDateCal == null) {
        return;
      }
      let date = new Date(this.referenceDateCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.referenceDateCal != toDate) {
        this.referenceDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.referenceDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    referenceDateCal(val) {
      this.referenceDate = this.formatDate(val);
    },
    page: function (newValue) {
      this.page = newValue;
      this.getItemList("");
    },
    sortOptions: {
      handler(items) {
        // console.debug("watch sortByApi", items);
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_MST_007_SORT[sortBy].replaceAll("{0}", ascdesc); // + ascdesc;
          this.getItemList("get-search");
        }
      },
      deep: true,
    },
  },
  created() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}
.overflow {
  overflow: hidden;
}
.toatlNum {
  margin-left: 10px;
}
.txt-center {
  margin: auto;
}
.link {
  text-decoration: none;
}
</style>
