<!-- P-MST-016/画面権限一括修正 -->
<template>
  <div style="background: white" id="aa">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_roleMasterBatchAddUpdate" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="search-row"> </v-row>
          <v-row>
            <div class="search-row-exeBtn">
              <!--ボタン領域-->
              <div class="btn-search-area" style="float: right">
                <!-- 戻るボタン-->
                <v-btn color="primary" class="api-btn" @click="backPage" v-show="!iniFlg">
                  {{ $t("btn.btn_back") }}
                </v-btn>
                <!-- 修正登録ボタン-->
                <v-btn color="primary" id="btn-search" class="post-btn" @click="upadateAdd">
                  {{ $t("btn.btn_fixinsert") }}
                </v-btn>
              </div>
            </div>
          </v-row>
          <!-- 戻るボタンを押下時出現ダイアログ -->
          <v-dialog v-model="updateDialogMessage" :max-width="800">
            <v-card>
              <v-card-title class="blue-grey lighten-3" primary-title>
                {{ $t("btn.btn_ok") }}
              </v-card-title>
              <v-card-text class="pa-4">
                <p>{{ backMessage }}</p>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="updateOks"> OK </v-btn>
                <v-btn @click="updateNg"> {{ $t("btn.btn_cancel") }} </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          class="tableData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :server-items-length="itemsPerPage"
          disable-filtering
          :hide-default-footer="true"
          :options.sync="sortOptions"
          height="100%"
        >
          <!-- 画面種別 -->
          <template v-slot:[`item.roleResourceKeyName`]="{ item }">
            <v-row>
              <v-col style="text-align: left">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="text-overflow"
                      style="width: 500px; text-align: left !important"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ item.roleResourceKeyName }}
                    </div>
                  </template>
                  <div>{{ item.roleResourceKeyName }}</div>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
          <!-- 権限チェックボックス1 -->
          <template v-if="checkboxFlg1" v-slot:[`item.policy1`]="{ item }">
            <input
              type="checkbox"
              v-model="item.policy1"
              true-value="1"
              false-value="0"
              style="transform: scale(2)"
              :disabled="!checkboxFlg1"
            />
          </template>
          <!-- 権限チェックボックス2 -->
          <template v-if="checkboxFlg2" v-slot:[`item.policy2`]="{ item }">
            <input
              type="checkbox"
              v-model="item.policy2"
              true-value="1"
              false-value="0"
              style="transform: scale(2)"
              :disabled="!checkboxFlg2"
            />
          </template>
          <!-- 権限チェックボックス3 -->
          <template v-if="checkboxFlg3" v-slot:[`item.policy3`]="{ item }">
            <input
              type="checkbox"
              v-model="item.policy3"
              true-value="1"
              false-value="0"
              style="transform: scale(2)"
              :disabled="!checkboxFlg3"
            />
          </template>
          <!-- 権限チェックボックス4 -->
          <template v-if="checkboxFlg4" v-slot:[`item.policy4`]="{ item }">
            <input
              type="checkbox"
              v-model="item.policy4"
              true-value="1"
              false-value="0"
              style="transform: scale(2)"
              :disabled="!checkboxFlg4"
            />
          </template>
          <!-- 権限チェックボックス5 -->
          <template v-if="checkboxFlg5" v-slot:[`item.policy5`]="{ item }">
            <input
              type="checkbox"
              v-model="item.policy5"
              true-value="1"
              false-value="0"
              style="transform: scale(2)"
              :disabled="!checkboxFlg5"
            />
          </template>
        </v-data-table>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :okAction="fixInsert"
        :redMessage="ConfirmDialog.redMessage"
        :screenFlag="ConfirmDialog.screenFlag"
        :changeFlag="ConfirmDialog.changeFlag"
      />
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :screenFlag="infoDialog.screenFlag"
        :homePageFlag="infoDialog.homePageFlag"
        :firstPageFlag="infoDialog.firstPageFlag"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
        :iniMasterRegistFlg="infoDialog.iniMasterRegistFlg"
        :iniMasterPageParams="infoDialog.iniMasterPageParams"
      />
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import ConfirmDialog from "@/components/ConfirmDialog";
import sideMenu from "@/components/Menu";
//import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { getParameter } from "../../assets/scripts/js/GetParameter";
//import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
// import { resolve } from "url";
export default {
  name: appConfig.SCREEN_ID.P_MNG_011,
  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
    sideMenu,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // ロールパターンリスト
    rolePatternList: [],
    // ロールSIDリスト
    roleSidList: [],
    // ロール詳細SIDリスト（変更前）
    roleDetailSidBeforeList: [],
    // ロール詳細SIDリスト（変更後）
    roleDetailSidAfterList: [],
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 初期導入フラグ
    iniFlg: false,
    // 取引先CD(引継ぎ用)
    paramClientCd: "",
    // ユーザー一覧画面からの遷移
    manageUser: false,
    u_10: false,
    // ページング
    itemsPerPage: 10,
    // メニュー
    openMenu: null,
    // 権限チェックボックス表示フラグ
    checkboxFlg1: false,
    checkboxFlg2: false,
    checkboxFlg3: false,
    checkboxFlg4: false,
    checkboxFlg5: false,
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    // メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: true,
      // ダイアログの外クリック制御
      outsideClickNotCloseFlg: false,
      iniMasterRegistFlg: false,
      iniMasterPageParams: {},
    },
    // 確認メッセージ
    ConfirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
    },
    // ソート
    sortItem: "",
    sortOptions: {},
    // ヘッダ
    headerItems: [
      // 画面種別
      {
        text: i18n.tc("label.lbl_roleResourceKeyName"),
        value: "roleResourceKeyName",
        width: "500px",
        align: "center",
        class: "roles-fixed",
        sortable: false,
      },
      // チェックボックス
      // { text: "選択", value: "check1", width: "4%", align: "center", sortable: false },
    ],
    inputList: [],
  }),
  methods: {
    openPopup() {
      this.pListPopup = true;
    },

    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },

    // 初期化
    init() {
      //展開情報検索処理
      // this.getSearch();
      this.getInitData();
      this.getSearch();
    },

    // 初期データ取得
    getInitData() {
      // ロールパターンをコードマスタから取得する
      const rolePatternList = getParameter.getCodeMst(appConfig.CODETYPE.ROLE_PATTERNS);
      Promise.all([rolePatternList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.rolePatternList = result[0];
          this.setRoleName();
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * ロール名(可変)をセット
     */
    setRoleName() {
      for (let i = 0; i < this.rolePatternList.length; i++) {
        this.headerItems.push({
          text: i18n.tc(this.rolePatternList[i].text),
          // value: this.rolePatternList[i].value,
          // value: "policy1",
          value: `policy` + `${i + 1}`,
          width: "175px",
          class: "roles-fixed",
          align: "center",
          sortable: false,
        });
        // チェックボックス有効化
        eval("this.checkboxFlg" + `${i + 1}` + "=" + true + ";");
      }
    },

    // 画面権限一覧取得
    getSearch() {
      const config = this.$httpClient.createGetApiRequestConfig();
      //APIに渡す値をconfig.paramsにセット==================================//
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_ROLES_LIST, config)
          .then((response) => {
            // console.debug("btnSearch() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            //headerItems内削除
            this.headerItems.splice(0, this.headerItems.length);

            //headerItems設定-----------------------------------------//
            this.headerItems.push(
              // 画面種別
              {
                text: i18n.tc("label.lbl_roleResourceKeyName"),
                value: "roleResourceKeyName",
                width: "200px",
                align: "center",
                class: "roles-fixed",
                sortable: false,
              }
            );

            this.setRoleName();

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];

              jsonData.resIdv.rolesList.forEach((row) => {
                list.push({
                  // ロール詳細SID（禁止）
                  roleDetailSid0: row.roleDetailSid0,
                  // ロール詳細SID（許可）
                  roleDetailSid1: row.roleDetailSid1,
                  // ロールタイプ
                  roleType: row.roleType,
                  // ロール付与対象キー
                  roleResourceKey: row.roleResourceKey,
                  // ロール付与対象キー名称
                  roleResourceKeyName: row.roleResourceKeyName,
                  // ロール付与対象キー区分
                  roleResourceKeyDiv: row.roleResourceKeyDiv,
                  // ロールSID1
                  roleSid1: row.roleSid1,
                  // ポリシー1
                  policy1: row.policy1,
                  policy1_wk: row.policy1,
                  // 更新日時1
                  updateDatetime1: row.updateDatetime1,
                  // ロールSID2
                  roleSid2: row.roleSid2,
                  // ポリシー2
                  policy2: row.policy2,
                  policy2_wk: row.policy2,
                  // 更新日時2
                  updateDatetime2: row.updateDatetime2,
                  // ロールSID3
                  roleSid3: row.roleSid3,
                  // ポリシー3
                  policy3: row.policy3,
                  policy3_wk: row.policy3,
                  // 更新日時3
                  updateDatetime3: row.updateDatetime3,
                  // ロールSID4
                  roleSid4: row.roleSid4,
                  // ポリシー4
                  policy4: row.policy4,
                  policy4_wk: row.policy4,
                  // 更新日時4
                  updateDatetime4: row.updateDatetime4,
                });
              });
              this.inputList = list;
              resolve(response);

              // 0件の場合は、ボタンを非活性にする
              if (this.inputList.length == 0) {
                this.disableBtn = true;
              } else {
                this.disableBtn = false;
              }
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    // 修正登録ボタン押下：ダイヤログ表示

    // 修正登録ボタンダイヤログ：OK押下

    // 修正登録ボタンダイヤログ：NG押下

    // 修正登録ボタン押下時
    upadateAdd() {
      this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
    },

    // 確認ダイアログOK押下時
    fixInsert() {
      // リスト初期化
      this.roleSidList = [];
      this.roleDetailSidBeforeList = [];
      this.roleDetailSidAfterList = [];

      // ロールList
      var bodyList = [];

      for (var i = 0; i < this.inputList.length; i++) {
        // ロール1
        var inputMap = {};
        // ロールSIDセット;
        // if (
        //   this.inputList[i].policy1 != this.inputList[i].policy1_wk ||
        //   this.inputList[i].updateDatetime1 == null
        // ) {
        inputMap.roleSid = this.inputList[i].roleSid1;
        // 変更前SIDリストセット
        if (this.inputList[i].policy1_wk == "0") {
          inputMap.roleDetailSidBefore = this.inputList[i].roleDetailSid0;
        } else {
          inputMap.roleDetailSidBefore = this.inputList[i].roleDetailSid1;
        }
        // 変更後SIDリストセット
        if (this.inputList[i].policy1 == "0") {
          inputMap.roleDetailSidAfter = this.inputList[i].roleDetailSid0;
        } else {
          inputMap.roleDetailSidAfter = this.inputList[i].roleDetailSid1;
        }
        // 更新日1
        inputMap.updateDatetime = this.inputList[i].updateDatetime1;
        // 削除フラグ
        inputMap.deleteFlg = "0";
        bodyList.push(inputMap);
        // }

        // ロール2
        inputMap = {};
        // ロールSIDセット;
        // if (
        //   this.inputList[i].policy2 != this.inputList[i].policy2_wk ||
        //   this.inputList[i].updateDatetime2 == null
        // ) {
        inputMap.roleSid = this.inputList[i].roleSid2;
        // 変更前SIDリストセット
        if (this.inputList[i].policy2_wk == "0") {
          inputMap.roleDetailSidBefore = this.inputList[i].roleDetailSid0;
        } else {
          inputMap.roleDetailSidBefore = this.inputList[i].roleDetailSid1;
        }
        // 変更後SIDリストセット
        if (this.inputList[i].policy2 == "0") {
          inputMap.roleDetailSidAfter = this.inputList[i].roleDetailSid0;
        } else {
          inputMap.roleDetailSidAfter = this.inputList[i].roleDetailSid1;
        }
        // 更新日2
        inputMap.updateDatetime = this.inputList[i].updateDatetime2;
        // 削除フラグ
        inputMap.deleteFlg = "0";
        bodyList.push(inputMap);
        // }

        // ロール3
        inputMap = {};
        // ロールSIDセット
        // if (
        //   this.inputList[i].policy3 != this.inputList[i].policy3_wk ||
        //   this.inputList[i].updateDatetime3 == null
        // ) {
        inputMap.roleSid = this.inputList[i].roleSid3;
        // 変更前SIDリストセット
        if (this.inputList[i].policy3_wk == "0") {
          inputMap.roleDetailSidBefore = this.inputList[i].roleDetailSid0;
        } else {
          inputMap.roleDetailSidBefore = this.inputList[i].roleDetailSid1;
        }
        // 変更後SIDリストセット
        if (this.inputList[i].policy3 == "0") {
          inputMap.roleDetailSidAfter = this.inputList[i].roleDetailSid0;
        } else {
          inputMap.roleDetailSidAfter = this.inputList[i].roleDetailSid1;
        }
        // 更新日3
        inputMap.updateDatetime = this.inputList[i].updateDatetime3;
        // 削除フラグ
        inputMap.deleteFlg = "0";
        bodyList.push(inputMap);
        // }

        // ロール4
        inputMap = {};
        // ロールSIDセット;
        // if (
        //   this.inputList[i].policy4 != this.inputList[i].policy4_wk ||
        //   this.inputList[i].updateDatetime4 == null
        // ) {
        inputMap.roleSid = this.inputList[i].roleSid4;
        // 変更前SIDリストセット
        if (this.inputList[i].policy4_wk == "0") {
          inputMap.roleDetailSidBefore = this.inputList[i].roleDetailSid0;
        } else {
          inputMap.roleDetailSidBefore = this.inputList[i].roleDetailSid1;
        }
        // 変更後SIDリストセット
        if (this.inputList[i].policy4 == "0") {
          inputMap.roleDetailSidAfter = this.inputList[i].roleDetailSid0;
        } else {
          inputMap.roleDetailSidAfter = this.inputList[i].roleDetailSid1;
        }
        // 更新日4
        inputMap.updateDatetime = this.inputList[i].updateDatetime4;
        // 削除フラグ
        inputMap.deleteFlg = "0";
        bodyList.push(inputMap);
        // }
      }

      console.log(bodyList);

      // 1つも変更がない場合、エラー
      // if (this.roleDetailSidAfterList.length == 0) {
      //   this.alertDialog = true;
      //   this.checkMessage = messsageUtil.getMessage("P-EXT-003_001_E");
      //   console.log("変更なし");
      //   return;
      // }

      // ローディング画面表示ON
      this.loadingCounter = 1;

      const body = this.$httpClient.createRequestBodyConfig();

      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_MST_016;
      body.reqIdv.rolesList = bodyList;

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          // ここがGETとの違い。APIの接続方法としては同じ
          .securePost(appConfig.API_URL.MST_ROLES_LIST, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("addShpSch() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = false;
              this.infoDialog.outsideClickNotCloseFlg = true;
              if (this.iniFlg) {
                this.infoDialog.homePageFlag = false;
                this.infoDialog.iniMasterRegistFlg = true;
                this.infoDialog.iniMasterPageParams = {
                  clientCd: this.paramClientCd,
                  masterCountUpdateFlg: true,
                };
              } else {
                this.infoDialog.homePageFlag = true;
              }
              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.outsideClickNotCloseFlg = false;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            this.infoDialog.outsideClickNotCloseFlg = false;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    // 戻るボタン押下
    backPage() {
      this.updateDialogMessage = true;
      this.backMessage = messsageUtil.getMessage("P-RCV-004_003_W");
      // this.$router.push({
      //   name: appConfig.SCREEN_ID.P_MST_014,
      // });
    },
    // 戻るボタンダイアログ：OK押下
    updateOks() {
      this.updateDialogMessage = false;
      if (this.manageUser) {
        this.$router.push({
          name: appConfig.SCREEN_ID.P_MST_012,
        });
      } else {
        this.$router.push({
          name: appConfig.SCREEN_ID.P_MST_014,
          params: {
            iniFlg: this.iniFlg,
            clientCd: this.paramClientCd,
          },
        });
      }
    },
    // 戻るボタンダイアログ：NG押下
    updateNg() {
      this.updateDialogMessage = false;
    },
    created() {
      this.init();
    },
  },

  computed: {},

  mounted() {
    // 前画面からのパラメータ設定
    this.iniFlg = this.$route.params.iniFlg; // 初期導入フラグ
    this.paramClientCd = this.$route.params.clientCd; // 取引先コード(引継ぎ用)
    this.manageUser = this.$route.params.manageUser; // ユーザー一覧画面からの遷移
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}
.overflow {
  overflow: hidden;
}

.diff2 {
  background-color: #d09cfc;
  // background-color:#d09cfc; 出荷保障の色
}
//ソート非表示
#listData.v-data-table--fixed-header
  ::v-deep
  .v-data-table__wrapper
  table
  thead
  tr
  th[aria-sort="none"]
  .v-icon {
  display: none;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper tbody tr,
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper tbody tr td {
  background-color: #ffffff;
}

#listData.v-data-table--fixed-header
  ::v-deep
  .v-data-table__wrapper
  tbody
  tr:has(> td > div.itemManageSubNo-one),
#listData.v-data-table--fixed-header
  ::v-deep
  .v-data-table__wrapper
  tbody
  tr
  td:has(> div.itemManageSubNo-one) {
  background-color: #dde5f0;
}

//固定化処理
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .roles-fixed {
  position: sticky !important;
  left: 0 !important;
  z-index: 6 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .product-fixed {
  position: sticky !important;
  left: 0 !important;
  z-index: 6 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .date-fixed {
  position: sticky !important;
  left: 217px !important;
  z-index: 6 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .screen-name-fixed {
  position: sticky !important;
  left: 419px !important;
  z-index: 6 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .ope-hist-fixed {
  position: sticky !important;
  left: 671px !important;
  z-index: 6 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .product-code-fixed {
  position: sticky !important;
  left: 751px !important;
  z-index: 6 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:first-child {
  position: sticky !important;
  left: 0 !important;
  z-index: 5 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:nth-child(2) {
  position: sticky !important;
  left: 217px !important;
  z-index: 5 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:nth-child(3) {
  position: sticky !important;
  left: 419px !important;
  z-index: 5 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:nth-child(4) {
  position: sticky !important;
  left: 671px !important;
  z-index: 5 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:nth-child(5) {
  position: sticky !important;
  left: 751px !important;
  z-index: 5 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper thead tr {
  background: #effad9;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper {
  max-height: 775px;
}

.tableData {
  border-collapse: collapse;
  table-layout: fixed;
}
</style>
