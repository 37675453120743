import { appConfig } from "./AppConfig";
import { messsageUtil } from "./MesssageUtil";
import { httpClient } from "./HttpClient";
import { commonFunction } from "./CommonFunction";

export default class PostParameter {
  /**
   * パラメータ更新処理
   */
  updateParams(body, params) {
    for (const key in params) {
      Object.assign(body[key], params[key]);
    }
  }

  /**
   * キュー管理情報登録処理
   */
  postMstQueInfo(params) {
    const body = httpClient.createRequestBodyConfig();
    this.updateParams(body, params);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .securePost(appConfig.API_URL.BIZ_QUE_INFO, body, appConfig.APP_CONFIG)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            resolve(response);
          } else {
            // エラー時
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameter postMstQueInfo Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }

  /**
   * 在庫一覧照会：データ出力
   */
  postStockSlipFormat(params) {
    const body = httpClient.createRequestBodyConfig();
    this.updateParams(body, params);
    return new Promise((resolve, reject) => {
      httpClient
        // 接続先のAPI_URLを入力
        .securePost(appConfig.API_URL.STOCK_SLIP_FORMAT, body, appConfig.APP_CONFIG)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // 正常時
          if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            // 返却されたurlを共通関数へ呼び出し
            commonFunction.getLink(jsonData.resIdv.filePath);
            resolve(response);
          } else {
            // エラー時
            reject(jsonData.resCom.resComMessage);
          }
        })
        .catch((resolve) => {
          console.error("GetParameter postStockSlipFormat Err", resolve);
          reject(messsageUtil.getMessage("P-999-999_999_E"));
        });
    });
  }
}
export const postParameter = new PostParameter();
