<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_deliveryOrderPlanNotice" />
      <!-- （画面名）異常通知-->
      <v-container fluid>
        <!-- 検索項目-->
        <!-- 営業所-->
        <v-row class="search-row">
          <div class="search-autocomplete first-search-item">
            <v-text-field
              dense
              readonly
              v-model="officeSelected"
              :items="officeList"
              :label="$t('label.lbl_salesOffice')"
              class="txt-single"
              persistent-hint
              :hint="setOffice()"
              margin-bottom="0"
              pa-3
            >
              <template slot="item" slot-scope="data">
                <span class="auto-txt-size"> {{ data.item.text }} </span>
              </template>
            </v-text-field>
          </div>
          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>
        </v-row>
        <v-row>
          <div class="search-row-exeBtn">
            <div class="btn-area" style="float: left">
              <!-- 戻るボタン-->
              <v-btn class="other-btn" v-on:click="backbtn">
                {{ $t("btn.btn_back") }}
              </v-btn>
            </div>
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <div class="btn-area">
              <!-- 確認ボタン-->
              <v-btn class="post-btn" value="1" v-on:click="rcvAchAdd">
                {{ $t("btn.btn_ok") }}
              </v-btn>

              <!-- メッセージダイアログ-->
              <ConfirmDialog
                :isShow.sync="confirmDialog.isOpen"
                :message="confirmDialog.message"
                :screenFlag="confirmDialog.screenFlag"
                :okAction="confirmDialog.okAction"
                :redMessage="confirmDialog.redMessage"
                :changeFlag="confirmDialog.changeFlag"
              />
            </div>
          </div>
        </v-row>
        <v-row>
          <div class="display-search-result-row">
            <!--検索結果表示-->
            <div class="text-label" style="float: left">
              <span class="toatlNum">総件数：{{ totalCount }}件</span>
            </div>
          </div>
        </v-row>
      </v-container>
      <!-- データテーブル（項目） -->
      <v-form ref="editedList" lazy-validation>
        <v-container fluid>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            disable-filtering
            disable-sort
            disable-pagination
            :hide-default-footer="true"
            height="620px"
          >
            <template v-slot:[`header.dataIssueDate`]="{}">
              <!-- データ発行日時 -->
              {{ $t("label.lbl_deta") }}<br />
              <span>発行日時</span>
            </template>

            <template v-slot:[`header.replacementDivision`]="{}">
              <!-- 差換区分 -->
              <span>差替</span><br />
              {{ $t("label.lbl_classification") }}
            </template>

            <template v-slot:[`header.importStatus`]="{}">
              <!-- 対応区分 -->
              <span>対応</span><br />
              {{ $t("label.lbl_classification") }}
            </template>

            <!-- ヘッダーチェックボックス -->
            <template v-slot:[`header.check`]="{ header }">
              <input
                type="checkbox"
                :checked="isAllSelected"
                :value="header.check"
                @click="selectAllCheck"
                style="transform: scale(2)"
              />
            </template>

            <!-- 一覧チェックボックス -->
            <template v-slot:[`item.check`]="{ item }">
              <input
                type="checkbox"
                v-model="item.check"
                :value="listCheckbox"
                style="transform: scale(2)"
              />
            </template>

            <!-- 伝票No. -->
            <template v-slot:[`item.billingSlipNo`]="{ item }">
              <a v-on:click="backlink(item)">{{ item.billingSlipNo }}</a>
            </template>

            <!-- 取込状態 -->
            <template v-slot:[`item.importStatus`]="{ item }">
              <p class="txt-center" style="margin-top: 16px" v-if="item.importStatus == '02'">
                通知
              </p>
              <p
                class="txt-center"
                style="color: red; margin-top: 16px"
                v-if="item.importStatus == '01'"
              >
                異常
              </p>
            </template>
          </v-data-table>
        </v-container>
      </v-form>

      <!-- 確認ボタンのダイアログ -->
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <v-dialog v-model="simpleDialog" persistent="" no-click-animation>
        <SimpleDialog
          :isShow.sync="simpleDialog"
          :title="infoDialog.title"
          :message="infoDialog.message"
          :firstPageFlag="infoDialog.firstPageFlag"
          :homePageFlag="infoDialog.homePageFlag"
          :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
          :entAchSerchFlg="infoDialog.entAchSerchFlg"
        />
      </v-dialog>
    </v-sheet>
  </div>
</template>
<script>
import Loading from "@/components/loading";
import ConfirmDialog from "@/components/ConfirmDialog";
import SimpleDialog from "@/components/SimpleDialog";
import NavBar from "../../components/NavBar.vue";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
// import { getParameter } from "../../assets/scripts/js/GetParameter";
//import { functionAuth } from "../assets/scripts/js/FunctionAuth";
//import { screenAuth } from "../assets/scripts/js/ScreenAuth";
import { i18n } from "../../lang/lang.js";

export default {
  name: "EnteringAchieveAdd",
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    ConfirmDialog,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    checkFlg: false,
    inputLocation: "",
    loadingCounter: 0,
    // 総件数
    totalCount: 0,
    // バリデーションチェックリスト
    editedList: [],
    // 営業所プルダウン
    officeSelected: "",
    officeList: [],
    checkList: [],
    // 確認メッセージ
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },

    openMenu: null,
    // メッセージダイアログ
    dialogMessage: false,
    // 入力チェックエラーフラグ
    errorFlg: true,
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    // 一覧index
    editedIndex: -1,

    // 確認メッセージ
    ConfirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
    },
    simpleDialog: false,
    // メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      homePageFlag: false,
      entAchSerchFlg: false,
      outsideClickNotCloseFlg: false,
    },
    productListIdx: -1,
    // 削除リスト
    deleteList: [],
    // アラート表示
    alertMessage: "",
    // 全選択チェックボックス
    isAllSelected: false,
    // 一覧選択チェックボックス
    listCheckbox: [],
    // ロケ検索
    // locaSearch: "",
    // ヘッダ
    headerItems: [
      { text: "選択", value: "check", width: "2%", align: "center", sortable: false },
      // データ発行日
      {
        text: i18n.tc("label.lbl_dataIssueDate"),
        value: "dataIssueDate",
        width: "5%",
        align: "center",
        sortable: true,
      },
      {
        // ユーザー取引先
        text: i18n.tc("label.lbl_userSupplier"),
        value: "userSupplier",
        width: "6%",
        align: "left",
        sortable: true,
      },
      {
        // 搬入日時
        text: i18n.tc("label.lbl_deliveryDateTime"),
        value: "deliveryDateTime",
        width: "5%",
        align: "center",
        sortable: true,
      },
      {
        // 伝票No
        text: i18n.tc("label.lbl_billingSlipNo"),
        value: "billingSlipNo",
        width: "3% ",
        align: "left",
        sortable: true,
      },
      {
        // 差替区分
        text: i18n.tc("label.lbl_classification"),
        value: "replacementDivision",
        width: "3%",
        align: "left",
        sortable: false,
      },
      {
        // 表題
        text: i18n.tc("label.lbl_noticeTitle"),
        value: "noticeTitle",
        width: "7%",
        align: "left",
        sortable: false,
      },
      {
        // 対応区分
        text: i18n.tc("label.lbl_classification"),
        value: "importStatus",
        width: "4%",
        align: "center",
        sortable: false,
      },
      {
        // 対応内容
        text: i18n.tc("label.lbl_treatmentContents"),
        value: "treatmentContents",
        width: "25%",
        align: "left",
        sortable: false,
      },
    ],
    inputList: [],
  }),

  created() {},

  methods: {
    /**
     * 初期起動：画面を開いた際に渡された値を設定(入荷予定一覧から値受け取る)
     */
    init() {
      this.emergencyNotice();
      // ログインユーザーの営業所SIDを取得
      this.user = sessionStorage.getItem("sales_office_sid");
      this.getCustomInfo();
      this.totalCount = this.$route.params.totalCount;
      // ログインユーザーの営業所SIDを営業所プルダウンにセット
      this.officeSelected = this.user;
      for (let i = 1; i < this.inputList.length; i++) {
        this.inputList[i].check = false;
      }
      console.log(this.$route.params.searchdeliveryDateCal);
    },
    emergencyNotice() {
      // 異常・緊急通知参照
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      // console.debug("getParameter getUserBiz Url", config);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_ANOMALY_NOTICE, config)
          .then((response) => {
            // console.debug("getParameter getUserBiz Response", response, jsonData);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            const list = [];
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              resolve(this.anomalyNumber);
            } else {
              reject(jsonData.resCom.resComMessage);
            }
            jsonData.resIdv.deliveryInstructions.forEach((row) => {
              list.push({
                dataIssueDate: row.dataCreateDate, // データ発行日
                userClientSid: row.userClientSid, // 取引先SID
                userClientCd: row.userClientCd, // 取引先コード
                userSupplier: row.userClientNm, // 取引先名
                deliveryDateTime: row.deliveryDatetime, // 納入日時
                billingSlipNo: row.outListNo, // 伝票No.
                replacementDivision: row.replacementDiv, // 差替区分
                autoNo: row.autoNo, // 自動連番
                noticeTitle: row.title, // 表題
                treatmentContents: row.contents, // 処置内容
                importStatus: row.capturingState, // 取込状態
                officeSid: row.officeSid, // 営業所SID
                updateDatetime: row.updateDatetime, // 更新日時
              });
            });
            this.inputList = list;
          })
          .catch((ex) => {
            console.error("GetParameter getUserBiz Err", ex);
            reject(messsageUtil.getMessage("P-999-999_999_E"));
          });
      });
    },
    /**
     * 営業所リストボックス取得処理
     */
    setOffice() {
      for (var i = 0; i < this.officeList.length; i++) {
        if (this.officeList[i].value == this.officeSelected) {
          return this.officeList[i].name;
        }
      }
    },

    /**
     * 営業所プルダウン
     */
    //
    getCustomInfo() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const config = this.$httpClient.createGetApiRequestConfig();

      // ★必須パラメータ
      config.params.isMst = "1";
      config.params.relatedPartyDiv = "02";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_RELATED, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("getCustomInfo() Response", response);
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              let list = [{ text: "", name: "", value: "" }];
              jsonData.resIdv.relatedPartys.forEach((row) => {
                if (row.relatedPartyLanguage.length > 0) {
                  list.push({
                    text: row.relatedPartyLanguage[0].relatedPartyName ?? "",
                    value: row.relatedPartyLanguage[0].relatedPartySid ?? "",
                    name: row.relatedPartyLanguage[0].relatedPartyName ?? "",
                  });
                }
              });
              this.officeList = list;
              let office = this.officeList.filter((Element) => {
                // ログインユーザーの営業所SIDと一致するAPIのレスポンスデータを取得する
                return Element.value === sessionStorage.getItem("sales_office_sid");
              });
              this.officeSelected = office.length > 0 ? office[0].text : "";

              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * 確認ボタン処理
     */
    updateOk() {
      this.updateDialogMessage = false;
    },
    /**
     * 納入指示一覧画面へ渡す値を設定
     */
    backbtn() {
      if (this.errorFlg) {
        this.updateDialogMessage = false;
        // 画面遷移判断フラグ
        this.checkFlg = true;
        this.$router.push({
          name: appConfig.MENU_ID.P_SUP_DELIVERY_ORDER_PLAN_LIST,
          params: {
            // 取引先SID
            searchSuppliersSelected: this.searchSuppliersSelected,
            transitionFlg: true,
            searchuserMakerSected: this.$route.params.searchuserMakerSected,
            searchimportStatus: this.$route.params.searchimportStatus,
            searchuserSuppliersSelected: this.$route.params.searchuserSuppliersSelected,

            searchClientCd: this.$route.params.searchClientCd,
            searchMakerClientCd: this.$route.params.searchMakerClientCd,
            searchselectHintClient: this.$route.params.searchselectHintClient,
            searchsupplierList: this.$route.params.searchsupplierList,
            searchSuppliersList: this.$route.params.searchSuppliersList,
            searchclientSelected: this.$route.params.searchclientSelected,

            searchdestinationyPlant: this.$route.params.searchdestinationyPlant,
            searchheaderDeliveryDestinationy: this.$route.params.searchheaderDeliveryDestinationy,
            searchheaderDeliveryPlan: this.$route.params.searchheaderDeliveryPlan,
            searchdeliveryDate: this.$route.params.searchdeliveryDate,
            searchdeliveryDateCal: this.$route.params.searchdeliveryDateCal,
            searchdeliveryTimeFrom: this.$route.params.searchdeliveryTimeFrom,
            searchdeliveryTimeTo: this.$route.params.searchdeliveryTimeTo,
            searchshipSlipNo: this.$route.params.searchshipSlipNo,
            searchdataIssueDateFrom: this.$route.params.searchdataIssueDateFrom,
            searchdataIssueDateFromCal: this.$route.params.searchdataIssueDateFromCal,
            searchdataIssueDateTo: this.$route.params.searchdataIssueDateTo,
            searchdataIssueDateToCal: this.$route.params.searchdataIssueDateToCal,
            searchIssuedDiff: this.$route.params.searchIssuedDiff,
            searchuserItemCd: this.$route.params.searchuserItemCd,
            searchproductHint: this.$route.params.searchproductHint,
            searchuserProductCdList: this.$route.params.searchuserProductCdList,
            searchuserProductCd: this.$route.params.searchuserProductCd,
            searchDestinationyPlantList: this.$route.params.searchDestinationyPlantList,
            searchautoUpdateFlag: this.$route.params.searchautoUpdateFlag,
            searchautoUpdateIntervalMin: this.$route.params.searchautoUpdateIntervalMin,
            searchInputListFlag: this.$route.params.searchInputListFlag,
            searchItemsPerPageBtn: this.$route.params.searchItemsPerPageBtn,
            btnid: this.$route.params.btnid,
            // 画面遷移フラグ
            checkFlg: this.checkFlg,
            // linkKbn: false,
          },
        });
      } else {
        this.updateDialogMessage = false;
        this.errorFlg = true;
      }
    },

    /**
     * link押下時
     */
    backlink(item) {
      var deliveryDatetime = item.deliveryDateTime;
      console.log(deliveryDatetime);
      deliveryDatetime = deliveryDatetime.substr(0, 10);
      this.$router.push({
        name: appConfig.MENU_ID.P_SUP_DELIVERY_ORDER_PLAN_LIST,
        params: {
          transitionFlg: true,
          linkKbn: true,
          searchshipSlipNo: item.billingSlipNo,
          userClientSid: item.userClientSid,
          searchdeliveryDate: deliveryDatetime,
          searchdeliveryDateCal: deliveryDatetime.replaceAll("/", "-"),
        },
      });
    },

    /**
     * 確認ボタン押下時の処理（メッセージ)
     */
    rcvAchAdd() {
      let listCheck =
        this.inputList.filter((element) => {
          return element.check === false || element.check === void 0;
        }).length === this.inputList.length;

      if (listCheck == true) {
        this.infoDialog.firstPageFlag = true;
        this.updateDialogMessage = true;
        this.infoDialog.outsideClickNotCloseFlg = false;
        this.backMessage = messsageUtil.getMessage("P-EXT-003_001_E");
      } else {
        this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
          i18n.tc("btn.btn_ok"),
        ]);
        this.confirmDialog.isOpen = true;
        this.confirmDialog.screenFlag = true;
        this.confirmDialog.changeFlag = false;
        this.confirmDialog.okAction = this.checkOkClick;
      }
    },

    /**
     * 確認ボタン押下処理
     */
    checkOkClick() {
      // 初期表示の設定
      let checkArray = [];

      this.checkList = this.inputList.filter((element) => {
        return element.check === true;
      });
      for (let i = 0; i < this.checkList.length; i++) {
        let item = this.checkList[i];
        checkArray.push({
          officeSid: item.officeSid,
          userClientSid: item.userClientSid,
          outListNo: item.billingSlipNo,
          replacementDiv: item.replacementDivision,
          capturingState: item.importStatus,
          autoNo: item.autoNo,
          updateDatetime: item.updateDatetime,
        });
      }

      const config = this.$httpClient.createRequestBodyConfig();
      config.reqIdv.enDtl = checkArray;
      config.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SUP_004;
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_ANOMALY_UPDATE, config, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              this.infoDialog.message = messsageUtil.getMessage("P-COM-001_005_C", [
                i18n.tc("btn.btn_ok"),
              ]);
              this.infoDialog.title = "結果";
              this.simpleDialog = true;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;

              this.inputList = this.inputList.filter((element) => {
                return element.check === false || element.check === void 0;
              });
              this.totalCount = this.totalCount - checkArray.length;
              if (0 > this.totalCount) {
                this.totalCount = "0";
              }
              this.isAllSelected = false;
            } else {
              reject(jsonData.resCom.resComMessage);
            }
          })
          .catch((ex) => {
            console.error("GetParameter getUserBiz Err", ex);
            reject(messsageUtil.getMessage("A-999-999_001_E"));
          });
      });
    },
    /**
     * チェックボックス押下処理
     */
    checkList(item) {
      if (!item.check) {
        // チェックを入れた場合
        this.listCheckbox.push(item);
      } else {
        // チェックを外した場合
        this.listCheckbox.splice(this.listCheckbox.indexOf(item), 1);
      }
    },
    /**
     * 全選択チェックボックス処理
     */
    selectAllCheck() {
      if (!this.isAllSelected) {
        this.listCheckbox = [];
        this.listCheckbox = true;
        this.isAllSelected = true;
        for (var i = 0; i < this.inputList.length; i++) {
          // if (this.inputList[i].deleteFlg === "0") {
          this.inputList[i].check = true;
        }
        // }
      } else {
        this.listCheckbox = [];
        this.listCheckbox = false;
        for (var j = 0; j < this.inputList.length; j++) {
          // if (this.inputList[j].deleteFlg === "0") {
          this.inputList[j].check = false;
        }
        // }
        this.isAllSelected = false;
      }
    },
  },

  computed: {},
  watch: {},

  mounted() {
    this.init();
  },
};
</script>

<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
//画面優先順位

.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
  pointer-events: none;
}

//新製品登録時、色変更
.isD {
  background-color: $color-warning !important;
  //background-color: $color-error !important;
}
.padding-bottom {
  margin-bottom: 20px;
}
.menu-btn {
  margin: 0.1rem 0.2rem;
  // font-size: $menu-fontsize !important;
  font-weight: bold;
}
.table-warehouse {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

::v-deep #btn-logout {
  img {
    width: 20px;
    height: 20px;
  }
}
#lbl-user-name {
  // font-size: $menu-fontsize !important;
  font-weight: bold;
  &:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url("../../assets/img/user_menu_icon.png");
    background-size: contain;
    vertical-align: middle;
  }
  .disabled {
    pointer-events: none;
  }
}
</style>
