<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-container fluid id="sub-bar">
      <v-col>
        <div id="lbl-screen-name">{{ $t("label.lbl_shipmentQuantityAdd") }}</div>
      </v-col>
    </v-container>
    <v-container fluid>
      <v-row class="search-row">
        <div class="search-autocomplete first-search-item">
          <v-text-field
            dense
            readonly
            v-model="deliveryDate"
            :label="$t('label.lbl_deliveryDate')"
            class="txt-single"
            persistent-hint
            margin-bottom="0"
            pa-3
          ></v-text-field>
        </div>
        <!-- 空白 -->
        <span class="item-spacer">&nbsp;</span>
        <div class="search-autocomplete">
          <v-text-field
            dense
            readonly
            v-model="deliveryTime"
            :label="$t('label.lbl_deliveryTime')"
            class="txt-single"
            persistent-hint
            margin-bottom="0"
            pa-3
          ></v-text-field>
        </div>
        <!-- 空白 -->
        <span class="item-spacer">&nbsp;</span>
        <div class="search-autocomplete">
          <v-text-field
            dense
            readonly
            v-model="shipmentTotalNumber"
            :label="$t('label.lbl_shipmentTotalNumber')"
            class="input-number-modal"
            persistent-hint
            margin-bottom="0"
            pa-3
          ></v-text-field>
        </div>
      </v-row>
      <v-row>
        <div class="search-row-exeBtn">
          <!--ボタン領域-->
          <div class="btn-area" style="float: left">
            <!-- 行追加ボタン-->
            <v-btn id="btn-pack" class="post-btn" @click="getAdd">
              {{ $t("btn.btn_rowsAdd") }}
            </v-btn>
          </div>
          <div class="btn-area">
            <!--閉じるボタン-->
            <v-btn class="post-btn" @click="close()">{{ $t("btn.btn_close") }}</v-btn>
            <!--登録ボタン-->
            <v-btn class="post-btn" @click="insert()">{{ $t("btn.btn_insert") }}</v-btn>
          </div>
        </div>
      </v-row>
    </v-container>
    <!-- データテーブル -->
    <v-container fluid>
      <v-data-table
        id="listData"
        :headers="headerItems"
        :items="inputList"
        :fixed-header="true"
        disable-sort
        disable-pagination
        :hide-default-footer="true"
        height="500px"
      >
        <template v-slot:[`item.shipSlipNo`]="{ item }">
          <div v-if="item.displayFlg" id="table-detail1">
            <v-text-field
              class="text-box"
              dense
              outlined
              maxlength="50"
              v-model="item.shipSlipNo"
              clear-icon="mdi-close-circle"
              clearable
            />
          </div>
          <div v-else>
            {{ item.shipSlipNo }}
          </div>
        </template>
        <template v-slot:[`item.shipmentQuantity`]="{ item, index }">
          <div v-if="item.displayFlg" id="table-detail1">
            <v-text-field
              class="input-number"
              dense
              outlined
              maxlength="9"
              v-model="item.shipmentQuantity"
              clear-icon="mdi-close-circle"
              clearable
              @change="changecount(item, index)"
              @click="clickPop(index)"
            />
          </div>
          <div v-else style="text-align: right">
            {{ item.shipmentQuantity }}
          </div>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import { i18n } from "../../lang/lang.js";
import Loading from "@/components/loading";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";

export default {
  name: "stkInquiryDetails",
  components: {
    Loading,
  },
  props: {
    entity: { type: Object, default: null },
    detailDialogData: {},
    hoge1: {
      default: "",
    },
    isOpenDetailDialog: { type: Boolean, default: false },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 取引先
    suppliersSelected: "",
    itemName: "",
    itemCd: "",
    qualityDiv: "",
    itemStandardSid: "",
    supplierList: [],
    deliveryDate: "",
    deliveryTime: "",
    shipmentTotalNumber: 0,
    stockList: [],
    headerItems: [
      // 出荷伝票No.
      {
        text: i18n.tc("label.lbl_shipSlipNo"),
        width: "150px",
        value: "shipSlipNo",
        sortable: false,
        align: "center",
      },
      // 出荷数量
      {
        text: i18n.tc("label.lbl_shipmentQuantity"),
        value: "shipmentQuantity",
        width: "150px",
        sortable: false,
        align: "center",
      },
      // 登録方法
      {
        text: i18n.tc("label.lbl_howtoRegister"),
        value: "howtoRegister",
        width: "100px",
        align: "center",
        sortable: false,
      },
    ],
    // 仮データ
    inputList: [],
    dummy_input: {
      shipSlipNo: "3044021401580",
      shipmentQuantity: "1,000",
      howtoRegister: "自動",
      displayFlg: false,
    },
    dummy_input1: {
      shipSlipNo: "3044025601801",
      shipmentQuantity: "300",
      howtoRegister: "CSV",
      displayFlg: false,
    },
    defaultItem: {
      shipSlipNo: "",
      shipmentQuantity: "",
      howtoRegister: "手入力",
      displayFlg: true,
    },
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
    },
  }),
  methods: {
    /**
     * 初期起動：画面を開いた際に渡された値を設定します。
     */
    init() {
      this.inputList = [];
      //搬入日
      this.deliveryDate = this.entity.deliveryDate;
      //搬入時刻
      this.deliveryTime = this.entity.deliveryTime;
      //出荷総数
      this.shipmentTotalNumber = this.entity.shipmentQuantity;
      // this.itemCd = this.entity.itemCd;
      // this.itemName = this.entity.itemName;
      // this.itemStandardSid = this.entity.itemStandardSid;
      this.inputList = this.entity.detailList;
      // this.inputList.push(Object.assign({}, this.dummy_input1));
    },

    //ダイアログを閉じる
    close() {
      this.$emit("closePopup");
      this.$emit("update:isOpenDetailDialog", false);
      this.inputList = [];
    },
    insert() {
      //出荷総数
      this.entity.shipmentQuantity = this.shipmentTotalNumber;
      //リスト返却
      this.entity.detailList = this.inputList;
      this.$emit("insertPopup");
      this.$emit("update:isOpenDetailDialog", false);
      this.inputList = [];
    },
    //入力行追加
    getAdd() {
      //行追加
      this.inputList.push(Object.assign({}, this.defaultItem));
    },
    changecount(val, index) {
      var value = commonFunction.getDelimiter(val.shipmentQuantity, 7);
      this.inputList[index].shipmentQuantity = value == 0 ? "" : value;
      var totalCount = 0;
      for (let i = 0; i < this.inputList.length; i++) {
        totalCount += Number(this.inputList[i].shipmentQuantity.replace(/[^0-9]/gi, ""));
      }
      this.shipmentTotalNumber = commonFunction.getDelimiter(totalCount, 9);
    },
    clickPop(val) {
      var number = this.inputList[val].shipmentQuantity;
      if (number != null && number.length > 3) {
        number = number.replaceAll(",", "");
      }
      this.inputList[val].shipmentQuantity = number;
    },
  },
  watch: {
    entity: {
      handler(newValue, oldValue) {
        console.info("handler", newValue, oldValue);
      },
    },
    // eslint-disable-next-line no-unused-vars
    isOpenDetailDialog: function (newValue) {
      if (newValue) {
        this.init();
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
#sub-bar {
  padding: none;
  height: 3rem;
}

.text-overflow {
  // v-tooltip
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#listData.v-data-table ::v-deep table {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>
