<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <!-- 新規登録時：displayFlg==1 -->
      <NavBar v-if="displayFlg == '1'" tittle="label.lbl_relatedPartiesAdd" />
      <!-- 修正時：displayFlg == 2 -->
      <NavBar v-if="displayFlg == '2'" tittle="label.lbl_relatedPartiesUpdate" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <!-- １行目スタート -->
          <v-row class="search-row">
            <!-- 関係先区分 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="relatedPartiesKbn"
                :items="relatedPartiesKbnList"
                :label="$t('label.lbl_relatedPartiesKbn')"
                :rules="[rules.inputRequired]"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                :readonly="searchFlg"
                pa-3
              >
                <template slot="item" slot-scope="data" class="nal">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <span class="item-spacer">&nbsp;</span>
            <!-- 顧客区分 -->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="clientKbn"
                :items="clientKbnList"
                :label="$t('label.lbl_clientKbn')"
                :hint="setSrhClient()"
                @change="changeClientKbn(clientKbn)"
                :rules="[rules.inputRequired]"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                pa-3
                :disabled="relatedPartiesKbn != '01'"
                :readonly="searchFlg"
              >
                <template slot="item" slot-scope="data" class="nal">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <span class="item-spacer">&nbsp;</span>
            <!-- 取引先 -->
            <div class="search-autocomplete" v-if="clientKbn == '02'">
              <v-autocomplete
                dense
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="setSrhSupplier()"
                :rules="[rules.inputRequired]"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                pa-3
                :readonly="searchFlg"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data" class="nal">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require" v-if="clientKbn == '02'">*</span>
            <span class="item-spacer" v-if="clientKbn == '02'">&nbsp;</span>

            <!-- コード -->
            <div class="serch-textbox-arrivalNo" v-if="!searchFlg">
              <v-text-field
                outlined
                dense
                v-model="clientBaseCode"
                :maxlength="clientBaselength"
                :label="clientBaseName"
                :rules="[rules.inputRequired]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :disabled="!clientKbn"
              />
            </div>
            <div class="serch-textbox-arrivalNo" v-else>
              <v-text-field
                outlined
                dense
                v-model="clientBaseCode"
                :maxlength="clientBaselength"
                :label="clientBaseName"
                class="txt-single"
                readonly
              />
            </div>
            <span class="require asterisk-spacer">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
          </v-row>
          <v-row v-if="displayFlg == '1' || !insertflg">
            <div class="search-row-exeBtn">
              <!--ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!--検索ボタン-->
                <v-btn class="get-btn" :disabled="searchFlg" @click="btnSearch()">
                  {{ $t("btn.btn_search") }}</v-btn
                >
                <!--画面クリアボタン-->
                <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>
            </div>
          </v-row>
          <!-- ここから二行目 -->
        </v-container>
      </v-form>
      <v-form ref="detailForm" lazy-validation>
        <v-container fluid>
          <v-row class="search-row">
            <!-- 名称 -->
            <div class="search-textbox-remark first-search-item">
              <v-text-field
                outlined
                dense
                v-model="clientBaseNm"
                maxlength="100"
                :label="name + $t('label.lbl_name')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :rules="[rules.inputRequired]"
                :disabled="!searchFlg"
              />
            </div>
            <span class="require asterisk-spacer">*</span>
          </v-row>
          <!-- 三行目 -->
          <v-row>
            <!-- カナ -->
            <div class="search-textbox-remark first-search-item">
              <v-text-field
                outlined
                dense
                v-model="clientBaseNmKana"
                maxlength="100"
                :label="name + $t('label.lbl_itemNameKanat')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :disabled="!searchFlg"
              />
            </div>
          </v-row>
          <!-- ４行目 -->
          <v-row>
            <!-- 略称 -->
            <div class="search-textbox-remark first-search-item">
              <v-text-field
                outlined
                dense
                v-model="clientBaseNmShort"
                maxlength="30"
                :label="name + $t('label.lbl_itemNameShort')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                :rules="[rules.inputRequired]"
                clearable
                :disabled="!searchFlg"
              />
            </div>
            <span class="require asterisk-spacer">*</span>
          </v-row>
          <!-- 6行目 -->
          <v-row>
            <!-- 郵便番号 -->
            <div class="serch-textbox-arrivalNo first-search-item">
              <v-text-field
                outlined
                dense
                v-model="postCd"
                maxlength="7"
                :label="$t('label.lbl_postCd')"
                :rules="[rules.inputRequired, rules.isNumber]"
                @input="changePostCd()"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :disabled="!searchFlg"
              />
            </div>
            <span class="require asterisk-spacer">*</span>
          </v-row>
          <!-- 7行目 -->
          <v-row style="margin-bottom: 10px">
            <!-- 住所 -->
            <div class="search-textbox-remark first-search-item">
              <v-autocomplete
                dense
                v-model="address"
                :items="addressList"
                :label="$t('label.lbl_popAddress')"
                :rules="[rules.inputRequired]"
                class="txt-single"
                @change="changeAddress()"
                :hint="setSrhAddress()"
                persistent-hint
                margin-bottom="0"
                pa-3
                :disabled="!searchFlg"
              >
                <template slot="item" slot-scope="data" class="nal">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>
          </v-row>
          <!-- 7行目 -->
          <v-row>
            <!-- 住所詳細 -->
            <div class="search-textbox-remark first-search-item">
              <v-text-field
                outlined
                dense
                v-model="otherAddress"
                maxlength="400"
                :label="$t('label.lbl_popAddress') + $t('label.lbl_Detail') + '(番地以降)'"
                class="txt-single"
                clear-icon="mdi-close-circle"
                :rules="[rules.inputRequired]"
                clearable
                :disabled="!searchFlg"
              />
            </div>
            <span class="require asterisk-spacer">*</span>
          </v-row>
          <!-- 8行目 -->
          <v-row>
            <!-- 電話番号 -->
            <div class="serch-textbox-arrivalNo first-search-item">
              <v-text-field
                outlined
                dense
                v-model="telNo"
                maxlength="20"
                :label="$t('label.lbl_telephone')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                :rules="[rules.isNumberHyphen]"
                clearable
                :disabled="!searchFlg"
              />
            </div>
            <span class="item-spacer">&nbsp;</span>
            <!-- FAX番号 -->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="faxNo"
                maxlength="20"
                :label="$t('label.lbl_faxNumber2')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                :rules="[rules.isNumberHyphen]"
                clearable
                :disabled="!searchFlg"
              />
            </div>
          </v-row>
          <!-- ５行目 -->
          <!-- 有効開始日時-->
          <v-row>
            <div class="date-style first-search-item">
              <v-text-field
                outlined
                dense
                v-model="validFrom"
                class="txt-single date-style"
                :label="$t('label.lbl_validStartDateAndTime')"
                @change="changeDateFrom"
                :rules="[rules.inputRequired, rules.yyyymmdd]"
                clear-icon="mdi-close-circle"
                maxlength="10"
                clearable
                :disabled="!searchFlg || displayFlg != '1'"
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="validFromMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    :disabled="!searchFlg || displayFlg != '1'"
                    v-on="on"
                    text
                    x-small
                  >
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="validFromCal" @input="validFromMenu = false" />
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                :disabled="!searchFlg || displayFlg != '1'"
                @click="prevDate"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                :disabled="!searchFlg || displayFlg != '1'"
                @click="nextDate"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- カレンダーの間 -->
            <div class="from_to">～</div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 有効終了日時 -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="validTo"
                class="txt-single date-style"
                :label="$t('label.lbl_validEndDateAndTime')"
                @change="changeDateTo"
                :rules="[
                  rules.inputRequired,
                  rules.yyyymmdd,
                  rules.inputToTimeCheck(validTo, validFrom),
                ]"
                clear-icon="mdi-close-circle"
                maxlength="10"
                clearable
                :disabled="!searchFlg || validToFlg"
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="validToMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" :disabled="!searchFlg || validToFlg" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="validToCal" @input="validToMenu = false" />
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                :disabled="!searchFlg || validToFlg"
                @click="prevToDate"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                :disabled="!searchFlg || validToFlg"
                @click="nextToDate"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-row>
          <!-- 9行目 -->
          <v-row>
            <div class="serch-textbox-arrivalNo first-search-item" style="float: left">
              <v-text-field
                outlined
                dense
                v-model="truckNm"
                maxlength="50"
                :label="$t('label.lbl_shippingCompanyName')"
                class="txt-single"
                readonly
                clearable
                clear-icon="mdi-close-circle"
                :rules="[rules.inputRequired]"
                :disabled="!searchFlg"
              />
            </div>

            <span class="require asterisk-spacer" style="float: left">*</span>

            <!-- 輸送会社選択ボタン -->
            <div style="float: left">
              <v-btn color="primary" class="api-btn" v-on:click="openTruck" :disabled="!searchFlg">
                {{ $t("btn.btn_addShpAdress") }}
              </v-btn>
            </div>
          </v-row>
          <v-row>
            <div class="search-row-exeBtn">
              <!--ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 戻るボタン-->
                <v-btn color="primary" class="api-btn" @click="backPage">
                  {{ $t("btn.btn_back") }}
                </v-btn>
                <!-- 登録ボタン-->
                <v-btn
                  v-if="displayFlg == '1'"
                  color="primary"
                  id="btn-pack"
                  :disabled="!searchFlg"
                  class="api-btn"
                  @click="insert()"
                >
                  {{ $t("btn.btn_insert") }}
                </v-btn>
                <v-btn
                  v-if="displayFlg == '2'"
                  color="primary"
                  id="btn-pack"
                  :disabled="!searchFlg"
                  class="api-btn"
                  @click="insert()"
                >
                  {{ $t("btn.btn_fix") }}
                </v-btn>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <!-- 戻るボタンを押下時出現ダイアログ -->
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
            <v-btn @click="updateNg"> {{ $t("btn.btn_cancel") }} </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- 登録完了dialog -->
      <v-form ref="formAdd" lazy-validation>
        <v-dialog
          v-model="registrationDialogMessage"
          :max-width="800"
          persistent
          no-click-animation
        >
          <v-card>
            <v-card-title class="blue-grey lighten-3" primary-title>
              {{ $t("label.lbl_result") }}
            </v-card-title>
            <v-card-text class="pa-4">
              <p style="margin-bottom: 0px; color: #000000">{{ insertMessage1 }}</p>
              <p style="margin-bottom: 0px; color: #000000">{{ insertMessage3 }}</p>
              <p style="margin-bottom: 0px; color: #000000; margin-left: 10px">
                {{ insertMessage4 }}
              </p>
              <p style="margin-bottom: 0px; color: #000000">{{ insertMessage5 }}</p>
              <p style="margin-bottom: 0px; color: #000000; margin-left: 10px">
                {{ insertMessage6 }}
              </p>
              <p style="color: #ff0000">{{ insertMessage2 }}</p>
              <v-row style="margin-top: 5px">
                <div class="serch-textbox-itemCnCd first-search-item">
                  <v-autocomplete
                    dense
                    v-model="suppliersSelectedSecond"
                    :items="supplierList"
                    :label="$t('label.lbl_supplier')"
                    :rules="[rules.inputRequired]"
                    class="txt-single"
                    persistent-hint
                    margin-bottom="0"
                    pa-3
                  >
                    <!-- アイテム一覧の表示 -->
                    <template slot="item" slot-scope="data" class="nal">
                      <span class="test">
                        {{ data.item.text }}
                      </span>
                    </template>
                    <!-- * -->
                  </v-autocomplete>
                </div>
                <div class="btn-search-area" style="float: left">
                  <!-- 連続登録ボタン-->
                  <v-btn color="primary" class="api-btn" @click="continuousAdd">
                    {{ $t("btn.btn_continuousAdd") }}
                  </v-btn>
                </div>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="movePage('M_HOM')">{{ $t("menu.P-MENU-000") }}</v-btn>
              <v-btn @click="movePage('P_MST_018')">{{ $t("label.lbl_relatedPartiesList") }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :okAction="postApi"
        :redMessage="ConfirmDialog.redMessage"
        :screenFlag="ConfirmDialog.screenFlag"
        :changeFlag="ConfirmDialog.changeFlag"
      />
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :okAction="postApi"
        :firstPageFlag="infoDialog.firstPageFlag"
        :relatedMstFlg="infoDialog.relatedMstFlg"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
      />
      <v-dialog v-model="isOpenTruck" :max-width="800">
        <Truck
          :isOpenDetailDialog.sync="isOpenTruck"
          @click="saveTruck"
          :truckInfo="truckInfo"
          ref="truck"
        />
      </v-dialog>
      <CheckDialog
        :isShow.sync="checkDialog.isOpen"
        :message="checkDialog.message"
        :screenFlag="checkDialog.screenFlag"
        :okAction="checkDialog.okAction"
        :redMessage="checkDialog.redMessage"
        :changeFlag="checkDialog.changeFlag"
      />
    </v-sheet>
  </div>
</template>

<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import Truck from "../../views/common/Truck";
import ConfirmDialog from "../../components/ConfirmDialog";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { i18n } from "../../lang/lang.js";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import CheckDialog from "@/components/ConfirmDialog";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";

export default {
  name: appConfig.SCREEN_ID.P_MST_017,
  components: {
    ConfirmDialog,
    Loading,
    SimpleDialog,
    sideMenu,
    CheckDialog,
    NavBar,
    Truck,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  mixins: [commonMixin, commonRules],
  data: () => ({
    //関係先区分
    relatedPartiesKbn: "01",
    relatedPartiesKbnList: [],
    //顧客区分
    clientKbn: "",
    clientKbnList: [],
    //取引先
    suppliersSelected: "",
    suppliersSelectedSecond: "",
    supplierList: [],
    //取引先・拠点コード
    clientBaseCode: "",
    clientBaseName: i18n.tc("label.lbl_supplier") + "/" + i18n.tc("label.lbl_baseCd"),
    clientBaselength: "0",
    //名称
    name: "",
    clientBaseNm: "",
    //カナ
    clientBaseNmKana: "",
    //略称
    clientBaseNmShort: "",
    //有効開始日
    validFrom: "",
    validFromCal: "",
    validFromMenu: false,
    //有効終了日
    validTo: "",
    validToCal: "",
    validToMenu: false,
    validToFlg: false,
    searchFlg: false,
    //郵便番号
    postCd: "",
    updateflg: false,
    //住所
    address: "",
    addressList: [],
    insertflg: false,
    //その他住所
    otherAddress: "",
    //電話番号
    telNo: "",
    //FAX番号
    faxNo: "",
    //輸送会社区分
    truckNm: "",
    truckKbn: "",
    truck: "",
    //更新日時
    updateDatetime: null,
    //関係先SID
    relatedPartySid: "",
    registrationDialogMessage: false,
    insertMessage1: "",
    insertMessage2: "",
    insertMessage3: "",
    insertMessage4: "",
    insertMessage5: "",
    insertMessage6: "",
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // インデックス番号
    index: 0,
    // メニュー
    openMenu: null,
    // ロケーションGr登録ダイアログ
    dialogNewLocationGr: false,
    popNewLocation: "",
    // ロケを選択したインデックス
    newLocationSelectedIndex: 0,
    // 詳細画面ポップアップ
    openDatailsAdd: "",
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // チェックボタンチェック後ダイアログ
    checkDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    locationGroupName: "",
    locationGroup: "",
    // 編集制御フラグ
    disableEdit: false,
    // 表示切替(1:登録画面 2:修正・削除画面)
    displayFlg: "1",
    inputList: [],
    // 更新リスト
    updateList: [],
    // 初期データ
    defaultData: [],
    // 配送先情報画面ダイアログ
    isOpenTruck: false,
    //輸送会社dialog
    truckInfo: {
      suppliersSelected: "",
      officeSid: "",
      truckInfo: "",
    },
    checkDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    rules: {
      inputToTimeCheck: (value1, value2) =>
        !value2 ||
        !value1 ||
        !(
          dateTimeHelper.validDate(commonUtil.zen2han(value2), "/") && dateTimeHelper.isDate(value2)
        ) ||
        new Date(value1).getTime() >= new Date(value2).getTime() ||
        i18n.tc("check.chk_toDate"),
    },
  }),
  methods: {
    // 初期化
    init() {
      this.loadingCounter = 1;
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);
      //取引先、コードマスタ取得
      this.getCodeMst();
      if (this.$route.params.name == appConfig.SCREEN_ID.P_MST_018) {
        this.displayFlg = this.$route.params.displayFlg;
        if (this.$route.params.displayFlg == "2") {
          this.searchFlg = true;
          this.insertflg = true;
        }
      }
      //期限開始日
      this.validFrom = dateTimeHelper.convertJST().substr(0, 10);
      this.validFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      //期限終了日
      this.validTo = dateTimeHelper.convertUTC2JST("2099/12/31").substr(0, 10);
      this.validToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertUTC2JST("2099/12/31"));
    },

    //修正画面初期表示検索
    search() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const config = this.$httpClient.createGetApiRequestConfig();
      //検索区分
      config.params.serachKbn = "01";
      //関係先SID
      config.params.relatedPartySid = this.$route.params.relatedPartySid;
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_RELATED_PARTY_ADD, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const row = jsonData.resIdv.relatedPartys;
              if (row != null) {
                if (row.baseCd != "") {
                  this.clientBaseCode = row.baseCd;
                  let client = this.supplierList.find(
                    (element) => element.clientSid === row.compSid
                  );
                  this.suppliersSelected = client.value; // 取引先
                } else {
                  this.clientBaseCode = row.clientCd;
                }
                //名称
                this.clientBaseNm = row.relatedPartyName;
                //名称カナ
                this.clientBaseNmKana = row.relatedPartyNameKana;
                //略称
                this.clientBaseNmShort = row.relatedPartyNameShort;
                //電話番号
                this.telNo = row.telephoneNumber;
                //FAX番号
                this.faxNo = row.faxNumber;
                //郵便番号
                this.postCd = row.postCd;
                if (row.postCd != "") {
                  //郵便番号SID
                  this.address = row.postSid;
                  //住所list
                  this.addressList.push({
                    value: row.postSid,
                    text: row.postCd + "：" + row.address,
                    address: row.address,
                    postCd: row.postCd,
                  });
                }
                //住所詳細
                this.otherAddress = row.addressDetail.replace(
                  row.address != null ? row.address : "",
                  ""
                );
                //有効開始日時
                this.validFrom = dateTimeHelper.convertUTC2JST(row.validFrom).substr(0, 10);
                this.validFromCal = dateTimeHelper.dateCalc(
                  dateTimeHelper.convertUTC2JST(row.validFrom)
                );
                //有効終了日時
                this.validTo = dateTimeHelper.convertUTC2JST(row.validTo).substr(0, 10);
                this.validToCal = dateTimeHelper.dateCalc(
                  dateTimeHelper.convertUTC2JST(row.validTo)
                );
                //路線業者SID
                this.truck = row.deliveryTraderSid;
                //輸送業者コード
                this.truckKbn = row.carrierCompCd;
                if (row.deliveryTraderName != "") {
                  this.truckNm = row.deliveryTraderName;
                } else {
                  this.truckNm = row.carrierCompCdName;
                }
                this.updateDatetime = row.updateDatetime;
                this.relatedPartySid = row.relatedPartySid;

                //日付チェック
                const validTocheck = new Date(this.validTo + " 23:59:59");
                if (validTocheck < new Date()) {
                  this.validToFlg = true;
                }
              }
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },

    /**
     * 顧客区分リストボックス取得処理
     */
    setSrhClient() {
      for (var i = 0; i < this.clientKbnList.length; i++) {
        if (this.clientKbnList[i].value == this.clientKbn) {
          this.name = this.clientKbnList[i].text;
        }
      }
    },

    changeAddress() {
      for (var i = 0; i < this.addressList.length; i++) {
        if (this.addressList[i].value == this.address) {
          this.postCd = this.addressList[i].postCd;
          this.changePostCd();
          return;
        }
      }
    },

    setSrhAddress() {
      for (var i = 0; i < this.addressList.length; i++) {
        if (this.addressList[i].value == this.address) {
          return this.addressList[i].address;
        }
      }
    },

    // コードマスタ
    getCodeMst() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 関係先区分
      const relatedPartiesKbnCode = getParameter.getCodeMst(appConfig.CODETYPE.RELATED_PARTY_DIV);
      // 顧客区分
      const clientKbnCode = getParameter.getCodeMst(appConfig.CODETYPE.CUSTOMER_DIV);
      // 取引先
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(null)
      );

      //輸送会社区分
      const outCarrierComp = getParameter.getCodeMst(appConfig.CODETYPE.OUT_CARRIER_COMP_DIV);

      Promise.all([relatedPartiesKbnCode, clientKbnCode, clientList, outCarrierComp])
        .then((result) => {
          this.relatedPartiesKbnList = result[0];
          this.clientKbnList = result[1];
          this.supplierList = result[2];
          this.carrierCompList = result[3];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          this.clientKbn = "02";
          this.changeClientKbn(this.clientKbn);
          if (
            this.$route.params.name == appConfig.SCREEN_ID.P_MST_018 &&
            this.$route.params.displayFlg == "2"
          ) {
            this.search();
          } else {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          }
        });
    },

    /**
     * 配送先選択画面：配送先選択ボタン処理
     */
    openTruck() {
      if (this.suppliersSelected) {
        //取引先SID
        this.truckInfo.suppliersSelected = this.suppliersSelected;
        //路線業者SID
        this.truckInfo.deliveryTraderSid = this.truck;
        //輸送業者コード
        this.truckInfo.carrierCompCd = this.truckKbn;
        this.isOpenTruck = true;
        if (this.$refs.truck != undefined) {
          //表示時処理
          this.$refs.truck.init();
        }
      } else {
        this.suppliersSelected = null;
      }
    },

    /**
     * 配送先選択画面：登録後処理
     */
    saveTruck(value) {
      this.truckInfo = value;

      // 画面表示用
      this.truckNm = this.truckInfo.carrierCompName;
      // API検索用
      this.truckKbn = this.truckInfo.carrierCompCd;
      this.truck = this.truckInfo.deliveryTraderSid;
      this.isOpenTruck = false;
    },

    /**
     * 有効開始日時を－１日します。
     */
    prevDate() {
      let date = new Date(this.validFromCal);
      this.validFromCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
    },

    /**
     * 有効開始日時を＋１日します。
     */
    nextDate() {
      let date = new Date(this.validFromCal);
      this.validFromCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
    },

    /**
     * 有効終了日時を－１日します。
     */
    prevToDate() {
      let date = new Date(this.validToCal);
      this.validToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
    },

    /**
     * 有効終了日時を＋１日します。
     */
    nextToDate() {
      let date = new Date(this.validToCal);
      this.validToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 日付入力(有効開始日時)
     */
    changeDateFrom(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.validFromCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.validFromCal = null;
      }
    },
    /**
     * 日付入力(有効終了日時)
     */
    changeDateTo(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.validToCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.validToCal = null;
      }
    },
    /**
     * 検索ボタン
     */
    btnSearch() {
      //バリデーションチェック
      if (this.$refs.form.validate()) {
        //データ取得
        // ローディング画面表示ON
        this.loadingCounter = 1;
        const config = this.$httpClient.createGetApiRequestConfig();
        if (this.suppliersSelected) {
          let compSid = this.supplierList.find(
            (element) => element.value === this.suppliersSelected
          ).clientSid;
          //会社SID
          config.params.compSid = compSid;
        }
        //関係先区分
        config.params.relatedPartyDiv = this.relatedPartiesKbn;
        //顧客区分
        config.params.clientKbn = this.clientKbn;
        //コード
        config.params.clientBaseCode = this.clientBaseCode;
        //検索区分
        config.params.serachKbn = "02";
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .secureGet(appConfig.API_URL.MST_RELATED_PARTY_ADD, config)
            .then((response) => {
              const jsonData = JSON.parse(JSON.stringify(response.data));
              // 正常時
              if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                const row = jsonData.resIdv.relatedPartys;
                if (row != null) {
                  this.displayFlg = "2";
                  //名称
                  this.clientBaseNm = row.relatedPartyName;
                  //名称カナ
                  this.clientBaseNmKana = row.relatedPartyNameKana;
                  //略称
                  this.clientBaseNmShort = row.relatedPartyNameShort;
                  //電話番号
                  this.telNo = row.telephoneNumber;
                  //FAX番号
                  this.faxNo = row.faxNumber;
                  //郵便番号
                  this.postCd = row.postCd;
                  if (row.postCd != "") {
                    //郵便番号SID
                    this.address = row.postSid;
                    //住所list
                    this.addressList.push({
                      value: row.postSid,
                      text: row.postCd + "：" + row.address,
                      address: row.address,
                      postCd: row.postCd,
                    });
                  }
                  //住所詳細
                  this.otherAddress = row.addressDetail.replace(
                    row.address != null ? row.address : "",
                    ""
                  );
                  //有効開始日時
                  this.validFrom = dateTimeHelper.convertUTC2JST(row.validFrom).substr(0, 10);
                  this.validFromCal = dateTimeHelper.dateCalc(
                    dateTimeHelper.convertUTC2JST(row.validFrom)
                  );
                  //有効終了日時
                  this.validTo = dateTimeHelper.convertUTC2JST(row.validTo).substr(0, 10);
                  this.validToCal = dateTimeHelper.dateCalc(
                    dateTimeHelper.convertUTC2JST(row.validTo)
                  );
                  //路線業者SID
                  this.truck = row.deliveryTraderSid;
                  //輸送業者コード
                  this.truckKbn = row.carrierCompCd;
                  if (row.deliveryTraderName != "") {
                    this.truckNm = row.deliveryTraderName;
                  } else {
                    this.truckNm = row.carrierCompCdName;
                  }
                  this.updateDatetime = row.updateDatetime;
                  this.relatedPartySid = row.relatedPartySid;
                }

                this.searchFlg = true;
                resolve();
              } else {
                // エラー時
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;

                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },
    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.checkDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.checkDialog.isOpen = true;
      this.checkDialog.screenFlag = true;
      this.checkDialog.changeFlag = false;
      this.checkDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      this.validFrom = "";
      this.validTo = "";
      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
      this.$refs.detailForm.resetValidation();
    },
    // 戻るボタン押下：ダイアログ表示
    backPage() {
      this.updateDialogMessage = true;
      this.backMessage = messsageUtil.getMessage("P-RCV-004_003_W");
    },
    // 戻るボタンダイアログ：NG押下
    updateNg() {
      this.updateDialogMessage = false;
    },
    // 戻るボタンダイアログ：OK押下
    updateOk() {
      this.updateDialogMessage = false;
      // 一覧画面の遷移前の検索条件
      // 一覧画面へ遷移
      this.$router.push({
        name: appConfig.SCREEN_ID.P_MST_018,
        params: {
          // 検索条件を渡す
          relatedPartiesKbn: this.$route.params.relatedPartiesKbn,
          clientKbn: this.$route.params.clientKbn,
          suppliersSelected: this.$route.params.suppliersSelected,
          clientBaseCode: this.$route.params.clientBaseCode,
          clientBaseNm: this.$route.params.clientBaseNm,
          validFrom: this.$route.params.validFrom,
          post: this.$route.params.post,
          // 選択したデータを渡す
          relatedPartySid: this.$route.params.relatedPartySid,
          baseCd: this.$route.params.baseCd,
          compSid: this.$route.params.compSid,
          displayFlg: this.$route.params.displayFlg,
          name: appConfig.SCREEN_ID.P_MST_017,
        },
      });
    },
    /**
     * 登録ボタン
     */
    insert() {
      if (this.$refs.detailForm.validate()) {
        this.checkDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
        this.checkDialog.isOpen = true;
        this.checkDialog.screenFlag = true;
        this.checkDialog.changeFlag = false;
        this.checkDialog.okAction = this.insertOkClick;
      }
    },
    continuousAdd() {
      if (this.$refs.formAdd.validate()) {
        this.checkDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
        this.checkDialog.isOpen = true;
        this.checkDialog.screenFlag = true;
        this.checkDialog.changeFlag = false;
        this.checkDialog.okAction = this.continuousInsert;
      }
    },
    //通常登録時
    insertOkClick() {
      this.relatedinsert(this.suppliersSelected);
    },
    //連続登録時
    continuousInsert() {
      this.relatedinsert(this.suppliersSelectedSecond);
    },
    relatedinsert(val) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const config = this.$httpClient.createRequestBodyConfig();
      config.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_MST_017;
      //関係先区分
      config.reqIdv.relatedPartyDiv = this.relatedPartiesKbn;
      if (this.relatedPartiesKbn == "01") {
        //顧客区分
        config.reqIdv.clientKbn = this.clientKbn;
        if (this.clientKbn == "02") {
          //会社SID
          config.reqIdv.compSid = this.supplierList.find(
            (element) => element.value === val
          ).clientSid;
          //取引先SID
          config.reqIdv.clientSid = val;
          //コード
          config.reqIdv.baseCd = this.clientBaseCode;
        } else {
          //取引先(今回 20240801 未実装)
        }
      }
      //名称
      config.reqIdv.name = this.clientBaseNm;
      //名称カナ
      config.reqIdv.nameKana = this.clientBaseNmKana;
      //略称
      config.reqIdv.nameShort = this.clientBaseNmShort;
      //電話番号
      config.reqIdv.telephoneNumber = this.telNo;
      //FAX番号
      config.reqIdv.faxNumber = this.faxNo;
      //郵便番号SID
      config.reqIdv.postSid = this.address;
      const addressDetail = this.addressList.find(
        (element) => element.value === this.address
      ).address;
      //住所詳細
      config.reqIdv.addressDetail = addressDetail + this.otherAddress.replace(addressDetail, "");
      //住所詳細(表示)
      config.reqIdv.addressDetailDisplay = this.otherAddress;
      //有効開始日時
      config.reqIdv.validFrom = dateTimeHelper.convertUTC(this.validFrom);
      //有効終了日時
      config.reqIdv.validTo = dateTimeHelper.convertUTC(this.validTo + " 23:59:59.999");
      //路線業者SID
      config.reqIdv.deliveryTraderSid = this.truck;
      //輸送業者コード
      config.reqIdv.carrierCompCd = this.truckKbn;
      //更新日時
      config.reqIdv.updateDatetime = this.updateDatetime;
      if (this.relatedPartySid) {
        //関係先SID
        config.reqIdv.relatedPartySid = this.relatedPartySid;
      }
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.MST_RELATED_PARTY_ADD, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              if (this.updateDatetime == null && this.supplierList.length > 1) {
                // バリデーションチェックをリセット
                this.$refs.formAdd.resetValidation();
                this.insertMessage3 = i18n.tc("label.lbl_supplier");
                this.insertMessage4 = this.supplierList.find(
                  (element) => element.value === val
                ).text;
                this.suppliersSelectedSecond = "";
                this.insertMessage5 = i18n.tc("label.lbl_baseCd");
                this.insertMessage6 = this.clientBaseCode;
                this.registrationDialogMessage = true;
                this.insertMessage1 = messsageUtil.getMessage("P-COM-131_002_C");
                this.insertMessage2 = messsageUtil.getMessage("P-COM-131_001_C");
              } else {
                this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
                this.infoDialog.title = "結果";
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                this.infoDialog.relatedMstFlg = true;
                this.infoDialog.outsideClickNotCloseFlg = true;
                this.infoDialog.firstPageFlag = false;
              }
              resolve();
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.isOpen = true;

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    postApi() {
      //
    },
    /**
     * 郵便番号入力時
     */
    changePostCd() {
      //桁数チェック
      if (this.postCd.length >= 4) {
        //郵便番号マスタ検索
        const config = this.$httpClient.createGetApiRequestConfig();
        config.params.postCode = this.postCd;
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .secureGet(appConfig.API_URL.MST_BIZ_POST_CODES, config)
            .then((response) => {
              const jsonData = JSON.parse(JSON.stringify(response.data));
              const list = [];
              // 正常時
              if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                jsonData.resIdv.postcodes.forEach((row) => {
                  list.push({
                    value: row.postSid,
                    text: row.postCd + "：" + row.address,
                    postCd: row.postCd,
                    address: row.address,
                  });
                });
                this.addressList = list;
                if (this.addressList.length == 1) {
                  this.address = this.addressList[0].value;
                }
                if (this.postCd.length == 7 && this.addressList.length == 0) {
                  this.address = "";
                } else {
                  const addressDetail = this.addressList.filter(
                    (element) => element.value === this.address
                  );
                  if (addressDetail.length == 0) {
                    this.address = "";
                  }
                }
              } else {
                // エラー時
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            });
        });
      } else {
        this.addressList = [];
        this.address = "";
      }
    },

    /**
     * 顧客区分変更時
     */
    changeClientKbn(val) {
      this.clientBaseCode = "";
      this.suppliersSelected = "";
      if (val == "01") {
        this.clientBaseName = i18n.tc("label.lbl_supplierCd");
        this.clientBaselength = "7";
      } else if (val == "02") {
        this.clientBaseName = i18n.tc("label.lbl_baseCd");
        this.clientBaselength = "30";
      } else {
        this.clientBaseName = i18n.tc("label.lbl_supplier") + "/" + i18n.tc("label.lbl_baseCd");
        this.clientBaselength = "0";
      }
    },
    movePage(pageNm, param) {
      if (this.$route.name !== appConfig.SCREEN_ID[pageNm]) {
        // 他画面に遷移する
        this.$router.push({ name: appConfig.SCREEN_ID[pageNm], params: param ?? {} });
      }
    },
  },
  mounted() {
    this.init();
  },
  computed: {},
  watch: {
    validFromCal(val) {
      this.validFrom = this.formatDate(val);
    },
    validToCal(val) {
      this.validTo = this.formatDate(val);
    },
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.search-textbox-remark {
  width: 41.5rem;
  margin-left: 15px;
}

.calender {
  display: flex;
}

// ロケグループ入力テキストボックス
.add-textbox {
  width: 10rem;
  padding: 20px 0 0 10px;
}

// 実行ボタンがある行
.search-row-exeBtns {
  width: 100%;
  margin-right: 12px;
}

// 検索項目間の空白
.item-spacers {
  margin-left: 50px;
  margin-right: 6px;
}

.isD {
  background-color: $color-warning !important;
}

.locationAlign {
  margin: 9px 0 0 0;
  height: 3.6rem;
  text-align: left;
}
</style>
