<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <!-- （画面名）User_Makerリンクマスタ-->
      <NavBar tittle="label.lbl_userMakerLink" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="search-row">
            <div class="search-1st-line" style="width: 100%; margin-right: 12px">
              <!-- ユーザー取引先-->
              <div class="search-autocomplete first-search-item" style="float: left; width: 30%">
                <v-autocomplete
                  dense
                  maxlength="100"
                  v-model="userSuppliersSelected"
                  :items="userSupplierList"
                  :label="$t('label.lbl_userSupplier')"
                  :hint="setSrhUserSupplier()"
                  class="txt-single"
                  persistent-hint
                  @change="changeUserSuppliersSelected"
                  :rules="[rules.inputRequired]"
                  pa-3
                  :error-messages="alertUserSupplierMessage"
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
              <span class="require">*</span>

              <span class="require" style="float: right">*</span>
              <!-- メーカ取引先-->
              <div
                class="search-autocomplete first-search-item"
                style="float: right; width: 30%; padding-left: 12px"
              >
                <v-autocomplete
                  dense
                  maxlength="100"
                  v-model="clientSelected"
                  :items="supplierList"
                  :label="$t('label.lbl_makerSupplier')"
                  :hint="setSrhClient()"
                  class="txt-single"
                  persistent-hint
                  @change="changeClient"
                  :search-input.sync="searchClient"
                  :rules="[rules.inputRequired]"
                  :error-messages="alertClientMessage"
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
            </div>
          </v-row>

          <v-row class="search-row">
            <span class="item-spacer">&nbsp;</span>
            <!-- 基本品番-->
            <div class="search-2nd-line" style="width: 100%; margin-right: 12px">
              <div class="search-autocomplete first-search-item" style="float: left; width: 30%">
                <v-autocomplete
                  dense
                  maxlength="100"
                  v-model="userBaseProductCd"
                  :items="userBaseProductCdList"
                  :label="$t('label.lbl_baseProductCd')"
                  :hint="setSrhUserBaseProductCd()"
                  class="txt-single"
                  persistent-hint
                  pa-3
                  @change="changeUserBaseProductCd"
                  :rules="[rules.inputRequired]"
                  :search-input.sync="searchUserBaseProductCd"
                  :error-messages="alertBaseProductCdMessage"
                >
                  >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
              <span class="require">*</span>

              <span class="require" style="float: right">*</span>
              <!-- Maker品番-->
              <div
                class="search-autocomplete first-search-item"
                style="float: right; width: 30%; padding-left: 12px"
              >
                <v-autocomplete
                  dense
                  maxlength="100"
                  v-model="makerProductCdSelected"
                  :items="makerProductCdList"
                  :label="$t('label.lbl_productCode')"
                  :hint="setSrhMakerProductCd()"
                  class="txt-single"
                  persistent-hint
                  @change="changeMakerProductCdSelected"
                  :rules="[rules.inputRequired]"
                  pa-3
                  :search-input.sync="searchMakerProductCd"
                  :error-messages="alertMakerProductCdMessage"
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
            </div>
          </v-row>

          <v-row>
            <!--情報ラベル-->
            <div class="display-search-info-label">
              <!--タイトル-->
              <div class="text-label" style="float: left; width: 30%; text-align: center">
                <span class="userInfo" style="color: green">ユーザー情報</span>
              </div>
              <span class="spacer" style="float: left; width: 5%">&nbsp;</span>
              <div class="text-label" style="float: left; width: 30%; text-align: center">
                <span class="linkInfo" style="color: brown">リンク情報</span>
              </div>
              <span class="spacer" style="float: left; width: 5%">&nbsp;</span>
              <div style="float: right; padding-left: 20px; width: 30%; text-align: center">
                <span class="makerInfo" style="color: blue">メーカー情報 </span>
              </div>
            </div>
          </v-row>

          <v-row>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!--ボタン領域-->
            <div class="search-row-exeBtn" style="padding-left: 12px">
              <!--ユーザ情報検索ボタン領域-->
              <div class="btn-search-area" style="float: left; width: 30%">
                <!-- ユーザー情報検索用ボタン -->
                <v-btn
                  class="get-btn"
                  style="float: left; text-transform: none; margin: 0 0 0 0px"
                  @click="getUserInfoList()"
                  >{{ $t("label.lbl_userSearch") }}</v-btn
                >
                <!--画面クリアボタン-->
                <v-btn class="other-btn" style="float: right" @click="clearScreen()">{{
                  $t("btn.btn_clear")
                }}</v-btn>
                <!-- 戻るボタン -->
                <v-btn class="other-btn" :style="backBtnstyle" @click="getBack()">{{
                  $t("btn.btn_back")
                }}</v-btn>
              </div>
              <!--空白-->
              <span class="spacer" style="float: left; width: 5%">&nbsp;</span>
              <!--メーカー情報検索ボタン領域-->
              <div class="btn-search-area" style="float: left; width: 30%">
                <!--ﾘﾝｸ情報検索用ボタン-->
                <v-btn
                  id="btn-content-config"
                  class="get-btn"
                  style="float: left; margin: 0 0 0 0px"
                  @click="getLinkInfoList()"
                  >{{ $t("label.lbl_linkSearch") }}</v-btn
                >
                <!--削除-->
                <v-btn
                  class="post-btn"
                  style="float: right"
                  :disabled="linkInfoInputList.length == 0"
                  @click="checkDelete()"
                  >{{ $t("btn.btn_delete") }}</v-btn
                >
                <!--登録-->
                <v-btn
                  class="post-btn"
                  style="float: right"
                  :disabled="linkInfoInputList.length == 0"
                  @click="openRegist()"
                  >{{ $t("btn.btn_insert") }}</v-btn
                >
              </div>
              <!--空白-->
              <span class="spacer" style="float: left; width: 5%">&nbsp;</span>
              <!--メーカー情報検索ボタン領域-->
              <div class="btn-search-area" style="float: right; width: 30%">
                <!--メーカー情報検索用ボタン-->
                <v-btn
                  class="get-btn"
                  style="float: left; text-transform: none; margin: 0 0 0 0px"
                  @click="getMakerInfoList()"
                  >{{ $t("label.lbl_makerSearch") }}</v-btn
                >
                <!--リンク追加ボタン-->
                <v-btn class="post-btn" style="float: right" @click="addMakerInfoList()">{{
                  $t("label.lbl_linkAdd")
                }}</v-btn>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <!-- ユーザ情報 -->
        <v-data-table
          id="listData"
          :class="tableData"
          fixed-header
          :headers="headerUserItems"
          :items="userInfoInputList"
          disable-filtering
          disable-pagination
          :hide-default-footer="true"
          style="float: left; width: 30%"
        >
          <!-- 商品コード -->
          <template v-slot:[`item.userProductBaseCd`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="text-overflow" style="max-width: 400px" v-bind="attrs" v-on="on">
                  {{ item.userProductBaseCd }}<br />
                  {{ item.userProductNm }}
                </div>
              </template>
              <span
                >{{ item.userProductBaseCd }}<br />
                {{ item.userProductNm }}</span
              >
            </v-tooltip>
          </template>
          <!-- リンク -->
          <template v-slot:[`item.linkSetting`]="{ item }">
            <div id="table-linkSetting" v-if="item.linkSetting == '0'" style="color: red">
              {{ $t("label.lbl_unlinked") }}
            </div>
            <div v-else></div>
          </template>
          <!-- 一覧チェックボックス -->
          <template v-slot:[`item.userCheck`]="{ item }">
            <input
              type="checkbox"
              v-model="item.userCheck"
              @click="clickUserCheck()"
              :value="listCheckbox"
              style="transform: scale(2)"
            />
          </template>
        </v-data-table>
        <!--空白-->
        <span class="spacer" style="float: left; width: 5%">&nbsp;</span>
        <!-- リンク情報 -->
        <v-data-table
          id="listData"
          :class="tableData"
          fixed-header
          :headers="headerLinkItems"
          :items="linkInfoInputList"
          disable-filtering
          disable-pagination
          :hide-default-footer="true"
          style="float: left; width: 30%"
        >
          <!-- 商品コード-->
          <template v-slot:item="{ item, index }">
            <tr
              :key="index"
              :id="index"
              :draggable="true"
              @dragstart="dragList($event, index)"
              @drop="dropList($event, index)"
              @dragover.prevent
              @dragenter.prevent
            >
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-overflow" style="max-width: 400px" v-bind="attrs" v-on="on">
                      {{ item.linkProductCd }}<br />
                      {{ item.linkProductNm }}
                    </div>
                  </template>
                  <span
                    >{{ item.linkProductCd }}<br />
                    {{ item.linkProductNm }}</span
                  >
                </v-tooltip>
              </td>
              <!-- 優先順-->
              <td class="text-center">
                <div id="table-priority">
                  {{ item.priority }}
                </div>
              </td>
              <!-- 一覧チェックボックス -->
              <td class="text-center">
                <input
                  type="checkbox"
                  v-model="item.linkCheck"
                  :value="listCheckbox"
                  style="transform: scale(2)"
                />
              </td>
            </tr>
          </template>
        </v-data-table>
        <!--空白-->
        <span class="spacer" style="float: left; width: 5%">&nbsp;</span>
        <!-- メーカ情報 -->
        <v-data-table
          id="listData"
          :class="tableData"
          fixed-header
          :headers="headerMakerItems"
          :items="makerInfoInputList"
          disable-filtering
          disable-pagination
          :hide-default-footer="true"
          style="float: right; width: 30%"
        >
          <!-- 商品コード・品名 -->
          <template v-slot:[`item.makerProductCd`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="text-overflow" style="max-width: 400px" v-bind="attrs" v-on="on">
                  {{ item.makerProductCd }}<br />
                  {{ item.makerProductNm }}
                </div>
              </template>
              <span
                >{{ item.makerProductCd }}<br />
                {{ item.makerProductNm }}</span
              >
            </v-tooltip>
          </template>
          <!-- 一覧チェックボックス -->
          <template v-slot:[`item.makerCheck`]="{ item }">
            <input
              type="checkbox"
              v-model="item.makerCheck"
              :value="listCheckbox"
              style="transform: scale(2)"
            />
          </template>
        </v-data-table>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <!-- 確認メッセージダイアログモーダル-->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :homePageFlag="infoDialog.homePageFlag"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
      />
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :changeFlag="confirmDialog.changeFlag"
      />
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <!-- 警告ダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import ConfirmDialog from "@/components/ConfirmDialog";

export default {
  name: appConfig.MENU_ID.BIZ_DELIVERY_INSTRUCTIONS_SEARCH,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    NavBar,
    ConfirmDialog,
  },
  props: {
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,

    rules: {
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
    },

    userSuppliersSelected: "", // ユーザー取引先
    clientSelected: "", // 取引先
    userProductBaseCd: "", //ユーザー品番
    userBaseProductCd: "", //基本品番
    makerProductCdSelected: "", //メーカ品番

    userBaseProductCdList: [], //基本品番(検索条件)
    makerProductCdList: [], //メーカー品番(検索条件)
    supplierList: [], //メーカー取引先(検索条件)
    userSupplierList: [], //ユーザー取引先(検索条件)

    //パラメータ引き継ぎ用
    searchClient: "",
    searchUserProductCd: "",
    searchUserBaseProductCd: "",
    searchMakerProductCd: "",

    openMenu: null, // メニュー
    suppliersSelected: "", // 取引先コードリスト
    selectHintClient: "",
    productHint: "",
    baseProductHint: "",
    makerProductHint: "",
    listCheckbox: [1, 2], // 一覧選択チェックボックス
    inputList: [], // 一覧
    userInfoInputList: [], // ユーザ情報一覧
    linkInfoInputList: [], // リンク情報一覧
    makerInfoInputList: [], // メーカ情報一覧
    deleteList: [], //削除リスト
    retentionList: [], //保持リスト
    updateFlg: false, // 更新フラグ
    backBtnstyle: "float: right; margin: 0 0 0 0px; display: none", // 戻るボタンスタイル
    backBtnstyleBk: "float: right; margin: 0 0 0 0px", // 戻るボタンスタイル

    precUserItemSid: "", //処理中ユーザー品番SID

    // 確認メッセージ
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
    },

    // バリデーションチェックメッセージダイアログ
    alertUserSupplierMessage: "",
    alertBaseProductCdMessage: "",
    alertClientMessage: "",
    alertMakerProductCdMessage: "",

    updateDialogMessage: false, // 戻るボタンダイアログ
    backMessage: "", // 戻るボタンダイアログメッセージ
    clearFlg: false, // クリアフラグ
    checkFlg: false, // チェックフラグ

    tableData: "tableData",

    // ユーザ情報ヘッダ
    headerUserItems: [
      // 基品番/付帯品番
      {
        text: i18n.tc("label.lbl_userBaseAccessoryProductCd"),
        value: "userProductBaseCd",
        width: "60%",
        align: "left",
        sortable: false,
      },
      // リンク設定
      {
        text: i18n.tc("label.lbl_link"),
        value: "linkSetting",
        width: "15%",
        align: "center",
        sortable: false,
      },
      // 選択
      {
        text: i18n.tc("label.lbl_choice"),
        value: "userCheck",
        width: "15%",
        align: "center",
        sortable: false,
      },
    ],
    // リンク情報ヘッダ
    headerLinkItems: [
      // 品番/付帯品番
      {
        text: i18n.tc("label.lbl_productCode") + "/" + i18n.tc("label.lbl_accessoryProduct"),
        value: "linkProductCd",
        width: "60%",
        align: "left",
        sortable: false,
      },
      // 優先順
      {
        text: i18n.tc("label.lbl_priority"),
        value: "priority",
        width: "15%",
        align: "center",
        sortable: false,
      },
      // 選択
      {
        text: i18n.tc("label.lbl_choice"),
        value: "linkCheck",
        width: "15%",
        align: "center",
        sortable: false,
      },
    ],
    // メーカ情報ヘッダ
    headerMakerItems: [
      // メ品番/付帯品番
      {
        text: i18n.tc("label.lbl_makerBaseAccessoryProductCd"),
        value: "makerProductCd",
        width: "60%",
        align: "left",
        sortable: false,
      },
      // 選択
      {
        text: i18n.tc("label.lbl_choice"),
        value: "makerCheck",
        width: "15%",
        align: "center",
        sortable: false,
      },
    ],
  }),
  created() {
    //
  },

  methods: {
    onBlur() {
      //
    },

    /**
     * ドラッグ
     */
    dragList(event, dragIndex) {
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.setData("drag-index", dragIndex);
    },
    /**
     * ドロップ
     */
    dropList(event, dropIndex) {
      const dragIndex = event.dataTransfer.getData("drag-index");
      const deleteList = this.linkInfoInputList.splice(dragIndex, 1);
      this.linkInfoInputList.splice(dropIndex, 0, deleteList[0]);
    },

    /**
     * 初期データ取得
     */
    getCustomInfo(jstInScheduleDate) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const supplierList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(jstInScheduleDate)
      );
      return Promise.all([supplierList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.userSupplierList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // 取引先API接続
    getSupplier() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.isGetClient = "1";

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_RELATEDBIZ, config)
          .then((response) => {
            // console.debug("getSupplier() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              jsonData.resIdv.relatedPartys.forEach((row) => {
                list.push({
                  text: `${row.clientCd}` + `（${row.relatedPartyName}）`,
                  value: row.relatedPartySid,
                  name: row.relatedPartyName,
                });
              });
              this.supplierList = list;
            } else {
              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessages["resComMessage" + sessionStorage.getItem("lang")];
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }
            resolve(response);
          })
          .catch((resolve) => {
            console.error("getSupplier() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * プルダウンチェンジイベント
     */
    changeUserSuppliersSelected() {
      // 取引先を変更する場合はクリアする
      this.alertUserSupplierMessage = "";
      this.userBaseProductCd = "";
      this.userBaseProductCdList = [];
    },

    changeClient(value) {
      this.makerProductCdSelected = "";
      this.makerProductCdList = [];
      this.alertClientMessage = "";
      // 取引先を選択時
      let val = this.supplierList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.selectHintClient = val.text;
        this.searchClient = val.text;
      }
      this.supplierList = this.supplierList.filter((v) => v.value == value);

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.selectHintClient = "";
      } else {
        // 処理なし
      }
    },

    changeUserBaseProductCd(value) {
      this.alertBaseProductCdMessage = "";
      // 既存の基本品番を選択時
      let val = this.userBaseProductCdList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.baseProductHint = val.text;
        this.searchUserBaseProductCd = val.text;
      }
      this.userBaseProductCdList = this.userBaseProductCdList.filter((v) => v.value == value);

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.baseProductHint = "";
      } else {
        // 処理なし
      }
    },

    changeMakerProductCdSelected(value) {
      this.alertMakerProductCdMessage = "";
      // 既存のメーカー品番を選択時
      let val = this.makerProductCdList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.makerProductHint = val.text;
        this.searchMakerProductCd = val.text;
      }
      this.makerProductCdList = this.makerProductCdList.filter((v) => v.value == value);

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.makerProductHint = "";
      } else {
        // 処理なし
      }
    },

    /**
     * 警告ダイアログ、OKボタン押下
     */
    updateOk() {
      this.updateDialogMessage = false;
    },

    /**
     *  ユーザー検索ボタン押下時処理
     */
    getUserInfoList() {
      //  入力チェック結果フラグ
      let inputCheckResult = true;
      if (
        this.userSuppliersSelected == undefined ||
        this.userSuppliersSelected == null ||
        this.userSuppliersSelected == ""
      ) {
        this.alertUserSupplierMessage = i18n.tc("check.chk_input");
        inputCheckResult = false;
      }

      if (
        this.userBaseProductCd == undefined ||
        this.userBaseProductCd == null ||
        this.userBaseProductCd == ""
      ) {
        this.alertBaseProductCdMessage = i18n.tc("check.chk_input");
        inputCheckResult = false;
      }

      if (inputCheckResult) {
        // バリデーションエラーメッセージを非表示
        this.alertUserSupplierMessage = "";
        this.alertBaseProductCdMessage = "";

        // ローディング画面表示ON
        this.loadingCounter = 1;

        // httpClientで使用する共通IFパラメータ等を作成する。
        const config = this.$httpClient.createGetApiRequestConfig();
        // ユーザー取引先
        config.params.clientSid = this.userSuppliersSelected;

        // 基本品番
        config.params.userItemBaseCd = this.userBaseProductCd;

        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .secureGet(appConfig.API_URL.BIZ_GET_USER_ITEM, config)
            .then((response) => {
              const jsonData = JSON.parse(JSON.stringify(response.data));
              // console.debug("getUserInfoList() Response", response); // 成功
              const list = [];
              if (jsonData.resCom.resComCode == "000") {
                // ユーザ品番リストを取得
                jsonData.resIdv.userItems.forEach((row) => {
                  let check = false;
                  if (row.userItemSid == this.precUserItemSid) {
                    check = true;
                  }
                  list.push({
                    userItemSid: row.userItemSid, // ユーザ品番SID
                    userProductBaseCd:
                      row.userItemBaseCd.substring(0, 50).trim() +
                      " " +
                      row.userItemBaseCd.slice(-50).trim(), // ユーザ基本品番
                    userProductNm: row.userItemName, // 品名
                    linkSetting: row.linkedFlg, // リンク済フラグ

                    userCheck: check, //選択
                  });
                });

                this.userInfoInputList = list;
                if (this.updateFlg) {
                  this.updateFlg = false;
                } else {
                  this.linkInfoInputList = [];
                }
                this.precUserItemSid = "";
                if (
                  this.$route.params.name == appConfig.MENU_ID.P_SUP_USER_ITEM_MASTER &&
                  this.checkFlg
                ) {
                  for (let i = 0; i < this.userInfoInputList.length; i++) {
                    if (
                      this.userInfoInputList[i].userProductBaseCd ==
                      this.$route.params.accessoryProduct
                    ) {
                      this.userInfoInputList[i].userCheck = true;
                      this.checkFlg = false;
                      break;
                    }
                  }
                  this.getLinkInfoList();
                }
                resolve(response);
              } else {
                // 処理結果コードが"000"（Success・Retry）以外の場合の処理です。
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.isOpen = true;
                this.infoDialog.title = appConfig.DIALOG.title;
                // OK NGボタン
                this.infoDialog.firstPageFlag = true;
                // タスクを終了します（失敗）。
                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },

    /**
     *  リンク検索ボタン押下時処理
     */
    getLinkInfoList() {
      let userInfcheckList = [];

      // チェック
      for (var i = 0; i < this.userInfoInputList.length; i++) {
        if (this.userInfoInputList[i].userCheck == true) {
          userInfcheckList.push(this.userInfoInputList[i]);
        }
      }

      if (userInfcheckList.length != 1) {
        this.errorFlg = false;
        this.updateDialogMessage = true;
        this.backMessage = messsageUtil.getMessage("P-SUP-017_001_E");
        return;
      }

      // バリデーションエラーメッセージを非表示
      this.alertUserSupplierMessage = "";
      this.alertBaseProductCdMessage = "";

      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();

      // ユーザ品番SID
      config.params.userItemSid = userInfcheckList[0].userItemSid;

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_GET_USER_MAKER_LINK, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("getLinkInfoList(userItemSid) Response", response); // 成功
            const list = [];
            if (jsonData.resCom.resComCode == "000") {
              // ユーザーメーカーリンクマスタリストを取得
              let priority = 0;
              jsonData.resIdv.userMakerLink.forEach((row) => {
                priority++;
                list.push({
                  userMakerSid: row.userMakerSid, // ユーザメーカSID
                  userItemSid: row.userItemSid, // ユーザ品番SID
                  clientSid: row.clientSid, // 取引先SID
                  itemStandardSid: row.itemStandardSid, // 商品標準SID
                  linkProductCd:
                    row.itemCd.substring(0, 50).trim() + " " + row.itemCd.slice(-50).trim(), // 品番
                  linkProductNm: row.itemName, // 品名
                  priority: priority, //優先順
                  updateDatetime: row.updateDatetime, //更新日時
                  linkCheck: false, // 選択
                });
              });

              this.linkInfoInputList = list;
              resolve(response);
            } else {
              // 処理結果コードが"000"（Success・Retry）以外の場合の処理です。
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.isOpen = true;
              this.infoDialog.title = appConfig.DIALOG.title;
              // OK NGボタン
              this.infoDialog.firstPageFlag = true;
              // タスクを終了します（失敗）。
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     *  メーカ検索ボタン押下時処理
     */
    getMakerInfoList() {
      //  入力チェック結果フラグ
      let inputCheckResult = true;

      if (
        this.clientSelected == undefined ||
        this.clientSelected == null ||
        this.clientSelected == ""
      ) {
        this.alertClientMessage = i18n.tc("check.chk_input");
        inputCheckResult = false;
      }
      if (
        this.makerProductCdSelected == undefined ||
        this.makerProductCdSelected == null ||
        this.makerProductCdSelected == ""
      ) {
        this.alertMakerProductCdMessage = i18n.tc("check.chk_input");
        inputCheckResult = false;
      }

      if (inputCheckResult) {
        this.alertClientMessage = "";
        this.alertMakerProductCdMessage = "";
        // ローディング画面表示ON
        this.loadingCounter = 1;

        // httpClientで使用する共通IFパラメータ等を作成する。
        const config = this.$httpClient.createGetApiRequestConfig();
        // リクエストパラメータ作成
        // 営業所SID
        config.params.officeSid = sessionStorage.getItem("sales_office_sid");
        // 商品標準SID
        config.params.itemStandardSid = this.makerProductCdSelected;
        // 削除Flg
        config.params.deleteFlg = "0";

        // console.debug("getMakerInfoList() Config", config);

        // // 接続先のAPI_URLを入力
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .secureGet(appConfig.API_URL.MST_ITEMSEARCH_DETAILS, config)
            .then((response) => {
              const jsonData = JSON.parse(JSON.stringify(response.data));
              // console.debug("getMakerInfoList() Response", response); // 成功
              const list = [];
              if (jsonData.resCom.resComCode == "000") {
                // ユーザ品番マスタリストを取得

                list.push({
                  itemStandardSid: this.makerProductCdSelected,
                  makerProductCd:
                    jsonData.resIdv.itemsStandard.itemCd.substring(0, 50).trim() +
                    " " +
                    jsonData.resIdv.itemsStandard.itemCd.slice(-50).trim(), // 品番
                  makerProductNm: jsonData.resIdv.itemsStandard.itemName, // 品名
                  linkCheck: false, // 選択
                });

                this.makerInfoInputList = list;
                resolve(response);
              } else {
                // 処理結果コードが"000"（Success・Retry）以外の場合の処理です。
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.isOpen = true;
                this.infoDialog.title = appConfig.DIALOG.title;
                // OK NGボタン
                this.infoDialog.firstPageFlag = true;
                // タスクを終了します（失敗）。
                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },
    /**
     *  削除ボタン押下時処理
     */
    linkDelete() {
      this.loadingCounter = 1;
      this.precUserItemSid = this.deleteList[0].userItemSid;
      // httpClientで使用する共通IFパラメータ等を作成する。
      const body = this.$httpClient.createRequestBodyConfig();
      // リクエストパラメータ作成
      // 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SUP_017;
      var bodyList = [];
      var count = 0;
      for (var i = 0; i < this.deleteList.length; i++) {
        if (this.deleteList[i].userMakerSid) {
          count++;
          bodyList.push({
            clientSid: this.deleteList[i].clientSid,
            userMakerSid: this.deleteList[i].userMakerSid,
            itemStandardSid: this.deleteList[i].itemStandardSid,
            updateDatetime: this.deleteList[i].updateDatetime,
          });
        }
      }

      body.reqIdv.mstUserMakerLinkList = bodyList;
      if (count > 0) {
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .securePost(appConfig.API_URL.BIZ_USER_MAKER_LINK_DELETE, body, appConfig.APP_CONFIG)

            .then((response) => {
              // console.debug("linkDelete() Response", response);
              const jsonData = JSON.parse(JSON.stringify(response.data));

              // 正常時
              if (jsonData.resCom.resComCode == "000") {
                this.infoDialog.message = messsageUtil.getMessage("P-COM-001_005_C", [
                  i18n.tc("btn.btn_delete"),
                ]);
                this.infoDialog.title = "結果";
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                this.infoDialog.outsideClickNotCloseFlg = false;

                for (var b = 0; b < this.retentionList.length; b++) {
                  this.retentionList[b].priority = b + 1;
                }
                // 削除以外セット
                this.linkInfoInputList = [];
                this.linkInfoInputList = this.retentionList;
                // 再検索
                this.updateFlg = true;
                this.getUserInfoList();

                // エラー時
              } else {
                reject(new Error(jsonData.resCom.resComMessage));
              }
            })
            .catch((ex) => {
              reject(ex);
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        }).catch((ex) => {
          this.infoDialog.message = ex.message;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
          this.infoDialog.homePageFlag = false;
          this.infoDialog.outsideClickNotCloseFlg = false;
        });
      } else {
        for (var b = 0; b < this.retentionList.length; b++) {
          this.retentionList[b].priority = b + 1;
        }

        this.infoDialog.message = messsageUtil.getMessage("P-COM-001_005_C", [
          i18n.tc("btn.btn_delete"),
        ]);
        this.infoDialog.title = "結果";
        this.infoDialog.isOpen = true;
        this.infoDialog.firstPageFlag = true;
        this.infoDialog.outsideClickNotCloseFlg = false;
        // 削除以外セット
        this.linkInfoInputList = [];
        this.linkInfoInputList = this.retentionList;
        // ローディング画面表示OFF
        this.loadingCounter = 0;
      }
    },
    /**
     *  登録ボタン押下時処理
     */
    linkRegist() {
      this.loadingCounter = 1;
      this.precUserItemSid = this.linkInfoInputList[0].userItemSid;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const body = this.$httpClient.createRequestBodyConfig();
      // リクエストパラメータ作成
      // 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SUP_017;
      var bodyList = [];
      var count = 1;
      for (var i = 0; i < this.linkInfoInputList.length; i++) {
        bodyList.push({
          clientSid: this.linkInfoInputList[i].clientSid,
          userMakerSid: this.linkInfoInputList[i].userMakerSid,
          userItemSid: this.linkInfoInputList[i].userItemSid,
          itemStandardSid: this.linkInfoInputList[i].itemStandardSid,
          priorityNo: count,
          updateDatetime: this.linkInfoInputList[i].updateDatetime,
        });
        count++;
      }
      body.reqIdv.mstUserMakerLinkList = bodyList;

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_USER_MAKER_LINK_REGIT, body, appConfig.APP_CONFIG)

          .then((response) => {
            // console.debug("linkDelete() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.infoDialog.message = messsageUtil.getMessage("P-COM-001_005_C", [
                i18n.tc("btn.btn_insert"),
              ]);
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.outsideClickNotCloseFlg = false;
              // 再検索
              this.updateFlg = true;
              this.getUserInfoList();
              this.getLinkInfoList();
              // エラー時
            } else {
              reject(new Error(jsonData.resCom.resComMessage));
            }
          })
          .catch((ex) => {
            reject(ex);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      }).catch((ex) => {
        this.infoDialog.message = ex.message;
        this.infoDialog.title = appConfig.DIALOG.title;
        this.infoDialog.isOpen = true;
        this.infoDialog.firstPageFlag = true;
        this.infoDialog.homePageFlag = false;
        this.infoDialog.outsideClickNotCloseFlg = false;
      });
    },
    /**
     *  リンク追加ボタン押下時処理
     */
    addMakerInfoList() {
      let linkAddList = [];
      let maxPriority = 0;
      let userInfcheckList = [];

      for (var i = 0; i < this.userInfoInputList.length; i++) {
        if (this.userInfoInputList[i].userCheck == true) {
          userInfcheckList.push(this.userInfoInputList[i]);
        }
      }

      if (userInfcheckList.length != 1) {
        this.errorFlg = false;
        this.updateDialogMessage = true;
        this.backMessage = messsageUtil.getMessage("P-SUP-017_001_E");
        return;
      }
      //ユーザ情報のリンク設定ありかつリンク情報が0件の場合エラー
      if (userInfcheckList[0].linkSetting == "1" && this.linkInfoInputList.length == 0) {
        this.errorFlg = false;
        this.updateDialogMessage = true;
        this.backMessage = messsageUtil.getMessage("P-SUP-017_002_E");
        return;
      }

      //リンク追加対象を取得する
      for (var j = 0; j < this.makerInfoInputList.length; j++) {
        if (this.makerInfoInputList[j].makerCheck == true) {
          maxPriority = maxPriority + 1;
          let data = {
            userMakerSid: "", // ユーザメーカSID
            userItemSid: userInfcheckList[0].userItemSid, //ユーザ品番SID
            itemStandardSid: this.makerInfoInputList[j].itemStandardSid, //商品標準SID
            linkProductCd: this.makerInfoInputList[j].makerProductCd, // ユ品番/付帯品番
            linkProductNm: this.makerInfoInputList[j].makerProductNm, // 品名
            priority: String(this.linkInfoInputList.length + 1), // 優先順
            updateDatetime: "", //更新日時
            linkCheck: false, // 選択
          };
          linkAddList.push(data);
        }
      }
      //リンク追加対象が存在しない場合エラー
      if (linkAddList.length < 1) {
        // ローディング画面表示OFF
        this.loadingCounter = 0;
        this.errorFlg = false;
        this.updateDialogMessage = true;
        this.backMessage = messsageUtil.getMessage("P-SUP-017_003_E");
        return;
      }
      //リンク情報に同一の品番(商品標準SID)が存在する場合エラー
      for (var k = 0; k < linkAddList.length; k++) {
        for (var l = 0; l < this.linkInfoInputList.length; l++) {
          if (linkAddList[k].itemStandardSid == this.linkInfoInputList[l].itemStandardSid) {
            this.errorFlg = false;
            this.updateDialogMessage = true;
            this.backMessage = messsageUtil.getMessage("P-SUP-017_004_E");
            // ローディング画面表示OFF
            this.loadingCounter = 0;
            return;
          }
        }
      }
      //リンク一覧に追加
      for (var m = 0; m < linkAddList.length; m++) {
        this.linkInfoInputList.push(linkAddList[m]);
      }
    },

    /**
     * チェックボックス押下処理
     */
    checkList(item) {
      if (!item.check) {
        // チェックを入れた場合
        this.listCheckbox.push(item);
      } else {
        // チェックを外した場合
        this.listCheckbox.splice(this.listCheckbox.indexOf(item), 1);
      }
    },

    /**
     * ユーザ情報の選択クリック
     */
    clickUserCheck() {
      this.linkInfoInputList = [];
    },

    /**
     * 初期値
     */
    init() {
      //リンク一覧（30行固定）
      this.linkInfoInputList = [];
      this.userInfoInputList = [];
      this.makerInfoInputList = [];
      this.alertUserSupplierMessage = "";
      this.alertBaseProductCdMessage = "";
      this.alertClientMessage = "";
      this.alertMakerProductCdMessage = "";

      this.$refs.form.resetValidation();
      if (
        this.$route.params.name == appConfig.MENU_ID.P_SUP_USER_ITEM_MASTER &&
        this.clearFlg == false
      ) {
        //ユーザー取引先
        this.userSuppliersSelected = this.$route.params.userClientSid;
        //基本品番
        this.userBaseProductCdList = [
          {
            text: this.$route.params.userBaseProductCd,
            value: this.$route.params.userBaseProductCd,
            name: this.$route.params.userBaseProductCd,
          },
        ];
        this.userBaseProductCd = this.$route.params.userBaseProductCd;
        this.baseProductHint = this.$route.params.userBaseProductCd;
        //戻るボタン有効
        this.backBtnstyle = this.backBtnstyleBk;
        this.checkFlg = true;
        //検索ボタン(ユーザー情報)
        this.getUserInfoList();
      }
      this.getCustomInfo();
      this.$watch();
    },

    /**
     * 削除ボタン押下時の処理（メッセージ)
     */
    checkDelete() {
      this.deleteList = [];
      this.retentionList = [];
      let userMakerSidList = [];
      for (var i = 0; i < this.linkInfoInputList.length; i++) {
        if (this.linkInfoInputList[i].linkCheck == true) {
          this.deleteList.push(this.linkInfoInputList[i]);
          userMakerSidList.push(this.linkInfoInputList[i].userMakerSid);
        } else {
          this.retentionList.push(this.linkInfoInputList[i]);
        }
      }

      if (this.deleteList.length == 0) {
        this.errorFlg = false;
        this.updateDialogMessage = true;
        this.backMessage = messsageUtil.getMessage("P-SUP-017_005_E");
        return;
      }

      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_delete"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.linkDelete;
    },
    /**
     * 登録ボタン押下時の処理（メッセージ)
     */
    openRegist() {
      this.confirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.linkRegist;
    },

    /**
     * 戻るボタン押下時の処理
     */
    getBack() {
      this.confirmDialog.message = messsageUtil.getMessage("P-RCV-004_003_W");
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.getBackOkClick;
    },
    /**
     * 戻り時処理
     */
    getBackOkClick() {
      this.$router.push({
        name: appConfig.MENU_ID.P_SUP_USER_ITEM_MASTER,
        params: {
          name: appConfig.MENU_ID.P_SUP_USER_MAKER_LINK,
          //基本品番
          userBaseProductCd: this.$route.params.userBaseProductCd,
          //ユーザー取引先SID
          userClientSid: this.$route.params.userClientSid,
          //検索条件-ユーザー取引先
          userSuppliersCdSerach: this.$route.params.userSuppliersCdSerach,
          //検索条件-基本品番
          userBaseProductCdSerach: this.$route.params.userBaseProductCdSerach,
          //検索条件-ユーザー品番
          userProductCdSerach: this.$route.params.userProductCdSerach,
          //ページャー
          page: this.$route.params.page,
          //ページング
          itemsPerPage: this.$route.params.itemsPerPage,
          //ソート
          sortItem: this.$route.params.sortItem,
        },
      });
    },

    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },

    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      this.userSuppliersSelected = "";
      this.clientSelected = "";
      this.supplierList = [];
      this.userBaseProductCd = "";
      this.userBaseProductCdList = [];
      this.makerProductCdSelected = "";
      this.makerProductCdList = [];

      this.userInfoInputList = []; // ユーザ情報一覧
      this.linkInfoInputList = []; // リンク情報一覧
      this.makerInfoInputList = []; // メーカ情報一覧
      this.clearFlg = true;

      // 初期化
      this.init(true);
    },

    /**
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * ユーザー取引先コードリストボックス取得処理
     */
    setSrhUserSupplier() {
      for (var i = 0; i < this.userSupplierList.length; i++) {
        if (this.userSupplierList[i].value == this.userSuppliersSelected) {
          return this.userSupplierList[i].name;
        }
      }
    },
    /**
     * 取引先コードリストボックス取得処理
     */
    setSrhClient() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.clientSelected) {
          return this.supplierList[i].name;
        }
      }
    },

    /**
     * 基本品番コードリストボックス取得処理
     */
    setSrhUserBaseProductCd() {
      for (var i = 0; i < this.userBaseProductCdList.length; i++) {
        if (this.userBaseProductCdList[i].value == this.userBaseProductCd) {
          return this.userBaseProductCdList[i].name;
        }
      }
    },
    /**
     * メーカー品番コードリストボックス取得処理
     */
    setSrhMakerProductCd() {
      for (var i = 0; i < this.makerProductCdList.length; i++) {
        if (this.makerProductCdList[i].value == this.makerProductCdSelected) {
          return this.makerProductCdList[i].name;
        }
      }
    },
  },

  mounted() {
    this.init();
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    searchClient(val) {
      if (val == null) {
        return;
      }
      if (val ?? false) {
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.selectHintClient) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          this.supplierList.length = 0;
          this.supplierList = [];
          this.clientSelected = "";
          return;
        }
        // 入力桁数が定義した数以上の場合検索処理
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // 選択した会社コードを取得

          const fromList = getParameter.getRelatedBizPartialMatch(
            appConfig.ISGETCLIENT.CLIENT,
            dateTimeHelper.convertUTC(this.arrivalScheduleDate),
            undefined,
            undefined,
            val,
            sessionStorage.getItem("sales_office_sid")
          );

          Promise.all([fromList])
            .then((result) => {
              // 画面の初期値を設定します。

              this.supplierList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.supplierList.length = 0;
          this.supplierList = [];
        }
      } else {
        this.supplierList.length = 0;
        this.supplierList = [];
        this.clientSelected = "";
      }
    },

    // 基本品番取得処理
    searchUserBaseProductCd(val) {
      if (val ?? false) {
        // ユーザー取引先が選択されてない場合、処理しない
        if (this.userSuppliersSelected == null || this.userSuppliersSelected == "") {
          return;
        }

        if (val == this.baseProductHint) {
          return;
        }

        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        // 入力桁数が定義した数以上の場合検索処理
        if (val.length >= appConfig.CNTCHARITEM) {
          // 基本品番を取得
          const fromList = getParameter.getUserItemBaseCd(this.userSuppliersSelected, val);
          Promise.all([fromList])
            .then((result) => {
              // 画面の初期値を設定します。
              this.userBaseProductCdList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.userBaseProductCdList.length = 0;
          this.userBaseProductCdList = [];
        }
      } else {
        this.userBaseProductCdList.length = 0;
        this.userBaseProductCdList = [];
        this.userBaseProductCd = "";
      }
    },

    // メーカー品番取得処理
    searchMakerProductCd(val) {
      if (val ?? false) {
        // メーカ取引先が選択されてない場合、処理しない
        if (this.clientSelected == null || this.clientSelected == "") {
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.makerProductHint) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        // 入力桁数が定義した数以上の場合検索処理
        if (val.length >= appConfig.CNTCHARITEM) {
          // API用
          const fromList = getParameter.getItemsBizAddName2(
            this.clientSelected,
            dateTimeHelper.convertUTC(),
            val
          );

          Promise.all([fromList])
            .then((result) => {
              // 画面の初期値を設定します。

              this.makerProductCdList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.makerProductCdList.length = 0;
          this.makerProductCdList = [];
        }
      } else {
        this.makerProductCdList.length = 0;
        this.makerProductCdList = [];
        this.makerProductCdSelected = "";
      }
    },

    dialog(val) {
      val || this.close();
    },

    page: function (newValue) {
      this.page = newValue;
      this.getApiList(false);
    },
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}
.serch-textbox-arrivalNo ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
  width: 153px;
}
#listData.v-data-table ::v-deep .v-data-table__wrapper {
  max-height: 500px;
  height: 100%;
}

#listData.v-data-table ::v-deep .v-data-table__wrapper {
  width: 100%;
  margin-right: 12px;
}
.textbox {
  width: 11.2rem;
}
.from_to {
  font-weight: bold;
}
.toatlNum {
  margin-left: 10px;
}
.span1 {
  width: 4.3rem;
}
.span2 {
  width: 8rem;
}
a {
  text-decoration: underline;
}

.cargos_btn ::v-deep .v-btn__content {
  color: #000 !important;
  font-weight: normal !important;
}
.v-btn.v-btn--has-bg.btn_search {
  width: 5.5rem;
}

.date-style {
  width: 11rem;
}
#listData ::v-deep th,
#listData ::v-deep td {
  padding: 0 6px;
}

// 検索結果表示件数部分
.display-search-info-label {
  width: 100%;
  margin-top: 20px;
  margin-left: 12px;
  margin-right: 12px;
  font-size: x-large;
  font-weight: bold;
}
</style>
