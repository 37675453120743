<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <!-- 新規登録時：displayFlg==1 -->
      <NavBar v-if="displayFlg == '1'" tittle="label.lbl_userProductMastaAdd" />
      <!-- 修正時：displayFlg == 2 -->
      <NavBar v-if="displayFlg == '2'" tittle="label.lbl_userProductMastaUpdateDelete" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <!-- 一行目スタート -->
          <!-- 登録画面-->
          <v-row v-if="displayFlg == '1'" class="search-row">
            <!-- ユーザー品番 -->
            <div class="search-textbox-remark">
              <v-text-field
                dense
                outlined
                v-model="itemCd"
                :label="$t('label.lbl_userProductCd')"
                persistent-hint
                clear-icon="mdi-close-circle"
                clearable
                class="txt-single"
                maxlength="50"
                counter="50"
                :rules="[rules.inputRequired]"
              >
              </v-text-field>
            </div>
            <span class="require">*</span>
          </v-row>

          <!-- 修正・削除時画面 -->
          <v-row v-if="displayFlg == '2'" class="search-row">
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- ユーザー品番 -->
            <div class="search-autocomplete" style="float: left">
              <v-text-field
                dense
                v-model="itemCd"
                :label="$t('label.lbl_userProductCd')"
                persistent-hint
                readonly
              >
              </v-text-field>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
          </v-row>

          <!-- ここから二行目 -->
          <v-row class="search-row">
            <!-- ユーザー品名 -->
            <div class="search-textbox-remark">
              <v-text-field
                outlined
                dense
                v-model="itemName"
                maxlength="500"
                counter="500"
                :label="$t('label.lbl_userProductCodeName')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :rules="[rules.inputRequired]"
              />
            </div>
            <span class="require">*</span>
          </v-row>
          <!-- 三行目 -->
          <v-row class="search-row">
            <!-- ユーザー品番付属 -->
            <div class="search-textbox-remark">
              <v-text-field
                outlined
                dense
                v-model="itemNameKana"
                maxlength="50"
                counter="50"
                :label="$t('label.lbl_userProductAccessoryCd')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
          </v-row>
          <!-- 四行目 -->
          <v-row class="search-row">
            <!-- ユーザー基本品番 -->
            <div class="search-textbox-remark">
              <v-text-field
                outlined
                dense
                v-model="itemNameShort"
                maxlength="50"
                counter="50"
                :label="$t('label.lbl_userBaseProductCd')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
          </v-row>
          <!-- 五行目 -->
          <v-row class="search-row">
            <!-- ユーザー名 -->
            <div class="search-textbox-remark">
              <v-text-field
                outlined
                dense
                v-model="actualWeight"
                maxlength="500"
                counter="500"
                :label="$t('label.lbl_userName')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :rules="[rules.inputRequired]"
              />
            </div>
            <span class="require">*</span>
          </v-row>

          <v-row>
            <div class="search-row-exeBtn">
              <!--ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 戻るボタン -->
                <v-btn color="primary" id="get-search" class="other-btn" @click="backPage">{{
                  $t("btn.btn_back")
                }}</v-btn>
                <!--修正登録ボタン-->
                <v-btn
                  v-if="displayFlg == 2"
                  color="primary"
                  id="btn-search"
                  class="post-btn"
                  @click="upadateAdd"
                  >{{ $t("btn.btn_fixinsert") }}</v-btn
                >
                <!--登録ボタン-->
                <v-btn
                  v-if="displayFlg == 1"
                  color="primary"
                  id="btn-search"
                  class="post-btn"
                  @click="upadateAdd"
                  >{{ $t("btn.btn_insert") }}</v-btn
                >
              </div>
            </div>
          </v-row>
          <!-- 戻るボタンを押下時出現ダイアログ -->
          <v-dialog v-model="updateDialogMessage" :max-width="800">
            <v-card>
              <v-card-title class="blue-grey lighten-3" primary-title>
                {{ $t("btn.btn_ok") }}
              </v-card-title>
              <v-card-text class="pa-4">
                <p>{{ backMessage }}</p>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="updateOk"> OK </v-btn>
                <v-btn @click="updateNg"> {{ $t("btn.btn_cancel") }} </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </v-form>

      <!-- 商品詳細情報登録画面ポップアップ -->
      <v-dialog v-model="openDatailsAdd" :max-width="1000">
        <v-card>
          <v-card-title id="sub-bar">
            <span id="lbl-screen-name">{{ $t("label.lbl_itemDetailAdd") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-form ref="detailPopup" lazy-validation>
                <!-- １行目 -->
                <v-row class="search-row">
                  <!-- 入庫単位数 -->
                  <div class="search-autocomplete first-search-item">
                    <v-text-field
                      dense
                      outlined
                      v-model="entUnitCnt"
                      :items="accKeysVal"
                      :label="$t('label.lbl_EntUnitCnt')"
                      class="txt-single input-number"
                      persistent-hint
                      @click="clickPop(entUnitCnt)"
                      @change="commaEntCnt"
                      clear-icon="mdi-close-circle"
                      :rules="[
                        rules.inputRequired,
                        rules.isNumber,
                        rules.isInteger,
                        rules.isRangeCheck0,
                        rules.limitNumLength,
                      ]"
                      clearable
                    ></v-text-field>
                  </div>
                  <span class="require">*</span>
                  <!-- 空白 -->
                  <span class="item-spacer">&nbsp;</span>
                  <!-- 入庫単位 -->
                  <div class="search-autocomplete first-search-item">
                    <v-autocomplete
                      dense
                      :items="unitList"
                      v-model="entUnit"
                      :label="$t('label.lbl_EntUnit')"
                      :rules="[rules.inputRequired]"
                    >
                      <template slot="item" slot-scope="data">
                        <span class="auto-txt-size">
                          {{ data.item.text }}
                        </span>
                      </template>
                    </v-autocomplete>
                  </div>
                  <span class="require">*</span>
                  <!-- 空白 -->
                  <span class="item-spacer">&nbsp;</span>
                </v-row>
                <!-- ２行目 -->
                <v-row class="search-row">
                  <!-- 出庫単位数 -->
                  <div class="search-autocomplete first-search-item">
                    <v-text-field
                      dense
                      outlined
                      v-model="shipUnitCnt"
                      :items="accKeysVal"
                      :label="$t('label.lbl_ShipUnitCnt')"
                      class="txt-single input-number"
                      persistent-hint
                      clear-icon="mdi-close-circle"
                      @click="clickPop(shipUnitCnt)"
                      clearable
                      @change="commaShipCnt"
                      :rules="[
                        rules.inputRequired,
                        rules.isNumber,
                        rules.isInteger,
                        rules.isRangeCheck0,
                        rules.limitNumLength,
                      ]"
                    ></v-text-field>
                  </div>
                  <span class="require">*</span>
                  <!-- 空白 -->
                  <span class="item-spacer">&nbsp;</span>
                  <!-- 出庫単位 -->
                  <div class="search-autocomplete first-search-item">
                    <v-autocomplete
                      dense
                      :items="unitList"
                      v-model="shipUnit"
                      :label="$t('label.lbl_ShipUnit')"
                      :rules="[rules.inputRequired]"
                    >
                      <template slot="item" slot-scope="data">
                        <span class="auto-txt-size">
                          {{ data.item.text }}
                        </span>
                      </template>
                    </v-autocomplete>
                  </div>
                  <span class="require">*</span>
                </v-row>

                <!-- ３行目 -->
                <v-row class="search-row">
                  <!-- 保管単位数 -->
                  <div class="search-autocomplete first-search-item">
                    <v-text-field
                      dense
                      outlined
                      v-model="stoUnitCnt"
                      :items="accKeysVal"
                      :label="$t('label.lbl_StorageUnitCnt')"
                      class="txt-single input-number"
                      persistent-hint
                      @click="clickPop(stoUnitCnt)"
                      @change="commaStoCnt"
                      clear-icon="mdi-close-circle"
                      clearable
                      :rules="[
                        rules.isNumber,
                        rules.limitNumLength,
                        rules.isRangeCheck0,
                        rules.inputUnitCheck(stoUnitCnt, stoUnit),
                      ]"
                      ref="stoUnitCnt"
                    ></v-text-field>
                  </div>
                  <!-- 空白 -->
                  <span class="item-spacer">&nbsp;</span>
                  <!-- 保管単位 -->
                  <div class="search-autocomplete first-search-item">
                    <v-autocomplete
                      dense
                      :items="unitList"
                      v-model="stoUnit"
                      persistent-hint
                      :label="$t('label.lbl_StorageUnit')"
                      :rules="[rules.inputUnitCheck(stoUnit, stoUnitCnt)]"
                    >
                      <template slot="item" slot-scope="data">
                        <span class="auto-txt-size">
                          {{ data.item.text }}
                        </span>
                      </template>
                    </v-autocomplete>
                  </div>
                  <!-- 空白 -->
                  <span class="item-spacer">&nbsp;</span>
                </v-row>

                <!-- 4行目 -->
                <v-row class="search-row">
                  <!-- 棚卸単位数 -->
                  <div class="search-autocomplete first-search-item">
                    <v-text-field
                      dense
                      outlined
                      clear-icon="mdi-close-circle"
                      clearable
                      v-model="invUnitCnt"
                      :items="accKeysVal"
                      :label="$t('label.lbl_InventUnitCnt')"
                      class="txt-single input-number"
                      persistent-hint
                      @click="clickPop(invUnitCnt)"
                      @change="commaInvCnt"
                      :rules="[
                        rules.isNumber,
                        rules.limitNumLength,
                        rules.isRangeCheck0,
                        rules.inputUnitCheck(invUnitCnt, inventUnit),
                      ]"
                      ref="invUnitCnt"
                    >
                    </v-text-field>
                  </div>
                  <!-- 空白 -->
                  <span class="item-spacer">&nbsp;</span>
                  <!-- 棚卸単位 -->
                  <div class="search-autocomplete first-search-item">
                    <v-autocomplete
                      dense
                      :items="unitList"
                      v-model="inventUnit"
                      :label="$t('label.lbl_InventUnit')"
                      :rules="[rules.inputUnitCheck(inventUnit, invUnitCnt)]"
                    >
                      <template slot="item" slot-scope="data">
                        <span class="auto-txt-size">
                          {{ data.item.text }}
                        </span>
                      </template>
                    </v-autocomplete>
                  </div>
                </v-row>

                <!-- 5行目 -->
                <v-row class="search-row">
                  <!-- 入荷荷姿数 -->
                  <div class="search-autocomplete first-search-item">
                    <v-text-field
                      dense
                      outlined
                      v-model="inPackingQuantity"
                      :items="accKeysVal"
                      :label="$t('label.lbl_recievePackingCnt')"
                      class="txt-single input-number"
                      @click="clickPop(inPackingQuantity)"
                      @change="commaPackingCnt"
                      clear-icon="mdi-close-circle"
                      clearable
                      :rules="[rules.isNumber, rules.limitNumLength, rules.isRangeCheck0]"
                      ref="inPackingQuantity"
                    />
                  </div>
                  <!-- 空白 -->
                  <span class="item-spacer">&nbsp;</span>

                  <!-- 製品特性区分 -->
                  <div class="search-autocomplete first-search-item">
                    <v-autocomplete
                      dense
                      v-model="itemsPropertiesDiv"
                      :items="productSpecificDivList"
                      :label="$t('label.lbl_productSpecificDiv')"
                      class="txt-single"
                      persistent-hint
                      :rules="[rules.inputRequired]"
                    >
                      <template slot="item" slot-scope="data">
                        <span class="auto-txt-size"> {{ data.item.text }} </span>
                      </template>
                    </v-autocomplete>
                  </div>
                  <span class="require">*</span>
                  <!-- 空白 -->
                  <span class="item-spacer">&nbsp;</span>
                  <!-- 単価Gr -->
                  <div class="search-autocomplete first-search-item">
                    <v-autocomplete
                      dense
                      v-model="unitPriceGroup"
                      :items="basePriceList"
                      :label="$t('label.lbl_unitPriceGroup')"
                      class="txt-single"
                      persistent-hint
                      :rules="[rules.inputRequired]"
                    >
                      <template slot="item" slot-scope="data">
                        <span class="auto-txt-size"> {{ data.item.text }} </span>
                      </template>
                    </v-autocomplete>
                  </div>
                  <span class="require">*</span>
                  <!-- 空白 -->
                  <span class="item-spacer">&nbsp;</span>
                  <!-- 品番代替コード -->
                  <div class="search-autocomplete first-search-item">
                    <v-text-field
                      dense
                      outlined
                      v-model="itemSurrogateCd"
                      maxlength="50"
                      counter="50"
                      :label="$t('label.lbl_itemAlternativeCd')"
                      class="txt-single"
                      clear-icon="mdi-close-circle"
                      clearable
                    />
                  </div>
                  <!-- 空白 -->
                  <span class="item-spacer">&nbsp;</span>
                  <!-- JANCD -->
                  <div class="search-autocomplete first-search-item">
                    <v-text-field
                      dense
                      outlined
                      v-model="janCd"
                      maxlength="20"
                      :label="$t('label.lbl_janCode')"
                      class="txt-single"
                      clear-icon="mdi-close-circle"
                      clearable
                    />
                  </div>
                  <!-- 空白 -->
                  <span class="item-spacer">&nbsp;</span>
                  <!-- ITFCD -->
                  <div class="search-autocomplete first-search-item">
                    <v-text-field
                      dense
                      outlined
                      v-model="itfCd"
                      maxlength="20"
                      :label="$t('label.lbl_itfCode')"
                      class="txt-single"
                      clear-icon="mdi-close-circle"
                      clearable
                    />
                  </div>
                  <!-- 空白 -->
                  <span class="item-spacer">&nbsp;</span>
                </v-row>
              </v-form>

              <v-row style="margin-bottom: 5px">
                <div class="search-row-exeBtn">
                  <div class="btn-area">
                    <!-- 閉じるボタン -->
                    <v-btn
                      color="primary"
                      id="btn-search"
                      class="other-btn"
                      @click="closeDetailsInfo()"
                    >
                      {{ $t("btn.btn_close") }}</v-btn
                    >
                    <!-- 登録ボタン -->
                    <v-btn
                      color="primary"
                      id="btn-search"
                      class="other-btn"
                      @click="addDetailsInfo()"
                      :disabled="disableBtn"
                    >
                      {{ $t("btn.btn_insert") }}</v-btn
                    >
                  </div>
                </div>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- 付帯情報登録画面 -->
      <!-- <v-dialog v-model="openItemAttachedFlg" :max-width="800">
        <v-card>
          <v-card-title id="sub-bar">
            <span id="lbl-screen-name">{{ $t("label.lbl_arrivalScheduleRegistDetail") }}</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="accForm">
              <v-container>
                <v-row>
                  <v-col class="text-h5"
                    >{{ $t("label.lbl_accessoryInfo") }}
                    <v-btn @click="addAccItem" text>
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row> -->
      <!-- 付帯情報入力箇所 -->
      <!-- <v-row>
                  <v-data-table
                    hide-default-header
                    :headers="accHeader"
                    :items="accList"
                    disable-filtering
                    disable-pagination
                    disable-sort
                    :hide-default-footer="true"
                  > -->
      <!-- 付帯情報：カテゴリー入力欄 -->
      <!-- <template v-slot:[`item.text`]="{ item }">
                      <v-combobox
                        v-model="item.text"
                        maxlength="20"
                        :items="accKeys"
                        items-text="text"
                        outlined
                        dense
                        clear-icon="mdi-close-circle"
                        clearable
                        :rules="[rules.inputRequired]"
                      >
                      </v-combobox>
                      <v-text-field v-show="false" v-model="item.text"></v-text-field>
                    </template> -->
      <!-- 付帯情報：値入力欄 -->
      <!-- <template v-slot:[`item.value`]="{ item }">
                      <v-combobox
                        outlined
                        dense
                        :items="accKeysVal"
                        v-model="item.value"
                        items-text="value"
                        maxlength="20"
                        clear-icon="mdi-close-circle"
                        clearable
                        :rules="[rules.inputRequired]"
                      />
                    </template> -->
      <!-- 付帯情報：行削除 -->
      <!-- <template v-slot:[`item.delete`]="{ item }">
                      <v-btn small @click="deleteAccItem(item)" text>
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text> -->
      <!-- <v-card-actions>
            <v-spacer></v-spacer> -->
      <!-- 閉じるボタン -->
      <!-- <v-btn color="primary" id="btn-search" class="other-btn" @click="closeItemAttached()"> -->
      <!-- {{ $t("btn.btn_close") }}</v-btn -->
      <!-- > -->
      <!-- 登録ボタン -->
      <!-- <v-btn
              color="primary"
              id="btn-search"
              class="other-btn"
              @click="add()"
              :disabled="disableBtn"
            >
              {{ $t("btn.btn_insert") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog> -->
      <!-- 設定ボタン押下後エラー時出現エラー -->
      <v-dialog v-model="copyDialogMsg" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ alertMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="okClicked"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :title="ConfirmDialog.title"
        :okAction="postApi"
        :redMessage="ConfirmDialog.redMessage"
        :screenFlag="ConfirmDialog.screenFlag"
        :changeFlag="ConfirmDialog.changeFlag"
      />
      <!-- 登録成功時ダイアログ -->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :userProductMstFlg="infoDialog.userProductMstFlg"
        :userProductMstAddFlg="infoDialog.userProductMstAddFlg"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
        :okAction="infoDialog.okAction"
      />
    </v-sheet>
  </div>
</template>

<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import ConfirmDialog from "../../components/ConfirmDialog";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { i18n } from "../../lang/lang.js";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";
export default {
  name: appConfig.SCREEN_ID.P_MST_008,
  components: {
    ConfirmDialog,
    Loading,
    SimpleDialog,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    suppliersSelectedName: "", //取引先名
    temporaryRegist: "", //仮登録
    detailsInfo: "", //詳細情報
    itemStandardSid: "", //商品SID
    searchFilter: [],
    // 取引先
    // suppliersSelected: "",
    //supplierList: [],
    // 品名
    itemName: "",
    // 品名略称
    itemNameShort: "",
    // カナ
    itemNameKana: "",
    // // 40品目区分リスト
    // item40DivList: [],
    // // 品番代替コード
    // itemSurrogateCd: "",
    // // 製品特性区分
    // itemsPropertiesDiv: "",
    // // 製品特性区分リスト
    // productSpecificDivList: [],
    // // 単価グループリスト
    // basePriceList: [],
    // // 単位リスト
    // unitList: [],
    // // 治具リスト
    // jigList: [],
    // // 基本単位
    // baseUnit: "",
    // // 詳細APIから取得する値
    // itemCd: "", //品番
    // updateDatetime: "", //更新日時
    // items40Sid: "", //40品目SID
    actualWeight: "", //実重量
    // itemCategoryDiv: "", //品目区分
    // validFrom: "", //有効開始日時（標準）
    // validTo: "", //有効終了日時（標準）
    // 配列のインデックス
    index: 0,
    editedIndex: "",
    // 編集制御フラグ
    disableEdit: false,
    // ダイアログ登録ボタン制御
    disableBtn: false,
    // 有効開始日時チェックエラーメッセージ
    dateStartCheckMsg: "",
    // 有効終了日時チェックエラーメッセージ
    dateEndCheckMsg: "",
    // ロットプルダウン
    lotPullList: [],
    // メニュー
    openMenu: null,
    // 詳細画面ポップアップ
    openDatailsAdd: "",
    // 商品付属登録情報ポップアップ
    openItemAttachedFlg: false,
    // API接続後表示メッセージ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      userProductMstFlg: false,
      firstPageFlag: false,
      outsideClickNotCloseFlg: false,
    },
    // 登録ボタン押下時確認メッセージ
    ConfirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      title: "",
    },
    // 表示切替(1:登録画面 2:修正・削除画面)
    displayFlg: "",
    // 一覧データ
    inputList: [],
    // 削除・修正用リスト
    deleteList: [],
    // カレンダーメニュー表示フラグ
    dateMenuTo: false,
    dateMenuFrom: false,
    // 有効開始日と有効終了日
    validEndDate: "",
    validStartDate: "",
    validEndDateCal: "",
    editedList: [],
    // コピー時生成するデータ
    defaultItem: {
      check: "",
      detail: "",
      validStartDate: "",
      validEndDate: "",
      lotCnt: "",
      jigCd: "",
      itemCapa: "",
      itemCapaUnit: "",
      invCapa: "",
      subItem: [], //付属情報POST用
      accInfos: "", //付属情報保存用
    },
    // 付帯情報名プルダウン
    accKeys: [],
    // 付帯情報値プルダウン
    accKeysVal: [],
    // 詳細情報登録画面（POPUP）入力項目
    entUnitCnt: "", //入庫単位数
    entUnit: "", //入庫単位
    shipUnitCnt: "", //出庫単位数
    shipUnit: "", //出庫単位
    stoUnitCnt: "", //保管単位数
    stoUnit: "", //保管単位
    invUnitCnt: "", //棚卸単位数
    inventUnit: "", //棚卸単位
    inPackingQuantity: "", //入荷荷姿数
    // JANコード
    janCd: "",
    // ITFコード
    itfCd: "",
    // 単価グループ
    unitPriceGroup: "",
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    // コピー時アラートダイアログ
    copyDialogMsg: false,
    alertMessage: "",
    warningCheckFlg: "0",
    // バリデーションルール
    rules: {
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      isNumber: (value) =>
        value == null ||
        !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
        i18n.tc("check.chk_inputNumber"),
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      isZenkakuKana: (value) =>
        value == null || !!value.match(/^[ァ-ヶー]*$/) || i18n.tc("check.chk_inputZenkakuKana"),
      isInteger: (value) =>
        Number.isInteger(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
        i18n.tc("check.chk_inputNotDecimal"),
      isRangeCheck: (value) => {
        if (value != null && value != undefined && value != "") {
          if (0 >= Number(commonUtil.zen2han(value).replaceAll(",", "")))
            return i18n.tc("check.chk_inputMoreOne");
        }
        return true;
      },
      isRangeCheck0: (value) => {
        if (value != null && value != undefined && value != "") {
          if (1 > Number(commonUtil.zen2han(value).replaceAll(",", "")))
            return i18n.tc("check.chk_inputMore");
        }
        return true;
      },

      limitNumLength: (value) =>
        value == null ||
        String(value).replaceAll(",", "").length < 10 ||
        i18n.tc("check.chk_limitNumLength"), // 文字数の制約
      inputNumber52: (value) => {
        let ar = String(value).replaceAll(",", "");
        if (value != undefined) {
          if (ar.includes(".")) {
            let numL = ar.split(".");
            if (numL[0].length > 5 || numL[1].length > 3)
              return i18n.tc("check.chk_inputNumber_5-3");
          } else {
            if (ar.length > 5) {
              return i18n.tc("check.chk_inputNumber_5-3");
            }
          }
        }
        return true;
      },
      inputNumber93: (value) => {
        let ar = String(value).replaceAll(",", "");
        if (value != undefined) {
          if (ar.includes(".")) {
            let numL = ar.split(".");
            if (numL[0].length > 9 || numL[1].length > 3)
              return i18n.tc("check.chk_inputNumber_9-3");
          } else {
            if (ar.length > 9) {
              return i18n.tc("check.chk_inputNumber_9-3");
            }
          }
        }
        return true;
      },
      inputUnitCheck: (value1, value2) => !value2 || !!value1 || i18n.tc("check.chk_input"),
      inputinventCheck: (value1, value2) => !value2 || !!value1 || i18n.tc("check.chk_input"),
    },
  }),
  methods: {
    init() {
      // 新規登録か修正・削除か判断
      this.displayFlg = this.$route.params.displayFlg;
      this.itemCd = this.$route.params.itemCd;
      this.itemName = this.$route.params.userProdustName;
      this.itemNameKana = this.$route.params.userProductAccessoryCd;
      this.itemNameShort = this.$route.params.userBaseProductCd;
      this.actualWeight = this.$route.params.userName;

      if (this.displayFlg == "1") {
        this.inputList.push({
          validStartDate: dateTimeHelper.convertJST().substr(0, 10),
          validEndDate: dateTimeHelper.convertUTC2JST("2099/12/31 14:59:59.999").substr(0, 10),
          lotCnt: "",
          jigCd: "",
          itemCapa: "",
          itemCapaUnit: "",
          invCapa: "",
          subItem: [], //付帯情報渡す用
          accInfos: "", //付帯情報表示用
          delete: "",
          inboundUnitQuantity: "",
          inboundUnit: "",
          inventorycountUnitQuantity: "",
          inventorycountUnit: "",
          outboundUnit: "",
          outboundUnitQuantity: "",
          storageUnit: "",
          storageUnitQuantity: "",
          inPackingQuantity: "",
        });
        // 前画面からのコピーがある場合
        if (this.$route.params.copyList) {
          // 取引先
          // this.suppliersSelected = this.$route.params.suppliersSelected;
          // ユーザー品番
          this.itemCd = this.$route.params.copyList[0].productCnCd;
          // ユーザー品名
          this.itemName = this.$route.params.copyList[0].productName;
          // ユーザー付属
          this.itemNameKana = this.$route.params.copyList[0].userProductAccessoryCd;
          // ユーザー基本品番
          this.itemNameShort = this.$route.params.copyList[0].userBaseProductCd;
          // ユーザー名
          this.actualWeight = this.$route.params.copyList[0].userName;
        }

        // テーブル１行目の明細の日付セット
        this.inputList[0].validStartDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertJST().substr(0, 10)
        );
        this.inputList[0].validEndDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST("2099/12/31 14:59:59.999".substr(0, 10))
        );
        // 標準有効開始日（裏で持っている値）
        this.validFrom = dateTimeHelper.convertJST();
        // 標準有効終了日（裏で持っている値）
        this.validTo = dateTimeHelper.convertUTC2JST("2099/12/31 14:59:59.999");
      } else if (this.displayFlg == "2") {
        // 検索条件を保存
        this.searchFilter = this.$route.params.searchFilter[0];

        // 前画面から送られた値をセット
        this.itemCd = this.$route.params.itemCd; //品番
        this.referenceDate = this.$route.params.referenceDate; //基準日
        this.temporaryRegist = this.$route.params.temporaryRegist; //仮登録
        this.detailsInfo = this.$route.params.detailsInfo; //詳細情報
        this.suppliersSelected = this.$route.params.supplier; //取引先
        this.itemStandardSid = this.$route.params.itemSid; //商品SID

        // 商品マスタ明細取得
      }
      this.getInitData();
    },
    getInitData() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC("")
      );
      const item40List = this.getItems40();
      const lotCnt = getParameter.getCodeMst(appConfig.CODETYPE.LOT_DIV);
      // 製品特性区分
      const productDiv = getParameter.getCodeMst(appConfig.CODETYPE.PRODUCTSPECIFIC_DIV);
      const jigLst = this.getJigCd();
      const unitMstList = this.getUnitMst();
      const unitGrMstList = this.getUnitGrMst();
      Promise.all([clientList, item40List, lotCnt, productDiv, jigLst, unitMstList, unitGrMstList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.supplierList = result[0];
          this.item40DivList = result[1];
          this.lotPullList = result[2];
          this.productSpecificDivList = result[3];
          this.jigList = result[4];
          this.unitList = result[5];
          this.basePriceList = result[6];
        })
        .then(() => {
          if (this.displayFlg == "2") {
            this.getItemDetails();
          }
        })
        .then(() => {
          for (var t = 0; t < this.unitList.length; t++) {
            if (this.baseUnit == this.unitList[t].text) {
              this.baseUnit = this.unitList[t].value;
            }
            for (var m = 0; m < this.inputList.length; m++) {
              if (this.inputList[m].inboundUnit == this.unitList[t].text) {
                this.inputList[m].inboundUnit = this.unitList[t].value;
              }
              if (this.inputList[m].outboundUnit == this.unitList[t].text) {
                this.inputList[m].outboundUnit = this.unitList[t].value;
              }
              if (this.inputList[m].inventorycountUnit == this.unitList[t].text) {
                this.inputList[m].inventorycountUnit = this.unitList[t].value;
              }
              if (this.inputList[m].storageUnit == this.unitList[t].text) {
                this.inputList[m].storageUnit = this.unitList[t].value;
              }
            }
          }
          // 修正・削除なら
          if (this.displayFlg == "2") {
            for (let i = 0; i < this.supplierList.length; i++) {
              if (this.suppliersSelected == this.supplierList[i].value) {
                this.suppliersSelectedName = this.supplierList[i].text;
              }
            }
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    okClicked() {
      this.copyDialogMsg = false;
    },
    // コピーボタン押下時処理
    makeCopy() {
      // ２行以上はコピーできないように制御するためチェックした行を確認
      var checkCnt = [];
      for (var i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].check) {
          checkCnt.push(this.inputList[i]);
        }
      }

      if (checkCnt.length == 0) {
        // コピー対象未選択
        this.copyDialogMsg = true;
        this.alertMessage = messsageUtil.getMessage("P-MST-006_002_E");
      } else if (checkCnt.length <= 1) {
        // 選択された行が２未満ならデータを複製する
        for (var m = 0; m < this.inputList.length; m++) {
          if (this.inputList[m].check) {
            this.defaultItem = {
              // 有効開始日
              validStartDate: this.inputList[m].validStartDate,
              // 有効終了日
              validEndDate: this.inputList[m].validEndDate,
              // 有効開始日カレンダー
              validStartDateCal: this.inputList[m].validStartDateCal,
              // 有効終了日カレンダー
              validEndDateCal: this.inputList[m].validEndDateCal,
              // ロット
              lotCnt: this.inputList[m].lotCnt,
              // 治具コード
              jigCd: this.inputList[m].jigCd,
              // 個体容量単位
              itemCapaUnit: this.inputList[m].itemCapaUnit,
              // 個体容量
              itemCapa: this.inputList[m].itemCapa,
              // 個体重量単位
              itemWeightUnit: this.inputList[m].itemWeightUnit,
              // 個体重量
              itemWeight: this.inputList[m].itemWeight,
              // 付帯情報
              subItem: this.inputList[m].subItem,
              // 付帯情報
              accInfos: this.inputList[m].accInfos,
              // 入庫単位数
              inboundUnitQuantity: this.inputList[m].inboundUnitQuantity,
              // 入庫単位
              inboundUnit: this.inputList[m].inboundUnit,
              // 出庫単位数
              outboundUnitQuantity: this.inputList[m].outboundUnitQuantity,
              // 出庫単位
              outboundUnit: this.inputList[m].outboundUnit,
              // 棚卸単位
              inventorycountUnit: this.inputList[m].inventorycountUnit,
              // 棚卸単位数
              inventorycountUnitQuantity: this.inputList[m].inventorycountUnitQuantity,
              // 保管単位
              storageUnit: this.inputList[m].storageUnit,
              // 保管単位数
              storageUnitQuantity: this.inputList[m].storageUnitQuantity,
              // 入荷荷姿数
              inPackingQuantity: this.inputList[m].inPackingQuantity,
              // 製品特性区分
              itemsPropertiesDiv: this.inputList[m].itemsPropertiesDiv,
              // 単価グループ
              unitPriceGroup: this.inputList[m].unitPriceGroup,
              // JANコード
              janCd: this.inputList[m].janCd,
              // ITFコード
              itfCd: this.inputList[m].itfCd,
              // 品番代替コード
              itemSurrogateCd: this.inputList[m].itemSurrogateCd,
              // 削除フラグ
              deleteFlg: "0",
              // 編集制御フラグ
              disableEdit: false,
            };
            this.inputList.push(Object.assign({}, this.defaultItem));
            // チェック外す
            this.inputList[m].check = false;
          }
        }
      } else {
        // チェックが２行以上の場合は、アラートダイアログ
        this.copyDialogMsg = true;
        this.alertMessage = messsageUtil.getMessage("P-MST-001_001_E");
      }
    },
    // 登録ボタン押下時確認ダイアログ
    upadateAdd() {
      //エラー内容初期化処理--------------------------------------------------
      this.loadingCounter = 1;
      // for (var i = 0; i < this.inputList.length; i++) {
      //   this.inputList[i].dateEndCheckMsg = i18n.tc("");
      //   this.inputList[i].dateStartCheckMsg = i18n.tc("");
      // }
      this.loadingCounter = 0;
      // this.warningCheckFlg = "0";
      // //エラー内容初期化処理--------------------------------------------------
      // // エラーの数
      // let count = 0;
      // let timeDupe = 0;
      // let dateValid = 0;

      // 画面ヘッダー部の入力チェックOKだった場合
      if (this.$refs.form.validate()) {
        if (this.displayFlg == "1") {
          this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
        }
        if (this.displayFlg == "2") {
          this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-004_002_C");
        }
        this.ConfirmDialog.isOpen = true;
        this.ConfirmDialog.title = "確認";
        this.ConfirmDialog.screenFlag = true;
        this.ConfirmDialog.changeFlag = false;
      }
      // // 確認ダイアログOK押下時
      // if (this.$refs.form.validate()) {
      //   // errorメッセージ解除
      //   this.dateEndCheckMsg = "";
      //   this.dateStartCheckMsg = "";
      //   // ローディング画面表示ON
      //   this.loadingCounter = 1;
      // }

      //   //明細部の入力チェックOKだった場合
      //   if (this.$refs.editedList.validate()) {
      //     // 詳細画面入力チェック実施
      //     for (var a = 0; a < this.inputList.length; a++) {
      //       // 詳細画面の必須項目入力チェック
      //       if (
      //         !this.inputList[a].inboundUnit ||
      //         !this.inputList[a].inboundUnitQuantity ||
      //         !this.inputList[a].outboundUnit ||
      //         !this.inputList[a].outboundUnitQuantity ||
      //         !this.inputList[a].itemsPropertiesDiv ||
      //         !this.inputList[a].unitPriceGroup
      //       ) {
      //         //dialog表示
      //         this.copyDialogMsg = true;
      //         this.alertMessage = i18n.tc("check.chk_inputRequiredForm");
      //         return;
      //       }
      //       console.log(a);
      //       console.log(this.inputList[a].storageUnitQuantity);
      //       console.log(this.inputList[a].storageUnit);
      //       //保管単位数又は保管単位のチェック
      //       if (
      //         this.inputList[a].storageUnitQuantity != "" ||
      //         this.inputList[a].storageUnit != ""
      //       ) {
      //         if (
      //           this.inputList[a].storageUnitQuantity == "" &&
      //           this.inputList[a].storageUnit != ""
      //         ) {
      //           //dialog表示
      //           this.copyDialogMsg = true;
      //           this.alertMessage = i18n.tc("check.chk_inputUnitCnt2");
      //           return;
      //         }
      //         if (
      //           this.inputList[a].storageUnit == "" &&
      //           this.inputList[a].storageUnitQuantity != ""
      //         ) {
      //           //dialog表示
      //           this.copyDialogMsg = true;
      //           this.alertMessage = i18n.tc("check.chk_inputUnitCnt1");
      //           return;
      //         }
      //       }
      //       //棚卸単位数又は棚卸単位のチェック
      //       if (
      //         this.inputList[a].inventorycountUnitQuantity != "" ||
      //         this.inputList[a].inventorycountUnit != ""
      //       ) {
      //         if (
      //           this.inputList[a].inventorycountUnitQuantity == "" &&
      //           this.inputList[a].inventorycountUnit != ""
      //         ) {
      //           //dialog表示
      //           this.copyDialogMsg = true;
      //           this.alertMessage = i18n.tc("check.chk_inputinventCnt2");
      //           return;
      //         }
      //         if (
      //           this.inputList[a].inventorycountUnit == "" &&
      //           this.inputList[a].inventorycountUnitQuantity != ""
      //         ) {
      //           //dialog表示
      //           this.copyDialogMsg = true;
      //           this.alertMessage = i18n.tc("check.chk_inputinventCnt1");
      //           return;
      //         }
      //       }
      //     }

      //     // 明細行チェック
      //     for (var d = 0; d < this.inputList.length; d++) {
      //       if (
      //         // 有効終了日が有効開始日時よりも先日付であるかチェック
      //         new Date(this.inputList[d].validStartDate).getTime() >
      //         new Date(this.inputList[d].validEndDate).getTime()
      //       ) {
      //         this.inputList[d].checkFlg = true;
      //         this.inputList[d].dateEndCheckMsg = i18n.tc("check.chk_inputValiFrom");
      //         timeDupe++;
      //       } else if (d < this.inputList.length - 1) {
      //         //有効終了日時が次行の有効開始日時より未来の日付かチェック
      //         if (this.inputList[d].validEndDate >= this.inputList[d + 1].validStartDate) {
      //           // エラーメッセージを表示
      //           this.inputList[d].dateEndCheckMsg = i18n.tc("check.chk_dateComparison");
      //           this.inputList[d + 1].dateStartCheckMsg = i18n.tc("check.chk_dateComparison");

      //           timeDupe++;
      //           // }
      //         }
      //       }
      //     }

      //     if (timeDupe > 0) {
      //       return;
      //     }

      //     // 数値チェック、期間重複チェック問題なしなら確認ダイアログ
      //     if (count == 0 && timeDupe == 0 && dateValid == 0) {
      //       this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
      //       this.ConfirmDialog.isOpen = true;
      //       this.ConfirmDialog.title = "確認";
      //       this.ConfirmDialog.screenFlag = true;
      //       this.ConfirmDialog.changeFlag = false;
      //     } else {
      //       // if (this.$refs.editedList.validate()) {
      //       //   // 明細部、入力エラー時（カウント1）
      //       for (var j = 0; j < this.inputList.length; j++) {
      //         if (this.inputList[j].checkFlg) {
      //           // 関係ないレコードの入力チェックエラーを解除
      //           eval("this.$refs.validStartDate" + j + ".resetValidation()");
      //           eval("this.$refs.validEndDate" + j + ".resetValidation()");
      //         } else {
      //           this.copyDialogMsg = true;
      //           this.alertMessage = messsageUtil.getMessage("P-MST-001_002_E");
      //         }
      //         // }
      //       }
      //     }
      //   } else {
      //     // 明細行チェック
      //     for (var b = 0; b < this.inputList.length; b++) {
      //       if (!!this.inputList[b].validStartDate && !!this.inputList[b].validEndDate) {
      //         if (
      //           // 有効終了日が有効開始日時よりも先日付であるかチェック
      //           new Date(this.inputList[b].validStartDate).getTime() >
      //           new Date(this.inputList[b].validEndDate).getTime()
      //         ) {
      //           this.inputList[b].checkFlg = true;
      //           this.inputList[b].dateEndCheckMsg = i18n.tc("check.chk_inputValiFrom");
      //           timeDupe++;
      //         } else if (b < this.inputList.length - 1 && !!this.inputList[b + 1].validStartDate) {
      //           //有効終了日時が次行の有効開始日時より未来の日付かチェック
      //           if (this.inputList[b].validEndDate >= this.inputList[b + 1].validStartDate) {
      //             // エラーメッセージを表示
      //             this.inputList[b].dateEndCheckMsg = i18n.tc("check.chk_dateComparison");
      //             this.inputList[b + 1].dateStartCheckMsg = i18n.tc("check.chk_dateComparison");

      //             timeDupe++;
      //             // }
      //           }
      //         }
      //       }
      //     }

      //     if (timeDupe > 0) {
      //       return;
      //     }
      // }
      // }
    },
    // 確認ダイアログOK押下時
    postApi() {
      if (this.$refs.form.validate()) {
        // errorメッセージ解除
        this.dateEndCheckMsg = "";
        this.dateStartCheckMsg = "";
        // ローディング画面表示ON
        // this.loadingCounter = 1;
      }

      // const body = this.$httpClient.createRequestBodyConfig();

      // // **********必須パラメータ**********
      // body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_MST_008; //画面ID
      // body.reqIdv.clientSid = this.suppliersSelected; //取引先SID
      // body.reqIdv.itemCategoryDiv = "02"; //品目区分
      // body.reqIdv.items40Sid = this.items40Sid; //40品目SID（40品目区分）
      // body.reqIdv.itemName = this.itemName; //品名
      // body.reqIdv.updateDatetime = this.updateDatetime; //更新日時
      // body.reqIdv.itemCd = this.itemCd; //品番
      // body.reqIdv.itemStandardSid = this.itemStandardSid; //商品SID
      // body.reqIdv.baseUnit = this.baseUnit; //基本単位
      // body.reqIdv.actualWeight = String(this.actualWeight).replaceAll(",", ""); //実重量
      // body.reqIdv.deleteFlg = "0"; //削除フラグ
      // if (this.warningCheckFlg != "0") {
      //   body.reqIdv.warningCheckFlg = this.warningCheckFlg;
      // }
      // **********ここまで必須パラメータ**********

      // body.reqIdv.itemNameShort = this.itemNameShort; //略称
      // body.reqIdv.itemNameKana = this.itemNameKana; //品名カナ
      // body.reqIdv.itemSurrogateCd = this.itemSurrogateCd; // 品番代替コード

      // // post用
      // var itemCommon = [];

      // //明細最大値
      // let detail = this.inputList.length - 1;

      // /**
      //  * inputList = 画面に表示されるデータ
      //  * deleteList = ゴミ箱アイコンを押して削除したデータ
      //  * this.displayFlg == 2 商品マスタ修正・削除画面
      //  * this.displayFlg == 1 商品マスタ登録画面
      //  */
      // var lotAircraftnumberFlg = "";
      // // 修正時
      // if (this.displayFlg == 2) {
      //   //商品SID
      //   body.reqIdv.itemStandardSid = this.$route.params.itemSid;
      //   body.reqIdv.validFrom = dateTimeHelper.convertUTC(this.inputList[0].validStartDate);
      //   body.reqIdv.validTo = dateTimeHelper.convertUTC(
      //     this.inputList[detail].validEndDate + " 23:59:59.999"
      //   ); //有効終了日付
      //   if (this.deleteList.length > 0 && this.inputList.length > 0) {
      //     for (let i = 0; i < this.deleteList.length; i++) {
      //       // ロット有無フラグをポストように修正
      //       if (this.deleteList[i].lotCnt == "01") {
      //         lotAircraftnumberFlg = "0";
      //       } else {
      //         lotAircraftnumberFlg = "1";
      //       }
      //       let subItems = [];
      //       for (var k = 0; k < this.deleteList[i].subItem.length; k++) {
      //         subItems.push({
      //           subInformation: this.deleteList[i].subItem[k].text,
      //           subInformationComment: this.deleteList[i].subItem[k].value,
      //         });
      //       }
      //       if (this.deleteList[i].itemCommonSid) {
      //         itemCommon.push({
      //           itemCommonSid: this.deleteList[i].itemCommonSid
      //             ? this.deleteList[i].itemCommonSid
      //             : "", //★商品共通SID
      //           officeSid: this.deleteList[i].officeSid
      //             ? // コピーした場合officeSidがない為offiseSidを取得あする
      //               this.deleteList[i].officeSid
      //             : sessionStorage.getItem("sales_office_sid"), //営業所SID
      //           validFrom: dateTimeHelper.convertUTC(this.deleteList[i].validStartDate), //有効開始日付
      //           validTo: dateTimeHelper.convertUTC(
      //             this.deleteList[i].validEndDate + " 23:59:59.999"
      //           ), //有効終了日付
      //           inboundUnit: this.deleteList[i].inboundUnit, //入庫単位
      //           inboundUnitQuantity: this.deleteList[i].inboundUnitQuantity
      //             ? String(this.deleteList[i].inboundUnitQuantity).replaceAll(",", "")
      //             : null, //入庫単位数
      //           outboundUnit: this.deleteList[i].outboundUnit, //出庫単位
      //           outboundUnitQuantity: this.deleteList[i].outboundUnitQuantity
      //             ? String(this.deleteList[i].outboundUnitQuantity).replaceAll(",", "")
      //             : null, //出庫単位数
      //           storageUnit: this.deleteList[i].storageUnit, //保管単位
      //           storageUnitQuantity: this.deleteList[i].storageUnitQuantity
      //             ? String(this.deleteList[i].storageUnitQuantity).replaceAll(",", "")
      //             : null, //保管単位数
      //           inventorycountUnit: this.deleteList[i].inventorycountUnit, //棚卸単位
      //           inventorycountUnitQuantity: this.deleteList[i].inventorycountUnitQuantity
      //             ? String(this.deleteList[i].inventorycountUnitQuantity).replaceAll(",", "")
      //             : null, //棚卸単位数
      //           lotAircraftnumberFlg: lotAircraftnumberFlg, //ロット有無
      //           jigCd: this.deleteList[i].jigCd, //治具CD
      //           pieceCapacityQuantity: this.deleteList[i].itemCapa
      //             ? String(this.deleteList[i].itemCapa).replaceAll(",", "")
      //             : null, //個体容量
      //           pieceCapacityUnit: this.deleteList[i].itemCapaUnit, //個体容量単位
      //           pieceCapacityWeight: this.deleteList[i].itemWeight
      //             ? String(this.deleteList[i].itemWeight).replaceAll(",", "")
      //             : null, //個体重量, //個体重量
      //           pieceCapacityWeightUnit: this.deleteList[i].itemWeightUnit, //個体重量単位
      //           finalOutboundDate: this.deleteList[i].finalOutboundDate, //最終出庫日
      //           finalInboundDate: this.deleteList[i].finalInboundDate, //最終入庫日
      //           deleteFlg: this.deleteList[i].deleteFlg, //削除フラグ
      //           inPackingQuantity: this.deleteList[i].inPackingQuantity
      //             ? String(this.deleteList[i].inPackingQuantity).replaceAll(",", "")
      //             : null, //入荷荷姿数
      //           itemsPropertiesDiv: this.deleteList[i].itemsPropertiesDiv, //製品特性区分
      //           unitPriceGroup: this.deleteList[i].unitPriceGroup, //単価グループ
      //           janCd: this.deleteList[i].janCd, //JAN
      //           itfCd: this.deleteList[i].itfCd, //ITF
      //           itemSurrogateCd: this.deleteList[i].itemSurrogateCd, //品番代替コード
      //           updateDatetime: this.deleteList[i].updateDatetime
      //             ? this.deleteList[i].updateDatetime
      //             : "", //更新日時
      //           subItem: subItems,
      //         });
      //       }
      //     }
      //     for (let i = 0; i < this.inputList.length; i++) {
      //       // ロット有無フラグをポスト用に修正
      //       if (this.inputList[i].lotCnt == "01") {
      //         lotAircraftnumberFlg = "0";
      //       } else {
      //         lotAircraftnumberFlg = "1";
      //       }
      //       let subItems = [];
      //       for (var l = 0; l < this.inputList[i].subItem.length; l++) {
      //         subItems.push({
      //           subInformation: this.inputList[i].subItem[l].text,
      //           subInformationComment: this.inputList[i].subItem[l].value,
      //         });
      //       }
      //       itemCommon.push({
      //         itemCommonSid: this.inputList[i].itemCommonSid
      //           ? this.inputList[i].itemCommonSid
      //           : "", //★商品共通SID
      //         officeSid: this.inputList[i].officeSid
      //           ? // コピーした場合officeSidがない為offiseSidを取得あする
      //             this.inputList[i].officeSid
      //           : sessionStorage.getItem("sales_office_sid"), //営業所SID
      //         validFrom: dateTimeHelper.convertUTC(this.inputList[i].validStartDate), //有効開始日付
      //         validTo: dateTimeHelper.convertUTC(
      //           this.inputList[i].validEndDate + " 23:59:59.999"
      //         ), //有効終了日付
      //         inboundUnit: this.inputList[i].inboundUnit, //入庫単位
      //         inboundUnitQuantity: this.inputList[i].inboundUnitQuantity
      //           ? String(this.inputList[i].inboundUnitQuantity).replaceAll(",", "")
      //           : null, //入庫単位数
      //         outboundUnit: this.inputList[i].outboundUnit, //出庫単位
      //         outboundUnitQuantity: this.inputList[i].outboundUnitQuantity
      //           ? String(this.inputList[i].outboundUnitQuantity).replaceAll(",", "")
      //           : null, //出庫単位数
      //         storageUnit: this.inputList[i].storageUnit, //保管単位
      //         storageUnitQuantity: this.inputList[i].storageUnitQuantity
      //           ? String(this.inputList[i].storageUnitQuantity).replaceAll(",", "")
      //           : null, //保管単位数
      //         inventorycountUnit: this.inputList[i].inventorycountUnit, //棚卸単位
      //         inventorycountUnitQuantity: this.inputList[i].inventorycountUnitQuantity
      //           ? String(this.inputList[i].inventorycountUnitQuantity).replaceAll(",", "")
      //           : null, //棚卸単位数
      //         lotAircraftnumberFlg: lotAircraftnumberFlg, //ロット有無
      //         jigCd: this.inputList[i].jigCd, //治具CD
      //         pieceCapacityQuantity: this.inputList[i].itemCapa
      //           ? String(this.inputList[i].itemCapa).replaceAll(",", "")
      //           : null, //個体容量
      //         pieceCapacityUnit: this.inputList[i].itemCapaUnit, //個体容量単位
      //         pieceCapacityWeight: this.inputList[i].itemWeight
      //           ? String(this.inputList[i].itemWeight).replaceAll(",", "")
      //           : null, //個体重量, //個体重量
      //         pieceCapacityWeightUnit: this.inputList[i].itemWeightUnit, //個体重量単位
      //         finalOutboundDate: this.inputList[i].finalOutboundDate, //最終出庫日
      //         finalInboundDate: this.inputList[i].finalInboundDate, //最終入庫日
      //         deleteFlg: this.inputList[i].deleteFlg, //削除フラグ
      //         inPackingQuantity: this.inputList[i].inPackingQuantity
      //           ? String(this.inputList[i].inPackingQuantity).replaceAll(",", "")
      //           : null, //入荷荷姿数
      //         itemsPropertiesDiv: this.inputList[i].itemsPropertiesDiv, //製品特性区分
      //         unitPriceGroup: this.inputList[i].unitPriceGroup, //単価グループ
      //         janCd: this.inputList[i].janCd, //JAN
      //         itfCd: this.inputList[i].itfCd, //ITF
      //         itemSurrogateCd: this.inputList[i].itemSurrogateCd, //品番代替コード
      //         updateDatetime: this.inputList[i].updateDatetime
      //           ? this.inputList[i].updateDatetime
      //           : "", //更新日時
      //         subItem: subItems,
      //       });
      //     }
      //   } else if (this.inputList.length > 0) {
      //     for (let i = 0; i < this.inputList.length; i++) {
      //       // ロット有無フラグをポスト用に修正
      //       if (this.inputList[i].lotCnt == "01") {
      //         lotAircraftnumberFlg = "0";
      //       } else {
      //         lotAircraftnumberFlg = "1";
      //       }
      //       let subItems = [];
      //       for (var a = 0; l < this.inputList[i].subItem.length; a++) {
      //         subItems.push({
      //           subInformation: this.inputList[i].subItem[a].text,
      //           subInformationComment: this.inputList[i].subItem[a].value,
      //         });
      //       }
      //       itemCommon.push({
      //         itemCommonSid: this.inputList[i].itemCommonSid
      //           ? this.inputList[i].itemCommonSid
      //           : "", //★商品共通SID
      //         officeSid: this.inputList[i].officeSid
      //           ? // コピーした場合officeSidがない為offiseSidを取得あする
      //             this.inputList[i].officeSid
      //           : sessionStorage.getItem("sales_office_sid"), //営業所SID
      //         validFrom: dateTimeHelper.convertUTC(this.inputList[i].validStartDate), //有効開始日付
      //         validTo: dateTimeHelper.convertUTC(
      //           this.inputList[i].validEndDate + " 23:59:59.999"
      //         ), //有効終了日付
      //         inboundUnit: this.inputList[i].inboundUnit, //入庫単位
      //         inboundUnitQuantity: this.inputList[i].inboundUnitQuantity
      //           ? String(this.inputList[i].inboundUnitQuantity).replaceAll(",", "")
      //           : null, //入庫単位数
      //         outboundUnit: this.inputList[i].outboundUnit, //出庫単位
      //         outboundUnitQuantity: this.inputList[i].outboundUnitQuantity
      //           ? String(this.inputList[i].outboundUnitQuantity).replaceAll(",", "")
      //           : null, //出庫単位数
      //         storageUnit: this.inputList[i].storageUnit, //保管単位
      //         storageUnitQuantity: this.inputList[i].storageUnitQuantity
      //           ? String(this.inputList[i].storageUnitQuantity).replaceAll(",", "")
      //           : null, //保管単位数
      //         inventorycountUnit: this.inputList[i].inventorycountUnit, //棚卸単位
      //         inventorycountUnitQuantity: this.inputList[i].inventorycountUnitQuantity
      //           ? String(this.inputList[i].inventorycountUnitQuantity).replaceAll(",", "")
      //           : null, //棚卸単位数
      //         lotAircraftnumberFlg: lotAircraftnumberFlg, //ロット有無
      //         jigCd: this.inputList[i].jigCd, //治具CD
      //         pieceCapacityQuantity: this.inputList[i].itemCapa
      //           ? String(this.inputList[i].itemCapa).replaceAll(",", "")
      //           : null, //個体容量
      //         pieceCapacityUnit: this.inputList[i].itemCapaUnit, //個体容量単位
      //         pieceCapacityWeight: this.inputList[i].itemWeight
      //           ? String(this.inputList[i].itemWeight).replaceAll(",", "")
      //           : null, //個体重量, //個体重量
      //         pieceCapacityWeightUnit: this.inputList[i].itemWeightUnit, //個体重量単位
      //         finalOutboundDate: this.inputList[i].finalOutboundDate, //最終出庫日
      //         finalInboundDate: this.inputList[i].finalInboundDate, //最終入庫日
      //         deleteFlg: this.inputList[i].deleteFlg, //削除フラグ
      //         inPackingQuantity: this.inputList[i].inPackingQuantity
      //           ? String(this.inputList[i].inPackingQuantity).replaceAll(",", "")
      //           : null, //入荷荷姿数
      //         itemsPropertiesDiv: this.inputList[i].itemsPropertiesDiv, //製品特性区分
      //         unitPriceGroup: this.inputList[i].unitPriceGroup, //単価グループ
      //         janCd: this.inputList[i].janCd, //JAN
      //         itfCd: this.inputList[i].itfCd, //ITF
      //         itemSurrogateCd: this.inputList[i].itemSurrogateCd, //品番代替コード
      //         updateDatetime: this.inputList[i].updateDatetime
      //           ? this.inputList[i].updateDatetime
      //           : "", //更新日時
      //         subItem: subItems,
      //       });
      //     }
      //   }
      // } else {
      //   //有効開始日付：明細一行目のFromを登録
      //   body.reqIdv.validFrom = dateTimeHelper.convertUTC(this.inputList[0].validStartDate);
      //   body.reqIdv.validTo = dateTimeHelper.convertUTC(
      //     this.inputList[detail].validEndDate + " 23:59:59.999"
      //   ); //有効終了日付

      //   for (let i = 0; i < this.inputList.length; i++) {
      //     // ロット有無フラグをポスト用に修正
      //     if (this.inputList[i].lotCnt == "01") {
      //       lotAircraftnumberFlg = "0";
      //     } else {
      //       lotAircraftnumberFlg = "1";
      //     }
      //     let subItems = [];
      //     for (var b = 0; b < this.inputList[i].subItem.length; b++) {
      //       subItems.push({
      //         subInformation: this.inputList[i].subItem[b].text,
      //         subInformationComment: this.inputList[i].subItem[b].value,
      //       });
      //     }
      //     itemCommon.push({
      //       officeSid: sessionStorage.getItem("sales_office_sid"), //営業所SID
      //       validFrom: dateTimeHelper.convertUTC(this.inputList[i].validStartDate), //有効開始日付
      //       validTo: dateTimeHelper.convertUTC(this.inputList[i].validEndDate + " 23:59:59.999"), //有効終了日付
      //       inboundUnit: this.inputList[i].inboundUnit, //入庫単位
      //       inboundUnitQuantity: String(this.inputList[i].inboundUnitQuantity).replaceAll(
      //         ",",
      //         ""
      //       ), //入庫単位数
      //       outboundUnit: this.inputList[i].outboundUnit, //出庫単位
      //       outboundUnitQuantity: String(this.inputList[i].outboundUnitQuantity).replaceAll(
      //         ",",
      //         ""
      //       ), //出庫単位数
      //       storageUnit: this.inputList[i].storageUnit, //保管単位
      //       storageUnitQuantity: this.inputList[i].storageUnitQuantity
      //         ? String(this.inputList[i].storageUnitQuantity).replaceAll(",", "")
      //         : null, //保管単位数
      //       inventorycountUnit: this.inputList[i].inventorycountUnit, //棚卸単位
      //       inventorycountUnitQuantity: this.inputList[i].inventorycountUnitQuantity
      //         ? String(this.inputList[i].inventorycountUnitQuantity).replaceAll(",", "")
      //         : null, //棚卸単位数
      //       inPackingQuantity: this.inputList[i].inPackingQuantity
      //         ? String(this.inputList[i].inPackingQuantity).replaceAll(",", "")
      //         : null, //入荷荷姿数
      //       lotAircraftnumberFlg: lotAircraftnumberFlg, //ロット有無
      //       jigCd: this.inputList[i].jigCd, //治具CD
      //       pieceCapacityQuantity: this.inputList[i].itemCapa
      //         ? String(this.inputList[i].itemCapa).replaceAll(",", "")
      //         : null, //個体容量
      //       pieceCapacityUnit: this.inputList[i].itemCapaUnit, //個体容量単位
      //       pieceCapacityWeight: this.inputList[i].itemWeight
      //         ? String(this.inputList[i].itemWeight).replaceAll(",", "")
      //         : null, //個体重量
      //       pieceCapacityWeightUnit: this.inputList[i].itemWeightUnit, //個体重量単位
      //       // finalOutboundDate: this.inputList[i].finalOutboundDate, //最終出庫日
      //       // finalInboundDate: this.inputList[i].finalInboundDate, //最終入庫日
      //       itemsPropertiesDiv: this.inputList[i].itemsPropertiesDiv, //製品特性区分
      //       unitPriceGroup: this.inputList[i].unitPriceGroup, //単価グループ
      //       janCd: this.inputList[i].janCd, //JAN
      //       itfCd: this.inputList[i].itfCd, //ITF
      //       itemSurrogateCd: this.inputList[i].itemSurrogateCd, //品番代替コード
      //       deleteFlg: "0", //削除フラグ

      //       subItem: subItems,
      //     });
      //   }
      // }

      // body.reqIdv.itemCommon = itemCommon.slice();
      // // console.debug("postApi() Body", body);

      // return new Promise((resolve, reject) => {
      //   this.$httpClient
      //     // 接続先のAPI_URLを入力
      //     .securePost(appConfig.API_URL.MST_ITEMADD, body, appConfig.APP_CONFIG)
      //     .then((response) => {
      //       // console.debug("postApi() Response", response);
      //       const jsonData = JSON.parse(JSON.stringify(response.data));

      //       // 正常時
      //       if (jsonData.resCom.resComCode == "000") {
      this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
      this.infoDialog.title = "結果";
      this.infoDialog.isOpen = true;
      this.infoDialog.screenFlag = true;
      this.infoDialog.outsideClickNotCloseFlg = true;
      // 画面遷移ボタン

      this.infoDialog.firstPageFlag = false;
      this.infoDialog.userProductMstFlg = true;
      this.infoDialog.userProductMstAddFlg = true;
      this.infoDialog.okAction = this.clear;
      this.close();
      //         resolve(response);
      //         // エラー時
      //       }
      //       //janコードが別で使用されている場合
      //       else if (jsonData.resCom.resComCode == "004") {
      //         this.warningCheckFlg = "1";
      //         this.ConfirmDialog.message = jsonData.resCom.resComMessage;
      //         this.ConfirmDialog.isOpen = true;
      //         this.ConfirmDialog.title = "警告";
      //         this.ConfirmDialog.screenFlag = true;
      //         this.ConfirmDialog.changeFlag = false;

      //         reject();
      //       } else {
      //         this.infoDialog.message = jsonData.resCom.resComMessage;
      //         this.infoDialog.title = appConfig.DIALOG.title;
      //         this.infoDialog.isOpen = true;
      //         this.infoDialog.screenFlag = true;
      //         this.infoDialog.firstPageFlag = true;

      //         reject();
      //       }
      //     })
      //     .catch((ex) => {
      //       this.infoDialog.message = ex;
      //       this.infoDialog.title = appConfig.DIALOG.title;
      //       this.infoDialog.isOpen = true;
      //       this.infoDialog.screenFlag = true;
      //       this.infoDialog.firstPageFlag = true;
      //       reject();
      //     })
      //     .finally(() => {
      // ローディング画面表示OFF
      this.loadingCounter = 0;
      //  });
      //  });
      //  }
    },
    /**
     * 入力情報をクリア
     */
    clear() {
      this.itemCd = "";
      this.itemName = "";
      this.itemNameKana = "";
      this.itemNameShort = "";
      this.actualWeight = "";
      this.displayFlg = "1";
      this.$refs.form.resetValidation();
    },
    // 詳細情報登録画面をポップアップで開く
    // openDetailsAdd(item, index) {
    //   this.index = index;

    //   // 編集できないデータの場合登録ボタンの非活性化
    //   if (this.inputList[this.index].disableEdit) {
    //     this.disableBtn = true;
    //   } else {
    //     this.disableBtn = false;
    //   }
    //   // 入庫単位数
    //   this.entUnitCnt = this.inputList[this.index].inboundUnitQuantity;
    //   // 入庫単位
    //   this.entUnit = this.inputList[this.index].inboundUnit;
    //   // 棚卸単位数
    //   this.invUnitCnt = this.inputList[this.index].inventorycountUnitQuantity ?? "";
    //   // 棚卸単位
    //   this.inventUnit = this.inputList[this.index].inventorycountUnit ?? "";
    //   // 出庫単位
    //   this.shipUnit = this.inputList[this.index].outboundUnit;
    //   // 出庫単位数
    //   this.shipUnitCnt = this.inputList[this.index].outboundUnitQuantity;
    //   // 保管単位
    //   this.stoUnit = this.inputList[this.index].storageUnit ?? "";
    //   // 保管単位数
    //   this.stoUnitCnt = this.inputList[this.index].storageUnitQuantity ?? "";
    //   // 入荷荷姿数
    //   this.inPackingQuantity = this.inputList[this.index].inPackingQuantity ?? "";
    //   // 製品特性区分
    //   this.itemsPropertiesDiv = this.inputList[this.index].itemsPropertiesDiv;
    //   // 単価グループ
    //   this.unitPriceGroup = this.inputList[this.index].unitPriceGroup ?? "";
    //   // JANコード
    //   this.janCd = this.inputList[this.index].janCd;
    //   // ITFコード
    //   this.itfCd = this.inputList[this.index].itfCd;
    //   // 品番代替コード
    //   this.itemSurrogateCd = this.inputList[this.index].itemSurrogateCd;
    //   this.openDatailsAdd = true;
    // },
    // // 詳細情報登録画面：登録ボタン押下処理
    // addDetailsInfo() {
    //   // // 保管単位数
    //   // if (!this.stoUnitCnt) {
    //   //   eval("this.$refs.stoUnitCnt.resetValidation()");
    //   // }
    //   // // 棚卸単位数
    //   // if (!this.invUnitCnt) {
    //   //   eval("this.$refs.invUnitCnt.resetValidation()");
    //   // }
    //   // 入荷荷姿数
    //   // if (!this.inPackingQuantity) {
    //   //   eval("this.$refs.inPackingQuantity.resetValidation()");
    //   // }
    //   //
    //   // 必須項目が入力されていたら、inputListの該当行に渡す
    //   if (this.$refs.detailPopup.validate()) {
    //     //保管単位、棚卸単位のチェック処理

    //     // 入庫単位
    //     this.inputList[this.index].inboundUnit = this.entUnit;
    //     // 入庫単位数
    //     this.inputList[this.index].inboundUnitQuantity = this.entUnitCnt;
    //     // 棚卸単位数
    //     this.inputList[this.index].inventorycountUnitQuantity = this.invUnitCnt ?? "";
    //     // 棚卸単位
    //     this.inputList[this.index].inventorycountUnit = this.inventUnit ?? "";
    //     // 出庫単位
    //     this.inputList[this.index].outboundUnit = this.shipUnit;
    //     // 出庫単位数
    //     this.inputList[this.index].outboundUnitQuantity = this.shipUnitCnt;
    //     // 保管単位
    //     this.inputList[this.index].storageUnit = this.stoUnit ?? "";
    //     // 保管単位数
    //     this.inputList[this.index].storageUnitQuantity = this.stoUnitCnt ?? "";
    //     // 入荷荷姿数
    //     this.inputList[this.index].inPackingQuantity = this.inPackingQuantity;
    //     // 製品特性区分
    //     this.inputList[this.index].itemsPropertiesDiv = this.itemsPropertiesDiv;
    //     // 単価グループ
    //     this.inputList[this.index].unitPriceGroup = this.unitPriceGroup;
    //     // JANコード
    //     this.inputList[this.index].janCd = this.janCd;
    //     // ITFコード
    //     this.inputList[this.index].itfCd = this.itfCd;
    //     // 品番代替コード
    //     this.inputList[this.index].itemSurrogateCd = this.itemSurrogateCd;
    //     this.openDatailsAdd = false;
    //   }
    // },
    // // 詳細情報登録画面：ポップアップ閉じる
    // closeDetailsInfo() {
    //   this.openDatailsAdd = false;
    // },
    // // 付属情報登録画面POPUP：行追加処理
    // addAccItem() {
    //   this.accList.push({ text: "", value: "", required: "1" });
    // },
    // // 付属情報登録画面POPUP：行削除処理
    // deleteAccItem(item) {
    //   this.editedIndex = this.accList.indexOf(item);
    //   this.accList.splice(this.editedIndex, 1);
    // },
    // // 付属情報登録画面POPUP：登録ボタン押下
    // add() {
    //   this.inputList[this.index].subItem = Object.assign([], this.accList);

    //   this.openItemAttachedFlg = false;
    // },
    // 付属情報登録画面POPUP：ダイアログOPEN
    // openItemAttached(item, index) {
    //   this.accList = [];
    //   // マスタを取得する。
    //   if (item.subItem.length > 0) {
    //     item.subItem.forEach((row, index) => {
    //       if (row.required != "1") {
    //         row.slice(index, 1);
    //       }
    //     });
    //   }
    //   this.accList = Object.assign([], item.subItem);
    //   // 選択した明細のインデックスを取得
    //   this.index = index;

    //   // 編集できないデータの場合登録ボタンの非活性化
    //   if (this.inputList[this.index].disableEdit) {
    //     this.disableBtn = true;
    //   } else {
    //     this.disableBtn = false;
    //   }

    //   this.openItemAttachedFlg = true;
    // },
    // ポップアップを閉じる
    // closeItemAttached() {
    //   this.openItemAttachedFlg = false;
    // },
    // clickPop(val) {
    //   let myName = event.target;
    //   let pop = val;
    //   if (val != undefined && val != null && val.length > 3) {
    //     pop = val.replaceAll(",", "");
    //   }
    //   console.log(myName.value);
    //   if (pop != undefined) myName.value = pop;
    // },
    /**
     * 商品マスタ修正・削除：前画面の商品SIDをもとに明細取得
     */
    getItemDetails() {
      // ローディング画面表示ON
      const config = this.$httpClient.createGetApiRequestConfig();
      // 営業所SID
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      // 商品共通SID
      config.params.itemStandardSid = this.$route.params.itemSid;
      // 削除Flg
      config.params.deleteFlg = "0";

      // console.debug("getItemDetails() Config", config);
      var list = [];
      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_ITEMSEARCH_DETAILS, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("getItemDetails() Response", response);
            // 成功
            if (jsonData.resCom.resComCode == "000") {
              this.itemCd = jsonData.resIdv.itemsStandard.itemCd; //品番
              this.validFrom = dateTimeHelper.convertUTC2JST(
                jsonData.resIdv.itemsStandard.validFrom
              ); //有効開始日
              this.validTo = jsonData.resIdv.itemsStandard.validTo
                ? dateTimeHelper.convertUTC2JST(jsonData.resIdv.itemsStandard.validTo)
                : dateTimeHelper.convertUTC2JST("2099/12/31 14:59:59.999"); //有効終了日
              this.itemCategoryDiv = jsonData.resIdv.itemsStandard.itemCategoryDiv; //品目区分
              this.items40Sid = jsonData.resIdv.itemsStandard.items40Sid; //40品目SID
              this.itemName = jsonData.resIdv.itemsStandard.itemName; //品名
              this.itemNameShort = jsonData.resIdv.itemsStandard.itemNameShort; //略称
              this.itemNameKana = jsonData.resIdv.itemsStandard.itemNameKana; //カナ名
              this.updateDatetime = jsonData.resIdv.itemsStandard.updateDatetime; //更新日時
              this.baseUnit = jsonData.resIdv.itemsStandard.baseUnit ?? ""; //基本単位
              this.actualWeight = jsonData.resIdv.itemsStandard.actualWeight
                ? commonUtil.formatToCurrency(Number(jsonData.resIdv.itemsStandard.actualWeight))
                : ""; //実重量

              jsonData.resIdv.itemsStandard.itemCommons.forEach((row) => {
                list.push({
                  //商品共通SID
                  itemCommonSid: row.itemCommonSid,
                  //営業所SID
                  officeSid: row.officeSid,
                  //入庫単位
                  inboundUnit: row.inboundUnit,
                  //入庫単位数
                  inboundUnitQuantity: row.inboundUnitQuantity
                    ? commonUtil.formatToCurrency(Number(row.inboundUnitQuantity))
                    : "",
                  //出庫単位
                  outboundUnit: row.outboundUnit,
                  //出庫単位数
                  outboundUnitQuantity: row.outboundUnitQuantity
                    ? commonUtil.formatToCurrency(Number(row.outboundUnitQuantity))
                    : "",
                  // 保管単位
                  storageUnit: row.storageUnit ?? "",
                  // 保管単位数
                  storageUnitQuantity: row.storageUnitQuantity
                    ? commonUtil.formatToCurrency(Number(row.storageUnitQuantity))
                    : "",
                  // 棚卸単位
                  inventorycountUnit: row.inventorycountUnit ?? "",
                  // 棚卸単位数
                  inventorycountUnitQuantity: row.inventorycountUnitQuantity
                    ? commonUtil.formatToCurrency(Number(row.inventorycountUnitQuantity))
                    : "",
                  //有効開始日付
                  validStartDate: dateTimeHelper.convertUTC2JST(row.validFrom).substr(0, 10),
                  //有効終了日付
                  validEndDate: row.validTo
                    ? dateTimeHelper.convertUTC2JST(row.validTo).substr(0, 10)
                    : dateTimeHelper.convertUTC2JST("2099/12/31 14:59:59.999").substr(0, 10),
                  // 治具Cd
                  jigCd: row.jigCd,
                  // ロット有無
                  lotCnt: row.lotAircraftnumberFlg == "0" ? "01" : "02",
                  // 個体容量
                  itemCapa: row.pieceCapacityQuantity
                    ? commonUtil.formatToCurrency(Number(row.pieceCapacityQuantity))
                    : "",
                  // 個体容量単位
                  itemCapaUnit: row.pieceCapacityUnit,
                  // 個体重量
                  itemWeight: row.pieceCapacityWeight
                    ? commonUtil.formatToCurrency(Number(row.pieceCapacityWeight))
                    : "",
                  // 個体重量単位
                  itemWeightUnit: row.pieceCapacityWeightUnit,
                  // 入荷荷姿数
                  inPackingQuantity: row.inPackingQuantity
                    ? commonUtil.formatToCurrency(Number(row.inPackingQuantity))
                    : "",
                  // 製品特性区分
                  itemsPropertiesDiv: row.itemsPropertiesDiv == "0" ? "01" : row.itemsPropertiesDiv,
                  // 単価グループ
                  unitPriceGroup: row.unitPriceGroup ?? "",
                  // JANコード
                  janCd: row.janCd,
                  // ITFコード
                  itfCd: row.itfCd,
                  // 品番代替コード
                  itemSurrogateCd: row.itemSurrogateCd,
                  //更新日時
                  updateDatetime: row.updateDatetime,
                  //最終出庫日
                  finalOutboundDate: row.finalOutboundDate,
                  //最終入庫日
                  finalInboundDate: row.finalInboundDate,
                  deleteFlg: row.deleteFlg,
                  subItem: [],
                  accInfos: "",
                });
                resolve();
              });
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }
          })
          .then(() => {
            list.forEach((row) => {
              let itemSubList = getParameter.getItemIndividual(row.itemCommonSid);

              Promise.all([itemSubList]).then((result) => {
                let arrItemSub = [];
                let itemSub = [];
                // 画面の初期値を設定します。
                itemSub = result[0];
                itemSub.forEach((rows) => {
                  arrItemSub.push(rows.text + ":" + rows.value);
                });
                row.subItem = itemSub;
                row.accInfos = arrItemSub.toString();
              });
            });

            this.inputList = list;
            // 削除・更新用データ

            // 日付チェック
            this.checkValidDate();

            // カレンダーとテキストボックスを連動させる処理

            for (var t = 0; t < this.inputList.length; t++) {
              this.inputList[t].validStartDateCal = dateTimeHelper.dateCalc(
                dateTimeHelper.convertUTC2JST(this.inputList[t].validStartDate)
              );
              this.inputList[t].validEndDateCal = dateTimeHelper.dateCalc(
                dateTimeHelper.convertUTC2JST(this.inputList[t].validEndDate)
              );
            }
          })
          .catch((ex) => {
            console.error("getItemDetails() err", ex);
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          });
      });
    },
    // 単位マスタ取得
    getUnitMst() {
      // ローディング画面表示ON
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.isMst = "1";

      // console.debug("getUnitMst() Config", config);

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_UNIT, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("getUnitMst() Response", response);

            // 成功
            if (jsonData.resCom.resComCode == "000") {
              const list = [];

              jsonData.resIdv.unit.forEach((row) => {
                list.push({
                  text: row.unitCdName, //単位名
                  value: row.unitCd, //単位Cd
                  name: row.unitCdName, //単位名
                });
              });

              resolve(list);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          });
      });
    },

    // 単価グループマスタ
    getUnitGrMst() {
      // ローディング画面表示ON
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.isMst = "1";

      // console.debug("getUnitGrMst() Config", config);

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_UNITPRICE_GROUP, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("getUnitGrMst() Response", response);

            // // 成功
            if (jsonData.resCom.resComCode == "000") {
              const list = [];

              jsonData.resIdv.unitPriceGroup.forEach((row) => {
                list.push({
                  text: `${row.unitPriceGroup}（${row.unitPriceGroupName}）`, //単価グループ名
                  value: row.unitPriceGroup, //単位Cd
                  name: row.unitPriceGroupName, //単位名
                });
              });

              resolve(list);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          });
      });
    },
    // 治具コード取得
    getJigCd() {
      // ローディング画面表示ON
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");

      // console.debug("getJigCd() Config", config);

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_JIG, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("getJigCd() Response", response);

            // 成功
            if (jsonData.resCom.resComCode == "000") {
              const list = [];

              jsonData.resIdv.jigs.forEach((row) => {
                list.push({
                  text: row.jigName, //治具コード
                  value: row.jigCd,
                  name: row.jigName, //治具名
                });
              });

              resolve(list);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          });
      });
    },
    // 取引先取得
    getCustomInfo(jstInScheduleDate) {
      // ローディング画面表示ON
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(jstInScheduleDate)
      );
      Promise.all([clientList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.supplierList = result[0];

          // 修正・削除なら
          if (this.displayFlg == "2") {
            for (let i = 0; i < this.supplierList.length; i++) {
              if (this.suppliersSelected == this.supplierList[i].value) {
                this.suppliersSelectedName = this.supplierList[i].text;
              }
            }
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        });
    },
    // ４０品目区分API（GET）
    getItems40() {
      // ローディング画面表示ON
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.isMst = "0";
      // 営業所SID（ログインユーザ情報）
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");

      // console.debug("getItems40() Config", config);

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_40ITEMSEARCH, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("getItems40() Response", response);

            // 成功
            if (jsonData.resCom.resComCode == "000") {
              const list = [];

              jsonData.resIdv.items40.forEach((row) => {
                list.push({
                  text: row.items40Language[0].items40Name, //品名
                  value: row.items40Sid, // 商品SID
                  name: row.items40Language[0].items40Name,
                });
              });

              resolve(list);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          });
      });
    },
    /**
     * 商品マスタ修正・削除：有効期間が過去のものは修正できないように制御
     */
    checkValidDate() {
      var today = new Date();
      for (var t = 0; t < this.inputList.length; t++) {
        if (new Date(this.inputList[t].validEndDate).getTime() < today.getTime()) {
          this.inputList[t].disableEdit = true;
        } else {
          this.inputList[t].disableEdit = false;
        }
      }
    },
    // 戻るボタン押下：ダイアログ表示
    backPage() {
      this.updateDialogMessage = true;
      this.backMessage = messsageUtil.getMessage("P-RCV-004_003_W");
    },

    // 戻るボタンダイアログ：OK押下
    updateOk() {
      this.updateDialogMessage = false;

      // 一覧画面の遷移前の検索条件
      var searchFilter = this.searchFilter;
      // 一覧画面へ遷移
      if (this.displayFlg == "1") {
        this.$router.push({
          name: appConfig.SCREEN_ID.P_SUP_012,
          params: {
            seachSuppliersSelected: this.$route.params.seachSuppliersSelected,
            seachItemSelected: this.$route.params.seachItemSelected,
            seachTemporaryRegist: this.$route.params.seachTemporaryRegist,
            seachDetailsInfo: this.$route.params.seachDetailsInfo,
            searchFilter,
          },
        });
      } else {
        this.$router.push({
          name: appConfig.SCREEN_ID.P_SUP_012,
          params: {
            seachSuppliersSelected: this.$route.params.seachSuppliersSelected,
            seachItemSelected: this.$route.params.seachItemSelected,
            seachTemporaryRegist: this.$route.params.seachTemporaryRegist,
            seachDetailsInfo: this.$route.params.seachDetailsInfo,
            searchFilter,
            isTransition: true,
          },
        });
      }
    },
    // 戻るボタンダイアログ：NG押下
    updateNg() {
      this.updateDialogMessage = false;
    },

    /**
     * 行削除処理
     */
    deleteItem(item) {
      // 既に登録済みのもの
      if (this.inputList.length == 1) {
        this.infoDialog.title = "結果";
        this.infoDialog.message = messsageUtil.getMessage("P-MST-006_003_E");
        this.infoDialog.isOpen = true;
        this.infoDialog.screenFlag = true;
        this.infoDialog.firstPageFlag = true;
        return;
      }
      if (this.itemStandardSid) {
        this.editedIndex = this.inputList.indexOf(item);
        var deleteIndex = this.deleteList.length;
        // 削除したリストを退避する
        this.deleteList[deleteIndex] = this.inputList[this.editedIndex];
        this.deleteList[deleteIndex].deleteFlg = "1";
        this.inputList[this.editedIndex].deleteFlg = "1";
        // 画面上からは明細削除
        this.inputList.splice(this.editedIndex, 1);
      } else {
        this.editedIndex = this.inputList.indexOf(item);
        this.inputList.splice(this.editedIndex, 1);
      }
    },
    /**
     * 日付入力されたら修正
     */
    changeDateFrom(val, index) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }

      if (dateTimeHelper.validDate(strDt, "/")) {
        // 選択した行の有効開始日も更新
        this.inputList[index].validStartDate = strDt;
        this.inputList[index].validStartDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      }
    },
    changeDateTo(val, index) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        // 選択した行の有効終了日も更新
        this.inputList[index].validEndDate = strDt;
        this.inputList[index].validEndDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      }
    },
    /**
     * 日付テキストボックスとカレンダーの連動処理
     * カレンダーで選択した日付がテキストボックスに反映される
     */
    inputCallenderFrom(validStartDateCal, index) {
      this.inputList[index].validStartDate = this.formatDate(validStartDateCal);
      this.inputList[index].dateMenuFrom = false;
    },
    inputCallenderTo(validEndDateCal, index) {
      this.inputList[index].validEndDate = this.formatDate(validEndDateCal);
      this.inputList[index].dateMenuTo = false;
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    // 今日以前の日付を選択できないようにする
    allowedDate(val) {
      let today = new Date();
      today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      return today <= new Date(val);
    },
    // *********カンマ区切り処理*********
    // 入庫単位数
    commaEntCnt(val) {
      this.entUnitCnt = commonFunction.getDelimiter(val, 9);
    },
    // 出庫単位数
    commaShipCnt(val) {
      this.shipUnitCnt = commonFunction.getDelimiter(val, 9);
    },
    // 保管単位数
    commaStoCnt(val) {
      this.stoUnitCnt = commonFunction.getDelimiter(val, 9);
    },
    // 棚卸単位数
    commaInvCnt(val) {
      this.invUnitCnt = commonFunction.getDelimiter(val, 9);
    },
    // 入荷荷姿数
    commaPackingCnt(val) {
      this.inPackingQuantity = commonFunction.getDelimiter(val, 9);
    },
    // 個体容量
    commaItemCapa(val, index) {
      this.inputList[index].itemCapa = commonFunction.getDelimiterFew(val, 9, 3);
    },
    // 個体重量
    commaItemWeight(val, index) {
      this.inputList[index].itemWeight = commonFunction.getDelimiterFew(val, 9, 3);
    },
    // 実重量
    commaActualWeight(val) {
      this.actualWeight = commonFunction.getDelimiterFew(val, 5, 3);
    },
    // *********ここまでカンマ区切り処理*********
    // プルダウン値セット処理
    setItem40Div() {
      return commonFunction.getListSetName(this.item40DivList, this.items40Sid);
    },
    // 基本単位
    setBasicUnit() {
      return commonFunction.getListSetName(this.unitList, this.baseUnit);
    },
    // 取引先
    setSupplier() {
      return commonFunction.getListSetName(this.supplierList, this.suppliersSelected);
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    validStartDateCal(val) {
      this.validStartDate = this.formatDate(val);
    },
    validEndDateCal(val) {
      this.validEndDate = this.formatDate(val);
    },
    // accList: {
    //   handler() {
    //     let accInfo = [];

    //     this.accList.forEach((row) => {
    //       accInfo.push(row.text + ":" + row.value);
    //     });
    //     this.inputList[this.index].accInfos = accInfo.toString();
    //   },
    //   deep: true,
    // },
  },
  created() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.search-textbox-remark {
  width: 41.5rem;
  margin-left: 15px;
}
.calender {
  display: flex;
}
</style>
